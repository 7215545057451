import React, { ReactElement, useMemo } from 'react'
import { Info } from '@mui/icons-material'
import FlagIcon from '@mui/icons-material/Flag'
import { Alert, Button, Grid, Stack } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { PersonalFormLayout } from 'components/personal/PersonalFormLayout'
import { filterNotDeleted } from 'components/util/listUtil'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { PersonFormModel, ZielFormModel } from 'form/KundeFormModel'
import { createDefaultZielFormModel } from 'form/kundeFormModelCreator'
import { zielInitialTouched } from 'form/kundeFormTouched'
import { zielValidationSchema } from 'form/kundeFormValidationSchema'
import { risikoklasseOptions, zielArtOptions } from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { ZielArt } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'
import { useZiele } from 'state/useZiele'

type PersonalZieleFormProps = {
  selectiveFormProps?: SelectiveFormProps
}
export const PersonalZieleForm = ({
  selectiveFormProps,
}: PersonalZieleFormProps): ReactElement | null => {
  const { editableKunde, zieleMutator } = useEditableKunde()
  const { ziele } = useZiele(0)
  const mainPerson = editableKunde.personen[0]
  const notDeletedZieleCount = ziele.length
  const totalZieleCount = mainPerson.ziele.length
  if (notDeletedZieleCount === 0 && totalZieleCount === 0 && selectiveFormProps) {
    return null
  }
  return (
    <PersonalFormLayout title={'Ziele'}>
      <Grid container spacing={4} rowSpacing={6}>
        {notDeletedZieleCount === 0 && !selectiveFormProps && (
          <Grid item md={6} xs={12}>
            <Alert color={'info'} icon={<Info />} sx={{ mr: 3 }}>
              Keine Ziele vorhanden.
            </Alert>
          </Grid>
        )}
        <Grid item md={12} sm={12}>
          <ZielePersonContainer
            personIndex={0}
            person={mainPerson}
            selectiveFormProps={selectiveFormProps}
          />
        </Grid>
        {!selectiveFormProps && (
          <Grid item xs={12}>
            <Stack direction='row' spacing={2}>
              <Button
                onClick={() => zieleMutator.addZiel(0, createDefaultZielFormModel(true))}
                variant='outlined'
                startIcon={<FlagIcon />}
              >
                Ziel hinzufügen
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </PersonalFormLayout>
  )
}

type ZielPersonContainerProps = {
  person: PersonFormModel
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

const ZielePersonContainer = ({
  person,
  personIndex,
  selectiveFormProps,
}: ZielPersonContainerProps): ReactElement => {
  return (
    <Stack>
      {person.ziele.map((ziel, zielIndex) => (
        <ZielContainer
          key={zielIndex}
          personIndex={personIndex}
          ziel={ziel}
          zielIndex={zielIndex}
          selectiveFormProps={selectiveFormProps}
        />
      ))}
    </Stack>
  )
}

export const toZielName = (bezeichnung: string | undefined): string => {
  return bezeichnung || 'Unbekanntes Ziel'
}

type ZielContainerProps = {
  personIndex: number
  ziel: ZielFormModel
  zielIndex: number
  selectiveFormProps?: SelectiveFormProps
}

const ZielContainer = ({
  personIndex,
  zielIndex,
  selectiveFormProps,
}: ZielContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.ziele`
  const formPath = `${basePath}.${zielIndex}`
  const zielArtOptions = useZielArtOptions(personIndex)
  const { zieleMutator } = useEditableKunde()
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<ZielFormModel>(
    formPath,
    selectiveFormProps,
  )
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: ZielFormModel): void => {
    zieleMutator.removeZiel(personIndex, zielIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && (!selectiveFormProps || initialFormValues.isNew)) {
    return null
  }
  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(ziel) => zieleMutator.updateZiel(personIndex, zielIndex, ziel)}
      validationSchema={zielValidationSchema}
      initialTouched={zielInitialTouched}
      validateOnMount={true}
    >
      {(formikProps) => {
        const isZielImmobilienWunsch = formikProps.values.zielArt === 'IMMOBILIENWUNSCH'
        return (
          <ExpansionPanel
            title={formikProps.values.bezeichnung || 'Unbekanntes Ziel'}
            index={zielIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Ziel löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={formikProps.values.isDeleted}
            displayAsNew={selectiveFormProps && formikProps.values.isNew}
            displayValidationError={!formikProps.isValid}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextInput<ZielFormModel>
                      name={'bezeichnung'}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<ZielFormModel>
                      name={'zielkapital'}
                      label={'Zielkapital'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectInput<ZielFormModel>
                      name={'risikoklasse'}
                      label={'Risikoklasse'}
                      options={risikoklasseOptions}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectInput<ZielFormModel>
                      name={'zielArt'}
                      label={'Art'}
                      options={zielArtOptions}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <VersichertePersonSelect
                      name={'zugeordnetePersonen'}
                      label={'Zugeordnete Personen'}
                      selectiveFieldProps={selectiveFieldProps}
                      multiple
                      noKinder
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isZielImmobilienWunsch && (
                      <Alert color={'info'} icon={<Info />} sx={{ mr: 3 }}>
                        Dieses Ziel wird als Immobilienwunsch deklariert und kann daher keiner
                        Geldanlage zugeordnet werden. Es ist immer nur ein Immobilienwunsch möglich.
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <DateInput<ZielFormModel>
                      name={'zielzeitpunkt'}
                      label={'Termin'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<ZielFormModel>
                      name={'bemerkungen'}
                      label={'Bemerkungen'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<ZielFormModel>
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}

const useZielArtOptions = (personIndex: number): Option<ZielArt>[] => {
  const { personen } = useEditableKunde(({ editableKunde }) => editableKunde)
  return useMemo(() => {
    const existingImmoZiel = filterNotDeleted(personen[personIndex].ziele).find(
      (ziel) => ziel.zielArt === 'IMMOBILIENWUNSCH',
    )
    const isImmoZielAvailable = !existingImmoZiel
    return isImmoZielAvailable
      ? zielArtOptions
      : zielArtOptions.map((art) => ({ ...art, disabled: art.value === 'IMMOBILIENWUNSCH' }))
  }, [personen])
}
