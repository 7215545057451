import { nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { AnlegerprofilFormModel } from 'form/KundeFormModel'
import { createDefaultAnlegerprofil } from 'form/kundeFormModelCreator'
import { Anlegerprofil } from 'interfaces/mynorm-api-model-interfaces'

const defaultAnlegerprofil = createDefaultAnlegerprofil()

export const convertToAnlegerprofilFormModel = (
  anlegerprofil: Anlegerprofil | undefined,
): AnlegerprofilFormModel => {
  if (!anlegerprofil) {
    return defaultAnlegerprofil
  }

  return {
    finanzanlagen: {
      geldmarktfondsKenntnisse:
        valueOrDefault(
          anlegerprofil.finanzanlagen?.geldmarktfondsKenntnisse,
          defaultAnlegerprofil.finanzanlagen.geldmarktfondsKenntnisse,
        ) ?? false,
      geldmarktfondsAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.geldmarktfondsAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.geldmarktfondsAnzahl,
      ),
      geldmarktfondsUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.geldmarktfondsUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.geldmarktfondsUmsatz,
      ),
      rentenfondsAnleihenKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.rentenfondsAnleihenKenntnisse,
        defaultAnlegerprofil.finanzanlagen.rentenfondsAnleihenKenntnisse,
      ),
      rentenfondsAnleihenAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.rentenfondsAnleihenAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.rentenfondsAnleihenAnzahl,
      ),
      rentenfondsAnleihenUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.rentenfondsAnleihenUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.rentenfondsAnleihenUmsatz,
      ),
      fondsAktienKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.fondsAktienKenntnisse,
        defaultAnlegerprofil.finanzanlagen.fondsAktienKenntnisse,
      ),
      fondsAktienAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.fondsAktienAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.fondsAktienAnzahl,
      ),
      fondsAktienUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.fondsAktienUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.fondsAktienUmsatz,
      ),
      mischfondsKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.mischfondsKenntnisse,
        defaultAnlegerprofil.finanzanlagen.mischfondsKenntnisse,
      ),
      mischfondsAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.mischfondsAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.mischfondsAnzahl,
      ),
      mischfondsUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.mischfondsUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.mischfondsUmsatz,
      ),
      immobilienfondsKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.immobilienfondsKenntnisse,
        defaultAnlegerprofil.finanzanlagen.immobilienfondsKenntnisse,
      ),
      immobilienfondsAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.immobilienfondsAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.immobilienfondsAnzahl,
      ),
      immobilienfondsUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.immobilienfondsUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.immobilienfondsUmsatz,
      ),
      strukturierteWertpapierKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.strukturierteWertpapierKenntnisse,
        defaultAnlegerprofil.finanzanlagen.strukturierteWertpapierKenntnisse,
      ),
      strukturierteWertpapierAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.strukturierteWertpapierAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.strukturierteWertpapierAnzahl,
      ),
      strukturierteWertpapierUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.strukturierteWertpapierUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.strukturierteWertpapierUmsatz,
      ),
      terminGeschaefteKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.terminGeschaefteKenntnisse,
        defaultAnlegerprofil.finanzanlagen.terminGeschaefteKenntnisse,
      ),
      terminGeschaefteAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.terminGeschaefteAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.terminGeschaefteAnzahl,
      ),
      terminGeschaefteUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.terminGeschaefteUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.terminGeschaefteUmsatz,
      ),
      geschlosseneInvestmentvermoegenKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.geschlosseneInvestmentvermoegenKenntnisse,
        defaultAnlegerprofil.finanzanlagen.geschlosseneInvestmentvermoegenKenntnisse,
      ),
      geschlosseneInvestmentvermoegenAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.geschlosseneInvestmentvermoegenAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.geschlosseneInvestmentvermoegenAnzahl,
      ),
      geschlosseneInvestmentvermoegenUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.geschlosseneInvestmentvermoegenUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.geschlosseneInvestmentvermoegenUmsatz,
      ),
      sonstigeKenntnisse: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.sonstigeKenntnisse,
        defaultAnlegerprofil.finanzanlagen.sonstigeKenntnisse,
      ),
      sonstigeAnzahl: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.sonstigeAnzahl?.toString(),
        defaultAnlegerprofil.finanzanlagen.sonstigeAnzahl,
      ),
      sonstigeUmsatz: nullableValueOrDefault(
        anlegerprofil.finanzanlagen?.sonstigeUmsatz?.toString(),
        defaultAnlegerprofil.finanzanlagen.sonstigeUmsatz,
      ),
    },
    andereAnlagen: {
      sparTagesTermingeldKenntnisse: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sparTagesTermingeldKenntnisse,
        defaultAnlegerprofil.andereAnlagen.sparTagesTermingeldKenntnisse,
      ),
      sparTagesTermingeldAnzahl: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sparTagesTermingeldAnzahl?.toString(),
        defaultAnlegerprofil.andereAnlagen.sparTagesTermingeldAnzahl,
      ),
      sparTagesTermingeldUmsatz: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sparTagesTermingeldUmsatz?.toString(),
        defaultAnlegerprofil.andereAnlagen.sparTagesTermingeldUmsatz,
      ),
      immobilienKenntnisse: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.immobilienKenntnisse,
        defaultAnlegerprofil.andereAnlagen.immobilienKenntnisse,
      ),
      immobilienAnzahl: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.immobilienAnzahl?.toString(),
        defaultAnlegerprofil.andereAnlagen.immobilienAnzahl,
      ),
      immobilienUmsatz: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.immobilienUmsatz?.toString(),
        defaultAnlegerprofil.andereAnlagen.immobilienUmsatz,
      ),
      sonstigesKenntnisse: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sonstigesKenntnisse,
        defaultAnlegerprofil.andereAnlagen.sonstigesKenntnisse,
      ),
      sonstigesAnzahl: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sonstigesAnzahl?.toString(),
        defaultAnlegerprofil.andereAnlagen.sonstigesAnzahl,
      ),
      sonstigesUmsatz: nullableValueOrDefault(
        anlegerprofil.andereAnlagen?.sonstigesUmsatz?.toString(),
        defaultAnlegerprofil.andereAnlagen.sonstigesUmsatz,
      ),
    },
    kreditfinanzierteAnlagen: {
      finanzierungsArt: nullableValueOrDefault(
        anlegerprofil.kreditfinanzierteAnlagen?.finanzierungsArt,
        defaultAnlegerprofil.kreditfinanzierteAnlagen.finanzierungsArt,
      ),
      kreditVolumen: nullableValueOrDefault(
        anlegerprofil.kreditfinanzierteAnlagen?.kreditVolumen?.toString(),
        defaultAnlegerprofil.kreditfinanzierteAnlagen.kreditVolumen,
      ),
    },
    dienstleistungen: {
      beratungsfreieOrderausfuehrung: nullableValueOrDefault(
        anlegerprofil.dienstleistungen?.beratungsfreieOrderausfuehrung,
        defaultAnlegerprofil.dienstleistungen.beratungsfreieOrderausfuehrung,
      ),
      anlageberatung: nullableValueOrDefault(
        anlegerprofil.dienstleistungen?.anlageberatung,
        defaultAnlegerprofil.dienstleistungen.anlageberatung,
      ),
      vermoegensverwaltung: nullableValueOrDefault(
        anlegerprofil.dienstleistungen?.vermoegensverwaltung,
        defaultAnlegerprofil.dienstleistungen.vermoegensverwaltung,
      ),
    },
  }
}
