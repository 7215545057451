import React, { ReactElement } from 'react'
import { Formik, FormikProps } from 'formik'

import { useCreateVertrag } from 'api/useCreateVertrag'
import { PaginatedFormikDialog } from 'components/personal/absicherung2/dialog/PagniatedFormikDialog'
import { VertragFormPage1 } from 'components/personal/absicherung2/dialog/VertragFormPage1'
import { VertragFormPage2 } from 'components/personal/absicherung2/dialog/VertragFormPage2'
import { convertToCreateVertrag } from 'form/vertraege/VertragConverter'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'
import { createDefaultVertragFormModel } from 'form/vertraege/VertragFormModelCreator'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { CreateVertrag } from 'interfaces/mynorm-api-model-interfaces'
import { useImmobilien } from 'state/useImmobilien'
import { useSachwerte } from 'state/useSachwerte'

const ERROR_MESSAGE = 'Beim Speichern des Vertrages ist ein Fehler aufgetreten.'
const SUCCESS_MESSAGE = 'Der Vertrag wurde erfolgreich gespeichert.'
const DIALOG_BUTTON = 'Vertrag erstellen'
const DIALOG_TITLE = 'Vertrag erstellen'

export const CreateVertragFormDialog = (): ReactElement => {
  const { mutate: createVertrag, ...mutationResult } = useCreateVertrag()
  const { latestHaushalt } = useHaushaltState()
  const { immobilien } = useImmobilien()
  const { sachwerte } = useSachwerte()

  const handleSubmit = (formModel: VertragFormModel): void => {
    const vertrag = convertVertrag(formModel)
    if (vertrag === undefined) {
      return
    }
    createVertrag(vertrag)
  }

  const convertVertrag = (formModel: VertragFormModel): CreateVertrag | undefined => {
    const kundeId = latestHaushalt?.personen[0].person.meta.finfireId
    if (!kundeId) {
      return undefined
    }
    return convertToCreateVertrag(formModel, kundeId, immobilien, sachwerte)
  }
  const printConsoleAction = (formProps: FormikProps<VertragFormModel>): void => {
    const vertrag = convertVertrag(formProps.values)
    // eslint-disable-next-line no-console
    console.log(vertrag)
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(vertrag))
  }

  return (
    <Formik initialValues={createDefaultVertragFormModel()} onSubmit={handleSubmit}>
      {(props: FormikProps<VertragFormModel>) => {
        const page1 = <VertragFormPage1 formProps={props} />
        const page2 = <VertragFormPage2 formProps={props} />
        return (
          <PaginatedFormikDialog
            formProps={props}
            pages={[page1, page2]}
            title={DIALOG_TITLE}
            dialogButtonText={DIALOG_BUTTON}
            onReset={() => props.resetForm({ values: createDefaultVertragFormModel() })}
            mutationState={mutationResult}
            errorMessage={ERROR_MESSAGE}
            successMessage={SUCCESS_MESSAGE}
            printConsoleAction={() => printConsoleAction(props)}
          />
        )
      }}
    </Formik>
  )
}
