import React, { ReactElement, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Login2FAForm } from 'components/login-form/Login2FAForm'
import { LoginUserPassForm } from 'components/login-form/LoginUserPassForm'

type LoginFormPage = 'login' | '2fa'

export type LoginFormProps = {
  recentlyLoggedOut?: boolean
  onSubmit?: () => void
  onSuccessfulLogin?: () => void
}

export const LoginForm = ({
  recentlyLoggedOut,
  onSuccessfulLogin,
  onSubmit,
}: LoginFormProps): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState<LoginFormPage>(() =>
    searchParams.get('2faHash') ? '2fa' : 'login',
  )

  const on2FARequired = (hash: string): void => {
    setSearchParams({ '2faHash': hash })
    setPage('2fa')
  }

  return (
    <>
      {page === 'login' && (
        <LoginUserPassForm
          onSuccessfulLogin={onSuccessfulLogin}
          onSubmit={onSubmit}
          on2FARequired={on2FARequired}
          recentlyLoggedOut={recentlyLoggedOut}
        />
      )}
      {page === '2fa' && (
        <Login2FAForm
          onSuccessfulLogin={onSuccessfulLogin}
          twoFactorHash={searchParams.get('2faHash')}
        />
      )}
    </>
  )
}
