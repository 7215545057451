import { compact } from 'lodash'

import { Deletable } from 'form/haushalt-converter/person-converter/menschConverter'
import { Addable, Identifiable } from 'form/KundeFormModel'
import { RecursivePartial } from 'utils/typeUtils'

export const mapList = <F extends Identifiable & Addable, S extends Deletable & Identifiable>(
  formModels: F[],
  sources: S[] | undefined,
  mapper: (formModel: F, source: S | undefined) => RecursivePartial<S & Deletable>,
): Array<RecursivePartial<S & Deletable>> => {
  const existing = (sources || []).map((source) => {
    const formModel = compact(formModels).find((formModel) => getId(formModel) === getId(source))
    if (!formModel || formModel.isDeleted) {
      return {
        ...source,
        deleteFlag: true,
      } as RecursivePartial<S>
    }
    return mapper(formModel, source)
  })
  const newAdded = compact(formModels)
    .filter(
      (formModel) =>
        formModel.isNew && !existing.find((e) => getId(e as Identifiable) === getId(formModel)),
    )
    .map((formModel) => mapper(formModel, undefined))

  return [...existing, ...newAdded]
}

const getId = (identifiable: Identifiable): string | undefined => {
  return identifiable?.meta?.myviId
}
