import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { FormikProps } from 'formik'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import {
  GeldAnlageFormModel,
  ImmobilieFormModel,
  KindFormModel,
  ZielFormModel,
} from 'form/KundeFormModel'
import { geldAnlageVerwendungszweckTypOptions, objektArtOptions } from 'form/kundeOptions'
import { GeldanlageZielArt } from 'interfaces/mynorm-api-model-interfaces'
import { useImmobilien } from 'state/useImmobilien'
import { useKinder } from 'state/useKinder'
import { useZiele } from 'state/useZiele'

type VerwendungszweckContainerProps = {
  personIndex: number
  formikProps: FormikProps<GeldAnlageFormModel>
  selectiveFieldProps?: SelectiveInputProps
}

export const VerwendungszweckContainer = ({
  personIndex,
  formikProps,
  selectiveFieldProps,
}: VerwendungszweckContainerProps): ReactElement => {
  const { kinder } = useKinder()
  const { ziele } = useZiele(personIndex)
  const { immobilien } = useImmobilien(personIndex)
  const isForZiel = formikProps.values.geldanlageZielArt === 'ZIEL'
  const isForInstandhaltung = formikProps.values.geldanlageZielArt === 'INSTANDHALTUNGSRUECKLAGE'
  const isForAusbildungssparen = formikProps.values.geldanlageZielArt === 'AUSBILDUNGSSPAREN'

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SelectInput<GeldAnlageFormModel>
          name={'geldanlageZielArt'}
          label={'Verwendungszweck'}
          options={geldAnlageVerwendungszweckTypOptions.map((option) => ({
            ...option,
            disabled: isVerwendungszweckDisabled(option.value, ziele, kinder, immobilien),
          }))}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      {isForZiel && (
        <Grid item xs={12} md={6}>
          <SelectInput
            name={'zielId'}
            label={'Ziel'}
            options={ziele.filter(isNoImmobilienwunsch).map(toZielOption)}
            selectiveFieldProps={selectiveFieldProps}
          />
        </Grid>
      )}
      {isForInstandhaltung && (
        <Grid item xs={12} md={6}>
          <SelectInput
            name={'immobilieId'}
            label={'Immobilie'}
            options={immobilien.map(toImmobilieOption)}
            selectiveFieldProps={selectiveFieldProps}
          />
        </Grid>
      )}
      {isForAusbildungssparen && (
        <Grid item xs={12} md={6}>
          <SelectInput
            name={'kindId'}
            label={'Kind'}
            options={kinder.map(toKindOption)}
            selectiveFieldProps={selectiveFieldProps}
          />
        </Grid>
      )}
    </Grid>
  )
}

const isVerwendungszweckDisabled = (
  value: GeldanlageZielArt,
  ziele: ZielFormModel[],
  kinder: KindFormModel[],
  immobilien: ImmobilieFormModel[],
): boolean => {
  if (value === 'ZIEL' && ziele.length === 0) return true
  if (value === 'AUSBILDUNGSSPAREN' && kinder.length === 0) return true
  if (value === 'INSTANDHALTUNGSRUECKLAGE' && immobilien.length === 0) return true
  return false
}

const isNoImmobilienwunsch = (ziel: ZielFormModel): boolean => {
  return ziel.zielArt !== 'IMMOBILIENWUNSCH'
}

const toZielOption = (ziel: ZielFormModel): Option => ({
  value: ziel.meta.myviId,
  label: ziel.bezeichnung || 'Unbekanntes Ziel',
})

const toKindOption = (kind: KindFormModel): Option => ({
  value: kind.meta.finfireId || kind.meta.myviId,
  label: `${kind.vorname} ${kind.nachname}`,
})

const toImmobilieOption = (immobilie: ImmobilieFormModel): Option => {
  return {
    value: immobilie.meta.finfireId || immobilie.meta.myviId,
    label:
      (immobilie.bezeichnung ||
        objektArtOptions.find((objektArt) => objektArt.value === immobilie.objektArt)?.label) ??
      '',
  }
}
