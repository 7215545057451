import React, { ReactElement, useState } from 'react'
import { Check } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { UseMutationResult } from '@tanstack/react-query'
import { saveAs } from 'file-saver'

import { ReportRequest } from 'api/useCreateReportErweitertesKundendokument'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

type ReportRowProps = {
  name: string
  description?: string
  perPersonPossible?: boolean
  haushalt: HaushaltAggregate | undefined
  downloadMutation?: UseMutationResult<Blob, unknown, ReportRequest>
  uploadMutation?: UseMutationResult<void, unknown, ReportRequest>
}
export const ReportDownloadRow = ({
  name,
  description,
  perPersonPossible,
  haushalt,
  downloadMutation,
  uploadMutation,
}: ReportRowProps): ReactElement => {
  const [perPerson, setPerPerson] = useState(false)
  const isError = downloadMutation?.isError || uploadMutation?.isError
  const handleDownload = (): void => {
    if (!haushalt) return
    downloadMutation?.reset()
    const kundeName = extractFullName(haushalt.personen[0]?.person)
    const fileName = `${name}-${kundeName}.pdf`
    const request: ReportRequest = {
      haushalt: haushalt,
      perPerson: perPerson,
    }
    downloadMutation?.mutate(request, {
      onSuccess: (data) => {
        if (!data) return
        saveAs(data, fileName)
      },
    })
  }

  const handleUpload = (): void => {
    if (!haushalt) return
    uploadMutation?.reset()
    uploadMutation?.mutate({
      haushalt: haushalt,
      perPerson: perPerson,
    })
  }

  return (
    <Grid container p={1} bgcolor={(theme) => theme.palette.grey.A200}>
      <Grid item xs={5} container justifyContent='center' flexDirection='column'>
        <Typography variant='h6' color={(theme) => theme.palette.primary.main}>
          {name}
        </Typography>
        <Typography variant='caption'>{description}</Typography>
      </Grid>
      <Grid item xs={3} container alignItems='center'>
        {perPersonPossible && (
          <FormControl error={false}>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  color='secondary'
                  checked={perPerson}
                  onChange={() => setPerPerson((prevState) => !prevState)}
                />
              }
              label={'Personen aufgeteilt?'}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={4} container alignItems='center'>
        <Grid item xs={6} alignSelf={isError ? 'flex-end' : 'center'}>
          {downloadMutation && (
            <>
              <LoadingButton
                variant='outlined'
                size='small'
                onClick={() => handleDownload()}
                disabled={!haushalt}
                loading={downloadMutation.isLoading}
                startIcon={downloadMutation.isSuccess ? <Check color={'success'} /> : undefined}
                style={{ minWidth: '180px' }}
              >
                PDF Herunterladen
              </LoadingButton>
            </>
          )}
        </Grid>
        <Grid item xs={6} alignSelf={isError ? 'flex-end' : 'center'}>
          {uploadMutation && (
            <LoadingButton
              variant='outlined'
              size='small'
              onClick={() => handleUpload()}
              disabled={!haushalt}
              loading={uploadMutation.isLoading}
              startIcon={uploadMutation.isSuccess ? <Check color={'success'} /> : undefined}
              style={{ minWidth: '180px' }}
            >
              In Finfire speichern
            </LoadingButton>
          )}
        </Grid>
        {isError && (
          <Grid item xs={12} container alignItems='flex-start'>
            <Typography variant='caption' color={(theme) => theme.palette.error.main}>
              Beim Erstellen des Reports ist ein Fehler aufgetreten.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
