import React, { ReactElement, useMemo } from 'react'
import GroupIcon from '@mui/icons-material/Group'
import { Box } from '@mui/material'
import { compact } from 'lodash'

import { CockpitCard } from 'components/cockpit/CockpitCard'
import styles from 'components/cockpit/CockpitSummaryCard.module.scss'
import { CockpitSummaryDialog } from 'components/cockpit/CockpitSummaryDialog'
import { CurrencyCountUp } from 'components/count-up/CurrencyCountUp'
import { PersonalHaushaltForm } from 'components/personal/haushalt/PersonalHaushaltForm'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

const extractFamilyString = (haushalt?: HaushaltAggregate): string => {
  const nrOfPersons = haushalt?.personen?.length || 0
  const person = extractFullName(haushalt?.personen?.[0]?.person)
  const partner = nrOfPersons > 1 ? extractFullName(haushalt?.personen?.[1]?.person) : undefined
  const numberOfChildren = haushalt?.kinder?.length || 0
  return `${compact([person, partner]).join(', ')}${
    numberOfChildren > 0
      ? ` und ${numberOfChildren} ${numberOfChildren > 1 ? 'Kinder' : 'Kind'}`
      : ''
  }`
}

type CockpitPersonCardProps = {
  dinScore?: GesamtErgebnisse
  haushalt?: HaushaltAggregate
  dialogOpen: boolean
  setDialogVisibility: (isVisible: boolean) => void
}

export const CockpitPersonCard = ({
  dinScore,
  haushalt,
  dialogOpen,
  setDialogVisibility,
}: CockpitPersonCardProps): ReactElement => {
  const familyString = extractFamilyString(haushalt)
  const expensesCountUp = useMemo(
    () => (
      <CurrencyCountUp
        value={dinScore?.scoreHaushalt?.finanzBilanz?.gesamtAusgaben?.geldwert || 0}
      />
    ),
    [dinScore],
  )

  const incomeCountUp = useMemo(
    () => (
      <CurrencyCountUp
        value={dinScore?.scoreHaushalt?.finanzBilanz?.gesamtEinnahmen?.geldwert || 0}
      />
    ),
    [dinScore],
  )

  return (
    <>
      <CockpitCard
        title={'Personen'}
        icon={<GroupIcon />}
        onClick={() => setDialogVisibility(true)}
      >
        <Box>
          <Box className={styles.names}>{familyString}</Box>
          <Box className={styles.line}>
            <hr />
          </Box>
          <Box className={styles.entry}>
            <div>Einnahmen</div>
            <div>{incomeCountUp}</div>
          </Box>
          <Box className={styles.entry}>
            <div>Ausgaben</div>
            <div>{expensesCountUp}</div>
          </Box>
        </Box>
      </CockpitCard>
      {haushalt && (
        <CockpitSummaryDialog open={dialogOpen} onClose={() => setDialogVisibility(false)}>
          <PersonalHaushaltForm />
        </CockpitSummaryDialog>
      )}
    </>
  )
}
