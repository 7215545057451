import { WritableDraft } from 'immer/dist/types/types-external'
import { compact } from 'lodash'

import { convertMensch, Deletable } from 'form/haushalt-converter/person-converter/menschConverter'
import { KindFormModel } from 'form/KundeFormModel'
import { HaushaltAggregate, Kind } from 'interfaces/mynorm-api-model-interfaces'
import { RecursivePartial } from 'utils/typeUtils'

export const convertKinder = (
  kinderFormModels: KindFormModel[],
  draft: WritableDraft<RecursivePartial<HaushaltAggregate>>,
): RecursivePartial<Kind & Deletable>[] => {
  const kinder = (draft.kinder || []).map((kind) => {
    const kindFormModel = compact(kinderFormModels).find(
      (kindFormModel) => kindFormModel.meta.myviId === kind?.meta?.myviId,
    )
    if (kindFormModel) {
      return convertKind(kindFormModel, kind, kindFormModel.isDeleted)
    } else {
      return { ...kind, trennungFlag: true }
    }
  })
  const newKinder = compact(kinderFormModels)
    .filter((kindFormModel) => kindFormModel.isNew)
    .map((kinderFormModel) => convertKind(kinderFormModel))

  return [...kinder, ...newKinder]
}

const convertKind = (
  formModel: KindFormModel,
  original?: RecursivePartial<Kind>,
  trennung?: boolean,
): RecursivePartial<Kind> => {
  return convertMensch(formModel, original, trennung)
}
