import { nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { convertToMenschFormModel } from 'form/kunde-form-model-converter/mensch/menschFormModelConverter'
import { convertToAnlegerprofilFormModel } from 'form/kunde-form-model-converter/person/anlegerprofilFormModelConverter'
import { convertToAnschriftFormModel } from 'form/kunde-form-model-converter/person/anschriftFormModelConverter'
import { convertToFinanzenFormModel } from 'form/kunde-form-model-converter/person/finanzenFormModelConverter'
import { convertToGesetzlicheVersorgungFormModel } from 'form/kunde-form-model-converter/person/gesetzlicheVersorgungFormModelConverter'
import { convertToKontaktdatenFormModel } from 'form/kunde-form-model-converter/person/kontaktdatenFormModelConverter'
import { convertToVermoegenFormModel } from 'form/kunde-form-model-converter/person/vermoegen/vermoegenFormModelConverter'
import { convertToZielFormModel } from 'form/kunde-form-model-converter/person/zielFormModelConverter'
import { GesetzlicheVersorgungFormModel, PersonFormModel, ZielFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import {
  GesetzlicheVersorgung,
  PersonAggregate,
  Ziel,
} from 'interfaces/mynorm-api-model-interfaces'

const defaultPersonFormModel = createDefaultPersonFormModel()

const convertToZielFormModels = (ziele: Ziel[] | undefined): ZielFormModel[] => {
  if (!ziele) {
    return defaultPersonFormModel.ziele
  }
  return ziele.map(convertToZielFormModel)
}

const convertToGesetzlicheVersorgungFormModels = (
  gesetzlicheVersorgungen: GesetzlicheVersorgung[] | undefined,
): GesetzlicheVersorgungFormModel[] => {
  if (!gesetzlicheVersorgungen) {
    return defaultPersonFormModel.gesetzlicheVersorgungen
  }
  return gesetzlicheVersorgungen.map(convertToGesetzlicheVersorgungFormModel)
}

export const convertToPersonFormModel = (
  personAggregate: PersonAggregate | undefined,
): PersonFormModel => {
  const person = personAggregate?.person
  if (!person) {
    return defaultPersonFormModel
  }
  return {
    ...convertToMenschFormModel(person),
    titel: valueOrDefault(person.titel, defaultPersonFormModel.titel),
    steuerIdentifikationsnummer: nullableValueOrDefault(
      person.steuerIdentifikationsnummer,
      defaultPersonFormModel.steuerIdentifikationsnummer,
    ),
    anschrift: convertToAnschriftFormModel(person.anschrift),
    kontaktdaten: convertToKontaktdatenFormModel(person.kontaktdaten),
    finanzen: convertToFinanzenFormModel(person.finanzen),
    ziele: convertToZielFormModels(personAggregate.ziele),
    vermoegen: convertToVermoegenFormModel(personAggregate.vermoegen),
    gesetzlicheVersorgungen: convertToGesetzlicheVersorgungFormModels(
      personAggregate.gesetzlicheVersorgungen,
    ),
    anlegerprofil: convertToAnlegerprofilFormModel(personAggregate.anlegerprofil),
    vertraege: personAggregate.vertraege ?? [],
  }
}
