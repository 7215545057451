import produce from 'immer'

import { KundeFormModel } from 'form/KundeFormModel'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const mergeVertraegeIntoHaushalt = (
  baseHaushalt: HaushaltAggregate,
  haushaltWithNewVertraege: HaushaltAggregate,
): HaushaltAggregate => {
  return produce(baseHaushalt, (draft: HaushaltAggregate) => {
    const kundeVertraege = haushaltWithNewVertraege?.personen[0]?.vertraege
    const partnerVertraege = haushaltWithNewVertraege?.personen[1]?.vertraege

    if (kundeVertraege && draft.personen.length > 0) {
      draft.personen[0].vertraege = kundeVertraege
    }
    if (partnerVertraege && draft.personen.length > 1) {
      draft.personen[1].vertraege = partnerVertraege
    }
  })
}

export const mergeVertraegeOfHaushaltIntoKundeFormModel = (
  baseKundeFormModel: KundeFormModel,
  haushaltWithNewVertraege: HaushaltAggregate,
): KundeFormModel => {
  return produce(baseKundeFormModel, (draft: KundeFormModel) => {
    const kundeVertraege = haushaltWithNewVertraege?.personen[0]?.vertraege
    const partnerVertrage = haushaltWithNewVertraege?.personen[1]?.vertraege

    if (kundeVertraege) {
      draft.personen[0].vertraege = kundeVertraege
    }
    if (partnerVertrage) {
      draft.personen[1].vertraege = partnerVertrage
    }
  })
}

export const mergeVertraegeOfKundeFormModelIntoKundeFormModel = (
  baseKundeFormModel: KundeFormModel,
  kundeFormModelWithNewVertraege: KundeFormModel,
): KundeFormModel => {
  return produce(baseKundeFormModel, (draft: KundeFormModel) => {
    const kundeVertraege = kundeFormModelWithNewVertraege?.personen[0]?.vertraege
    const partnerVertraege = kundeFormModelWithNewVertraege?.personen[1]?.vertraege

    if (kundeVertraege) {
      draft.personen[0].vertraege = kundeVertraege
    }
    if (partnerVertraege) {
      draft.personen[1].vertraege = partnerVertraege
    }
  })
}
