import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { KundeFormModel, SachwertFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type SachwerteMutator = {
  updateSachwert: (
    personIndex: number,
    sachwertIndex: number,
    sachwertFormModel: SachwertFormModel,
  ) => void
  removeSachwert: (personIndex: number, sachwertIndex: number) => void
  addSachwert: (personIndex: number, sachwertFormModel: SachwertFormModel) => void
}

export const sachwerteMutator = (set: Mutator): SachwerteMutator => {
  return {
    updateSachwert: (
      personIndex: number,
      sachwertIndex: number,
      sachwertFormModel: SachwertFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].vermoegen.sachwerte[sachwertIndex] = sachwertFormModel
          }),
        }
      })
    },
    removeSachwert: (personIndex: number, sachwertIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const sachwert = draft.personen[personIndex].vermoegen.sachwerte[sachwertIndex]
            if (sachwert.isNew) {
              draft.personen[personIndex].vermoegen.sachwerte.splice(sachwertIndex, 1)
            } else {
              draft.personen[personIndex].vermoegen.sachwerte[sachwertIndex].isDeleted = true
            }
          }),
        }
      })
    },
    addSachwert: (personIndex: number, sachwertFormModel: SachwertFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const id = uuidv4()
            sachwertFormModel.meta.myviId = id
            sachwertFormModel.meta.finfireId = id
            sachwertFormModel.isNew = true
            draft.personen[personIndex].vermoegen.sachwerte.push(sachwertFormModel)
          }),
        }
      })
    },
  }
}
