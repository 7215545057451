import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios from 'axios'

import { ReportRequest } from 'api/useCreateReportErweitertesKundendokument'
import { useFetchUserConfig } from 'api/useFetchUserConfig'
import { BackendPaths } from 'configurations/backendPaths'

export const useUploadErweitertesKundendokument = (): UseMutationResult<
  void,
  unknown,
  ReportRequest
> => {
  const { data: userConfig } = useFetchUserConfig()
  const isFeFinance = userConfig?.guidanceMitNormLpFarbe === 'GRUEN'
  return useMutation({
    mutationFn: (reportRequest) => uploadErweitertesKundendokument(reportRequest, isFeFinance),
  })
}

const uploadErweitertesKundendokument = async (
  reportRequest: ReportRequest,
  isFeFinance: boolean,
): Promise<void> => {
  await axios.post<Blob>(BackendPaths.uploadErweitertesKundendokument, reportRequest.haushalt, {
    params: { feFinance: isFeFinance, trennungNachPersonen: reportRequest.perPerson },
  })
}
