import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_0.png'
import chart1 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_1.png'
import chart2 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_2.png'
import chart3 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_3.png'
import chart4 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_4.png'
import chart5 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_5.png'
import chart6 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_6.png'
import chart7 from 'assets/images/guidance/mitnorm/ziele-und-wuensche-1/ziele_und_wuensche_1_7.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_2.png'
import chart3Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_3.png'
import chart4Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_4.png'
import chart5Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_5.png'
import chart6Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_6.png'
import chart7Fe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-1/ziele_und_wuensche_1_7.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm1Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm1Page = (): ReactElement => {
  const charts = [undefined, chart0, chart1, chart2, chart3, chart4, chart5, chart6, chart7]
  const chartsFe = [
    undefined,
    chart0Fe,
    chart1Fe,
    chart2Fe,
    chart3Fe,
    chart4Fe,
    chart5Fe,
    chart6Fe,
    chart7Fe,
  ]
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={0}
      nextPagePath={Paths.guidanceMitNorm2}
      previousPagePath={Paths.guidanceMitNorm0}
      guidanceColor='mitnorm'
      numberOfInterPages={9}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title={'ZIELE UND WÜNSCHE'} subTitle='mitNORM'>
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              {interPageIndex > 0 && (
                <GuidanceMitNormImage
                  src={charts[interPageIndex]}
                  feSrc={chartsFe[interPageIndex]}
                  alt='Ziele Diagramm'
                />
              )}
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
