import React, { ReactElement } from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/cockpit/LinearDisplay.module.scss'

type LinearDisplayProps = {
  value: number
  formattedValue?: string
  progressClassName?: string
  labelClassName?: string
}

export const LinearDisplay = ({
  value,
  formattedValue,
  progressClassName,
  labelClassName,
}: LinearDisplayProps): ReactElement => {
  return (
    <Box className={styles.linearDisplayContainer}>
      <LinearProgress
        variant='determinate'
        value={value}
        className={clsx(progressClassName, !progressClassName ? styles.linearDisplay : '')}
        color='secondary'
      />
      <Box className={styles.linearDisplayLabel}>
        <Typography className={clsx(labelClassName, !labelClassName ? styles.label : '')}>
          {formattedValue ? formattedValue : `${value} %`}
        </Typography>
      </Box>
    </Box>
  )
}
