import { useEffect } from 'react'

import { Person } from 'interfaces/mynorm-api-model-interfaces'
import { abbreviatedFullName } from 'utils/nameUtils'

const BASE_TITLE = 'MYVI Guide'

export const usePageTitle = (person?: Person): null => {
  useEffect(() => {
    if (person === undefined || person === null || !person.vorname || !person.nachname) {
      document.title = BASE_TITLE
      return
    }
    document.title = `${abbreviatedFullName(person)} | ${BASE_TITLE}`
  }, [person])
  return null
}
