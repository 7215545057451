import React, { ReactElement, useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import { isDayjs } from 'dayjs'
import { get, isEqual } from 'lodash'

import styles from 'components/controls/selective-inputs/SelectiveCommon.module.scss'
import {
  SelectiveFormProps,
  SelectiveInputProps,
} from 'components/controls/selective-inputs/SelectiveModels'
import { useEditableKunde } from 'state/useEditableKunde'

type SelectiveComparisonResult = {
  fieldName: string
  fieldPath: string
  isDirty: boolean
  currentValue: unknown
  originalValue: unknown
  isNew: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValueDirty = (isNew: boolean, currentValue: any, originalValue: any): boolean => {
  if (isNew) {
    return false
  }
  if (isDayjs(currentValue) && isDayjs(originalValue)) {
    return !currentValue.isSame(originalValue)
  }
  return !isEqual(currentValue || false, originalValue || false)
}

const getSelectiveComparison = <FormModel,>(
  fieldName: string,
  formPath: string,
  originalFormModel: FormModel,
  currentFormModel: FormModel,
): SelectiveComparisonResult => {
  const fieldPath = formPath ? `${formPath}.${fieldName}` : fieldName

  const formModel = get(currentFormModel, formPath)
  const originalValue = get(originalFormModel, fieldPath)
  const currentValue = get(currentFormModel, fieldPath)
  const isNew = formModel?.isNew
  const isDirty = isValueDirty(isNew, currentValue, originalValue)

  return { isDirty, currentValue, originalValue, fieldName, fieldPath, isNew }
}

export const useSelectiveKundeFieldComparison = (
  fieldName: string,
  formPath: string,
): SelectiveComparisonResult => {
  const { originalKunde, editableKunde } = useEditableKunde()
  return getSelectiveComparison(fieldName, formPath, originalKunde, editableKunde)
}

export const useSelectiveKundeFieldsComparison = (
  fieldNames: string[],
  formPath: string,
): SelectiveComparisonResult[] => {
  const { originalKunde, editableKunde } = useEditableKunde()
  return fieldNames.map((fieldName) =>
    getSelectiveComparison(fieldName, formPath, originalKunde, editableKunde),
  )
}

type SelectiveSwitchResult = {
  switchActive: boolean
  switchComponent: ReactElement
}

export const useSelectiveSwitch = (isDirty: boolean): SelectiveSwitchResult => {
  const [checked, setChecked] = useState(isDirty)
  return {
    switchComponent: isDirty ? (
      <Switch
        checked={checked}
        color='secondary'
        onChange={(event, checked) => setChecked(checked)}
        className={styles.selectiveSwitch}
      />
    ) : (
      <></>
    ),
    switchActive: checked,
  }
}

type UseSelectiveKundeFormType<FormModel> = {
  initialFormValues: FormModel
  selectiveFieldProps?: SelectiveInputProps
}

export const useSelectiveKundeForm = <FormModel,>(
  formPath: string,
  selectiveFormProps?: SelectiveFormProps,
): UseSelectiveKundeFormType<FormModel> => {
  const { editableKunde } = useEditableKunde()
  const initialFormValues = get(editableKunde, formPath)
  const selectiveFieldProps = selectiveFormProps?.onSelected
    ? {
        formPath: formPath,
        onSelected: selectiveFormProps?.onSelected,
      }
    : undefined

  useEffect(() => {
    if (initialFormValues?.isNew) {
      selectiveFieldProps?.onSelected({
        selected: true,
        fieldPath: `${formPath}.isNew`,
      })
    }
  }, [initialFormValues?.isNew])

  return {
    initialFormValues,
    selectiveFieldProps,
  }
}
