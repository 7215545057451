import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'

import backgroundImage from 'assets/images/guidance/career/overview/karriere_overview_background.png'
import styles from 'components/guidance/career/GuidanceCareer1Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { GuidanceTextBullet } from 'components/guidance/GuidanceTextBullet'
import { Paths } from 'configurations/paths'

export const GuidanceCareer1Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={0}
      previousPagePath={Paths.guidancePersonnel5}
      nextPagePath={Paths.guidanceCareer2}
      guidanceColor='career'
      backgroundImage={backgroundImage}
      numberOfInterPages={4}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate hideTitleContainer={true}>
          <Stack direction='row' className={styles.boxContainer}>
            <Box className={styles.box1}>
              {interPageIndex === 3 && (
                <h2 className={styles.box1Text}>
                  MARKTWERT STEIGERN DURCH DIE ENTWICKLUNG PERSÖNLICHER KOMPETENZEN
                </h2>
              )}
            </Box>
            <Box className={styles.box2}></Box>
            <Box className={styles.box3}>
              <Stack className={styles.box3Container}>
                <GuidanceTextBullet hideContent={interPageIndex < 1}>
                  <>
                    Persönlichkeit
                    <br />
                    analysieren
                  </>
                </GuidanceTextBullet>
                <GuidanceTextBullet>
                  <>
                    Persönlich
                    <br />
                    entwickeln
                  </>
                </GuidanceTextBullet>
                <GuidanceTextBullet hideContent={interPageIndex < 2}>
                  <>
                    Karriere-
                    <br />
                    Coaching
                  </>
                </GuidanceTextBullet>
              </Stack>
            </Box>
          </Stack>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
