import { ImmobilieFormModel } from 'form/KundeFormModel'
import { Immobilie } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertImmobilie = (
  formModel: ImmobilieFormModel,
  source: Immobilie | undefined,
): RecursivePartial<Immobilie> => {
  const useFormModelAsDefault = formModel.isNew
  return {
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    immobilieNutzungsArt: mapToRequestModelType(
      formModel.objektArt === 'MIETWOHNUNG' ? 'GEMIETET' : formModel.immobilieNutzungsArt,
      source?.immobilieNutzungsArt,
      useFormModelAsDefault,
    ),
    entschuldetImRuhestand:
      mapToRequestModelType(
        formModel.entschuldetImRuhestand,
        source?.entschuldetImRuhestand,
        useFormModelAsDefault,
      ) ?? false,
    kreditlinieInstandhaltung: mapToNumericRequestModelType(
      formModel.kreditlinieInstandhaltung,
      source?.kreditlinieInstandhaltung,
      useFormModelAsDefault,
    ),
    objektArt: mapToRequestModelType(formModel.objektArt, source?.objektArt, useFormModelAsDefault),
    wohngebaeudeVersicherungViaETG:
      mapToRequestModelType(
        formModel.wohngebaeudeVersicherungViaETG,
        source?.wohngebaeudeVersicherungViaETG,
        useFormModelAsDefault,
      ) ?? false,
    hausUndGrundbesitzerHaftpflichtViaETG:
      mapToRequestModelType(
        formModel.hausUndGrundbesitzerHaftpflichtViaETG,
        source?.hausUndGrundbesitzerHaftpflichtViaETG,
        useFormModelAsDefault,
      ) ?? false,
    instanhaltungsruecklageViaETG:
      mapToRequestModelType(
        formModel.instanhaltungsruecklageViaETG,
        source?.instanhaltungsruecklageViaETG,
        useFormModelAsDefault,
      ) ?? false,
    aktuelleBaumassnahmen: mapToRequestModelType(
      formModel.aktuelleBaumassnahmen,
      source?.aktuelleBaumassnahmen,
      useFormModelAsDefault,
    ),
    wohnflaeche: mapToNumericRequestModelType(
      formModel.wohnflaeche,
      source?.wohnflaeche,
      useFormModelAsDefault,
    ),
    baujahr: mapToNumericRequestModelType(
      formModel.baujahr,
      source?.baujahr,
      useFormModelAsDefault,
    ),
    photovoltaik:
      mapToRequestModelType(formModel.photovoltaik, source?.photovoltaik, useFormModelAsDefault) ??
      false,
    oeltank:
      mapToRequestModelType(formModel.oeltank, source?.oeltank, useFormModelAsDefault) ?? false,
    wert1914: mapToNumericRequestModelType(
      formModel.wert1914,
      source?.wert1914,
      useFormModelAsDefault,
    ),
    neubauSumme: mapToNumericRequestModelType(
      formModel.neubauSumme,
      source?.neubauSumme,
      useFormModelAsDefault,
    ),
    neuwertHausrat: mapToNumericRequestModelType(
      formModel.neuwertHausrat,
      source?.neuwertHausrat,
      useFormModelAsDefault,
    ),
    verkehrswert: mapToNumericRequestModelType(
      formModel.verkehrswert,
      source?.verkehrswert,
      useFormModelAsDefault,
    ),
    riskanteAnteile: mapToNumericPercentRequestModelType(
      formModel.riskanteAnteile,
      source?.riskanteAnteile,
      useFormModelAsDefault,
    ),
    quelleRisikoklassifizierung: mapToRequestModelType(
      formModel.quelleRisikoklassifizierung,
      source?.quelleRisikoklassifizierung,
      useFormModelAsDefault,
    ),
    bemerkungen: mapToRequestModelType(
      formModel.bemerkungen,
      source?.bemerkungen,
      useFormModelAsDefault,
    ),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
      useFormModelAsDefault,
    ),
    adresse: {
      strasse: mapToRequestModelType(
        formModel.adresse.strasse,
        source?.adresse?.strasse,
        useFormModelAsDefault,
      ),
      hausnummer: mapToRequestModelType(
        formModel.adresse.hausnummer,
        source?.adresse?.hausnummer,
        useFormModelAsDefault,
      ),
      postleitzahl: mapToRequestModelType(
        formModel.adresse.postleitzahl,
        source?.adresse?.postleitzahl,
        useFormModelAsDefault,
      ),
      ort: mapToRequestModelType(
        formModel.adresse.ort,
        source?.adresse?.ort,
        useFormModelAsDefault,
      ),
      bundesland: mapToRequestModelType(
        formModel.adresse.bundesland,
        source?.adresse?.bundesland,
        useFormModelAsDefault,
      ),
      land: mapToRequestModelType(
        formModel.adresse.land,
        source?.adresse?.land,
        useFormModelAsDefault,
      ),
    },
    eigentuemer: mapToRequestModelType(
      formModel.eigentuemer?.map((personFinfireId) =>
        personFinfireId === EXTERNE_PERSON_OPTION_VALUE
          ? {
              personFinfireId: null,
              externePerson: true,
              externePersonName: mapToRequestModelType(
                formModel.eigentuemerExternePersonName,
                source?.eigentuemer?.find((e) => e.externePerson)?.externePersonName,
              ),
            }
          : { personFinfireId, externePerson: false, externePersonName: null },
      ),
      source?.eigentuemer,
      useFormModelAsDefault,
    ),
    bezeichnung: mapToRequestModelType(
      formModel.bezeichnung,
      source?.bezeichnung,
      useFormModelAsDefault,
    ),
    createFlag: formModel.isNew,
    deleteFlag: formModel.isDeleted,
    saveError: formModel.saveError,
  }
}
