import React, { ReactElement } from 'react'
import { Formik, FormikProps } from 'formik'

import { useUpdateVertrag } from 'api/useUpdateVertrag'
import { PaginatedFormikDialog } from 'components/personal/absicherung2/dialog/PagniatedFormikDialog'
import { VertragFormPage1 } from 'components/personal/absicherung2/dialog/VertragFormPage1'
import { VertragFormPage2 } from 'components/personal/absicherung2/dialog/VertragFormPage2'
import { convertToVertrag } from 'form/vertraege/VertragConverter'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'
import { convertToVertragFormModel } from 'form/vertraege/VertragFormModelConverter'
import { Vertrag } from 'interfaces/mynorm-api-model-interfaces'
import { useImmobilien } from 'state/useImmobilien'
import { useSachwerte } from 'state/useSachwerte'

const ERROR_MESSAGE = 'Beim Speichern des Vertrages ist ein Fehler aufgetreten.'
const SUCCESS_MESSAGE = 'Der Vertrag wurde erfolgreich gespeichert.'
const DIALOG_BUTTON = 'Vertrag ändern'
const DIALOG_TITLE = 'Vertrag ändern'

type EditVertragDialogProps = {
  vertrag: Vertrag
}

export const EditVertragDialog = ({ vertrag }: EditVertragDialogProps): ReactElement => {
  const { mutate: updateVertrag, ...mutationResult } = useUpdateVertrag()
  const { immobilien } = useImmobilien()
  const { sachwerte } = useSachwerte()
  const handleSubmit = (vertragFormModelToSubmit: VertragFormModel): void => {
    const vertragForUpdate = convertToVertrag(
      vertragFormModelToSubmit,
      vertrag,
      immobilien,
      sachwerte,
    )
    updateVertrag(vertragForUpdate)
  }
  const printConsoleAction = (formProps: FormikProps<VertragFormModel>): void => {
    const vertragToUpdate = convertToVertrag(formProps.values, vertrag, immobilien, sachwerte)
    // eslint-disable-next-line no-console
    console.log(vertragToUpdate)
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(vertragToUpdate))
  }

  return (
    <Formik initialValues={convertToVertragFormModel(vertrag)} onSubmit={handleSubmit}>
      {(props: FormikProps<VertragFormModel>) => {
        const page1 = <VertragFormPage1 formProps={props} />
        const page2 = <VertragFormPage2 formProps={props} />
        return (
          <PaginatedFormikDialog
            formProps={props}
            pages={[page1, page2]}
            title={DIALOG_TITLE}
            dialogButtonText={DIALOG_BUTTON}
            onReset={() => props.resetForm({ values: convertToVertragFormModel(vertrag) })}
            mutationState={mutationResult}
            errorMessage={ERROR_MESSAGE}
            successMessage={SUCCESS_MESSAGE}
            printConsoleAction={() => printConsoleAction(props)}
          />
        )
      }}
    </Formik>
  )
}
