import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { VersichertesRisikoBlockProps } from 'components/personal/absicherung2/blocks/RisikenFormBlock'
import { determinePermittedFahrzeugTypes } from 'components/personal/absicherung2/blocks/VertragsProdukteFormUtil'
import { VersichertesFahrzeugSelect } from 'components/personal/absicherung2/selects/VersichertesFahrzeugSelect'
import { useProduktMappings } from 'state/useProduktConfig'

type VersicherteFahrzeugeBlockProps = {
  produktId: string
} & VersichertesRisikoBlockProps

export const VersicherteFahrzeugeBlock = ({
  name,
  risiko,
  produktId,
  disabled,
}: VersicherteFahrzeugeBlockProps): ReactElement | null => {
  const produktMappings = useProduktMappings()
  const permittedFahrzeugTypes = determinePermittedFahrzeugTypes(produktId, produktMappings)
  const { risikoTyp } = risiko
  if (risikoTyp !== 'FAHRZEUG') {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <VersichertesFahrzeugSelect
          name={`${name}.risikoFahrzeug.finfireId`}
          typesFilter={permittedFahrzeugTypes}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
