import React, { ReactElement } from 'react'
import CountUp from 'react-countup'

import { formatNumberWithFractionDigits } from 'components/util/formatUtil'

type CurrencyCountUpProps = {
  value: number
}

export const CurrencyCountUp = ({ value }: CurrencyCountUpProps): ReactElement => {
  return (
    <CountUp
      end={value}
      useGrouping={true}
      useEasing={true}
      duration={1}
      formattingFn={(value) => `${formatNumberWithFractionDigits(value, 0)} €`}
    />
  )
}
