import * as React from 'react'
import { ReactElement } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { applicationConfigLoader } from 'api/loaders/applicationConfigLoader'
import { customerLoader } from 'api/loaders/customerLoader'
import { GuidanceCareer1Page } from 'components/guidance/career/GuidanceCareer1Page'
import { GuidanceCareer2Page } from 'components/guidance/career/GuidanceCareer2Page'
import { GuidanceCareer3Page } from 'components/guidance/career/GuidanceCareer3Page'
import { GuidanceCareer4Page } from 'components/guidance/career/GuidanceCareer4Page'
import { GuidanceCareer5Page } from 'components/guidance/career/GuidanceCareer5Page'
import { GuidanceCareer6Page } from 'components/guidance/career/GuidanceCareer6Page'
import { GuidanceCareer7Page } from 'components/guidance/career/GuidanceCareer7Page'
import { GuidanceCareer8Page } from 'components/guidance/career/GuidanceCareer8Page'
import { GuidanceCareer9Page } from 'components/guidance/career/GuidanceCareer9Page'
import { GuidanceCareer10Page } from 'components/guidance/career/GuidanceCareer10Page'
import { GuidanceCareer11Page } from 'components/guidance/career/GuidanceCareer11Page'
import { GuidanceCareer12Page } from 'components/guidance/career/GuidanceCareer12Page'
import { GuidanceCareer13Page } from 'components/guidance/career/GuidanceCareer13Page'
import { GuidanceCareer14Page } from 'components/guidance/career/GuidanceCareer14Page'
import { GuidanceCareer15Page } from 'components/guidance/career/GuidanceCareer15Page'
import { GuidanceCompany1Page } from 'components/guidance/company/GuidanceCompany1Page'
import { GuidanceOverviewPage } from 'components/guidance/GuidanceOverviewPage'
import { GuidanceMitNorm0Page } from 'components/guidance/mitnorm/GuidanceMitNorm0Page'
import { GuidanceMitNorm1Page } from 'components/guidance/mitnorm/GuidanceMitNorm1Page'
import { GuidanceMitNorm2Page } from 'components/guidance/mitnorm/GuidanceMitNorm2Page'
import { GuidanceMitNorm3Page } from 'components/guidance/mitnorm/GuidanceMitNorm3Page'
import { GuidanceMitNorm4Page } from 'components/guidance/mitnorm/GuidanceMitNorm4Page'
import { GuidanceMitNorm5Page } from 'components/guidance/mitnorm/GuidanceMitNorm5Page'
import { GuidanceMitNorm6Page } from 'components/guidance/mitnorm/GuidanceMitNorm6Page'
import { GuidanceMitNorm9Page } from 'components/guidance/mitnorm/GuidanceMitNorm9Page'
import { GuidanceMitNorm10Page } from 'components/guidance/mitnorm/GuidanceMitNorm10Page'
import { GuidanceMitNorm11Page } from 'components/guidance/mitnorm/GuidanceMitNorm11Page'
import { GuidanceMitNorm12Page } from 'components/guidance/mitnorm/GuidanceMitNorm12Page'
import { GuidanceMitNorm13Page } from 'components/guidance/mitnorm/GuidanceMitNorm13Page'
import { GuidanceMitNorm14Page } from 'components/guidance/mitnorm/GuidanceMitNorm14Page'
import { GuidanceMitNorm15Page } from 'components/guidance/mitnorm/GuidanceMitNorm15Page'
import { GuidanceMitNorm16Page } from 'components/guidance/mitnorm/GuidanceMitNorm16Page'
import { GuidanceMitNorm17Page } from 'components/guidance/mitnorm/GuidanceMitNorm17Page'
import { GuidancePersonnel1Page } from 'components/guidance/personnel/GuidancePersonnel1Page'
import { GuidancePersonnel2Page } from 'components/guidance/personnel/GuidancePersonnel2Page'
import { GuidancePersonnel3Page } from 'components/guidance/personnel/GuidancePersonnel3Page'
import { GuidancePersonnel4Page } from 'components/guidance/personnel/GuidancePersonnel4Page'
import { GuidancePersonnel5Page } from 'components/guidance/personnel/GuidancePersonnel5Page'
import { Paths } from 'configurations/paths'
import { CustomerAbsicherungRoute } from 'routes/CustomerAbsicherungRoute'
import { CustomerAnlegerprofilRoute } from 'routes/CustomerAnlegerprofilRoute'
import { CustomerCockpitRoute } from 'routes/CustomerCockpitRoute'
import { CustomerDatenRoute } from 'routes/CustomerDatenRoute'
import { CustomerFinanzenRoute } from 'routes/CustomerFinanzenRoute'
import { CustomerHaushaltRoute } from 'routes/CustomerHaushaltRoute'
import { CustomerRoute } from 'routes/CustomerRoute'
import { CustomerSelectionRoute } from 'routes/CustomerSelectionRoute'
import { CustomerZieleRoute } from 'routes/CustomerZieleRoute'
import { GuidanceRoute } from 'routes/GuidanceRoute'
import { LoginRoute } from 'routes/LoginRoute'
import { RootErrorRoute, RootRoute } from 'routes/RootRoute'

const queryClient = new QueryClient()

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    errorElement: <RootErrorRoute />,
    loader: applicationConfigLoader(queryClient),
    children: [
      { index: true, element: <CustomerSelectionRoute /> },
      {
        path: '/guidance',
        element: <GuidanceRoute />,
        children: [
          { index: true, element: <GuidanceOverviewPage /> },
          { path: Paths.guidanceMitNorm0, element: <GuidanceMitNorm0Page /> },
          { path: Paths.guidanceMitNorm1, element: <GuidanceMitNorm1Page /> },
          { path: Paths.guidanceMitNorm2, element: <GuidanceMitNorm2Page /> },
          { path: Paths.guidanceMitNorm3, element: <GuidanceMitNorm3Page /> },
          { path: Paths.guidanceMitNorm4, element: <GuidanceMitNorm4Page /> },
          { path: Paths.guidanceMitNorm5, element: <GuidanceMitNorm5Page /> },
          { path: Paths.guidanceMitNorm6, element: <GuidanceMitNorm6Page /> },
          { path: Paths.guidanceMitNorm9, element: <GuidanceMitNorm9Page /> },
          { path: Paths.guidanceMitNorm10, element: <GuidanceMitNorm10Page /> },
          { path: Paths.guidanceMitNorm11, element: <GuidanceMitNorm11Page /> },
          { path: Paths.guidanceMitNorm12, element: <GuidanceMitNorm12Page /> },
          { path: Paths.guidanceMitNorm13, element: <GuidanceMitNorm13Page /> },
          { path: Paths.guidanceMitNorm14, element: <GuidanceMitNorm14Page /> },
          { path: Paths.guidanceMitNorm15, element: <GuidanceMitNorm15Page /> },
          { path: Paths.guidanceMitNorm16, element: <GuidanceMitNorm16Page /> },
          { path: Paths.guidanceMitNorm17, element: <GuidanceMitNorm17Page /> },
          { path: Paths.guidanceCareer1, element: <GuidanceCareer1Page /> },
          { path: Paths.guidanceCareer2, element: <GuidanceCareer2Page /> },
          { path: Paths.guidanceCareer3, element: <GuidanceCareer3Page /> },
          { path: Paths.guidanceCareer4, element: <GuidanceCareer4Page /> },
          { path: Paths.guidanceCareer5, element: <GuidanceCareer5Page /> },
          { path: Paths.guidanceCareer6, element: <GuidanceCareer6Page /> },
          { path: Paths.guidanceCareer7, element: <GuidanceCareer7Page /> },
          { path: Paths.guidanceCareer8, element: <GuidanceCareer8Page /> },
          { path: Paths.guidanceCareer9, element: <GuidanceCareer9Page /> },
          { path: Paths.guidanceCareer10, element: <GuidanceCareer10Page /> },
          { path: Paths.guidanceCareer11, element: <GuidanceCareer11Page /> },
          { path: Paths.guidanceCareer12, element: <GuidanceCareer12Page /> },
          { path: Paths.guidanceCareer13, element: <GuidanceCareer13Page /> },
          { path: Paths.guidanceCareer14, element: <GuidanceCareer14Page /> },
          { path: Paths.guidanceCareer15, element: <GuidanceCareer15Page /> },
          { path: Paths.guidancePersonnel1, element: <GuidancePersonnel1Page /> },
          { path: Paths.guidancePersonnel2, element: <GuidancePersonnel2Page /> },
          { path: Paths.guidancePersonnel3, element: <GuidancePersonnel3Page /> },
          { path: Paths.guidancePersonnel4, element: <GuidancePersonnel4Page /> },
          { path: Paths.guidancePersonnel5, element: <GuidancePersonnel5Page /> },
          { path: Paths.guidanceCompany1, element: <GuidanceCompany1Page /> },
        ],
      },
      {
        path: '/kunde/:customerId',
        element: <CustomerRoute />,
        loader: customerLoader(queryClient),
        shouldRevalidate: () => false,
        children: [
          {
            id: 'customerData',
            path: '/kunde/:customerId/daten',
            element: <CustomerDatenRoute />,
            children: [
              {
                id: 'customerDataHaushalt',
                path: '/kunde/:customerId/daten/haushalt',
                element: <CustomerHaushaltRoute />,
              },
              {
                id: 'customerDataZiele',
                path: '/kunde/:customerId/daten/ziele',
                element: <CustomerZieleRoute />,
              },
              {
                id: 'customerDataFinanzen',
                path: '/kunde/:customerId/daten/finanzen',
                element: <CustomerFinanzenRoute />,
              },
              {
                id: 'customerDataAbsicherung',
                path: '/kunde/:customerId/daten/absicherung',
                element: <CustomerAbsicherungRoute />,
              },
              {
                id: 'customerDataAnlegerprofil',
                path: '/kunde/:customerId/daten/anlegerprofil',
                element: <CustomerAnlegerprofilRoute />,
              },
            ],
          },
          {
            path: '/kunde/:customerId/cockpit',
            element: <CustomerCockpitRoute />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <LoginRoute />,
  },
])

export const ApplicationProvider = (): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
