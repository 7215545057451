import React, { ReactElement, useEffect } from 'react'
import { Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material'

import styles from 'components/controls/inputs/CheckboxInput.module.scss'
import selectiveStyles from 'components/controls/selective-inputs/SelectiveCommon.module.scss'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { useSelectiveKundeFieldComparison, useSelectiveSwitch } from 'hooks/useSelectiveComparison'

type SelectiveCheckboxProps = {
  name: string
  label: string
  selectiveInputProps: SelectiveInputProps
}

export const SelectiveCheckbox = ({
  name,
  label,
  selectiveInputProps,
}: SelectiveCheckboxProps): ReactElement => {
  const { isDirty, currentValue, fieldPath } = useSelectiveKundeFieldComparison(
    name,
    selectiveInputProps.formPath,
  )
  const { switchActive, switchComponent } = useSelectiveSwitch(isDirty)

  useEffect(() => {
    selectiveInputProps.onSelected({
      selected: switchActive,
      fieldPath,
    })
  }, [switchActive])

  const className = switchActive ? selectiveStyles.selectedCheckbox : ''
  return (
    <Stack direction='row' className={selectiveStyles.selectiveWrapper}>
      <FormControl error={false} className={styles.selective}>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              size='small'
              onChange={undefined}
              color='secondary'
              checked={!!currentValue}
              className={styles.checkbox}
              disabled={!switchActive}
            />
          }
          label={label}
        />
      </FormControl>
      {switchComponent}
    </Stack>
  )
}
