import { defer, LoaderFunction } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'

import { fetchHaushalt, haushaltQueryKey } from 'api/useHaushalt'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const customerLoader = (queryClient: QueryClient): LoaderFunction => {
  return async ({ params }) => {
    return defer({
      haushalt: storeHaushalt(queryClient, params.customerId),
    })
  }
}

const storeHaushalt = async (
  queryClient: QueryClient,
  id?: string,
): Promise<HaushaltAggregate | null> => {
  const { haushalt: existingHaushalt, customerId } = useHaushaltState.getState()
  if (existingHaushalt && customerId === id) {
    return existingHaushalt
  } else {
    const haushalt = await loadHaushalt(queryClient, id)
    if (haushalt === null) {
      return null
    }
    if (haushalt.personen?.[0]?.person?.firmenkunde) {
      throw new Error('Firmenkunden werden nicht unterstützt')
    }
    useHaushaltState.getState().setHaushalt(haushalt, id)
    return haushalt
  }
}

const loadHaushalt = async (
  queryClient: QueryClient,
  id?: string,
): Promise<HaushaltAggregate | null> => {
  try {
    return await queryClient.fetchQuery({
      queryKey: haushaltQueryKey(id),
      queryFn: () => fetchHaushalt(id),
    })
  } catch (e) {
    return null
  }
}
