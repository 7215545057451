import React, { ReactElement } from 'react'
import HouseIcon from '@mui/icons-material/House'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import PersonIcon from '@mui/icons-material/Person'
import SecurityIcon from '@mui/icons-material/Security'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { VertragGeschaeft } from 'interfaces/mynorm-api-model-interfaces'

export const VERTRAG_GESCHAEFT_OPTIONS: Option<VertragGeschaeft>[] = [
  { label: 'Sachversicherung', value: 'SACH' },
  { label: 'Lebensversicherung', value: 'LEBEN' },
  { label: 'Krankenversicherung', value: 'KRANKEN' },
  { label: 'Sachgewerbeversicherung', value: 'SACHGEWERBE' },
]

type GeschaeftSelectProps = {
  name: string
  label?: string
  disabled?: boolean
}

export const GeschaeftSelect = ({ name, label, disabled }: GeschaeftSelectProps): ReactElement => {
  return (
    <SelectInput
      options={VERTRAG_GESCHAEFT_OPTIONS}
      name={name}
      label={label ?? 'Geschaeft'}
      disabled={disabled}
    />
  )
}

export const getGeschaeftIcon = (geschaeft: VertragGeschaeft): ReactElement => {
  switch (geschaeft) {
    case 'KRANKEN':
      return <LocalHospitalIcon />
    case 'LEBEN':
      return <PersonIcon />
    case 'SACH':
    case 'SACHGEWERBE':
      return <HouseIcon />
    default:
      return <SecurityIcon />
  }
}

export const formatGeschaeft = (geschaeft: VertragGeschaeft): string => {
  switch (geschaeft) {
    case 'KRANKEN':
      return 'Krankenversicherungen'
    case 'LEBEN':
      return 'Lebensversicherungen'
    case 'SACH':
      return 'Sachversicherungen'
    case 'SACHGEWERBE':
      return 'Sachgewerbeversicherungen'
    default:
      return 'Versicherungen'
  }
}
