import React, { ReactElement } from 'react'
import { useField } from 'formik'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { SelectiveTextInput } from 'components/controls/selective-inputs/SelectiveTextInput'
import { MenschFormModel } from 'form/KundeFormModel'
import { useHaushaltPersonen } from 'state/useHaushaltPersonen'
import { formatMultipleValues, formatSingleValue } from 'utils/formatUtils'
import { EXTERNE_PERSON_OPTION_VALUE, extractFullName } from 'utils/nameUtils'

type VersichertePersonSelectProps = {
  name: string
  label?: string
  withExternePerson?: boolean
  noKinder?: boolean
  multiple?: boolean
  selectiveFieldProps?: SelectiveInputProps
  toMenschOptions?: (mensch: Array<MenschFormModel>) => Option[]
  disabled?: boolean
}

export const VersichertePersonSelect = ({
  name,
  label,
  withExternePerson,
  noKinder,
  multiple,
  selectiveFieldProps,
  toMenschOptions = toMenschOptionDefault,
  disabled,
}: VersichertePersonSelectProps): ReactElement => {
  const [fieldInputProps] = useField(name)
  const { alle, personen } = useHaushaltPersonen()
  const externePersonOption: Option = {
    value: EXTERNE_PERSON_OPTION_VALUE,
    label: 'Externe Person',
  }
  const options = noKinder ? toMenschOptions(personen) : toMenschOptions(alle)
  if (withExternePerson) {
    options.push(externePersonOption)
  }

  if (selectiveFieldProps) {
    return (
      <SelectiveTextInput
        name={name}
        label={label ?? 'Versicherte Person'}
        value={multiple ? '' : formatSingleValue(options, fieldInputProps.value)}
        defaultValue={[]}
        selectiveFieldProps={{
          ...selectiveFieldProps,
          formatValue: (value) => {
            if (Array.isArray(value)) {
              return formatMultipleValues(options, value)
            }
            return formatSingleValue(options, value)
          },
        }}
      />
    )
  }

  return (
    <SelectInput
      options={options}
      name={name}
      label={label ?? 'Versicherte Person'}
      multiple={multiple}
      disabled={disabled}
    />
  )
}

const toMenschOptionDefault = (menschen: MenschFormModel[]): Option[] => {
  return (menschen || []).map((mensch) => ({
    label: extractFullName(mensch),
    value: mensch.meta.finfireId || mensch.meta.myviId,
  }))
}
