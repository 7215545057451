import { filterNotDeleted } from 'components/util/listUtil'
import { KindFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

type UseKinderResult = {
  kinder: KindFormModel[]
  findKind: (kindId: string | undefined) => KindFormModel | undefined
}
export const useKinder = (): UseKinderResult => {
  const { editableKunde } = useEditableKunde()
  const existingKinder = filterNotDeleted(editableKunde.kinder)

  return {
    kinder: existingKinder,
    findKind: (kindId) => {
      return existingKinder.find((kind) => hasId(kind, kindId))
    },
  }
}
