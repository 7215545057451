import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/vorgehensweise-nach-din/vorgehensweise_nach_din_0.png'
import chart1 from 'assets/images/guidance/mitnorm/vorgehensweise-nach-din/vorgehensweise_nach_din_1.png'
import chart2 from 'assets/images/guidance/mitnorm/vorgehensweise-nach-din/vorgehensweise_nach_din_2.png'
import spider from 'assets/images/guidance/mitnorm/vorgehensweise-nach-din/vorgehensweise_nach_din_spider.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/vorgehensweise-nach-din/vorgehensweise_nach_din_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/vorgehensweise-nach-din/vorgehensweise_nach_din_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/vorgehensweise-nach-din/vorgehensweise_nach_din_2.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm4Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

import 'assets/fonts/raleway/raleway-thin.ttf'

export const GuidanceMitNorm4Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart2]
  const chartsFe = [chart0Fe, chart1Fe, chart2Fe, chart2Fe]
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={3}
      previousPagePath={Paths.guidanceMitNorm3}
      nextPagePath={Paths.guidanceMitNorm5}
      guidanceColor='mitnorm'
      numberOfInterPages={4}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title={'VORGEHENSWEISE NACH DIN'} subTitle={'mitNORM'}>
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              <GuidanceMitNormImage
                src={charts[interPageIndex]}
                feSrc={chartsFe[interPageIndex]}
                alt='DIN Methode'
              />
            </Box>
            <Box className={styles.spider}>
              {interPageIndex === 3 && <img src={spider} alt='Bereiche' />}
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
