import { convertToBeteiligungFormModel } from 'form/kunde-form-model-converter/person/vermoegen/beteiligungFormModelConverter'
import { convertToDarlehenFormModel } from 'form/kunde-form-model-converter/person/vermoegen/darlehenFormModelConverter'
import { convertToGeldanlageFormModel } from 'form/kunde-form-model-converter/person/vermoegen/geldanlageFormModelConverter'
import { convertToImmobilieFormModel } from 'form/kunde-form-model-converter/person/vermoegen/immobilieFormModelConverter'
import { convertToSachwertFormModel } from 'form/kunde-form-model-converter/person/vermoegen/sachwertFormModelConverter'
import {
  BeteiligungFormModel,
  DarlehenFormModel,
  GeldAnlageFormModel,
  ImmobilieFormModel,
  SachwertFormModel,
  VermoegenFormModel,
} from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import {
  Beteiligung,
  Darlehen,
  Geldanlage,
  Immobilie,
  Sachwert,
  Vermoegen,
} from 'interfaces/mynorm-api-model-interfaces'

const defaultPersonFormModel = createDefaultPersonFormModel()

const convertToBeteiligungFormModels = (
  beteiligungen: Beteiligung[] | undefined,
): BeteiligungFormModel[] => {
  if (!beteiligungen) {
    return defaultPersonFormModel.vermoegen.beteiligungen
  }
  return beteiligungen.map(convertToBeteiligungFormModel)
}
const convertToDarlehenFormModels = (darlehen: Darlehen[] | undefined): DarlehenFormModel[] => {
  if (!darlehen) {
    return defaultPersonFormModel.vermoegen.darlehen
  }
  return darlehen.map(convertToDarlehenFormModel)
}
const convertToGeldanlageFormModels = (
  geldAnlagen: Geldanlage[] | undefined,
): GeldAnlageFormModel[] => {
  if (!geldAnlagen) {
    return defaultPersonFormModel.vermoegen.geldAnlagen
  }
  return geldAnlagen.map(convertToGeldanlageFormModel)
}
const convertToSachwertFormModels = (sachwerte: Sachwert[] | undefined): SachwertFormModel[] => {
  if (!sachwerte) {
    return defaultPersonFormModel.vermoegen.sachwerte
  }
  return sachwerte.map(convertToSachwertFormModel)
}

const convertToImmobilienFormModels = (
  immobilien: Immobilie[] | undefined,
): ImmobilieFormModel[] => {
  if (!immobilien) {
    return defaultPersonFormModel.vermoegen.immobilien
  }
  return immobilien.map(convertToImmobilieFormModel)
}

export const convertToVermoegenFormModel = (
  vermoegen: Vermoegen | undefined,
): VermoegenFormModel => {
  if (!vermoegen) {
    return defaultPersonFormModel.vermoegen
  }
  return {
    beteiligungen: convertToBeteiligungFormModels(vermoegen.beteiligungen),
    darlehen: convertToDarlehenFormModels(vermoegen.darlehen),
    geldAnlagen: convertToGeldanlageFormModels(vermoegen.geldanlagen),
    sachwerte: convertToSachwertFormModels(vermoegen.sachwerte),
    immobilien: convertToImmobilienFormModels(vermoegen.immobilien),
  }
}
