import { convertZahlweise } from 'form/haushalt-converter/person-converter/vermoegen-converter/vermoegenConverter'
import { GesetzlicheVersorgungFormModel } from 'form/KundeFormModel'
import { GesetzlicheVersorgung } from 'interfaces/mynorm-api-model-interfaces'
import {
  isNullishOrEmpty,
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { mapList } from 'utils/listUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertGesetzlicheVersorgungen = (
  versorgungenFormModel: GesetzlicheVersorgungFormModel[],
  versorgungen: GesetzlicheVersorgung[],
): RecursivePartial<GesetzlicheVersorgung & { isNew?: boolean }>[] => {
  if (isNullishOrEmpty(versorgungenFormModel) && isNullishOrEmpty(versorgungen)) {
    return versorgungen
  }

  return mapList(versorgungenFormModel, versorgungen, convertGesetzlicheVersorgung)
}

const convertGesetzlicheVersorgung = (
  formModel: GesetzlicheVersorgungFormModel,
  source?: GesetzlicheVersorgung,
): GesetzlicheVersorgung => {
  return {
    meta: {
      myviId: formModel.meta.myviId,
      finfireId: mapToRequestModelType(formModel.meta.finfireId, source?.meta.finfireId),
      salesforceId: mapToRequestModelType(formModel.meta.salesforceId, source?.meta?.salesforceId),
      salesforceLastModified: mapToRequestModelType(
        formModel.meta.salesforceLastModified,
        source?.meta?.salesforceLastModified,
      ),
      finfireLastModified: mapToRequestModelType(
        formModel.meta.finfireLastModified,
        source?.meta?.finfireLastModified,
      ),
    },
    zugeordnetePersonId: mapToRequestModelType(
      formModel.zugeordnetePersonId,
      source?.zugeordnetePersonId,
    ),
    gesetzlicheVersorgungsTyp: mapToRequestModelType(
      formModel.gesetzlicheVersorgungsTyp,
      source?.gesetzlicheVersorgungsTyp,
    ),
    altersrenteBeiAnpassung1: mapToNumericRequestModelType(
      formModel.altersrenteBeiAnpassung1,
      source?.altersrenteBeiAnpassung1,
    ),
    altersrenteBeiAnpassung2: mapToNumericRequestModelType(
      formModel.altersrenteBeiAnpassung2,
      source?.altersrenteBeiAnpassung2,
    ),
    anpassungssatz1: mapToNumericPercentRequestModelType(
      formModel.anpassungssatz1,
      source?.anpassungssatz1,
    ),
    anpassungssatz2: mapToNumericPercentRequestModelType(
      formModel.anpassungssatz2,
      source?.anpassungssatz2,
    ),
    beitrag: mapToNumericRequestModelType(formModel.beitrag, source?.beitrag),
    berufsunfaehigkeitsrente: mapToNumericRequestModelType(
      formModel.berufsunfaehigkeitsrente,
      source?.berufsunfaehigkeitsrente,
    ),
    erwerbsminderungsrente: mapToNumericRequestModelType(
      formModel.erwerbsminderungsrente,
      source?.erwerbsminderungsrente,
    ),
    gesetzlicheAltersrente: mapToNumericRequestModelType(
      formModel.gesetzlicheAltersrente,
      source?.gesetzlicheAltersrente,
    ),
    versorgungseinrichtung: mapToRequestModelType(
      formModel.versorgungseinrichtung,
      source?.versorgungseinrichtung,
    ),
    waisenrente: mapToNumericRequestModelType(formModel.waisenrente, source?.waisenrente),
    witwenrente: mapToNumericRequestModelType(formModel.witwenrente, source?.witwenrente),
    zahlweise: convertZahlweise(formModel.zahlweise, source?.zahlweise),
    bemerkungen: mapToRequestModelType(formModel.bemerkungen, source?.bemerkungen),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
    ),
    createFlag: formModel.isNew ?? false,
    deleteFlag: formModel.isDeleted ?? false,
    saveError: false,
  }
}
