import React, { ReactElement, useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { cloneDeep, get, set, unset } from 'lodash'

import { useUpdateHaushalt } from 'api/haushalt/useUpdateHaushalt'
import { mergeVertraegeIntoHaushalt } from 'api/mergeVertraege'
import { OnSelectProps } from 'components/controls/selective-inputs/SelectiveModels'
import { DiffDebugDialog } from 'components/debug/DiffDebugDialog'
import { useErrorMessage } from 'components/debug/useErrorMessage'
import { ExportHaushaltButton } from 'components/export-haushalt/ExportHaushaltButton'
import { PersonalAnlegerProfilForm } from 'components/personal/anlegerprofil/PersonalAnlegerProfilForm'
import { PersonalFinanzenForm } from 'components/personal/finanzen/PersonalFinanzenForm'
import { PersonalHaushaltForm } from 'components/personal/haushalt/PersonalHaushaltForm'
import { PersonalZieleForm } from 'components/personal/ziele/PersonalZieleForm'
import { hasChanges, mergeHaushalt } from 'components/save-haushalt/mergeHaushalt'
import { KundeFormModel } from 'form/KundeFormModel'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { usePreventExit } from 'hooks/usePreventExit'
import { useValidateKunde } from 'hooks/useValidateKunde'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'
import { ObjectToDelete, useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'

export const SaveHaushaltButton = (): ReactElement | null => {
  const [latestHaushaltWithoutVersicherungen, setLatestHaushaltWithoutVersicherungen] =
    useState<HaushaltAggregate>()
  const { haushalt, latestHaushalt } = useHaushaltState()

  useEffect(() => {
    if (latestHaushalt && haushalt) {
      setLatestHaushaltWithoutVersicherungen(mergeVertraegeIntoHaushalt(latestHaushalt, haushalt))
    }
  }, [haushalt, latestHaushalt])

  if (!haushalt || !latestHaushaltWithoutVersicherungen) {
    return null
  }
  return (
    <SaveHaushaltButtonComponent
      haushalt={haushalt}
      latestHaushaltWithoutVersicherungen={latestHaushaltWithoutVersicherungen}
    />
  )
}
const saveSuccessful = (responseHaushalt: HaushaltAggregate | undefined): boolean => {
  if (!responseHaushalt) {
    return true
  }
  return responseHaushalt?.personen.every((person) => {
    const geldanlagen = person.vermoegen.geldanlagen.every((item) => !item.saveError)
    const immobilien = person.vermoegen.immobilien.every((item) => !item.saveError)
    const darlehen = person.vermoegen.darlehen.every((item) => !item.saveError)
    const beteiligungen = person.vermoegen.beteiligungen.every((item) => !item.saveError)
    const sachwerte = person.vermoegen.sachwerte.every((item) => !item.saveError)
    const gvs = person.gesetzlicheVersorgungen.every((item) => !item.saveError)
    return geldanlagen && immobilien && darlehen && beteiligungen && sachwerte && gvs
  })
}

type SaveHaushaltButtonProps = {
  haushalt: HaushaltAggregate
  latestHaushaltWithoutVersicherungen: HaushaltAggregate
}

const SaveHaushaltButtonComponent = ({
  haushalt,
  latestHaushaltWithoutVersicherungen,
}: SaveHaushaltButtonProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const { editableKunde, originalKunde } = useEditableKunde()
  const [fieldPathsSelectedForUpdate, setFieldPathsSelectedForUpdate] = useState<string[]>([])
  const { pathsToDelete, resetPathsToDelete } = useSelectivePathsToUpdateState()

  const {
    mutate: updateHaushalt,
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    reset: resetMutation,
  } = useUpdateHaushalt()
  const errorMessage = useErrorMessage(error)
  const { errors } = useValidateKunde(editableKunde)

  const changesExist = hasChanges(haushalt, latestHaushaltWithoutVersicherungen)
  const isSaveSuccessful = isSuccess && saveSuccessful(data)

  usePreventExit(changesExist)

  useEffect(() => {
    setFieldPathsSelectedForUpdate([])
    resetPathsToDelete()
  }, [haushalt])

  const handleClose = (): void => {
    setOpen(false)
  }

  const resetDialog = (): void => {
    resetMutation()
  }

  const applySelectedUpdates = (
    editedFormModel: KundeFormModel,
    originalFormModel: KundeFormModel,
  ): KundeFormModel => {
    const copyOfOriginal = cloneDeep(originalFormModel)
    pathsToDelete.forEach((objectToDelete: ObjectToDelete) => {
      const indexToDelete = get(copyOfOriginal, objectToDelete.path).findIndex(
        (models: unknown) => get(models, objectToDelete.idField) === objectToDelete.idValue,
      )
      unset(copyOfOriginal, `${objectToDelete.path}.${indexToDelete}`)
    })
    fieldPathsSelectedForUpdate.map((fieldPath) => {
      if (fieldPath.endsWith('.isNew')) {
        const fieldBasePath = fieldPath.replace('.isNew', '')
        set(copyOfOriginal, fieldBasePath, get(editedFormModel, fieldBasePath))
      } else {
        const editedValue = get(editedFormModel, fieldPath)
        set(copyOfOriginal, fieldPath, editedValue)
      }
    })
    copyOfOriginal.hasPartner = editedFormModel.hasPartner
    if (copyOfOriginal.hasPartner && copyOfOriginal.personen.length > 1) {
      copyOfOriginal.personen[1].meta.myviId = editedFormModel.personen[1].meta.myviId
    }
    copyOfOriginal.isImported = editedFormModel.isImported
    return copyOfOriginal
  }

  const handleSave = (): void => {
    if (!haushalt) {
      return
    }
    const kundeWithSelectedUpdates = applySelectedUpdates(editableKunde, originalKunde)
    const haushaltWithSelectedUpdates = mergeHaushalt(kundeWithSelectedUpdates, haushalt)
    const haushaltWithSelectedUpdatedButWithoutVersicherungen = mergeVertraegeIntoHaushalt(
      haushaltWithSelectedUpdates,
      haushalt,
    )
    updateHaushalt(haushaltWithSelectedUpdatedButWithoutVersicherungen, {
      onSuccess: async () => {
        resetPathsToDelete()
        setFieldPathsSelectedForUpdate([])
      },
      onError: async () => {
        resetPathsToDelete()
        setFieldPathsSelectedForUpdate([])
      },
    })
  }

  return (
    <>
      {changesExist && (
        <Badge badgeContent={errors.length} color={'error'}>
          <Button color='primary' variant='outlined' onClick={() => setOpen(true)}>
            Speichern
          </Button>
        </Badge>
      )}
      <Dialog open={open} fullWidth maxWidth='xl' TransitionProps={{ onExited: resetDialog }}>
        <DialogTitle>
          <Stack direction='row' alignItems={'center'} gap={2}>
            <h3>Speichern</h3>
            <Typography>Alle vorgenommenen Änderungen sind farblich hervorgehoben.</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!isSuccess && !isError && (
            <Comparison
              setFieldPathsSelectedForUpdate={setFieldPathsSelectedForUpdate}
              haushalt={haushalt}
              latestHaushalt={latestHaushaltWithoutVersicherungen}
            />
          )}
          {isError && <Alert severity={'error'}>{errorMessage}</Alert>}
          {isSuccess && (
            <>
              {isSaveSuccessful && (
                <Alert severity={'success'}>Die Änderungen wurden erfolgreich gespeichert</Alert>
              )}
              {!isSaveSuccessful && (
                <Alert severity={'warning'}>
                  Es konnten nicht alle Vermögenswerte gespeichert werden.
                </Alert>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isError && (
            <ExportHaushaltButton haushalt={haushalt} buttonLabel={'Als Datei herunterladen'} />
          )}
          {editableKunde && originalKunde && (
            <DiffDebugDialog
              originalData={haushalt}
              requestData={mergeHaushalt(
                applySelectedUpdates(editableKunde, originalKunde),
                haushalt,
              )}
              refetchedData={haushalt}
              error={undefined}
              buttonText={'Pre-save Debug'}
            />
          )}
          <Button variant='outlined' onClick={handleClose} disabled={isLoading}>
            {!isSuccess && !isError ? 'Abbrechen' : 'Schließen'}
          </Button>
          {!isError && !isSuccess && (
            <LoadingButton
              color='primary'
              variant='contained'
              loading={isLoading}
              onClick={handleSave}
            >
              Speichern
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

type ComparisonProps = {
  setFieldPathsSelectedForUpdate: React.Dispatch<React.SetStateAction<string[]>>
  haushalt: HaushaltAggregate
  latestHaushalt: HaushaltAggregate
}
const Comparison = ({
  setFieldPathsSelectedForUpdate,
  haushalt,
  latestHaushalt,
}: ComparisonProps): ReactElement | null => {
  const handleSelected = (selectProps: OnSelectProps): void => {
    setFieldPathsSelectedForUpdate((prevState) => {
      const fields = prevState.filter((value) => value !== selectProps.fieldPath)
      if (selectProps.selected) {
        fields.push(selectProps.fieldPath)
      }
      return fields
    })
  }

  const hasAnlegerprofilChanges = haushalt.personen.some((person, index) => {
    return hasChanges(person.anlegerprofil, latestHaushalt.personen[index]?.anlegerprofil)
  })
  return (
    <Stack>
      <PersonalHaushaltForm
        selectiveFormProps={{ onSelected: handleSelected }}
        hideImportButton={true}
      />
      <PersonalFinanzenForm selectiveFormProps={{ onSelected: handleSelected }} />
      <PersonalZieleForm selectiveFormProps={{ onSelected: handleSelected }} />
      {hasAnlegerprofilChanges && (
        <>
          <h2>Anlegerprofil</h2>
          <h4>Anlegerprofil wurde geändert</h4>
          <div style={{ display: 'none' }}>
            <PersonalAnlegerProfilForm selectiveFormProps={{ onSelected: handleSelected }} />
          </div>
        </>
      )}
    </Stack>
  )
}
