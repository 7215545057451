import { isLebensDetailsRelevant } from 'components/personal/absicherung2/blocks/details/LebensDetailsFormBlock'
import { isRentenDetailsRelevant } from 'components/personal/absicherung2/blocks/details/RentenDetailsFormBlock'
import {
  AnlagestrukturDetailsFormModel,
  BerufshaftpflichtDetailsFormModel,
  BerufsunfaehigkeitsDetailsFormModel,
  BetrieblicheAltersvorsorgeDetailsFormModel,
  DetailsFormModel,
  DreadDiseaseDetailsFormModel,
  ErwerbsunfaehigkeitsDetailsFormModel,
  FunktionsinvaliditaetsDetailsFormModel,
  GesetzlicheKrankenversicherungDetailsFormModel,
  GrundfaehigkeitsDetailsFormModel,
  HaftpflichtDetailsFormModel,
  HausratDetailsFormModel,
  InvaliditaetsDetailsFormModel,
  KfzDetailsFormModel,
  KrankentagegeldDetailsFormModel,
  LebensDetailsFormModel,
  LebensUndRentenDetailsFormModel,
  PflegerenteDetailsFormModel,
  PflegetagegeldDetailsFormModel,
  PrivathaftpflichtDetailsFormModel,
  RechtsschutzDetailsFormModel,
  RentenDetailsFormModel,
  TierhalterHaftpflichtDetailsFormModel,
  TodesfallDetailsFormModel,
  UnfallDetailsFormModel,
  UnfallzusatzDetailsFormModel,
  WohngebaeudeDetailsFormModel,
} from 'form/vertraege/VertragFormModel'
import {
  AnlagestrukturDetails,
  BerufshaftpflichtDetails,
  BerufsunfaehigkeitsDetails,
  BetrieblicheAltersvorsorgeDetails,
  Details,
  DreadDiseaseDetails,
  ErwerbsunfaehigkeitsDetails,
  FunktionsinvaliditaetsDetails,
  GesetzlicheKrankenversicherungDetails,
  GrundfaehigkeitsDetails,
  HaftpflichtDetails,
  HausratDetails,
  InvaliditaetsDetails,
  KfzDetails,
  KrankentagegeldDetails,
  LebensDetails,
  LebensUndRentenDetails,
  PflegerenteDetails,
  PflegetagegeldDetails,
  PrivathaftpflichtDetails,
  RechtsschutzDetails,
  RentenDetails,
  Risiko,
  TierhalterHaftpflichtDetails,
  TodesfallDetails,
  UnfallDetails,
  UnfallzusatzDetails,
  WohngebaeudeDetails,
} from 'interfaces/mynorm-api-model-interfaces'
import {
  isRelevantForDetailsTypAndFinfireProduktId,
  useProduktConfig,
} from 'state/useProduktConfig'
import { toNumberOrUndefined, toPercentOrUndefined } from 'utils/converterUtils'

export const convertDetails = (
  detailsFormModel: DetailsFormModel,
  produktId: string,
  risiken: Risiko[],
): Details => {
  return {
    haftpflichtDetails: convertHaftpflichtDetails(detailsFormModel.haftpflichtDetails, produktId),
    hausratDetails: convertHausratDetails(detailsFormModel.hausratDetails, produktId),
    unfallDetails: convertUnfallDetails(detailsFormModel.unfallDetails, risiken, produktId),
    pflegetagegeldDetails: convertPflegetagegeldDetails(
      detailsFormModel.pflegetagegeldDetails,
      produktId,
    ),
    krankentagegeldDetails: convertKrankentagegeldDetails(
      detailsFormModel.krankentagegeldDetails,
      produktId,
    ),
    gesetzlicheKrankenversicherungDetails: convertGesetzlicheKrankenversicherungDetails(
      detailsFormModel.gesetzlicheKrankenversicherungDetails,
      produktId,
    ),
    privathaftpflichtDetails: convertPrivathaftpflichtDetails(
      detailsFormModel.privathaftpflichtDetails,
      produktId,
    ),
    tierhalterHaftpflichtDetails: convertTierhalterHaftpflichtDetails(
      detailsFormModel.tierhalterHaftpflichtDetails,
      produktId,
    ),
    wohngebaeudeDetails: convertWohngebaeudeDetails(
      detailsFormModel.wohngebaeudeDetails,
      produktId,
    ),
    berufshaftpflichtDetails: convertBerufshaftpflichtDetails(
      detailsFormModel.berufshaftpflichtDetails,
      produktId,
    ),
    invaliditaetsDetails: convertInvaliditaetsDetails(
      detailsFormModel.invaliditaetsDetails,
      produktId,
    ),
    kfzDetails: convertKfzDetails(detailsFormModel.kfzDetails, produktId),
    rechtsschutzDetails: convertRechtsschutzDetails(
      detailsFormModel.rechtsschutzDetails,
      produktId,
    ),
    lebensDetails: convertLebensDetails(
      detailsFormModel.lebensDetails,
      detailsFormModel.betrieblicheAltersvorsorgeDetails,
      produktId,
    ),
    todesfallDetails: convertTodesfallDetails(detailsFormModel.todesfallDetails, produktId),
    anlagestrukturDetails: convertAnlagestrukturDetails(
      detailsFormModel.anlagestrukturDetails,
      produktId,
    ),
    rentenDetails: convertRentenDetails(
      detailsFormModel.rentenDetails,
      detailsFormModel.betrieblicheAltersvorsorgeDetails,
      produktId,
    ),
    betrieblicheAltersvorsorgeDetails: convertBetrieblicheAltersvorsorgeDetails(
      detailsFormModel.betrieblicheAltersvorsorgeDetails,
      produktId,
    ),
    berufsunfaehigkeitsDetails: convertBerufsunfaehigkeitsDetails(
      detailsFormModel.berufsunfaehigkeitsDetails,
      produktId,
    ),
    funktionsinvaliditaetsDetails: convertFunktionsinvaliditaetsDetails(
      detailsFormModel.funktionsinvaliditaetsDetails,
      produktId,
    ),
    grundfaehigkeitsDetails: convertGrundfaehigkeitsDetails(
      detailsFormModel.grundfaehigkeitsDetails,
      produktId,
    ),
    dreadDiseaseDetails: convertDreadDiseaseDetails(
      detailsFormModel.dreadDiseaseDetails,
      produktId,
    ),
    pflegerenteDetails: convertPflegerenteDetails(detailsFormModel.pflegerenteDetails, produktId),
    unfallzusatzDetails: convertUnfallzusatzDetails(
      detailsFormModel.unfallzusatzDetails,
      produktId,
    ),
    erwerbsunfaehigkeitsDetails: convertErwerbsunfaehigkeitsDetails(
      detailsFormModel.erwerbsunfaehigkeitsDetails,
      produktId,
    ),
    lebensUndRentenDetails: convertLebensUndRentenDetails(
      detailsFormModel.lebensUndRentenDetails,
      produktId,
    ),
  }
}

const convertHaftpflichtDetails = (
  formModel: HaftpflichtDetailsFormModel | undefined,
  produktId: string,
): HaftpflichtDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('HAFTPFLICHT', produktId)) {
    return undefined
  }

  return {
    detailsTyp: 'HAFTPFLICHT',
    deckungssummeModus: formModel.deckungssummeModus,
    deckungssummePauschal: toNumberOrUndefined(formModel?.deckungssummePauschal),
    deckungssummePersonen: toNumberOrUndefined(formModel?.deckungssummePersonen),
    deckungssummeSach: toNumberOrUndefined(formModel?.deckungssummeSach),
    deckungssummeVermoegen: toNumberOrUndefined(formModel?.deckungssummeVermoegen),
  }
}

const convertHausratDetails = (
  formModel: HausratDetailsFormModel | undefined,
  produktId: string,
): HausratDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('HAUSRAT', produktId)) {
    return undefined
  }

  return {
    detailsTyp: 'HAUSRAT',
    hausratVersicherungssummeArt: formModel?.hausratVersicherungssummeArt,
    individuelleVersicherungssumme: toNumberOrUndefined(formModel?.individuelleVersicherungssumme),
  }
}

const convertUnfallDetails = (
  unfallDetailsFormModels: UnfallDetailsFormModel[] | undefined,
  risikoFormModels: Risiko[],
  produktId: string,
): UnfallDetails[] | undefined => {
  if (
    !unfallDetailsFormModels ||
    !isRelevantForDetailsTypAndFinfireProduktId('UNFALL', produktId)
  ) {
    return undefined
  }
  return unfallDetailsFormModels.map((unfallDetailsFormModel, unfallDetailIndex) => {
    const risikoFormModel = risikoFormModels[unfallDetailIndex]
    return {
      detailsTyp: 'UNFALL',
      grundSumme: toNumberOrUndefined(unfallDetailsFormModel.grundSumme),
      unfallRente: toNumberOrUndefined(unfallDetailsFormModel.unfallRente),
      progression: toPercentOrUndefined(unfallDetailsFormModel.progression),
      todesfallLeistung: toNumberOrUndefined(unfallDetailsFormModel.todesfallLeistung),
      externePerson: risikoFormModel?.risikoPerson?.externePerson ?? false,
      externePersonName: risikoFormModel?.risikoPerson?.name,
      personId: risikoFormModel?.risikoPerson?.finfireId,
    }
  })
}

const convertPflegetagegeldDetails = (
  formModel: PflegetagegeldDetailsFormModel | undefined,
  produktId: string,
): PflegetagegeldDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('PFLEGETAGEGELD', produktId)) {
    return undefined
  }

  const convertFunction = formModel.leistungenPflegerestkostenProzent
    ? toPercentOrUndefined
    : toNumberOrUndefined

  return {
    detailsTyp: 'PFLEGETAGEGELD',
    ambulantPflegegrad1: convertFunction(formModel.ambulantPflegegrad1),
    ambulantPflegegrad2: convertFunction(formModel.ambulantPflegegrad2),
    ambulantPflegegrad3: convertFunction(formModel.ambulantPflegegrad3),
    ambulantPflegegrad4: convertFunction(formModel.ambulantPflegegrad4),
    ambulantPflegegrad5: convertFunction(formModel.ambulantPflegegrad5),
    stationaerPflegegrad1: convertFunction(formModel.stationaerPflegegrad1),
    stationaerPflegegrad2: convertFunction(formModel.stationaerPflegegrad2),
    stationaerPflegegrad3: convertFunction(formModel.stationaerPflegegrad3),
    stationaerPflegegrad4: convertFunction(formModel.stationaerPflegegrad4),
    stationaerPflegegrad5: convertFunction(formModel.stationaerPflegegrad5),
    leistungenPflegerestkostenProzent: formModel.leistungenPflegerestkostenProzent,
  }
}

const convertKrankentagegeldDetails = (
  formModel: KrankentagegeldDetailsFormModel | undefined,
  produktId: string,
): KrankentagegeldDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('KRANKENTAGEGELD', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'KRANKENTAGEGELD',
    ambulant: formModel.ambulant,
    ausland: formModel.ausland,
    stationaer: formModel.stationaer,
    zahn: formModel.zahn,
    krankentagegeldBeginn: formModel.krankentagegeldBeginn,
    krankentagegeld: toNumberOrUndefined(formModel.krankentagegeld),
    selbstbehalt: toNumberOrUndefined(formModel.selbstbehalt),
  }
}

const convertGesetzlicheKrankenversicherungDetails = (
  formModel: GesetzlicheKrankenversicherungDetailsFormModel | undefined,
  produktId: string,
): GesetzlicheKrankenversicherungDetails | undefined => {
  if (
    !formModel ||
    !isRelevantForDetailsTypAndFinfireProduktId('GESETZLICHE_KRANKENVERSICHERUNG', produktId)
  ) {
    return undefined
  }
  return {
    detailsTyp: 'GESETZLICHE_KRANKENVERSICHERUNG',
    gesetzlicheKrankenversicherungArt: formModel.gesetzlicheKrankenversicherungArt,
    anspruchAufKrankengeld: formModel.anspruchAufKrankengeld,
  }
}

const convertPrivathaftpflichtDetails = (
  formModel: PrivathaftpflichtDetailsFormModel | undefined,
  produktId: string,
): PrivathaftpflichtDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('PRIVATHAFTPFLICHT', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'PRIVATHAFTPFLICHT',
    privatHaftpflichtTarifart: formModel.privatHaftpflichtTarifart,
    einschlussBetreiberhaftpflicht: formModel.einschlussBetreiberhaftpflicht,
    einschlussDiensthaftpflicht: formModel.einschlussDiensthaftpflicht,
    einschlussEhrenamt: formModel.einschlussEhrenamt,
    einschlussGewaesserschadenHaftpflicht: formModel.einschlussGewaesserschadenHaftpflicht,
    einschlussHausGrundbesitzerHaftpflicht: formModel.einschlussHausGrundbesitzerHaftpflicht,
    einschlussWassersportHaftpflicht: formModel.einschlussWassersportHaftpflicht,
    versicherteImmobilien: formModel.versicherteImmobilien,
    versicherteWasserfahrzeuge: formModel.versicherteWasserfahrzeuge,
  }
}

const convertTierhalterHaftpflichtDetails = (
  formModel: TierhalterHaftpflichtDetailsFormModel | undefined,
  produktId: string,
): TierhalterHaftpflichtDetails | undefined => {
  if (
    !formModel ||
    !isRelevantForDetailsTypAndFinfireProduktId('TIERHALTERHAFTPFLICHT', produktId)
  ) {
    return undefined
  }
  return {
    detailsTyp: 'TIERHALTERHAFTPFLICHT',
    tierArt: formModel.tierArt,
  }
}
const convertWohngebaeudeDetails = (
  formModel: WohngebaeudeDetailsFormModel | undefined,
  produktId: string,
): WohngebaeudeDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('WOHNGEBAUEDE', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'WOHNGEBAUEDE',
    wohnflaeche: toNumberOrUndefined(formModel.wohnflaeche),
    wert1914: toNumberOrUndefined(formModel.wert1914),
    einschlussBauleistung: formModel.einschlussBauleistung,
    einschlussElementar: formModel.einschlussElementar,
    einschlussFeuer: formModel.einschlussFeuer,
    einschlussFeuerrohbau: formModel.einschlussFeuerrohbau,
    einschlussLeitungswasser: formModel.einschlussLeitungswasser,
    einschlussSturm: formModel.einschlussSturm,
    gleitenderNeuwert: formModel.gleitenderNeuwert,
    unterversicherungsverzicht: formModel.unterversicherungsverzicht,
    deckungssumme: toNumberOrUndefined(formModel.deckungssumme),
  }
}
const convertBerufshaftpflichtDetails = (
  formModel: BerufshaftpflichtDetailsFormModel | undefined,
  produktId: string,
): BerufshaftpflichtDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('BERUFSHAFTPFLICHT', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'BERUFSHAFTPFLICHT',
    syndikusanwalt: formModel.syndikusanwalt,
    einschlussPrivathaftpflicht: formModel.einschlussPrivathaftpflicht,
    betriebsbeauftragte: formModel.betriebsbeauftragte,
  }
}
const convertInvaliditaetsDetails = (
  formModel: InvaliditaetsDetailsFormModel | undefined,
  produktId: string,
): InvaliditaetsDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('INVALIDITAET', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'INVALIDITAET',
    todesfallleistung: toNumberOrUndefined(formModel.todesfallleistung),
    invaliditaetsrenteMonatlich: toNumberOrUndefined(formModel.invaliditaetsrenteMonatlich),
    invaliditaetssumme: toNumberOrUndefined(formModel.invaliditaetssumme),
  }
}
const convertKfzDetails = (
  formModel: KfzDetailsFormModel | undefined,
  produktId: string,
): KfzDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('KFZ', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'KFZ',
    deckungssummeKfz: toNumberOrUndefined(formModel.deckungssummeKfz),
    kfzVersicherungsumfang: formModel.kfzVersicherungsumfang,
  }
}
const convertRechtsschutzDetails = (
  formModel: RechtsschutzDetailsFormModel | undefined,
  produktId: string,
): RechtsschutzDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('RECHTSSCHUTZ', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'RECHTSSCHUTZ',
    versicherungssummeModus: formModel.versicherungssummeModus,
    einschlussBeruf: formModel.einschlussBeruf,
    einschlussMieter: formModel.einschlussMieter,
    einschlussPrivat: formModel.einschlussPrivat,
    einschlussVerkehr: formModel.einschlussVerkehr,
    rechtsschutzTarifart: formModel.rechtsschutzTarifart,
    einschlussVermieter: formModel.einschlussVermieter,
    versicherungssumme: toNumberOrUndefined(formModel.versicherungssumme),
  }
}

const convertLebensDetails = (
  formModel: LebensDetailsFormModel | undefined,
  bavDetailsFormModel: BetrieblicheAltersvorsorgeDetailsFormModel | undefined,
  produktId: string,
): LebensDetails | undefined => {
  const isRelevant = isLebensDetailsRelevant(
    produktId,
    useProduktConfig.getState().produktMappings,
    bavDetailsFormModel?.bavTyp,
  )
  if (!formModel || !isRelevant) {
    return undefined
  }
  return {
    detailsTyp: 'LEBEN',
    versicherungssumme: toNumberOrUndefined(formModel.versicherungssumme),
    ablaufleistungPrognostiziert: toNumberOrUndefined(formModel.ablaufleistungPrognostiziert),
  }
}

const convertTodesfallDetails = (
  formModel: TodesfallDetailsFormModel | undefined,
  produktId: string,
): TodesfallDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('TODESFALL', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'TODESFALL',
    todesfallleistung: toNumberOrUndefined(formModel.todesfallleistung),
    todesfallleistungMitUeberschuss: toNumberOrUndefined(formModel.todesfallleistungMitUeberschuss),
  }
}
const convertAnlagestrukturDetails = (
  formModel: AnlagestrukturDetailsFormModel | undefined,
  produktId: string,
): AnlagestrukturDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('ANLAGENSTRUKTUR', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'ANLAGENSTRUKTUR',
    aktien: toPercentOrUndefined(formModel.aktien),
    zinsanlagen: toPercentOrUndefined(formModel.zinsanlagen),
    immobilien: toPercentOrUndefined(formModel.immobilien),
    beteiligungen: toPercentOrUndefined(formModel.beteiligungen),
    sonstiges: toPercentOrUndefined(formModel.sonstiges),
    riskanteAnteile: toPercentOrUndefined(formModel.riskanteAnteile),
    quelleRisikoklassifizierung: formModel.quelleRisikoklassifizierung,
  }
}

const convertRentenDetails = (
  formModel: RentenDetailsFormModel | undefined,
  bavFormModel: BetrieblicheAltersvorsorgeDetailsFormModel | undefined,
  produktId: string,
): RentenDetails | undefined => {
  const isRelevant = isRentenDetailsRelevant(
    produktId,
    useProduktConfig.getState().produktMappings,
    bavFormModel?.bavTyp,
  )
  if (!formModel || !isRelevant) {
    return undefined
  }
  return {
    detailsTyp: 'RENTE',
    beginnRentenzahlung: formModel.beginnRentenzahlung?.toDate(),
    renteMonatlich: toNumberOrUndefined(formModel.renteMonatlich),
    renteMonatlichMitUeberschuss: toNumberOrUndefined(formModel.renteMonatlichMitUeberschuss),
    rentensteigerungJaehrlich: toPercentOrUndefined(formModel.rentensteigerungJaehrlich),
  }
}

const convertBetrieblicheAltersvorsorgeDetails = (
  formModel: BetrieblicheAltersvorsorgeDetailsFormModel | undefined,
  produktId: string,
): BetrieblicheAltersvorsorgeDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('BAV', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'BAV',
    bavTyp: formModel.bavTyp,
    durchfuehrungsweg: formModel.durchfuehrungsweg,
    leistungszusageProzentual: toPercentOrUndefined(formModel.leistungszusageProzentual),
    pauschalversteuert: formModel.pauschalversteuert,
  }
}
const convertBerufsunfaehigkeitsDetails = (
  formModel: BerufsunfaehigkeitsDetailsFormModel | undefined,
  produktId: string,
): BerufsunfaehigkeitsDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('BERUFSUNFAEHIGKEIT', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'BERUFSUNFAEHIGKEIT',
    berufsunfaehigkeitsRente: toNumberOrUndefined(formModel.berufsunfaehigkeitsRente),
    berufsunfaehigkeitsRenteMitUeberschuss: toNumberOrUndefined(
      formModel.berufsunfaehigkeitsRenteMitUeberschuss,
    ),
    leistungsendeBerufsunfaehigkeitsRente:
      formModel.leistungsendeBerufsunfaehigkeitsRente?.toDate(),
    weiterspargarantie: formModel.weiterspargarantie,
    weiterspargarantieInProzent: toPercentOrUndefined(formModel.weiterspargarantieInProzent),
    abstrakterVerweisungsverzicht: formModel.abstrakterVerweisungsverzicht,
    dienstunfaehigkeitsKlausel: formModel.dienstunfaehigkeitsKlausel,
  }
}
const convertFunktionsinvaliditaetsDetails = (
  formModel: FunktionsinvaliditaetsDetailsFormModel | undefined,
  produktId: string,
): FunktionsinvaliditaetsDetails | undefined => {
  if (
    !formModel ||
    !isRelevantForDetailsTypAndFinfireProduktId('FUNKTIONSINVALIDITAETS_RENTE', produktId)
  ) {
    return undefined
  }
  return {
    detailsTyp: 'FUNKTIONSINVALIDITAETS_RENTE',
    funktionsinvaliditaetsRenteMonatlich: toNumberOrUndefined(
      formModel.funktionsinvaliditaetsRenteMonatlich,
    ),
  }
}
const convertGrundfaehigkeitsDetails = (
  formModel: GrundfaehigkeitsDetailsFormModel | undefined,
  produktId: string,
): GrundfaehigkeitsDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('GRUNDFAEHIGKEIT', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'GRUNDFAEHIGKEIT',
    grundefaehigkeitsRenteMonatlich: toNumberOrUndefined(formModel.grundefaehigkeitsRenteMonatlich),
  }
}
const convertDreadDiseaseDetails = (
  formModel: DreadDiseaseDetailsFormModel | undefined,
  produktId: string,
): DreadDiseaseDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('DREAD_DISEASE', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'DREAD_DISEASE',
    versicherungssummeDreadDisease: toNumberOrUndefined(formModel.versicherungssummeDreadDisease),
  }
}
const convertPflegerenteDetails = (
  formModel: PflegerenteDetailsFormModel | undefined,
  produktId: string,
): PflegerenteDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('PFLEGE_RENTE', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'PFLEGE_RENTE',
    ambulantPflegerenteGrad1Monatlich: toNumberOrUndefined(
      formModel.ambulantPflegerenteGrad1Monatlich,
    ),
    ambulantPflegerenteGrad2Monatlich: toNumberOrUndefined(
      formModel.ambulantPflegerenteGrad2Monatlich,
    ),
    ambulantPflegerenteGrad3Monatlich: toNumberOrUndefined(
      formModel.ambulantPflegerenteGrad3Monatlich,
    ),
    ambulantPflegerenteGrad4Monatlich: toNumberOrUndefined(
      formModel.ambulantPflegerenteGrad4Monatlich,
    ),
    ambulantPflegerenteGrad5Monatlich: toNumberOrUndefined(
      formModel.ambulantPflegerenteGrad5Monatlich,
    ),
    stationaerPflegerenteGrad1Monatlich: toNumberOrUndefined(
      formModel.stationaerPflegerenteGrad1Monatlich,
    ),
    stationaerPflegerenteGrad2Monatlich: toNumberOrUndefined(
      formModel.stationaerPflegerenteGrad2Monatlich,
    ),
    stationaerPflegerenteGrad3Monatlich: toNumberOrUndefined(
      formModel.stationaerPflegerenteGrad3Monatlich,
    ),
    stationaerPflegerenteGrad4Monatlich: toNumberOrUndefined(
      formModel.stationaerPflegerenteGrad4Monatlich,
    ),
    stationaerPflegerenteGrad5Monatlich: toNumberOrUndefined(
      formModel.stationaerPflegerenteGrad5Monatlich,
    ),
  }
}
const convertUnfallzusatzDetails = (
  formModel: UnfallzusatzDetailsFormModel | undefined,
  produktId: string,
): UnfallzusatzDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('UNFALLZUSATZ', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'UNFALLZUSATZ',
    versicherungsssummeUnfall: toNumberOrUndefined(formModel.versicherungsssummeUnfall),
  }
}

const convertErwerbsunfaehigkeitsDetails = (
  formModel: ErwerbsunfaehigkeitsDetailsFormModel | undefined,
  produktId: string,
): ErwerbsunfaehigkeitsDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('ERWERBSUNFAEHIGKEIT', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'ERWERBSUNFAEHIGKEIT',
    erwerbsminderungsrente: toNumberOrUndefined(formModel.erwerbsminderungsrente),
  }
}

const convertLebensUndRentenDetails = (
  formModel: LebensUndRentenDetailsFormModel | undefined,
  produktId: string,
): LebensUndRentenDetails | undefined => {
  if (!formModel || !isRelevantForDetailsTypAndFinfireProduktId('LEBENS_UND_RENTEN', produktId)) {
    return undefined
  }
  return {
    detailsTyp: 'LEBENS_UND_RENTEN',
    rueckkaufwert: toNumberOrUndefined(formModel.rueckkaufwert),
    rueckkaufwertZeitpunkt: formModel.rueckkaufwertZeitpunkt?.toDate(),
    renditeklasse: formModel.renditeklasse,
    ausbildungssparenKindId:
      formModel.zielArt === 'AUSBILDUNGSSPAREN' ? formModel.ausbildungssparenKindId : undefined,
    zielAltersvorsorge: formModel.zielArt === 'ALTERSVORSORGE',
    zielId: formModel.zielArt === 'ZIEL' ? formModel.zielId : undefined,
  }
}
