import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { InvaliditaetsDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Invalidität'

type InvaliditaetsDetailsFormBlockProps = BaseDetailsFormBlockProps

export const InvaliditaetsDetailsFormBlock = ({
  name,
  produktId,
}: InvaliditaetsDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.invaliditaetsDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('INVALIDITAET', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={4} xs={12}>
        <NumberInput
          name={`${baseName}.invaliditaetsrenteMonatlich`}
          label={'Invaliditätsrente mtl.'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <NumberInput
          name={`${baseName}.invaliditaetssumme`}
          label={'Invaliditätssumme (einmalig)'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <NumberInput
          name={`${baseName}.todesfallleistung`}
          label={'Todesfallleistung'}
          inputUnit={'€'}
        />
      </Grid>
    </Grid>
  )
}

type InvaliditaetsDetailsReadViewProps = BaseDetailsReadViewProps & {
  invaliditaetsDetails: InvaliditaetsDetails | undefined
}

export const InvaliditaetsDetailsReadView = ({
  produktId,
  invaliditaetsDetails,
}: InvaliditaetsDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('INVALIDITAET', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Invaliditätsrente mtl.'}
        value={formatCurrency(invaliditaetsDetails?.invaliditaetsrenteMonatlich)}
      />
      <LabelValueEntry
        label={'Invaliditätssumme (einmalig)'}
        value={formatCurrency(invaliditaetsDetails?.invaliditaetssumme)}
      />
      <LabelValueEntry
        label={'Todesfallleistung'}
        value={formatCurrency(invaliditaetsDetails?.todesfallleistung)}
      />
    </Stack>
  )
}
