import React, { ReactElement, SyntheticEvent, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { CockpitCard } from 'components/cockpit/CockpitCard'
import { DinLevelResultTable } from 'components/cockpit/DinLevelResultTable'
import styles from 'components/cockpit/DinScoreCard.module.scss'
import { LinearDisplay } from 'components/cockpit/LinearDisplay'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

const scoreFormat = new Intl.NumberFormat('de', { maximumFractionDigits: 0 })
const formatLevel = (level: number): string => `Stufe ${level}`
type DinScoreCardProps = {
  level: number
  label: string
  score: number | undefined
  results: GesamtErgebnisse | undefined
  haushalt: HaushaltAggregate | undefined
}

export const DinScoreCard = ({
  level,
  label,
  score = 0,
  results,
  haushalt,
}: DinScoreCardProps): ReactElement => {
  const scorePercent = score * 100
  const formattedScore = useMemo(() => `${scoreFormat.format(scorePercent)} %`, [score])
  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <>
      <CockpitCard
        title={formatLevel(level)}
        titleAlign='center'
        onClick={() => setDialogOpen(true)}
      >
        <Stack className={styles.dinScoreContent}>
          <Box className={styles.linearDisplayBox}>
            <LinearDisplay value={scorePercent} formattedValue={formattedScore} />
          </Box>
          <Box>
            <Typography className={styles.subtitle}>{label}</Typography>
          </Box>
        </Stack>
      </CockpitCard>
      <DinLevelResult
        open={dialogOpen}
        level={level}
        label={label}
        results={results}
        onClose={() => setDialogOpen(false)}
        haushalt={haushalt}
      />
    </>
  )
}

type DinLevelResultProps = {
  open: boolean
  level: number
  label: string
  results: GesamtErgebnisse | undefined
  haushalt: HaushaltAggregate | undefined
  onClose: () => void
}

const DinLevelResult = ({
  open,
  level,
  label,
  results,
  haushalt,
  onClose,
}: DinLevelResultProps): ReactElement => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const personResult =
    results?.scorePersonen?.[selectedTabIndex]?.persoenlichesErgebnis?.filter(
      (erg) => erg.stufe === level,
    ) ?? []

  const handleTabChange = (event: SyntheticEvent, value: number): void => {
    setSelectedTabIndex(value)
  }

  const handleClose = (): void => {
    setSelectedTabIndex(0)
    onClose()
  }

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'} scroll='paper'>
      <DialogTitle>
        <Stack className={styles.dinLevelResultTitleBox} direction='row'>
          <Box>
            <h6>{formatLevel(level)}</h6>
            <h3>{label}</h3>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Tabs onChange={handleTabChange} value={selectedTabIndex}>
            {haushalt?.personen.map((personAggregate) => {
              return (
                <Tab
                  key={personAggregate.person.meta.myviId}
                  iconPosition={'start'}
                  icon={<PersonIcon />}
                  label={extractFullName(personAggregate.person)}
                  sx={{ minHeight: 0 }}
                />
              )
            })}
          </Tabs>
          <DinLevelResultTable results={personResult} level={level} haushalt={haushalt} />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
