import { Option } from 'components/controls/inputs/SelectInput'
import { ZahlweiseFormModel } from 'form/KundeFormModel'
import {
  Anrede,
  Baumassnahmen,
  Besoldungsgruppe,
  BesondereTaetigkeit,
  BeteiligungTyp,
  Beziehungsstatus,
  Bundesland,
  DarlehenTyp,
  EntgeltfortzahlungArbeitgeber,
  GeldanlageTyp,
  GeldanlageZielArt,
  GesetzlicheVersorgungsTyp,
  ImmobilieNutzungsArt,
  MenschHobby,
  ObjektArt,
  Risikoklasse,
  SachwertTyp,
  Taetigkeitsstatus,
  TilgungsTyp,
  UnternehmensTyp,
  Zahlart,
  ZielArt,
} from 'interfaces/mynorm-api-model-interfaces'

export const getOptionLabel = <T>(
  value: T | undefined | null,
  options: Option<T>[],
): string | undefined => {
  if (value === undefined || value === null) {
    return undefined
  }
  return options.find((option) => option.value === value)?.label
}

const noneLabel = '<Keine Angabe>'

export const anredeOptions: Option<Anrede>[] = [
  { value: 'NONE', label: noneLabel },
  { value: 'HERR', label: 'Herr' },
  { value: 'FRAU', label: 'Frau' },
]

export const geschlechtOptions: Option<Anrede>[] = [
  { value: 'NONE', label: noneLabel },
  { value: 'HERR', label: 'männlich' },
  { value: 'FRAU', label: 'weiblich' },
]

export const risikenOptions: Option<MenschHobby>[] = [
  { value: 'JAGD', label: 'Jagdsport' },
  { value: 'PFERD', label: 'Pferde' },
  { value: 'HUND', label: 'Hunde' },
]

export const beziehungsStatusOptions: Option<Beziehungsstatus>[] = [
  { value: 'VERHEIRATET', label: 'verheiratet' },
  { value: 'PARTNERSCHAFT', label: 'verpartnert' },
  { value: 'EINGETRAGENE_PARTNERSCHAFT', label: 'eingetr. Partnerschaft' },
]

export const taetigkeitsStatusOptions: Option<Taetigkeitsstatus>[] = [
  { value: 'NONE', label: noneLabel },
  { value: 'ANGESTELLT', label: 'Angestellt' },
  { value: 'ARBEITER', label: 'Arbeiter' },
  { value: 'BEAMTER', label: 'Beamter' },
  { value: 'RENTNER', label: 'Rentner' },
  { value: 'NICHT_TAETIG', label: 'Nicht tätig' },
  { value: 'OHNE_EINKOMMEN', label: 'Ohne Einkommen' },
  { value: 'ARBEITSLOS', label: 'Arbeitslos' },
  { value: 'SELBSTAENDIG', label: 'Selbständig' },
  { value: 'HANDWERKER_SELBSTAENDIG', label: 'Handwerklich selbständig' },
  { value: 'GESCHAEFTSFUEHRER', label: 'Geschäftsführer' },
  { value: 'PENSIONSZUSAGE_GESCHAEFTSFUEHRER', label: 'Pensionszusage' },
  { value: 'VORSTAND', label: 'Vorstand' },
  { value: 'BEZIEHER_VON_WITWEN_ODER_WAISENRENTEN', label: 'Bezieher Witwen/Waisenrente' },
  { value: 'SOLDAT', label: 'Soldat' },
  { value: 'IN_PENSION', label: 'Pensionär' },
  { value: 'OEFFENTLICHER_DIENST', label: 'Öffentlicher Dienst' },
  { value: 'STUDENT', label: 'Student' },
  { value: 'AKADEMIKER', label: 'Akademiker' },
  { value: 'FREIBERUFLER', label: 'Freiberufler' },
  { value: 'SCHUELER', label: 'Schüler' },
  { value: 'AZUBI', label: 'Azubi' },
  { value: 'HAUSFRAU_MANN', label: 'Hausfrau/-mann/Erziehungszeit' },
  { value: 'PRAKTIKANT', label: 'Praktikant' },
  { value: 'BFD', label: 'Bundesfreiwilligendienst' },
  { value: 'FSJ', label: 'Freiwilliges soziales Jahr' },
  { value: 'FWD', label: 'Freiwilliger Wehrdienst' },
  { value: 'WEHRPFL_ZIVI', label: 'Wehrpflicht / Zivi' },
  { value: 'SONSTIGE', label: 'Sonstige' },
  { value: 'BU_RENTENEMPFAENGER', label: 'BU-Rentenempfänger' },
  { value: 'PFLEGERENTEN_EMPFAENGER', label: 'Pflegerenten-Empfänger' },
  { value: 'PRIVATIER', label: 'Privatier' },
  { value: 'BEAMTER_PROBE', label: 'Beamte/r auf Probe' },
  { value: 'BEAMTER_ANWAERTER', label: 'Beamte/r Anwärter' },
  { value: 'ZEITSOLDAT', label: 'Soldat auf Zeit' },
]

export const besoldungsGruppeOptions: Option<Besoldungsgruppe>[] = [
  { value: 'A2', label: 'A2' },
  { value: 'A3', label: 'A3' },
  { value: 'A4', label: 'A4' },
  { value: 'A5', label: 'A5' },
  { value: 'A6', label: 'A6' },
  { value: 'A7', label: 'A7' },
  { value: 'A8', label: 'A8' },
  { value: 'A9', label: 'A9' },
  { value: 'A10', label: 'A10' },
  { value: 'A11', label: 'A11' },
  { value: 'A12', label: 'A12' },
  { value: 'A13', label: 'A13' },
  { value: 'A14', label: 'A14' },
  { value: 'A15', label: 'A15' },
  { value: 'A16', label: 'A16' },
  { value: 'B1', label: 'B1' },
  { value: 'B2', label: 'B2' },
  { value: 'B3', label: 'B3' },
  { value: 'B4', label: 'B4' },
  { value: 'B5', label: 'B5' },
  { value: 'B6', label: 'B6' },
  { value: 'B7', label: 'B7' },
  { value: 'B8', label: 'B8' },
  { value: 'B9', label: 'B9' },
  { value: 'B10', label: 'B10' },
  { value: 'B11', label: 'B11' },
  { value: 'W1', label: 'W1' },
  { value: 'W2', label: 'W2' },
  { value: 'W3', label: 'W3' },
  { value: 'R1', label: 'R1' },
  { value: 'R2', label: 'R2' },
  { value: 'R3', label: 'R3' },
  { value: 'R4', label: 'R4' },
  { value: 'R5', label: 'R5' },
  { value: 'R6', label: 'R6' },
  { value: 'R7', label: 'R7' },
  { value: 'R8', label: 'R8' },
  { value: 'R9', label: 'R9' },
  { value: 'R10', label: 'R10' },
  { value: 'AW_A2', label: 'AW_A2' },
  { value: 'AW_A3', label: 'AW_A3' },
  { value: 'AW_A4', label: 'AW_A4' },
  { value: 'AW_A5', label: 'AW_A5' },
  { value: 'AW_A6', label: 'AW_A6' },
  { value: 'AW_A7', label: 'AW_A7' },
  { value: 'AW_A8', label: 'AW_A8' },
  { value: 'AW_A9', label: 'AW_A9' },
  { value: 'AW_A10', label: 'AW_A10' },
  { value: 'AW_A11', label: 'AW_A11' },
  { value: 'AW_A12', label: 'AW_A12' },
  { value: 'AW_A13', label: 'AW_A13' },
  { value: 'AW_R1', label: 'AW_R1' },
]

export const besondereTaetigkeitenStatusOptions: Option<BesondereTaetigkeit>[] = [
  { value: 'BETRIEBSBEAUFTRAGT', label: 'Angestellte mit besond. Aufgaben/Betriebsbeauftrage' },
  {
    value: 'ORGAN_JURISTISCHE_PERSON_ODER_VEREIN',
    label: 'Organe von juristischen Personen/Körperschaften/Vereinen',
  },
  { value: 'SYNDIKUSANWALT_ODER_ANGESTELLTER_ARZT', label: 'Syndikusanwälte/Angestellte Ärzte' },
  { value: 'EHRENAMT', label: 'Ehrenamtlich tätig' },
]

export const entgeltforzahlungArbeitgeberOptions: Option<EntgeltfortzahlungArbeitgeber>[] = [
  { value: 'KEIN_ZUSCHUSS', label: 'Kein Zuschuss' },
  { value: 'AUFSTOCKUNG_AUF_NETTO', label: 'Aufstockung auf Netto' },
  { value: 'ABSOLUTER_BETRAG', label: 'Absoluter Beitrag' },
]

export const objektArtOptions: Option<ObjektArt>[] = [
  { value: 'DOPPELHAUS', label: 'Doppelhaus' },
  { value: 'DOPPELHAUSHAELFTE', label: 'Doppelhaushälfte' },
  { value: 'EIGENTUMSWOHNUNG', label: 'Eigentumswohnung' },
  { value: 'EINFAMILIENHAUS', label: 'Einfamilienhaus' },
  { value: 'EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG', label: 'Einfamilienhaus mit Einliegerwohnung' },
  { value: 'GRUNDSTUECK', label: 'Grundstück' },
  { value: 'MEHRFAMILIENHAUS', label: 'Mehrfamilienhaus' },
  { value: 'REIHENENDHAUS', label: 'Reihenhaus' },
  { value: 'REIHENMITTELHAUS', label: 'Reihenmittelhaus' },
  { value: 'ZWEIFAMILIENHAUS', label: 'Zweifamilienhaus' },
  { value: 'GEWERBEEINHEIT', label: 'Gewerbeeinheit' },
  { value: 'FERIENHAUS', label: 'Ferienhaus' },
  { value: 'SONSTIGE', label: 'Sonstige' },
  { value: 'MIETWOHNUNG', label: 'Mietwohnung' },
  { value: 'EINLIEGERWOHNUNG', label: 'Einliegerwohnung' },
]

export const immobilieNutzungsArtOptions: Option<ImmobilieNutzungsArt>[] = [
  { value: 'EIGENNUTZUNG', label: 'Eigennutzung' },
  { value: 'FREMDNUTZUNG', label: 'Fremdnutzung' },
  { value: 'GEMIETET', label: 'Gemietet' },
]

export const baumassnahmenOptions: Option<Baumassnahmen>[] = [
  { value: 'KEINE', label: 'Keine' },
  { value: 'IM_BAU', label: 'Im Bau' },
  { value: 'SANIERUNG', label: 'Sanierung/Renovierung' },
]

export const geldAnlageTypOptions: Option<GeldanlageTyp>[] = [
  { value: 'AKTIE_DEPOT', label: 'Aktie / Depot' },
  { value: 'FONDS', label: 'Fonds' },
  { value: 'RENTENPAPIER', label: 'Rentenpapier' },
  { value: 'SPARPLAN', label: 'Sparplan' },
  { value: 'SONSTIGE', label: 'Sonstige' },
  { value: 'FONDSSPARPLAN', label: 'Fondssparplan' },
  { value: 'BAUSPARVERTRAG', label: 'Bausparvertrag' },
  { value: 'FESTGELD', label: 'Festgeld' },
  { value: 'BUNDESWERTPAPIERE', label: 'Bundeswertpapiere' },
  { value: 'SPARBUCH', label: 'Sparbuch' },
  { value: 'TAGESGELD', label: 'Tagesgeld' },
  { value: 'GIROKONTO', label: 'Girokonto' },
]

export const geldAnlageVerwendungszweckTypOptions: Option<GeldanlageZielArt>[] = [
  { value: 'KEIN_ZIEL', label: 'Kein Ziel' },
  { value: 'ALTERSVORSORGE', label: 'Altersvorsorge' },
  { value: 'LIQUIDITAETSRESERVE', label: 'Liquiditätsreserve' },
  { value: 'FOLGEKOSTEN_UNFALL', label: 'Folgekosten Unfall' },
  { value: 'TODESFALLBEDINGTE_EINBUSSEN', label: 'Todesfallbedingte Einbußen' },
  { value: 'EIGENKAPITAL_IMMOBILIE', label: 'Eigenkapital Immobilie' },
  { value: 'ZIEL', label: 'Ziel' },
  { value: 'INSTANDHALTUNGSRUECKLAGE', label: 'Instandhaltungsrücklage' },
  { value: 'AUSBILDUNGSSPAREN', label: 'Ausbildungssparen' },
]

export const zahlweiseOptions: Option<ZahlweiseFormModel>[] = [
  { value: 'JAEHRLICH', label: 'jährlich' },
  { value: 'HALB_JAEHRLICH', label: 'halbjährlich' },
  { value: 'VIERTEL_JAEHRLICH', label: 'vierteljährlich' },
  { value: 'MONATLICH', label: 'monatlich' },
  { value: 'EINMALZAHLUNG', label: 'Einmahlzahlung' },
]

export const zahlartOptions: Option<Zahlart>[] = [
  { value: 'LASTSCHRIFT', label: 'Lastschrift' },
  { value: 'SELBSTZAHLER', label: 'Selbstzahler' },
  { value: 'VERMOEGENSWIRKSAME_LEISTUNGEN', label: 'Vermögenswirksame Leistungen' },
  { value: 'SEPA_BASISLASTSCHRIFT', label: 'SEPA Lastschrift' },
  { value: 'SEPA_FIRMENLASTSCHRIFT', label: 'SEPA Firmenlastschrift' },
  { value: 'SONSTIGE', label: 'Sonstige' },
]

export const darlehenTypOptions: Option<DarlehenTyp>[] = [
  { value: 'FREMDGENUTZE_IMMOBILIE', label: 'Fremdgenutzte Immobilie' },
  { value: 'SONSTIGE', label: 'Sonstige Verbindlichkeit' },
  { value: 'EIGENGENUTZE_IMMOBILIE_BAUSPAR', label: 'Eigengenutzte Immobilie / Bauspardarlehen' },
  { value: 'KONSUMENTEN_KREDIT', label: 'Konsumentenkredite' },
  { value: 'BUERGSCHAFT', label: 'Bürgschaft' },
  { value: 'ANDERE_EVENTUALVERBINDLICHKEITEN', label: 'Andere Eventualverbindlichkeiten' },
]
export const tilgungTypOptions: Option<TilgungsTyp>[] = [
  { value: 'GLEICHE_RATEN', label: 'Tilgung durch gleiche Raten' },
  { value: 'ANNUITAET', label: 'Annuitätische Raten' },
  { value: 'ENDFAELLIG', label: 'Endfällige Tilgung' },
]

export const beteiligungTypOptions: Option<BeteiligungTyp>[] = [
  { value: 'SCHIFF', label: 'Schiff' },
  { value: 'MEDIEN', label: 'Medien' },
  { value: 'WINDKRAFT', label: 'Windkraft' },
  { value: 'LEASING', label: 'Leasing' },
  { value: 'IMMOBILIEN', label: 'Immobilien' },
  { value: 'VENTURE_CAPITAL', label: 'Venture Capital' },
  { value: 'SONSTIGES', label: 'Sonstiges' },
  { value: 'UNTERNEHMEN', label: 'Unternehmen' },
]

export const beteiligungUnternehmenTypOptions: Option<UnternehmensTyp>[] = [
  { value: 'AG', label: 'AG' },
  { value: 'KGAA', label: 'KG AA' },
  { value: 'GMBH', label: 'GmbH' },
  { value: 'UG', label: 'UG' },
  { value: 'EG', label: 'EG' },
  { value: 'GBR', label: 'GBR' },
  { value: 'KG', label: 'KG' },
  { value: 'OHG', label: 'OHG' },
  { value: 'SILENT_SOCIETY', label: 'Stille Gesellschaft' },
]

export const zielArtOptions: Option<ZielArt>[] = [
  { value: 'IMMOBILIENWUNSCH', label: 'Immobilienwunsch' },
  { value: 'ERSATZINVESTITION', label: 'Ersatzinvestition' },
  { value: 'SONSTIGES_ZIEL', label: 'Sonstige' },
]

export const gesetzlicheVersorgungTypOptions: Option<GesetzlicheVersorgungsTyp>[] = [
  { value: 'DEUTSCHE_RENTENVERSICHERUNG', label: 'Deutsche Rentenversicherung' },
  { value: 'BERUFSSTAENDISCHES_VERSORGUNGSWERK', label: 'Berufsständisches Versorgungswerk' },
  { value: 'ZUSATZVERSORGUNGSKASSE', label: 'Zusatzversorgungskasse' },
  { value: 'BEAMTENVERSORGUNG', label: 'Beamtenversorgung' },
  { value: 'ANDERE_VERSORGUNGSEINRICHTUNG', label: 'Andere Versorgungseinrichtung' },
]

export const risikoklasseOptions: Option<Risikoklasse>[] = [
  { value: 'VORSICHTIG', label: 'Vorsichtig' },
  { value: 'SUBSTANZORIENTIERT', label: 'Substanzorientiert' },
  { value: 'AUSGEWOGEN', label: 'Ausgewogen' },
  { value: 'ERTRAGSORIENTIERT', label: 'Ertragsorientiert' },
  { value: 'SPEKULATIV', label: 'Spekulativ' },
]

export const bundeslandOptions: Option<Bundesland>[] = [
  { value: 'BADEN_WUERTTEMBERG', label: 'Baden-Württemberg' },
  { value: 'BAYERN', label: 'Bayern' },
  { value: 'BERLIN', label: 'Berlin' },
  { value: 'BRANDENBURG', label: 'Brandenburg' },
  { value: 'BREMEN', label: 'Bremen' },
  { value: 'HAMBURG', label: 'Hamburg' },
  { value: 'HESSEN', label: 'Hessen' },
  { value: 'MECKLENBURG_VORPOMMERN', label: 'Mecklenburg-Vorpommern' },
  { value: 'NIEDERSACHSEN', label: 'Niedersachsen' },
  { value: 'NORDRHEIN_WESTFALEN', label: 'Nordrhein-Westfalen' },
  { value: 'RHEINLAND_PFALZ', label: 'Rheinland-Pfalz' },
  { value: 'SAARLAND', label: 'Saarland' },
  { value: 'SACHSEN', label: 'Sachsen' },
  { value: 'SACHSEN_ANHALT', label: 'Sachen-Anhalt' },
  { value: 'SCHLESWIG_HOLSTEIN', label: 'Schleswig-Holstein' },
  { value: 'THUERINGEN', label: 'Thüringen' },
]

export const sachwertTypOptions: Option<SachwertTyp>[] = [
  { value: 'KRAFTFAHRZEUG', label: 'Kraftfahrzeug' },
  { value: 'LUFTFAHRZEUG', label: 'Luftfahrzeug' },
  { value: 'WASSERFAHRZEUG', label: 'Wasserfahrzeug' },
]
