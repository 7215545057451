export enum BackendPaths {
  userConfig = '/config/myviuserconfig',
  login = '/finfire/login',
  loginValidate = '/finfire/login/validate',
  logout = '/finfire/logout',

  createDinScore = '/finoso',
  generateErweitertesKundendokument = '/finoso/report/kundendokument',
  uploadErweitertesKundendokument = '/finoso/report/kundendokument/upload',
  generateVertragsspiegel = '/finoso/report/vertragsspiegel',
  generateFinanzfahrplan = '/finoso/report/finanzfahrplan',
  generateRisikoanalyse = '/finoso/report/risikoanalyse',

  deleteVertrag = '/vertrag',
  updateVertrag = '/vertrag',
  createVertrag = '/vertrag',
  getRisikoTypen = '/vertrag/risiko',
  getProduktSpecs = '/vertrag/produkt',
  getVertragGesellschaften = '/vertrag/gesellschaft',
  getProduktMapping = '/vertrag/produkt/mapping',

  getHaushalt = '/haushalt',
  updateHaushalt = '/haushalt',
  createHaushalt = '/haushalt',

  getPersonen = '/person',
  createPerson = '/person',
}
