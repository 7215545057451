import { convertAnlageStrukturFormModel } from 'form/anlageStrukturConverterUtil'
import {
  convertZahlweiseFormModel,
  handlePercent,
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { GeldAnlageFormModel } from 'form/KundeFormModel'
import { createDefaultGeldAnlageFormModel } from 'form/kundeFormModelCreator'
import { Geldanlage, Renditeklasse } from 'interfaces/mynorm-api-model-interfaces'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

const defaultGeldAnlageFormModel = createDefaultGeldAnlageFormModel()

export const convertToGeldanlageFormModel = (
  geldanlage: Geldanlage | undefined,
): GeldAnlageFormModel => {
  if (!geldanlage) {
    return defaultGeldAnlageFormModel
  }
  return {
    meta: convertToMeta(geldanlage.meta),
    anbieter: nullableValueOrDefault(geldanlage.anbieter, defaultGeldAnlageFormModel.anbieter),
    anlagenWert: {
      zeitwert: nullableValueOrDefault(
        geldanlage.anlagewert?.zeitwert?.toString(),
        defaultGeldAnlageFormModel.anlagenWert.zeitwert,
      ),
      endwert1: nullableValueOrDefault(
        geldanlage.anlagewert?.endwert1?.toString(),
        defaultGeldAnlageFormModel.anlagenWert.endwert1,
      ),
      endwert2: nullableValueOrDefault(
        geldanlage.anlagewert?.endwert2?.toString(),
        defaultGeldAnlageFormModel.anlagenWert.endwert2,
      ),
      endwert3: nullableValueOrDefault(
        geldanlage.anlagewert?.endwert3?.toString(),
        defaultGeldAnlageFormModel.anlagenWert.endwert3,
      ),
      zeitpunkt: nullableDayjsOrDefault(
        geldanlage.anlagewert?.zeitpunkt,
        defaultGeldAnlageFormModel.anlagenWert.zeitpunkt,
      ),
    },
    anlagenStruktur: convertAnlageStrukturFormModel(
      geldanlage.anlagestruktur,
      defaultGeldAnlageFormModel.anlagenStruktur,
    ),
    laufendeSparrate: {
      sparrate: nullableValueOrDefault(
        geldanlage.laufendeSparrate?.sparrate?.toString(),
        defaultGeldAnlageFormModel.laufendeSparrate.sparrate,
      ),
      zahlweise: convertZahlweiseFormModel(
        geldanlage.laufendeSparrate?.zahlweise,
        defaultGeldAnlageFormModel.laufendeSparrate.zahlweise,
      ),
      zahlart: valueOrDefault(
        geldanlage.laufendeSparrate?.zahlart,
        defaultGeldAnlageFormModel.laufendeSparrate.zahlart,
      ),
    },
    renditeklasse: nullableValueOrDefault<Renditeklasse | '' | undefined>(
      geldanlage.renditeklasse,
      defaultGeldAnlageFormModel.renditeklasse,
    ),
    bemerkungen: nullableValueOrDefault(
      geldanlage.bemerkungen,
      defaultGeldAnlageFormModel.bemerkungen,
    ),
    bemerkungenIntern: nullableValueOrDefault(
      geldanlage.bemerkungenIntern,
      defaultGeldAnlageFormModel.bemerkungenIntern,
    ),
    bezeichnung: nullableValueOrDefault(
      geldanlage.bezeichnung,
      defaultGeldAnlageFormModel.bezeichnung,
    ),
    geldAnlageTyp: valueOrDefault(
      geldanlage.geldAnlageTyp,
      defaultGeldAnlageFormModel.geldAnlageTyp,
    ),
    rendite: nullableValueOrDefault(
      handlePercent(geldanlage.rendite)?.toString(),
      defaultGeldAnlageFormModel.rendite,
    ),
    vertragsnummer: nullableValueOrDefault(
      geldanlage.vertragsnummer,
      defaultGeldAnlageFormModel.vertragsnummer,
    ),
    vertragsbeginn: nullableDayjsOrDefault(
      geldanlage.vertragsbeginn,
      defaultGeldAnlageFormModel.vertragsbeginn,
    ),
    vertragsende: nullableDayjsOrDefault(
      geldanlage.vertragsende,
      defaultGeldAnlageFormModel.vertragsende,
    ),
    geldanlagenEigentuemer: nullableValueOrDefault(
      geldanlage.geldanlagenEigentuemer?.map(
        (e) => e.personFinfireId ?? EXTERNE_PERSON_OPTION_VALUE,
      ),
      defaultGeldAnlageFormModel.geldanlagenEigentuemer,
    ),
    geldanlagenEigentuemerExternePersonName: nullableValueOrDefault(
      geldanlage.geldanlagenEigentuemer?.find((e) => e.externePerson)?.externePersonName,
      defaultGeldAnlageFormModel.geldanlagenEigentuemerExternePersonName,
    ),
    geldanlageZielArt: valueOrDefault(
      geldanlage.geldanlageZielArt,
      defaultGeldAnlageFormModel.geldanlageZielArt,
    ),
    zielId: valueOrDefault(geldanlage.zielId, defaultGeldAnlageFormModel.zielId),
    immobilieId: valueOrDefault(geldanlage.immobilieId, defaultGeldAnlageFormModel.immobilieId),
    kindId: valueOrDefault(geldanlage.kindId, defaultGeldAnlageFormModel.kindId),
    expanded: defaultGeldAnlageFormModel.expanded,

    isNew: geldanlage.createFlag ?? false,
    isDeleted: geldanlage.deleteFlag ?? false,
    saveError: geldanlage.saveError ?? false,
  }
}
