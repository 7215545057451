import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { STALE_TIME_ONE_HOUR } from 'api/apiUtils'
import { BackendPaths } from 'configurations/backendPaths'
import { VertragGeschaeft, VertragGesellschaft } from 'interfaces/mynorm-api-model-interfaces'

export const useFetchVertragGesellschaften = (
  geschaeft: VertragGeschaeft,
  searchString?: string,
  placeholderData?: VertragGesellschaft[],
): UseQueryResult<VertragGesellschaft[]> => {
  return useQuery(
    [geschaeft, searchString, 'fetchVertragGesellschaften'],
    () => fetchVertragGesellschaften(geschaeft, searchString),
    {
      refetchOnWindowFocus: false,
      enabled: !!searchString?.length,
      staleTime: STALE_TIME_ONE_HOUR,
      placeholderData: placeholderData,
    },
  )
}

const fetchVertragGesellschaften = async (
  geschaeft: VertragGeschaeft,
  searchString?: string,
): Promise<VertragGesellschaft[]> => {
  const queryParams = new URLSearchParams({
    name: searchString || '',
    geschaeft: geschaeft,
  })
  const response = await axios.get<VertragGesellschaft[]>(
    `${BackendPaths.getVertragGesellschaften}?${queryParams.toString()}`,
  )
  return response.data
}
