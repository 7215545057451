import {
  handlePercent,
  nullableDayjsOrDefault,
  nullableValueOrDefault,
} from 'form/formModelConverterUtil'
import {
  createAnlagestrukturDetailsFormModel,
  createBerufsunfaehigkeitsDetailsFormModel,
  createBetrieblicheAltersvorsorgeDetailsFormModel,
  createDetailsFormModel,
  createDreadDiseaseDetailsFormModel,
  createErwerbsunfaehigkeitsDetailsFormModel,
  createFunktionsinvaliditaetsDetailsFormModel,
  createGesetztlicheKrankenversicherungDetailsFormModel,
  createGrundfaehigkeitsDetailsFormModel,
  createHaftpflichtDetailsFormModel,
  createHausratFormModel,
  createInvaliditaetsDetailsFormModel,
  createKfzDetailsFormModel,
  createKrankentagegeldDetailsFormModel,
  createLebensDetailsFormModel,
  createLebensUndRentenDetailsFormModel,
  createPflegerenteDetailsFormModel,
  createPflegetagegeldDetailsFormModel,
  createPrivathaftpflichtDetailsFormModel,
  createRechtsschutzDetailsFormModel,
  createRentenDetailsFormModel,
  createTierhalterHaftpflichtDetailsFormModel,
  createTodesfallDetailsFormModel,
  createUnfallDetailsFormModel,
  createUnfallzusatzDetailsFormModel,
  createWohngebaeudeDetailsFormModel,
} from 'form/vertraege/details/DetailsCreator'
import {
  AnlagestrukturDetailsFormModel,
  BerufshaftpflichtDetailsFormModel,
  BerufsunfaehigkeitsDetailsFormModel,
  BetrieblicheAltersvorsorgeDetailsFormModel,
  DetailsFormModel,
  DreadDiseaseDetailsFormModel,
  ErwerbsunfaehigkeitsDetailsFormModel,
  FunktionsinvaliditaetsDetailsFormModel,
  GesetzlicheKrankenversicherungDetailsFormModel,
  GrundfaehigkeitsDetailsFormModel,
  HaftpflichtDetailsFormModel,
  HausratDetailsFormModel,
  InvaliditaetsDetailsFormModel,
  KfzDetailsFormModel,
  KrankentagegeldDetailsFormModel,
  LebensDetailsFormModel,
  LebensUndRentenDetailsFormModel,
  LebensUndRentenDetailsZielArt,
  PflegerenteDetailsFormModel,
  PflegetagegeldDetailsFormModel,
  PrivathaftpflichtDetailsFormModel,
  RechtsschutzDetailsFormModel,
  RentenDetailsFormModel,
  RisikoFormModel,
  TierhalterHaftpflichtDetailsFormModel,
  TodesfallDetailsFormModel,
  UnfallDetailsFormModel,
  UnfallzusatzDetailsFormModel,
  WohngebaeudeDetailsFormModel,
} from 'form/vertraege/VertragFormModel'
import {
  AnlagestrukturDetails,
  BerufshaftpflichtDetails,
  BerufsunfaehigkeitsDetails,
  BetrieblicheAltersvorsorgeDetails,
  Details,
  DreadDiseaseDetails,
  ErwerbsunfaehigkeitsDetails,
  FunktionsinvaliditaetsDetails,
  GesetzlicheKrankenversicherungDetails,
  GrundfaehigkeitsDetails,
  HaftpflichtDetails,
  HausratDetails,
  InvaliditaetsDetails,
  KfzDetails,
  KrankentagegeldDetails,
  LebensDetails,
  LebensUndRentenDetails,
  PflegerenteDetails,
  PflegetagegeldDetails,
  PrivathaftpflichtDetails,
  RechtsschutzDetails,
  RentenDetails,
  TierhalterHaftpflichtDetails,
  TodesfallDetails,
  UnfallDetails,
  UnfallzusatzDetails,
  WohngebaeudeDetails,
} from 'interfaces/mynorm-api-model-interfaces'

export const toDetailsFormModel = (
  details?: Details,
  risiken?: RisikoFormModel[],
): DetailsFormModel => {
  if (!details) {
    return createDetailsFormModel(risiken)
  }
  return {
    haftpflichtDetails: toHaftpflichtDetailsFormModel(details.haftpflichtDetails),
    hausratDetails: toHausratDetailsFormModel(details.hausratDetails),
    unfallDetails: (details.unfallDetails || []).map(toUnfallDetailsFormModel),
    pflegetagegeldDetails: toPflegetagegeldDetailsFormModel(details.pflegetagegeldDetails),
    krankentagegeldDetails: toKrankentagegeldDetailsFormModel(details.krankentagegeldDetails),
    gesetzlicheKrankenversicherungDetails: toGesetzlicheKrankenversicherungDetailsFormModel(
      details.gesetzlicheKrankenversicherungDetails,
    ),
    berufshaftpflichtDetails: toBerufshaftpflichtDetailsFormModel(details.berufshaftpflichtDetails),
    kfzDetails: toKfzDetailsFormModel(details.kfzDetails),
    invaliditaetsDetails: toInvaliditaetsDetailsFormModel(details.invaliditaetsDetails),
    rechtsschutzDetails: toRechtsschutzDetailsFormModel(details.rechtsschutzDetails),
    privathaftpflichtDetails: toPrivathaftpflichtDetailsFormModel(details.privathaftpflichtDetails),
    wohngebaeudeDetails: toWohngebaeudeDetailsFormModel(details.wohngebaeudeDetails),
    tierhalterHaftpflichtDetails: toTierhalterHaftpflichtDetailsFormModel(
      details.tierhalterHaftpflichtDetails,
    ),
    lebensDetails: toLebensDetailsFormModel(details.lebensDetails),
    todesfallDetails: toTodesfallDetailsFormModel(details.todesfallDetails),
    anlagestrukturDetails: toAnlagestrukturDetailsFormModel(details.anlagestrukturDetails),
    rentenDetails: toRentenDetailsFormModel(details.rentenDetails),
    betrieblicheAltersvorsorgeDetails: toBetrieblicheAltersvorsorgeDetailsFormModel(
      details.betrieblicheAltersvorsorgeDetails,
    ),
    berufsunfaehigkeitsDetails: toBerufsunfaehigkeitsDetailsFormModel(
      details.berufsunfaehigkeitsDetails,
    ),
    funktionsinvaliditaetsDetails: toFunktionsinvaliditaetsDetailsFormModel(
      details.funktionsinvaliditaetsDetails,
    ),
    grundfaehigkeitsDetails: toGrundfaehigkeitsDetailsFormModel(details.grundfaehigkeitsDetails),
    pflegerenteDetails: toPflegerenteDetailsFormModel(details.pflegerenteDetails),
    unfallzusatzDetails: toUnfallzusatzDetailsFormModel(details.unfallzusatzDetails),
    dreadDiseaseDetails: toDreadDiseaseDetailsFormModel(details.dreadDiseaseDetails),
    erwerbsunfaehigkeitsDetails: toErwerbsunfaehigkeitsDetailsFormModel(
      details.erwerbsunfaehigkeitsDetails,
    ),
    lebensUndRentenDetails: toLebensUndRentenDetailsFormModel(details.lebensUndRentenDetails),
  }
}

const defaultHaftpflichtDetails = createHaftpflichtDetailsFormModel()
const toHaftpflichtDetailsFormModel = (
  haftpflichtDetails: HaftpflichtDetails | undefined,
): HaftpflichtDetailsFormModel | undefined => {
  return {
    deckungssummeModus: nullableValueOrDefault(
      haftpflichtDetails?.deckungssummeModus,
      defaultHaftpflichtDetails.deckungssummeModus,
    ),
    deckungssummePersonen: nullableValueOrDefault(
      haftpflichtDetails?.deckungssummePersonen?.toString(),
      defaultHaftpflichtDetails.deckungssummePersonen,
    ),
    deckungssummeSach: nullableValueOrDefault(
      haftpflichtDetails?.deckungssummeSach?.toString(),
      defaultHaftpflichtDetails.deckungssummeSach,
    ),
    deckungssummePauschal: nullableValueOrDefault(
      haftpflichtDetails?.deckungssummePauschal?.toString(),
      defaultHaftpflichtDetails.deckungssummePauschal,
    ),
    deckungssummeVermoegen: nullableValueOrDefault(
      haftpflichtDetails?.deckungssummeVermoegen?.toString(),
      defaultHaftpflichtDetails.deckungssummeVermoegen,
    ),
  }
}

const defaultHausratDetails = createHausratFormModel()
const toHausratDetailsFormModel = (
  hausratDetails: HausratDetails | undefined,
): HausratDetailsFormModel | undefined => {
  return {
    hausratVersicherungssummeArt: nullableValueOrDefault(
      hausratDetails?.hausratVersicherungssummeArt,
      defaultHausratDetails.hausratVersicherungssummeArt,
    ),
    individuelleVersicherungssumme: nullableValueOrDefault(
      hausratDetails?.individuelleVersicherungssumme?.toString(),
      defaultHausratDetails.individuelleVersicherungssumme,
    ),
  }
}

const defaultUnfallDetailsModel = createUnfallDetailsFormModel()
const toUnfallDetailsFormModel = (unfallDetails: UnfallDetails): UnfallDetailsFormModel => {
  return {
    grundSumme: nullableValueOrDefault(
      unfallDetails?.grundSumme?.toString(),
      defaultUnfallDetailsModel.grundSumme,
    ),
    unfallRente: nullableValueOrDefault(
      unfallDetails?.unfallRente?.toString(),
      defaultUnfallDetailsModel.unfallRente,
    ),
    progression: nullableValueOrDefault(
      handlePercent(unfallDetails?.progression)?.toString(),
      defaultUnfallDetailsModel.progression,
    ),
    todesfallLeistung: nullableValueOrDefault(
      unfallDetails?.todesfallLeistung?.toString(),
      defaultUnfallDetailsModel.todesfallLeistung,
    ),
    externePerson: unfallDetails.externePerson,
    externePersonName: nullableValueOrDefault(
      unfallDetails.externePersonName,
      defaultUnfallDetailsModel.externePersonName,
    ),
    personId: nullableValueOrDefault(unfallDetails.personId, defaultUnfallDetailsModel.personId),
  }
}

const defaultPflegetagegeldDetailsFormModel = createPflegetagegeldDetailsFormModel()
const toPflegetagegeldDetailsFormModel = (
  pflegetagegeldDetails: PflegetagegeldDetails | undefined,
): PflegetagegeldDetailsFormModel | undefined => {
  const isPercent = !!pflegetagegeldDetails?.leistungenPflegerestkostenProzent
  return {
    leistungenPflegerestkostenProzent: nullableValueOrDefault(
      pflegetagegeldDetails?.leistungenPflegerestkostenProzent,
      defaultPflegetagegeldDetailsFormModel.leistungenPflegerestkostenProzent,
    ),
    ambulantPflegegrad1: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.ambulantPflegegrad1, isPercent),
      defaultPflegetagegeldDetailsFormModel?.ambulantPflegegrad1,
    ),
    ambulantPflegegrad2: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.ambulantPflegegrad2, isPercent),
      defaultPflegetagegeldDetailsFormModel?.ambulantPflegegrad2,
    ),
    ambulantPflegegrad3: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.ambulantPflegegrad3, isPercent),
      defaultPflegetagegeldDetailsFormModel?.ambulantPflegegrad3,
    ),
    ambulantPflegegrad4: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.ambulantPflegegrad4, isPercent),
      defaultPflegetagegeldDetailsFormModel?.ambulantPflegegrad4,
    ),
    ambulantPflegegrad5: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.ambulantPflegegrad4, isPercent),
      defaultPflegetagegeldDetailsFormModel?.ambulantPflegegrad5,
    ),

    stationaerPflegegrad1: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.stationaerPflegegrad1, isPercent),
      defaultPflegetagegeldDetailsFormModel?.stationaerPflegegrad1,
    ),
    stationaerPflegegrad2: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.stationaerPflegegrad2, isPercent),
      defaultPflegetagegeldDetailsFormModel?.stationaerPflegegrad2,
    ),
    stationaerPflegegrad3: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.stationaerPflegegrad3, isPercent),
      defaultPflegetagegeldDetailsFormModel?.stationaerPflegegrad3,
    ),
    stationaerPflegegrad4: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.stationaerPflegegrad4, isPercent),
      defaultPflegetagegeldDetailsFormModel?.stationaerPflegegrad4,
    ),
    stationaerPflegegrad5: nullableValueOrDefault(
      toPercentOrNumberValue(pflegetagegeldDetails?.stationaerPflegegrad5, isPercent),
      defaultPflegetagegeldDetailsFormModel?.stationaerPflegegrad5,
    ),
  }
}

const toPercentOrNumberValue = (
  value: number | undefined,
  isPercent: boolean,
): string | undefined => {
  return isPercent ? handlePercent(value)?.toString() : value?.toString()
}

const defaultKrankentagegeldDetailsFormModel = createKrankentagegeldDetailsFormModel()
const toKrankentagegeldDetailsFormModel = (
  krankentagegeldDetails: KrankentagegeldDetails | undefined,
): KrankentagegeldDetailsFormModel | undefined => {
  return {
    ambulant: !!krankentagegeldDetails?.ambulant,
    ausland: !!krankentagegeldDetails?.ausland,
    stationaer: !!krankentagegeldDetails?.stationaer,
    zahn: !!krankentagegeldDetails?.zahn,
    krankentagegeldBeginn: nullableValueOrDefault(
      krankentagegeldDetails?.krankentagegeldBeginn,
      defaultKrankentagegeldDetailsFormModel.krankentagegeldBeginn,
    ),
    krankentagegeld: nullableValueOrDefault(
      krankentagegeldDetails?.krankentagegeld?.toString(),
      defaultKrankentagegeldDetailsFormModel.krankentagegeld,
    ),
    selbstbehalt: nullableValueOrDefault(
      krankentagegeldDetails?.selbstbehalt?.toString(),
      defaultKrankentagegeldDetailsFormModel.selbstbehalt,
    ),
  }
}

const defaultGesetzlicheKrankenversicherungDetailsFormModel =
  createGesetztlicheKrankenversicherungDetailsFormModel()
const toGesetzlicheKrankenversicherungDetailsFormModel = (
  gesetzlicheKrankenversicherungDetails: GesetzlicheKrankenversicherungDetails | undefined,
): GesetzlicheKrankenversicherungDetailsFormModel | undefined => {
  return {
    gesetzlicheKrankenversicherungArt: nullableValueOrDefault(
      gesetzlicheKrankenversicherungDetails?.gesetzlicheKrankenversicherungArt,
      defaultGesetzlicheKrankenversicherungDetailsFormModel.gesetzlicheKrankenversicherungArt,
    ),
    anspruchAufKrankengeld: !!gesetzlicheKrankenversicherungDetails?.anspruchAufKrankengeld,
  }
}

const toBerufshaftpflichtDetailsFormModel = (
  berufshaftpflichtDetails: BerufshaftpflichtDetails | undefined,
): BerufshaftpflichtDetailsFormModel => {
  return {
    einschlussPrivathaftpflicht: !!berufshaftpflichtDetails?.einschlussPrivathaftpflicht,
    syndikusanwalt: !!berufshaftpflichtDetails?.syndikusanwalt,
    betriebsbeauftragte: !!berufshaftpflichtDetails?.betriebsbeauftragte,
  }
}

const defaultKfzDetails = createKfzDetailsFormModel()
const toKfzDetailsFormModel = (kfzDetails: KfzDetails | undefined): KfzDetailsFormModel => {
  return {
    kfzVersicherungsumfang: nullableValueOrDefault(
      kfzDetails?.kfzVersicherungsumfang,
      defaultKfzDetails.kfzVersicherungsumfang,
    ),
    deckungssummeKfz: nullableValueOrDefault(
      kfzDetails?.deckungssummeKfz?.toString(),
      defaultKfzDetails.deckungssummeKfz,
    ),
  }
}

const defaultInvaliditaetsDetails = createInvaliditaetsDetailsFormModel()
const toInvaliditaetsDetailsFormModel = (
  invaliditaetsDetails: InvaliditaetsDetails | undefined,
): InvaliditaetsDetailsFormModel => {
  return {
    invaliditaetssumme: nullableValueOrDefault(
      invaliditaetsDetails?.invaliditaetssumme?.toString(),
      defaultInvaliditaetsDetails.invaliditaetssumme,
    ),
    invaliditaetsrenteMonatlich: nullableValueOrDefault(
      invaliditaetsDetails?.invaliditaetsrenteMonatlich?.toString(),
      defaultInvaliditaetsDetails.invaliditaetsrenteMonatlich,
    ),
    todesfallleistung: nullableValueOrDefault(
      invaliditaetsDetails?.todesfallleistung?.toString(),
      defaultInvaliditaetsDetails.todesfallleistung,
    ),
  }
}

const defaultRechtsschutzDetails = createRechtsschutzDetailsFormModel()
const toRechtsschutzDetailsFormModel = (
  rechtsschutzDetails: RechtsschutzDetails | undefined,
): RechtsschutzDetailsFormModel => {
  return {
    versicherungssummeModus: nullableValueOrDefault(
      rechtsschutzDetails?.versicherungssummeModus,
      defaultRechtsschutzDetails.versicherungssummeModus,
    ),
    versicherungssumme: nullableValueOrDefault(
      rechtsschutzDetails?.versicherungssumme?.toString(),
      defaultRechtsschutzDetails.versicherungssumme,
    ),
    einschlussVermieter: !!rechtsschutzDetails?.einschlussVermieter,
    rechtsschutzTarifart: nullableValueOrDefault(
      rechtsschutzDetails?.rechtsschutzTarifart,
      defaultRechtsschutzDetails.rechtsschutzTarifart,
    ),
    einschlussVerkehr: !!rechtsschutzDetails?.einschlussVerkehr,
    einschlussPrivat: !!rechtsschutzDetails?.einschlussPrivat,
    einschlussMieter: !!rechtsschutzDetails?.einschlussMieter,
    einschlussBeruf: !!rechtsschutzDetails?.einschlussBeruf,
  }
}

const defaultPrivathaftpflichtDetails = createPrivathaftpflichtDetailsFormModel()

const toPrivathaftpflichtDetailsFormModel = (
  privathaftpflichtDetails: PrivathaftpflichtDetails | undefined,
): PrivathaftpflichtDetailsFormModel => {
  return {
    einschlussWassersportHaftpflicht: !!privathaftpflichtDetails?.einschlussWassersportHaftpflicht,
    einschlussGewaesserschadenHaftpflicht:
      !!privathaftpflichtDetails?.einschlussGewaesserschadenHaftpflicht,
    einschlussBetreiberhaftpflicht: !!privathaftpflichtDetails?.einschlussBetreiberhaftpflicht,
    einschlussEhrenamt: !!privathaftpflichtDetails?.einschlussEhrenamt,
    einschlussDiensthaftpflicht: !!privathaftpflichtDetails?.einschlussDiensthaftpflicht,
    einschlussHausGrundbesitzerHaftpflicht:
      !!privathaftpflichtDetails?.einschlussHausGrundbesitzerHaftpflicht,
    versicherteWasserfahrzeuge: privathaftpflichtDetails?.versicherteWasserfahrzeuge ?? [],
    versicherteImmobilien: privathaftpflichtDetails?.versicherteImmobilien ?? [],
    privatHaftpflichtTarifart: nullableValueOrDefault(
      privathaftpflichtDetails?.privatHaftpflichtTarifart,
      defaultPrivathaftpflichtDetails.privatHaftpflichtTarifart,
    ),
  }
}

const defaultWohngebaeudeDetails = createWohngebaeudeDetailsFormModel()
const toWohngebaeudeDetailsFormModel = (
  wohngebaeudeDetails: WohngebaeudeDetails | undefined,
): WohngebaeudeDetailsFormModel => {
  return {
    wohnflaeche: nullableValueOrDefault(
      wohngebaeudeDetails?.wohnflaeche?.toString(),
      defaultWohngebaeudeDetails.wohnflaeche,
    ),
    wert1914: nullableValueOrDefault(
      wohngebaeudeDetails?.wert1914?.toString(),
      defaultWohngebaeudeDetails.wert1914,
    ),
    unterversicherungsverzicht: !!wohngebaeudeDetails?.unterversicherungsverzicht,
    gleitenderNeuwert: !!wohngebaeudeDetails?.gleitenderNeuwert,
    einschlussSturm: !!wohngebaeudeDetails?.einschlussSturm,
    einschlussLeitungswasser: !!wohngebaeudeDetails?.einschlussLeitungswasser,
    einschlussFeuerrohbau: !!wohngebaeudeDetails?.einschlussFeuerrohbau,
    einschlussFeuer: !!wohngebaeudeDetails?.einschlussFeuer,
    einschlussElementar: !!wohngebaeudeDetails?.einschlussElementar,
    einschlussBauleistung: !!wohngebaeudeDetails?.einschlussBauleistung,
    deckungssumme: nullableValueOrDefault(
      wohngebaeudeDetails?.deckungssumme?.toString(),
      defaultWohngebaeudeDetails.deckungssumme,
    ),
  }
}

const defaultTierhalterHaftpflichtDetails = createTierhalterHaftpflichtDetailsFormModel()
const toTierhalterHaftpflichtDetailsFormModel = (
  tierhalterHaftpflichtDetails: TierhalterHaftpflichtDetails | undefined,
): TierhalterHaftpflichtDetailsFormModel => {
  return {
    tierArt: nullableValueOrDefault(
      tierhalterHaftpflichtDetails?.tierArt,
      defaultTierhalterHaftpflichtDetails.tierArt,
    ),
  }
}

const defaultLebensDetails = createLebensDetailsFormModel()
const toLebensDetailsFormModel = (
  lebensDetails: LebensDetails | undefined,
): LebensDetailsFormModel => {
  return {
    versicherungssumme: nullableValueOrDefault(
      lebensDetails?.versicherungssumme?.toString(),
      defaultLebensDetails.versicherungssumme,
    ),
    ablaufleistungPrognostiziert: nullableValueOrDefault(
      lebensDetails?.ablaufleistungPrognostiziert?.toString(),
      defaultLebensDetails.ablaufleistungPrognostiziert,
    ),
  }
}

const defaultTodesfallDetailsFormModel = createTodesfallDetailsFormModel()
const toTodesfallDetailsFormModel = (
  todesfallDetails: TodesfallDetails | undefined,
): TodesfallDetailsFormModel => {
  return {
    todesfallleistung: nullableValueOrDefault(
      todesfallDetails?.todesfallleistung?.toString(),
      defaultTodesfallDetailsFormModel.todesfallleistung,
    ),
    todesfallleistungMitUeberschuss: nullableValueOrDefault(
      todesfallDetails?.todesfallleistungMitUeberschuss?.toString(),
      defaultTodesfallDetailsFormModel.todesfallleistungMitUeberschuss,
    ),
  }
}

const defaultAnlagestrukturDetailsFormModel = createAnlagestrukturDetailsFormModel()
const toAnlagestrukturDetailsFormModel = (
  anlagestrukturDetails: AnlagestrukturDetails | undefined,
): AnlagestrukturDetailsFormModel => {
  return {
    aktien: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.aktien)?.toString(),
      defaultAnlagestrukturDetailsFormModel.aktien,
    ),
    zinsanlagen: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.zinsanlagen)?.toString(),
      defaultAnlagestrukturDetailsFormModel.zinsanlagen,
    ),
    immobilien: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.immobilien)?.toString(),
      defaultAnlagestrukturDetailsFormModel.immobilien,
    ),
    beteiligungen: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.beteiligungen)?.toString(),
      defaultAnlagestrukturDetailsFormModel.beteiligungen,
    ),
    sonstiges: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.sonstiges)?.toString(),
      defaultAnlagestrukturDetailsFormModel.sonstiges,
    ),
    riskanteAnteile: nullableValueOrDefault(
      handlePercent(anlagestrukturDetails?.riskanteAnteile)?.toString(),
      defaultAnlagestrukturDetailsFormModel.riskanteAnteile,
    ),
    quelleRisikoklassifizierung: nullableValueOrDefault(
      anlagestrukturDetails?.quelleRisikoklassifizierung,
      defaultAnlagestrukturDetailsFormModel.quelleRisikoklassifizierung,
    ),
  }
}

const defaultRentenDetailsFormModel = createRentenDetailsFormModel()
const toRentenDetailsFormModel = (
  rentenDetails: RentenDetails | undefined,
): RentenDetailsFormModel => {
  return {
    beginnRentenzahlung: nullableDayjsOrDefault(
      rentenDetails?.beginnRentenzahlung,
      defaultRentenDetailsFormModel.beginnRentenzahlung,
    ),
    renteMonatlich: nullableValueOrDefault(
      rentenDetails?.renteMonatlich?.toString(),
      defaultRentenDetailsFormModel.renteMonatlich,
    ),
    renteMonatlichMitUeberschuss: nullableValueOrDefault(
      rentenDetails?.renteMonatlichMitUeberschuss?.toString(),
      defaultRentenDetailsFormModel.renteMonatlichMitUeberschuss,
    ),
    rentensteigerungJaehrlich: nullableValueOrDefault(
      rentenDetails?.rentensteigerungJaehrlich?.toString(),
      defaultRentenDetailsFormModel.rentensteigerungJaehrlich,
    ),
  }
}

const defaultBetrieblicheAltersvorsorgeDetailsFormModel =
  createBetrieblicheAltersvorsorgeDetailsFormModel()
const toBetrieblicheAltersvorsorgeDetailsFormModel = (
  betrieblicheAltersvorsorgeDetails: BetrieblicheAltersvorsorgeDetails | undefined,
): BetrieblicheAltersvorsorgeDetailsFormModel => {
  return {
    durchfuehrungsweg: nullableValueOrDefault(
      betrieblicheAltersvorsorgeDetails?.durchfuehrungsweg,
      defaultBetrieblicheAltersvorsorgeDetailsFormModel.durchfuehrungsweg,
    ),
    bavTyp: nullableValueOrDefault(
      betrieblicheAltersvorsorgeDetails?.bavTyp,
      defaultBetrieblicheAltersvorsorgeDetailsFormModel.bavTyp,
    ),
    pauschalversteuert: nullableValueOrDefault(
      betrieblicheAltersvorsorgeDetails?.pauschalversteuert,
      defaultBetrieblicheAltersvorsorgeDetailsFormModel.pauschalversteuert,
    ),
    leistungszusageProzentual: nullableValueOrDefault(
      handlePercent(betrieblicheAltersvorsorgeDetails?.leistungszusageProzentual)?.toString(),
      defaultBetrieblicheAltersvorsorgeDetailsFormModel.leistungszusageProzentual,
    ),
  }
}

const defaultBerufsunfaehigkeitsDetailsFormModel = createBerufsunfaehigkeitsDetailsFormModel()
const toBerufsunfaehigkeitsDetailsFormModel = (
  berufsunfaehigkeitsDetails: BerufsunfaehigkeitsDetails | undefined,
): BerufsunfaehigkeitsDetailsFormModel => {
  return {
    berufsunfaehigkeitsRente: nullableValueOrDefault(
      berufsunfaehigkeitsDetails?.berufsunfaehigkeitsRente?.toString(),
      defaultBerufsunfaehigkeitsDetailsFormModel.berufsunfaehigkeitsRente,
    ),
    berufsunfaehigkeitsRenteMitUeberschuss: nullableValueOrDefault(
      berufsunfaehigkeitsDetails?.berufsunfaehigkeitsRenteMitUeberschuss?.toString(),
      defaultBerufsunfaehigkeitsDetailsFormModel.berufsunfaehigkeitsRenteMitUeberschuss,
    ),
    leistungsendeBerufsunfaehigkeitsRente: nullableDayjsOrDefault(
      berufsunfaehigkeitsDetails?.leistungsendeBerufsunfaehigkeitsRente,
      defaultBerufsunfaehigkeitsDetailsFormModel.leistungsendeBerufsunfaehigkeitsRente,
    ),
    weiterspargarantie: nullableValueOrDefault(
      berufsunfaehigkeitsDetails?.weiterspargarantie,
      defaultBerufsunfaehigkeitsDetailsFormModel.weiterspargarantie,
    ),
    weiterspargarantieInProzent: nullableValueOrDefault(
      handlePercent(berufsunfaehigkeitsDetails?.weiterspargarantieInProzent)?.toString(),
      defaultBerufsunfaehigkeitsDetailsFormModel.weiterspargarantieInProzent,
    ),
    dienstunfaehigkeitsKlausel: nullableValueOrDefault(
      berufsunfaehigkeitsDetails?.dienstunfaehigkeitsKlausel,
      defaultBerufsunfaehigkeitsDetailsFormModel.dienstunfaehigkeitsKlausel,
    ),
    abstrakterVerweisungsverzicht: nullableValueOrDefault(
      berufsunfaehigkeitsDetails?.abstrakterVerweisungsverzicht,
      defaultBerufsunfaehigkeitsDetailsFormModel.abstrakterVerweisungsverzicht,
    ),
  }
}

const defaultFunktionsinvaliditaetsDetailsFormModel = createFunktionsinvaliditaetsDetailsFormModel()
const toFunktionsinvaliditaetsDetailsFormModel = (
  funktionsinvaliditaetsDetails: FunktionsinvaliditaetsDetails | undefined,
): FunktionsinvaliditaetsDetailsFormModel => {
  return {
    funktionsinvaliditaetsRenteMonatlich: nullableValueOrDefault(
      funktionsinvaliditaetsDetails?.funktionsinvaliditaetsRenteMonatlich?.toString(),
      defaultFunktionsinvaliditaetsDetailsFormModel.funktionsinvaliditaetsRenteMonatlich,
    ),
  }
}

const defaultGrundfaehigkeitsDetailsFormModel = createGrundfaehigkeitsDetailsFormModel()
const toGrundfaehigkeitsDetailsFormModel = (
  grundfaehigkeitsDetails: GrundfaehigkeitsDetails | undefined,
): GrundfaehigkeitsDetailsFormModel => {
  return {
    grundefaehigkeitsRenteMonatlich: nullableValueOrDefault(
      grundfaehigkeitsDetails?.grundefaehigkeitsRenteMonatlich?.toString(),
      defaultGrundfaehigkeitsDetailsFormModel.grundefaehigkeitsRenteMonatlich,
    ),
  }
}

const defaultDreadDiseaseDetailsFormModel = createDreadDiseaseDetailsFormModel()
const toDreadDiseaseDetailsFormModel = (
  dreadDiseaseDetails: DreadDiseaseDetails | undefined,
): DreadDiseaseDetailsFormModel => {
  return {
    versicherungssummeDreadDisease: nullableValueOrDefault(
      dreadDiseaseDetails?.versicherungssummeDreadDisease?.toString(),
      defaultDreadDiseaseDetailsFormModel.versicherungssummeDreadDisease,
    ),
  }
}

const defaultPflegerenteDetailsFormModel = createPflegerenteDetailsFormModel()
const toPflegerenteDetailsFormModel = (
  pflegerenteDetails: PflegerenteDetails | undefined,
): PflegerenteDetailsFormModel => {
  return {
    ambulantPflegerenteGrad1Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.ambulantPflegerenteGrad1Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.ambulantPflegerenteGrad1Monatlich,
    ),
    ambulantPflegerenteGrad2Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.ambulantPflegerenteGrad2Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.ambulantPflegerenteGrad2Monatlich,
    ),
    ambulantPflegerenteGrad3Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.ambulantPflegerenteGrad3Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.ambulantPflegerenteGrad3Monatlich,
    ),
    ambulantPflegerenteGrad4Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.ambulantPflegerenteGrad4Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.ambulantPflegerenteGrad4Monatlich,
    ),
    ambulantPflegerenteGrad5Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.ambulantPflegerenteGrad5Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.ambulantPflegerenteGrad5Monatlich,
    ),
    stationaerPflegerenteGrad1Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.stationaerPflegerenteGrad1Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.stationaerPflegerenteGrad1Monatlich,
    ),
    stationaerPflegerenteGrad2Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.stationaerPflegerenteGrad2Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.stationaerPflegerenteGrad2Monatlich,
    ),
    stationaerPflegerenteGrad3Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.stationaerPflegerenteGrad3Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.stationaerPflegerenteGrad3Monatlich,
    ),
    stationaerPflegerenteGrad4Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.stationaerPflegerenteGrad4Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.stationaerPflegerenteGrad4Monatlich,
    ),
    stationaerPflegerenteGrad5Monatlich: nullableValueOrDefault(
      pflegerenteDetails?.stationaerPflegerenteGrad5Monatlich?.toString(),
      defaultPflegerenteDetailsFormModel.stationaerPflegerenteGrad5Monatlich,
    ),
  }
}

const defaultUnfallzusatzDetailsFormModel = createUnfallzusatzDetailsFormModel()
const toUnfallzusatzDetailsFormModel = (
  unfallzusatzDetails: UnfallzusatzDetails | undefined,
): UnfallzusatzDetailsFormModel => {
  return {
    versicherungsssummeUnfall: nullableValueOrDefault(
      unfallzusatzDetails?.versicherungsssummeUnfall?.toString(),
      defaultUnfallzusatzDetailsFormModel.versicherungsssummeUnfall,
    ),
  }
}

const defaultErwerbsunfaehigkeitsDetailsFormModel = createErwerbsunfaehigkeitsDetailsFormModel()
const toErwerbsunfaehigkeitsDetailsFormModel = (
  erwerbsunfaehigkeitsDetails: ErwerbsunfaehigkeitsDetails | undefined,
): ErwerbsunfaehigkeitsDetailsFormModel => {
  return {
    erwerbsminderungsrente: nullableValueOrDefault(
      erwerbsunfaehigkeitsDetails?.erwerbsminderungsrente?.toString(),
      defaultErwerbsunfaehigkeitsDetailsFormModel.erwerbsminderungsrente,
    ),
  }
}

const defaultLebensUndRentenDetailsFormModel = createLebensUndRentenDetailsFormModel()
const toLebensUndRentenDetailsFormModel = (
  lebensUndRentenDetails: LebensUndRentenDetails | undefined,
): LebensUndRentenDetailsFormModel => {
  const zielArt: LebensUndRentenDetailsZielArt = lebensUndRentenDetails?.zielId
    ? 'ZIEL'
    : lebensUndRentenDetails?.ausbildungssparenKindId
    ? 'AUSBILDUNGSSPAREN'
    : lebensUndRentenDetails?.zielAltersvorsorge
    ? 'ALTERSVORSORGE'
    : 'KEIN_ZIEL'
  return {
    rueckkaufwert: nullableValueOrDefault(
      lebensUndRentenDetails?.rueckkaufwert?.toString(),
      defaultLebensUndRentenDetailsFormModel.rueckkaufwert,
    ),
    rueckkaufwertZeitpunkt: nullableDayjsOrDefault(
      lebensUndRentenDetails?.rueckkaufwertZeitpunkt,
      defaultLebensUndRentenDetailsFormModel.rueckkaufwertZeitpunkt,
    ),
    renditeklasse: nullableValueOrDefault(
      lebensUndRentenDetails?.renditeklasse,
      defaultLebensUndRentenDetailsFormModel.renditeklasse,
    ),
    ausbildungssparenKindId: nullableValueOrDefault(
      zielArt === 'AUSBILDUNGSSPAREN' ? lebensUndRentenDetails?.ausbildungssparenKindId : undefined,
      defaultLebensUndRentenDetailsFormModel.ausbildungssparenKindId,
    ),
    zielId: nullableValueOrDefault(
      zielArt === 'ZIEL' ? lebensUndRentenDetails?.zielId : undefined,
      defaultLebensUndRentenDetailsFormModel.zielId,
    ),
    zielArt: zielArt,
  }
}
