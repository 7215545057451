import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/personnel/herausforderungen-am-arbeitsmarkt/herausforderungen_am_arbeitsmarkt_0.png'
import chart1 from 'assets/images/guidance/personnel/herausforderungen-am-arbeitsmarkt/herausforderungen_am_arbeitsmarkt_1.png'
import chart2 from 'assets/images/guidance/personnel/herausforderungen-am-arbeitsmarkt/herausforderungen_am_arbeitsmarkt_2.png'
import chart3 from 'assets/images/guidance/personnel/herausforderungen-am-arbeitsmarkt/herausforderungen_am_arbeitsmarkt_3.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/personnel/GuidancePersonnel2Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidancePersonnel2Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3]
  return (
    <GuidanceSubPage
      menuIndex={1}
      menuSubIndex={1}
      previousPagePath={Paths.guidancePersonnel1}
      nextPagePath={Paths.guidancePersonnel3}
      guidanceColor='personnel'
      numberOfInterPages={4}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title={'HERAUSFORDERUNGEN AM ARBEITSMARKT'}
          subTitle={'WIR PERSONALBERATER'}
        >
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              <img src={charts[interPageIndex]} alt='Daily News' />
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
