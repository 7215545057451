import React, { ReactElement } from 'react'
import { Warning } from '@mui/icons-material'
import { Alert, Stack } from '@mui/material'
import { groupBy } from 'lodash'

import { CreateVertragFormDialog } from 'components/personal/absicherung2/dialog/CreateVertragDialog'
import { VertraegeList } from 'components/personal/absicherung2/VertraegeList'
import { PersonalFormLayout } from 'components/personal/PersonalFormLayout'
import { useEditableKunde } from 'state/useEditableKunde'
import { useProduktMappings } from 'state/useProduktConfig'

export const PersonalAbsicherungFormV2 = (): ReactElement => {
  const produktMappings = useProduktMappings()
  const { editableKunde } = useEditableKunde()
  const vertraege = editableKunde.personen[0].vertraege
  const vertraegeByGeschaeft = groupBy(vertraege, (value) => value.vertragGeschaeft)
  return (
    <PersonalFormLayout title={'Absicherung'}>
      {produktMappings.length <= 0 ? (
        <Alert color={'error'} icon={<Warning />}>
          Leider ist ein technisches Problem aufgetreten und Verträge können momentan nicht
          angezeigt oder bearbeitet werden.
        </Alert>
      ) : (
        <>
          {!vertraege?.length && 'Keine Versicherungen bekannt.'}
          <br />
          <CreateVertragFormDialog />
          <Stack rowGap={8} paddingTop={4}>
            <VertraegeList vertraege={vertraegeByGeschaeft.LEBEN} geschaeft={'LEBEN'} />
            <VertraegeList vertraege={vertraegeByGeschaeft.SACH} geschaeft={'SACH'} />
            <VertraegeList vertraege={vertraegeByGeschaeft.KRANKEN} geschaeft={'KRANKEN'} />
          </Stack>
        </>
      )}
    </PersonalFormLayout>
  )
}
