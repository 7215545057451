import React, { ChangeEvent, ReactElement } from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { TextField, TextFieldProps } from '@mui/material'
import { useField, useFormikContext } from 'formik'

import { EndAdornment } from 'components/controls/inputs/EndAdornment'
import { HelperText } from 'components/controls/inputs/HelperText'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import { SelectiveNumberInput } from 'components/controls/selective-inputs/SelectiveNumberInput'

export type NumberInputProps<FormType> = {
  inputUnit?: '€' | 'm²' | '%' | 'Mark' | ''
  noGrouping?: boolean
  noDecimals?: boolean
  onBlur?: (values: ChangeEvent<HTMLInputElement>) => void
  noHelperText?: boolean
} & BaseInputProps<FormType> &
  Pick<TextFieldProps, 'variant' | 'size' | 'helperText'>

export const NumberInput = <FormType,>({
  name,
  label,
  tooltip,
  inputUnit,
  noGrouping,
  noDecimals,
  selectiveFieldProps,
  onBlur,
  variant,
  noHelperText,
  ...props
}: NumberInputProps<FormType>): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ onChange, ...fieldInputProps }, metaProps] = useField(name)
  const { setFieldValue } = useFormikContext()
  if (selectiveFieldProps) {
    return (
      <SelectiveNumberInput<FormType>
        name={name}
        label={label}
        selectiveFieldProps={selectiveFieldProps}
        inputUnit={inputUnit}
        noDecimals={noDecimals}
        noGrouping={noGrouping}
        variant={variant}
        size={props.size}
      />
    )
  }
  return (
    <InputWrapper tooltip={tooltip}>
      <NumericFormat
        {...props}
        {...fieldInputProps}
        valueIsNumericString={true}
        thousandSeparator={noGrouping ? '' : '.'}
        decimalSeparator={','}
        decimalScale={noDecimals ? 0 : undefined}
        allowedDecimalSeparators={['.', ',']}
        customInput={TextField}
        fullWidth
        label={label}
        error={!!metaProps.error && metaProps.touched}
        onBlur={onBlur}
        onValueChange={(values: NumberFormatValues) => {
          setFieldValue(name, values.value, true)
        }}
        variant={variant ?? 'standard'}
        helperText={
          noHelperText ? undefined : <HelperText metaProps={metaProps} text={props.helperText} />
        }
        InputProps={{ endAdornment: <EndAdornment text={inputUnit} /> }}
      />
    </InputWrapper>
  )
}
