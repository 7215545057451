export enum Paths {
  login = '/login',
  customers = '/',
  personal = '/kunde/:customerId/daten',
  cockpit = '/kunde/:customerId/cockpit',

  guidance = '/guidance',
  guidanceMitNorm0 = '/guidance/mitnorm/0',
  guidanceMitNorm1 = '/guidance/mitnorm/1',
  guidanceMitNorm2 = '/guidance/mitnorm/2',
  guidanceMitNorm3 = '/guidance/mitnorm/3',
  guidanceMitNorm4 = '/guidance/mitnorm/4',
  guidanceMitNorm5 = '/guidance/mitnorm/5',
  guidanceMitNorm6 = '/guidance/mitnorm/6',
  guidanceMitNorm9 = '/guidance/mitnorm/9',
  guidanceMitNorm10 = '/guidance/mitnorm/10',
  guidanceMitNorm11 = '/guidance/mitnorm/11',
  guidanceMitNorm12 = '/guidance/mitnorm/12',
  guidanceMitNorm13 = '/guidance/mitnorm/13',
  guidanceMitNorm14 = '/guidance/mitnorm/14',
  guidanceMitNorm15 = '/guidance/mitnorm/15',
  guidanceMitNorm16 = '/guidance/mitnorm/16',
  guidanceMitNorm17 = '/guidance/mitnorm/17',
  guidanceCareer1 = '/guidance/karriere-institut/1',
  guidanceCareer2 = '/guidance/karriere-institut/2',
  guidanceCareer3 = '/guidance/karriere-institut/3',
  guidanceCareer4 = '/guidance/karriere-institut/4',
  guidanceCareer5 = '/guidance/karriere-institut/5',
  guidanceCareer6 = '/guidance/karriere-institut/6',
  guidanceCareer7 = '/guidance/karriere-institut/7',
  guidanceCareer8 = '/guidance/karriere-institut/8',
  guidanceCareer9 = '/guidance/karriere-institut/9',
  guidanceCareer10 = '/guidance/karriere-institut/10',
  guidanceCareer11 = '/guidance/karriere-institut/11',
  guidanceCareer12 = '/guidance/karriere-institut/12',
  guidanceCareer13 = '/guidance/karriere-institut/13',
  guidanceCareer14 = '/guidance/karriere-institut/14',
  guidanceCareer15 = '/guidance/karriere-institut/15',

  guidancePersonnel1 = '/guidance/wir-personalberater/1',
  guidancePersonnel2 = '/guidance/wir-personalberater/2',
  guidancePersonnel3 = '/guidance/wir-personalberater/3',
  guidancePersonnel4 = '/guidance/wir-personalberater/4',
  guidancePersonnel5 = '/guidance/wir-personalberater/5',

  guidanceCompany1 = '/guidance/firmenberatung/1',

  notFound = '/404',

  externalWirPersonalberaterJobPortal = 'https://jobportal.wirpersonalberater.de/#/jobportal/betreuer/uebersicht',
  externalUnternehmerDialog = 'https://ud.myvi.de',
  externalKIPortal = 'https://www.daskarriereinstitut.de/de/login',
  externalIFIWebsite = 'https://www.ifi-ev.de/',
}
