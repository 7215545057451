import React, { PropsWithChildren, ReactElement, useEffect } from 'react'
import produce from 'immer'
import { cloneDeep } from 'lodash'
import create from 'zustand'

import { mergeHaushalt } from 'components/save-haushalt/mergeHaushalt'
import { convertToKundeFormModel } from 'form/kunde-form-model-converter/kundeFormModelConverter'
import { KundeFormModel } from 'form/KundeFormModel'
import { usePageTitle } from 'hooks/usePageTitle'
import { HaushaltAggregate, Vertrag } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'

export type HaushaltState = {
  customerId: string | ''
  haushalt: HaushaltAggregate | undefined
  latestHaushalt: HaushaltAggregate | undefined
  clear: () => void
  setHaushalt: (haushalt: HaushaltAggregate, customerId?: string) => void
  setHaushaltPartially: (
    originalHaushalt: HaushaltAggregate,
    editableHaushalt: KundeFormModel,
  ) => void
  setLatestHaushalt: (haushalt: HaushaltAggregate | undefined) => void
  removeVertrag: (vertragId: string) => void
  updateVertrag: (vertrag: Vertrag) => void
  addVertrag: (vertrag: Vertrag) => void
}

export const useHaushaltState = create<HaushaltState>((set, get) => ({
  customerId: '',
  haushalt: undefined,
  latestHaushalt: undefined,
  clear: () => {
    set({ customerId: '', haushalt: undefined, latestHaushalt: undefined })
  },
  setHaushalt: (haushalt, customerId) => {
    set((previous) => ({
      haushalt,
      customerId: !customerId ? previous.customerId : customerId,
    }))
    const kundeFormModel = convertToKundeFormModel(haushalt)
    useEditableKunde.setState({
      editableKunde: kundeFormModel,
      originalKunde: cloneDeep(kundeFormModel),
    })
  },
  setHaushaltPartially: (originalHaushalt, editableKunde) => {
    set({ haushalt: originalHaushalt })
    useEditableKunde.setState({
      editableKunde,
      originalKunde: convertToKundeFormModel(originalHaushalt),
    })
  },
  setLatestHaushalt: (latestHaushalt) => {
    set({ latestHaushalt })
  },
  removeVertrag: (vertragId) => {
    const updatedHaushalt = produce(get().latestHaushalt, (haushaltDraft) => {
      haushaltDraft?.personen?.forEach((p) => {
        p.vertraege = p.vertraege.filter((v) => v.meta.finfireId !== vertragId)
      })
    })
    if (updatedHaushalt) {
      get().setHaushalt(updatedHaushalt)
    }
  },
  updateVertrag: (vertrag) => {
    const updatedHaushalt = produce(get().latestHaushalt, (haushaltDraft) => {
      haushaltDraft?.personen?.forEach((p) => {
        const index = p.vertraege.findIndex((v) => v.meta.finfireId === vertrag.meta.finfireId)
        if (index !== -1) {
          p.vertraege.splice(index, 1, vertrag)
        }
      })
    })
    if (updatedHaushalt) {
      get().setHaushalt(updatedHaushalt)
    }
  },
  addVertrag: (vertrag) => {
    const updatedHaushalt = produce(get().latestHaushalt, (haushaltDraft) => {
      const person = haushaltDraft?.personen?.[0]
      person?.vertraege.push(vertrag)
    })
    if (updatedHaushalt) {
      get().setHaushalt(updatedHaushalt)
    }
  },
}))

const mergeLatestHaushalt = (
  formModel: KundeFormModel,
  haushalt?: HaushaltAggregate,
): HaushaltAggregate | undefined => {
  if (!haushalt) {
    return haushalt
  }
  return mergeHaushalt(formModel, haushalt)
}

const useUpdateLatestHaushalt = (): void => {
  const editableKunde = useEditableKunde(({ editableKunde }) => editableKunde)
  const { haushalt, setLatestHaushalt } = useHaushaltState(({ haushalt, setLatestHaushalt }) => ({
    haushalt,
    setLatestHaushalt,
  }))

  useEffect(() => {
    setLatestHaushalt(mergeLatestHaushalt(editableKunde, haushalt))
  }, [editableKunde, haushalt])
}

export const HaushaltStateContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const haushalt = useHaushaltState(({ haushalt }) => haushalt)

  useUpdateLatestHaushalt()
  usePageTitle(haushalt?.personen[0].person)

  return <>{children}</>
}
