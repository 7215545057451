import { orderBy } from 'lodash'

import { useFetchProduktSpecs } from 'api/useFetchProdukte'
import { ProduktSpecs, VertragGeschaeft } from 'interfaces/mynorm-api-model-interfaces'

type UseProdukteResult = {
  produkte: ProduktSpecs[]
  findProduktById: (produktId: string) => ProduktSpecs | undefined
}

export const useProduktSpecs = (vertragGeschaeft: VertragGeschaeft): UseProdukteResult => {
  const { data, isSuccess } = useFetchProduktSpecs(vertragGeschaeft)
  const produkte = orderBy(isSuccess ? data : [], (p) => p.produktName)
  return {
    produkte: produkte,
    findProduktById: (produktId: string) => {
      return produkte.find((produkt) => produkt.produktId === produktId)
    },
  }
}
