import { ImmobilieFormModel, SachwertFormModel } from 'form/KundeFormModel'
import { convertDetails } from 'form/vertraege/details/DetailsConverter'
import {
  PraemieFormModel,
  RisikoFormModel,
  VertragFormModel,
  VertragsProduktFormModel,
} from 'form/vertraege/VertragFormModel'
import {
  CreateVertrag,
  Praemie,
  Risiko,
  RisikoFahrzeug,
  RisikoImmobilie,
  RisikoPerson,
  RisikoTyp,
  Vertrag,
  VertragsProdukt,
} from 'interfaces/mynorm-api-model-interfaces'
import { hasId } from 'utils/comparisonUtils'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

export const convertToVertrag = (
  vertragFormModel: VertragFormModel,
  originalVertrag: Vertrag,
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): Vertrag => {
  return {
    meta: {
      myviId: originalVertrag.meta.myviId,
      finfireId: originalVertrag.meta.finfireId,
      salesforceId: originalVertrag.meta.salesforceId,
      finfireLastModified: originalVertrag.meta.finfireLastModified,
      salesforceLastModified: originalVertrag.meta.salesforceLastModified,
    },
    fremdvertrag: originalVertrag.fremdvertrag,
    gesamtPraemien: vertragFormModel.gesamtPraemien.map((praemieFormModel) => {
      return convertPraemie(praemieFormModel)
    }),
    praemienArt: vertragFormModel.praemienArt,
    jahrespraemie: 0,
    kunde: originalVertrag.kunde,
    prozessStatus: originalVertrag.prozessStatus,
    vermittlerNummer: originalVertrag.vermittlerNummer,
    vertragDisplaySummary: originalVertrag.vertragDisplaySummary,
    vertragGeschaeft: vertragFormModel.vertragGeschaeft,
    vertragGesellschaft: {
      gesellschaftId: vertragFormModel?.vertragGesellschaft?.gesellschaftId || '',
      name: vertragFormModel?.vertragGesellschaft?.name || '',
    },
    vertragNummer: vertragFormModel.vertragNummer,
    vertragStatus: originalVertrag.vertragStatus,
    vertragStatusDetail: originalVertrag.vertragStatusDetail,
    beginn: vertragFormModel.beginn?.toDate(),
    ende: vertragFormModel.ende?.toDate(),
    bemerkungen: vertragFormModel.bemerkungen,
    bemerkungenIntern: vertragFormModel.bemerkungenIntern,
    vertragsProdukte: convertVertragsProdukte(
      vertragFormModel.vertragsProdukte,
      immobilien,
      sachwerte,
    ),
  }
}

export const convertToCreateVertrag = (
  vertragFormModel: VertragFormModel,
  kundeId: string,
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): CreateVertrag => {
  return {
    fremdvertrag: vertragFormModel.fremdvertrag,
    gesamtPraemien: vertragFormModel.gesamtPraemien.map((praemieFormModel) => {
      return convertPraemie(praemieFormModel)
    }),
    praemienArt: vertragFormModel.praemienArt,
    kundeId: kundeId,
    vertragGeschaeft: vertragFormModel.vertragGeschaeft,
    gesellschaftId: vertragFormModel?.vertragGesellschaft?.gesellschaftId || '',
    vertragNummer: vertragFormModel.vertragNummer,
    vermittlerNummer: '',
    beginn: vertragFormModel.beginn.toDate(),
    ende: vertragFormModel.ende.toDate(),
    bemerkungen: vertragFormModel.bemerkungen,
    bemerkungenIntern: vertragFormModel.bemerkungenIntern,
    vertragsProdukte: convertVertragsProdukte(
      vertragFormModel.vertragsProdukte,
      immobilien,
      sachwerte,
    ),
  }
}

const convertVertragsProdukte = (
  vertragsProduktFormModels: VertragsProduktFormModel[],
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): VertragsProdukt[] => {
  return vertragsProduktFormModels.map((vPFormModel) => {
    const risiken = convertRisiken(vPFormModel.risiken, immobilien, sachwerte)
    return {
      produkt: {
        produktId: vPFormModel.produkt.produktId,
        produktName: vPFormModel.produkt.produktName,
      },
      risiken: risiken,
      details: convertDetails(vPFormModel.details, vPFormModel.produkt.produktId, risiken),
    }
  })
}

const convertRisiken = (
  risikoFormModels: RisikoFormModel[],
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): Risiko[] => {
  const risiken: Risiko[] = []
  risikoFormModels.forEach((risikoFormModel) => {
    const risiko = convertRisiko(risikoFormModel, immobilien, sachwerte)
    if (risiko) {
      risiken.push(risiko)
    }
  })
  return risiken
}

const convertRisiko = (
  risikoFormModel: RisikoFormModel,
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): Risiko | undefined => {
  if (!risikoFormModel.risikoTyp) {
    return undefined
  }
  return {
    risikoId: extractRisikoId(risikoFormModel),
    risikoTyp: convertRisikoTyp(risikoFormModel),
    finfireDisplayName: '',
    risikoImmobilie: convertImmobilie(risikoFormModel.risikoImmobilie, immobilien),
    risikoPerson: convertPerson(risikoFormModel.risikoPerson),
    risikoFahrzeug: convertFahrzeug(risikoFormModel.risikoFahrzeug, sachwerte),
    risikoSonstiges: risikoFormModel.risikoSonstiges,
    risikoTier: risikoFormModel.risikoTier,
    risikoReise: risikoFormModel.risikoReise,
    risikoObjekt: risikoFormModel.risikoObjekt,
    displayName: '',
    einzelPraemien: (risikoFormModel.einzelPraemien ?? []).map(convertPraemie),
  }
}

const extractRisikoId = (risikoFormModel: RisikoFormModel): string => {
  if (
    (risikoFormModel.risikoTyp === 'KUNDE' || risikoFormModel.risikoTyp === 'PERSON') &&
    risikoFormModel.risikoPerson?.finfireId !== EXTERNE_PERSON_OPTION_VALUE
  ) {
    return risikoFormModel.risikoPerson?.finfireId ?? risikoFormModel.risikoId
  }
  return risikoFormModel.risikoId
}

const convertPerson = (risikoPerson: RisikoPerson | undefined): RisikoPerson | undefined => {
  if (!risikoPerson) {
    return undefined
  }
  const externePerson = risikoPerson.finfireId === EXTERNE_PERSON_OPTION_VALUE
  return {
    finfireId: externePerson ? '' : risikoPerson.finfireId,
    externePerson: externePerson,
    bezeichnung: risikoPerson.bezeichnung,
    name: risikoPerson.name,
  }
}

const convertImmobilie = (
  risikoImmobilie: RisikoImmobilie | undefined,
  immobilien: ImmobilieFormModel[],
): RisikoImmobilie | undefined => {
  const id = risikoImmobilie?.finfireId
  if (!id) {
    return undefined
  }
  const immobilie = immobilien.find((immo) => immo.meta.finfireId === id)
  return {
    finfireId: id,
    hausnummer: immobilie?.adresse?.hausnummer,
    strasse: immobilie?.adresse?.strasse,
    immobilienTyp: immobilie?.immobilieNutzungsArt,
    bezeichnung: '',
  }
}

const convertFahrzeug = (
  risikoFahrzeug: RisikoFahrzeug | undefined,
  sachwerte: SachwertFormModel[],
): RisikoFahrzeug | undefined => {
  const id = risikoFahrzeug?.finfireId
  if (!id) {
    return undefined
  }
  const sachwert = sachwerte.find((sw) => hasId(sw, id))
  return {
    finfireId: id,
    hersteller: sachwert?.marke,
    kennzeichen: sachwert?.kennzeichen,
    modell: sachwert?.modell,
    bezeichnung: '',
  }
}

const convertRisikoTyp = (risikoFormModel: RisikoFormModel): RisikoTyp => {
  if (risikoFormModel.risikoTyp === 'PERSON' || risikoFormModel.risikoTyp === 'KUNDE') {
    if (
      risikoFormModel.risikoPerson?.finfireId &&
      risikoFormModel.risikoPerson?.finfireId !== EXTERNE_PERSON_OPTION_VALUE
    ) {
      return 'KUNDE'
    }
    return 'PERSON'
  }
  return risikoFormModel.risikoTyp
}

const convertPraemie = (praemieFormModel: PraemieFormModel): Praemie => {
  return {
    summe: praemieFormModel.summe,
    zahlweise: praemieFormModel.zahlweise,
    titel: praemieFormModel.titel,
    tarifArt: praemieFormModel.tarifArt,
    praemieTyp: praemieFormModel.praemieTyp,
  }
}
