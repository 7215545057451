import { isEmpty } from 'form/haushalt-converter/person-converter/personenConverter'
import { AusgabenFormModel } from 'form/KundeFormModel'
import { Ausgaben } from 'interfaces/mynorm-api-model-interfaces'
import { mapToNumericRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertAusgaben = (
  formModel: AusgabenFormModel | undefined,
  original: RecursivePartial<Ausgaben> | undefined,
): RecursivePartial<Ausgaben> | undefined => {
  if (formModel == null || (isEmpty(formModel) && original === null)) {
    return original
  }
  return {
    ...original,
    konsum: mapToNumericRequestModelType(formModel.konsum, original?.konsum),
    sonstige: mapToNumericRequestModelType(formModel.sonstige, original?.sonstige),
    wohnen: mapToNumericRequestModelType(formModel.wohnen, original?.wohnen),
  }
}
