import { Dayjs } from 'dayjs'

export const isNullishOrEmpty = (value: unknown): boolean => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  )
}

export const mapToRequestModelType = (
  formValue: unknown,
  sourceValue?: unknown,
  useFormModelAsDefault?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (isNullishOrEmpty(formValue) && isNullishOrEmpty(sourceValue) && !useFormModelAsDefault) {
    return sourceValue
  }
  return formValue || undefined
}

export const asValueOrUndefined = <T>(formValue: T): T | undefined => {
  if (isNullishOrEmpty(formValue)) {
    return undefined
  }
  return formValue
}

export const mapToNumericRequestModelType = (
  formValue: unknown,
  sourceValue?: unknown,
  useFormModelAsDefault?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (isNullishOrEmpty(formValue)) {
    return mapToRequestModelType(formValue, sourceValue, useFormModelAsDefault)
  }
  return +Number(formValue).toFixed(4)
}

export const mapToNumericPercentRequestModelType = (
  formValue: unknown,
  sourceValue?: unknown,
  useFormModelAsDefault?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const numericValue = mapToNumericRequestModelType(formValue, sourceValue, useFormModelAsDefault)
  if (typeof numericValue === 'number') {
    return +(numericValue / 100).toFixed(4)
  }
  return numericValue
}

export const mapToDateRequestModelType = (
  formValue: Dayjs | null,
  sourceValue?: unknown,
  useFormModelAsDefault?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (formValue == null && !useFormModelAsDefault) {
    return sourceValue
  }
  return formValue?.format('YYYY-MM-DD')
}
export const toNumberOrUndefined = (value?: string): number | undefined => {
  if (value === '0') {
    return 0
  }
  if (isNullishOrEmpty(value)) {
    return undefined
  }
  const num = Number(value)
  if (isNaN(num)) {
    return undefined
  }
  return num
}

export const toPercentOrUndefined = (value?: string): number | undefined => {
  const numericValue = toNumberOrUndefined(value)
  if (numericValue === undefined) {
    return undefined
  }
  return numericValue / 100
}
