import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import styles from 'components/header/MobileNavigation.module.scss'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const MobileNavigation = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null)

  const navigate = useNavigate()
  const { customerId } = useHaushaltState()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  return (
    <Box className={styles.mobileNavigation}>
      <IconButton onClick={handleOpenNavMenu} className={styles.menuIconButton}>
        <MenuIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        anchorEl={anchorElNav}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={styles.menu}
        keepMounted
      >
        <MenuItem onClick={() => navigate('/')}>
          <Typography className={styles.menuItemText}>Kundenauswahl</Typography>
        </MenuItem>
        <MenuItem onClick={() => navigate('/guidance')}>
          <Typography className={styles.menuItemText}>Guidance</Typography>
        </MenuItem>
        {customerId && [
          <MenuItem key={'daten'} onClick={() => navigate(`/kunde/${customerId}/daten`)}>
            <Typography className={styles.menuItemText}>Kundendaten</Typography>
          </MenuItem>,
          <MenuItem key={'cockpit'} onClick={() => navigate(`/kunde/${customerId}/cockpit`)}>
            <Typography className={styles.menuItemText}>Cockpit</Typography>
          </MenuItem>,
        ]}
      </Menu>
    </Box>
  )
}
