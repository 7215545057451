import React, { ReactElement } from 'react'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { useField } from 'formik'

import styles from 'components/controls/inputs/CheckboxInput.module.scss'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import { SelectiveCheckbox } from 'components/controls/selective-inputs/SelectiveCheckbox'

type CheckboxInputProps<FormType> = BaseInputProps<FormType> & {
  noWrapper?: boolean
}

export const CheckboxInput = <FormType,>({
  name,
  label,
  tooltip,
  noWrapper,
  disabled,
  selectiveFieldProps,
}: CheckboxInputProps<FormType>): ReactElement => {
  const [fieldInputProps] = useField(name)
  if (selectiveFieldProps) {
    return <SelectiveCheckbox name={name} label={label} selectiveInputProps={selectiveFieldProps} />
  }
  const control = (
    <FormControl error={false}>
      <FormControlLabel
        control={
          <Checkbox
            size='small'
            {...fieldInputProps}
            color='secondary'
            checked={fieldInputProps.value}
            className={styles.checkbox}
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormControl>
  )
  if (noWrapper) {
    return <div>{control}</div>
  }
  return <InputWrapper tooltip={tooltip}>{control}</InputWrapper>
}
