import { convertZahlweise } from 'form/haushalt-converter/person-converter/vermoegen-converter/vermoegenConverter'
import { BeteiligungFormModel } from 'form/KundeFormModel'
import { Beteiligung } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToDateRequestModelType,
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertBeteiligung = (
  formModel: BeteiligungFormModel,
  source?: Beteiligung,
): RecursivePartial<Beteiligung> => {
  const useFormModelAsDefault = formModel.isNew
  return {
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    bezeichnung: mapToRequestModelType(
      formModel.bezeichnung,
      source?.bezeichnung,
      useFormModelAsDefault,
    ),
    bemerkungen: mapToRequestModelType(
      formModel.bemerkungen,
      source?.bemerkungen,
      useFormModelAsDefault,
    ),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
      useFormModelAsDefault,
    ),
    gesellschaft: mapToRequestModelType(
      formModel.gesellschaft,
      source?.gesellschaft,
      useFormModelAsDefault,
    ),
    beteiligungTyp: mapToRequestModelType(
      formModel.beteiligungTyp,
      source?.beteiligungTyp,
      useFormModelAsDefault,
    ),
    unternehmensTyp: mapToRequestModelType(
      formModel.unternehmensTyp,
      source?.unternehmensTyp,
      useFormModelAsDefault,
    ),
    beteiligungseigentuemer: mapToRequestModelType(
      formModel.beteiligungseigentuemer.map((personFinfireId) =>
        personFinfireId === EXTERNE_PERSON_OPTION_VALUE
          ? {
              personFinfireId: null,
              externePerson: true,
              externePersonName: formModel.beteiligungseigentuemerExternePersonName,
            }
          : { personFinfireId, externePerson: false, externePersonName: null },
      ),
      source?.beteiligungseigentuemer,
      useFormModelAsDefault,
    ),
    zeitwert: mapToNumericRequestModelType(
      formModel.zeitwert,
      source?.zeitwert,
      useFormModelAsDefault,
    ),
    datumZeitwert: mapToDateRequestModelType(
      formModel.datumZeitwert,
      source?.datumZeitwert,
      useFormModelAsDefault,
    ),
    endwert: mapToNumericRequestModelType(
      formModel.endwert,
      source?.endwert,
      useFormModelAsDefault,
    ),
    laufendeSparrate: {
      ...source?.laufendeSparrate,
      sparrate: mapToNumericRequestModelType(
        formModel.laufendeSparrate.sparrate,
        source?.laufendeSparrate?.sparrate,
        useFormModelAsDefault,
      ),
      zahlweise: convertZahlweise(
        formModel.laufendeSparrate.zahlweise,
        source?.laufendeSparrate?.zahlweise,
        useFormModelAsDefault,
      ),
      zahlart: mapToRequestModelType(
        formModel.laufendeSparrate.zahlart,
        source?.laufendeSparrate?.zahlart,
        useFormModelAsDefault,
      ),
    },
    quelleRisikoklassifizierung: mapToRequestModelType(
      formModel.quelleRisikoklassifizierung,
      source?.quelleRisikoklassifizierung,
      useFormModelAsDefault,
    ),
    riskanteAnteile: mapToNumericPercentRequestModelType(
      formModel.riskanteAnteile,
      source?.riskanteAnteile,
      useFormModelAsDefault,
    ),
    vertragsnummer: mapToRequestModelType(
      formModel.vertragsnummer,
      source?.vertragsnummer,
      useFormModelAsDefault,
    ),
    vertragsbeginn: mapToDateRequestModelType(
      formModel.vertragsbeginn,
      source?.vertragsbeginn,
      useFormModelAsDefault,
    ),
    vertragsende: mapToDateRequestModelType(
      formModel.vertragsende,
      source?.vertragsende,
      useFormModelAsDefault,
    ),
    createFlag: formModel.isNew,
    deleteFlag: formModel.isDeleted,
    saveError: formModel.saveError,
  }
}
