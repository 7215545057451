import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel, FormTextCell } from 'components/controls/cells/FormCell'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { AnlegerprofilKreditfinanzierteAnlagenFormModel } from 'form/KundeFormModel'
import { anlegerprofilKreditfinanzierteAnlagenInitialTouched } from 'form/kundeFormTouched'
import { anlegerprofilKreditfinanzierteAnlagenValidationSchema } from 'form/kundeFormValidationSchema'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalAnlegerProfilKreditfinanzierteAnlagenFormProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
  isPartnerAvailable?: boolean
}

export const PersonalAnlegerProfilKreditfinanzierteAnlagenForm = ({
  personIndex,
  selectiveFormProps,
  isPartnerAvailable = false,
}: PersonalAnlegerProfilKreditfinanzierteAnlagenFormProps): ReactElement => {
  const { anlegerprofilMutator } = useEditableKunde()
  const { initialFormValues, selectiveFieldProps } =
    useSelectiveKundeForm<AnlegerprofilKreditfinanzierteAnlagenFormModel>(
      `personen.${personIndex}.anlegerprofil.kreditfinanzierteAnlagen`,
      selectiveFormProps,
    )
  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(kreditfinanzierteAnlagen) => {
        anlegerprofilMutator.updateKreditfinanzierteAnlagen(personIndex, kreditfinanzierteAnlagen)
      }}
      validationSchema={anlegerprofilKreditfinanzierteAnlagenValidationSchema}
      initialTouched={anlegerprofilKreditfinanzierteAnlagenInitialTouched}
      validateOnMount
    >
      {() => (
        <Table>
          <TableHead>
            <TableRow>
              <FormCellLabel $border={isPartnerAvailable} $highlighted></FormCellLabel>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <FormTextCell $border={isPartnerAvailable}>
                <TextInput<AnlegerprofilKreditfinanzierteAnlagenFormModel>
                  name={'finanzierungsArt'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilKreditfinanzierteAnlagenFormModel>
                  name={'kreditVolumen'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </EditableKundeFormik>
  )
}
