import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import backgroundMin from 'assets/images/guidance/career/persoenlichkeitsentwicklung/persoenlichkeitsentwicklung_background_min.png'
import notebook from 'assets/images/guidance/career/persoenlichkeitsentwicklung/persoenlichkeitsentwicklung_notebook.png'
import styles from 'components/guidance/career/GuidanceCareer5Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer5Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={4}
      previousPagePath={Paths.guidanceCareer4}
      nextPagePath={Paths.guidanceCareer6}
      guidanceColor='career'
      backgroundImage={backgroundMin}
      backgroundImageClass={styles.backgroundClass}
      numberOfInterPages={2}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title='PERSÖNLICHKEITSENTWICKLUNG'
          subTitle='Das Karriere-Institut'
        >
          <Grid container className={styles.container}>
            <Grid item lg={5} xs={12} className={styles.textContainer}>
              <h2 className={styles.text}>WIE ERREICHE ICH DAS?</h2>
              {interPageIndex === 1 ? (
                <h3 className={styles.text}>Durch Persönlichkeits&shy;entwicklung</h3>
              ) : (
                <h3>&nbsp;</h3>
              )}
            </Grid>
            <Grid item lg={7} xs={12} className={styles.imageContainer}>
              {interPageIndex === 1 && <img src={notebook} alt='Selbstverwirklichungsdiagramm' />}
            </Grid>
          </Grid>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
