import { convertAusgaben } from 'form/haushalt-converter/person-converter/ausgabenConverter'
import { convertBeruf } from 'form/haushalt-converter/person-converter/berufConverter'
import { convertEinkommen } from 'form/haushalt-converter/person-converter/einkommenConverter'
import { isEmpty } from 'form/haushalt-converter/person-converter/personenConverter'
import { PersonFinanzenFormModel } from 'form/KundeFormModel'
import { PersonFinanzen } from 'interfaces/mynorm-api-model-interfaces'
import { mapToNumericRequestModelType } from 'utils/converterUtils'

export const convertFinanzen = (
  formModel: PersonFinanzenFormModel | undefined,
  original: PersonFinanzen | undefined,
  useFormModelAsDefault: boolean,
): PersonFinanzen | undefined => {
  if (formModel == null || (isEmpty(formModel) && original === null)) {
    return original
  }
  return {
    ...original,
    ausgaben: convertAusgaben(formModel.ausgaben, original?.ausgaben),
    einkommen: convertEinkommen(formModel.einkommen, original?.einkommen),
    beruf: convertBeruf(formModel.beruf, original?.beruf, useFormModelAsDefault),
    zugesagteDispokredite: mapToNumericRequestModelType(
      formModel.zugesagteDispokredite,
      original?.zugesagteDispokredite,
    ),
    genommeneDispokrediteDurchschnittlich: mapToNumericRequestModelType(
      formModel.genommeneDispokrediteDurchschnittlich,
      original?.genommeneDispokrediteDurchschnittlich,
    ),
    anzahlKinderPflegeversicherung: mapToNumericRequestModelType(
      formModel.anzahlKinderPflegeversicherung,
      original?.anzahlKinderPflegeversicherung,
    ),
  }
}
