import React, { ReactElement, useState } from 'react'
import { ArrowCircleRightOutlined } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { useCreateErweitertesKundendokument } from 'api/useCreateReportErweitertesKundendokument'
import { useCreateFinanzfahrplan } from 'api/useCreateReportFinanzfahrplan'
import { useCreateVertragsspiegel } from 'api/useCreateReportVertragsspiegel'
import { useUploadErweitertesKundendokument } from 'api/useUploadErweitertesKundendokument'
import cockpitStyles from 'components/cockpit/Cockpit.module.scss'
import { CockpitSummaryDialog } from 'components/cockpit/CockpitSummaryDialog'
import { ReportDownloadRow } from 'components/cockpit/report/DinReportDownloadRow'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

type DinReportFormProps = {
  haushalt: HaushaltAggregate | undefined
}

export const DinReportButton = ({ haushalt }: DinReportFormProps): ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const uploadDinReportMutation = useUploadErweitertesKundendokument()
  const createErweitertesKundendokumentMutation = useCreateErweitertesKundendokument()
  const createVertragsspiegelMutation = useCreateVertragsspiegel()
  const createFinanzfahrplan = useCreateFinanzfahrplan()

  const handleClose = (): void => {
    setDialogOpen(false)
    createErweitertesKundendokumentMutation.reset()
    createVertragsspiegelMutation.reset()
    createFinanzfahrplan.reset()
    uploadDinReportMutation.reset()
  }

  return (
    <>
      <Button
        variant='contained'
        disableRipple
        className={cockpitStyles.reportButton}
        endIcon={<ArrowCircleRightOutlined className={cockpitStyles.buttonIcon} />}
        onClick={() => setDialogOpen(true)}
      >
        Report
      </Button>
      <CockpitSummaryDialog open={dialogOpen} onClose={handleClose}>
        <div>
          <div>
            <h2>DIN Reports</h2>
          </div>
          <Grid container>
            <Grid item xs={12} container gap={1}>
              <ReportDownloadRow
                name={'Erweitertes Kundendokument'}
                perPersonPossible
                description={
                  'Ziele, Vermögensbilanz, EAR, Scores, Finanzthemen, Altersvorsorge, Vertragspiegel, Rahmenparameter'
                }
                downloadMutation={createErweitertesKundendokumentMutation}
                uploadMutation={uploadDinReportMutation}
                haushalt={haushalt}
              />
              <ReportDownloadRow
                name={'Vertragsspiegel'}
                haushalt={haushalt}
                downloadMutation={createVertragsspiegelMutation}
              />
              <ReportDownloadRow
                name={'Finanzfahrplan'}
                perPersonPossible
                haushalt={haushalt}
                downloadMutation={createFinanzfahrplan}
              />
              {/* TODO enable when finoso api is resolved (https://trello.com/c/RCusqfsj)*/}
              {/* <RisikoanalyseRow haushalt={haushalt} />*/}
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item xs={12} container justifyContent='flex-end'>
              <Button variant='outlined' onClick={handleClose}>
                Schließen
              </Button>
            </Grid>
          </Grid>
        </div>
      </CockpitSummaryDialog>
    </>
  )
}
