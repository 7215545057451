import React, { ReactElement } from 'react'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'

import DevelopmentHint from 'components/development-hint/DevelopmentHint'
import { ApplicationProvider } from 'routes/router'
import { appTheme } from 'theme/Theme'

function App(): ReactElement {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ApplicationProvider />
        <DevelopmentHint />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
