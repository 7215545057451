import React, { ReactElement, ReactNode } from 'react'
import { FieldMetaProps } from 'formik'

type HelperTextProps<Value> = {
  metaProps?: FieldMetaProps<Value>
  text?: string | ReactNode
}

export const HelperText = <Value,>({ text, metaProps }: HelperTextProps<Value>): ReactElement => {
  if (text) {
    return <>{text}</>
  }
  if (metaProps?.touched && metaProps?.error) {
    return <>{metaProps.error}</>
  }
  return <span>&nbsp;</span>
}
