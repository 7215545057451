import {
  convertZahlweiseFormModel,
  handlePercent,
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { BeteiligungFormModel } from 'form/KundeFormModel'
import { createDefaultBeteiligungFormModel } from 'form/kundeFormModelCreator'
import { Beteiligung } from 'interfaces/mynorm-api-model-interfaces'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

const defaultBeteiligungFormModel = createDefaultBeteiligungFormModel()

export const convertToBeteiligungFormModel = (
  beteiligung: Beteiligung | undefined,
): BeteiligungFormModel => {
  if (!beteiligung) {
    return defaultBeteiligungFormModel
  }
  return {
    meta: convertToMeta(beteiligung.meta),
    bezeichnung: nullableValueOrDefault(
      beteiligung.bezeichnung,
      defaultBeteiligungFormModel.bezeichnung,
    ),
    bemerkungen: nullableValueOrDefault(
      beteiligung.bemerkungen,
      defaultBeteiligungFormModel.bemerkungen,
    ),
    bemerkungenIntern: nullableValueOrDefault(
      beteiligung.bemerkungenIntern,
      defaultBeteiligungFormModel.bemerkungenIntern,
    ),
    gesellschaft: nullableValueOrDefault(
      beteiligung.gesellschaft,
      defaultBeteiligungFormModel.gesellschaft,
    ),
    beteiligungseigentuemer: nullableValueOrDefault(
      beteiligung.beteiligungseigentuemer?.map(
        (e) => e.personFinfireId ?? EXTERNE_PERSON_OPTION_VALUE,
      ),
      defaultBeteiligungFormModel.beteiligungseigentuemer,
    ),
    beteiligungseigentuemerExternePersonName: nullableValueOrDefault(
      beteiligung.beteiligungseigentuemer?.find((e) => e.externePerson)?.externePersonName,
      defaultBeteiligungFormModel.beteiligungseigentuemerExternePersonName,
    ),
    vertragsnummer: nullableValueOrDefault(
      beteiligung.vertragsnummer,
      defaultBeteiligungFormModel.vertragsnummer,
    ),
    vertragsbeginn: nullableDayjsOrDefault(
      beteiligung.vertragsbeginn,
      defaultBeteiligungFormModel.vertragsbeginn,
    ),
    vertragsende: nullableDayjsOrDefault(
      beteiligung.vertragsende,
      defaultBeteiligungFormModel.vertragsende,
    ),
    riskanteAnteile: nullableValueOrDefault(
      handlePercent(beteiligung.riskanteAnteile),
      defaultBeteiligungFormModel.riskanteAnteile,
    ),
    zeitwert: nullableValueOrDefault(
      beteiligung.zeitwert?.toString(),
      defaultBeteiligungFormModel.zeitwert,
    ),
    beteiligungTyp: valueOrDefault(
      beteiligung.beteiligungTyp,
      defaultBeteiligungFormModel.beteiligungTyp,
    ),
    unternehmensTyp: nullableValueOrDefault(
      beteiligung.unternehmensTyp,
      defaultBeteiligungFormModel.unternehmensTyp,
    ),
    datumZeitwert: nullableDayjsOrDefault(
      beteiligung.datumZeitwert,
      defaultBeteiligungFormModel.datumZeitwert,
    ),
    endwert: nullableValueOrDefault(
      beteiligung.endwert?.toString(),
      defaultBeteiligungFormModel.endwert,
    ),
    laufendeSparrate: {
      sparrate: nullableValueOrDefault(
        beteiligung.laufendeSparrate?.sparrate?.toString(),
        defaultBeteiligungFormModel.laufendeSparrate.sparrate,
      ),
      zahlweise: convertZahlweiseFormModel(
        beteiligung.laufendeSparrate?.zahlweise,
        defaultBeteiligungFormModel.laufendeSparrate.zahlweise,
      ),
      zahlart: valueOrDefault(
        beteiligung.laufendeSparrate?.zahlart,
        defaultBeteiligungFormModel.laufendeSparrate.zahlart,
      ),
    },
    quelleRisikoklassifizierung: nullableValueOrDefault(
      beteiligung.quelleRisikoklassifizierung,
      defaultBeteiligungFormModel.quelleRisikoklassifizierung,
    ),
    isNew: beteiligung.createFlag ?? false,
    isDeleted: beteiligung.deleteFlag ?? false,
    saveError: beteiligung.saveError ?? false,
  }
}
