import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean } from 'components/util/formatUtil'
import { BerufshaftpflichtDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

type BerufshaftpflichtDetailsFormBlockProps = BaseDetailsFormBlockProps

const FORM_BLOCK_TITLE = 'Details - Berufshaftpflicht'

export const BerufshaftpflichtDetailsFormBlock = ({
  produktId,
  name,
}: BerufshaftpflichtDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.berufshaftpflichtDetails`

  if (!isRelevantForDetailsTypAndFinfireProduktId('BERUFSHAFTPFLICHT', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <CheckboxInput
            name={`${baseName}.einschlussPrivathaftpflicht`}
            label={'Einschluss Privathaftpflicht'}
          />
          <CheckboxInput
            name={`${baseName}.betriebsbeauftragte`}
            label={'Betriebsbeauftragte'}
            tooltip={
              'Hier sollte gekennzeichnet werden, wenn die versicherte Person Betriebsbeauftragte/r für z.B. Datenschutz oder Brandschutz ist und die Betriebshaftpflicht in diesem Fall leistet.'
            }
          />
          <CheckboxInput
            name={`${baseName}.syndikusanwalt`}
            label={'Syndikusanwalt / angestellter Arzt'}
            tooltip={
              'Hier sollte gekennzeichnet werden, wenn die versicherte Person im Rahmen der Betriebshaftpflichtversicherung als Syndikusanwalt oder angestellter Arzt versichert ist.'
            }
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

type BerufshaftpflichtDetailReadViewProps = BaseDetailsReadViewProps & {
  berufshaftpflichtDetails: BerufshaftpflichtDetails | undefined
}

export const BerufshaftpflichtDetailReadView = ({
  produktId,
  berufshaftpflichtDetails,
}: BerufshaftpflichtDetailReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('BERUFSHAFTPFLICHT', produktId)) {
    return null
  }

  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Einschluss Privathaftpflicht'}
        value={formatBoolean(berufshaftpflichtDetails?.einschlussPrivathaftpflicht)}
      />
      <LabelValueEntry
        label={'Betriebsbeauftragte'}
        value={formatBoolean(berufshaftpflichtDetails?.betriebsbeauftragte)}
      />
      <LabelValueEntry
        label={'Syndikusanwalt / angestellter Arzt'}
        value={formatBoolean(berufshaftpflichtDetails?.syndikusanwalt)}
      />
    </Stack>
  )
}
