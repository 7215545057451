import React, { ReactElement, ReactNode } from 'react'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useField } from 'formik'

import { HelperText } from 'components/controls/inputs/HelperText'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import { SelectiveTextInput } from 'components/controls/selective-inputs/SelectiveTextInput'
import { formatMultipleValues, formatSingleValue } from 'utils/formatUtils'

export type Option<T = string> = {
  value: (T & string) | (T & boolean)
  label: string
  disabled?: boolean
}

type SelectInputProps<FormType> = {
  options: Option<string | boolean>[]
  multiple?: boolean
} & BaseInputProps<FormType>

export const getLabelByValue = <T,>(options: Option<T>[], value: T): string | undefined => {
  return options.find((o) => o.value === value)?.label
}

export const SelectInput = <FormType,>({
  options,
  label,
  tooltip,
  name,
  multiple,
  selectiveFieldProps,
  ...props
}: SelectInputProps<FormType>): ReactElement => {
  const [fieldInputProps, metaProps] = useField(name)
  if (selectiveFieldProps) {
    return (
      <SelectiveTextInput<FormType>
        name={name}
        label={label}
        value={multiple ? '' : formatSingleValue(options, fieldInputProps.value)}
        defaultValue={[]}
        selectiveFieldProps={{
          ...selectiveFieldProps,
          formatValue: (value) => {
            if (Array.isArray(value)) {
              return formatMultipleValues(options, value)
            }
            return formatSingleValue(options, value)
          },
        }}
      />
    )
  }
  return (
    <BaseSelect
      {...props}
      options={options}
      {...fieldInputProps}
      name={fieldInputProps.name}
      value={fieldInputProps.value}
      onChange={fieldInputProps.onChange}
      label={label}
      tooltip={tooltip}
      multiple={multiple}
      error={!!metaProps.error && metaProps.touched}
      formHelperText={
        <FormHelperText error={!!metaProps.error && metaProps.touched}>
          <HelperText metaProps={metaProps} />
        </FormHelperText>
      }
    />
  )
}

type BaseSelectProps<OptionType = string> = {
  name?: string
  label?: string
  tooltip?: string
  options: Option<OptionType>[]
  multiple?: boolean
  value: OptionType | OptionType[] | undefined
  onChange: (event: SelectChangeEvent<OptionType | OptionType[]>, child: ReactNode) => void
  onClose?: (value: OptionType | OptionType[] | undefined) => void
  formHelperText?: ReactElement
  error?: boolean
}

export const BaseSelect = <OptionType,>({
  name,
  label,
  tooltip,
  options,
  multiple,
  value,
  onChange,
  onClose,
  formHelperText,
  error,
  ...props
}: BaseSelectProps<OptionType>): ReactElement => {
  return (
    <InputWrapper tooltip={tooltip}>
      <FormControl variant='standard' fullWidth error={error}>
        <InputLabel>{label}</InputLabel>
        <Select<OptionType | OptionType[]>
          {...props}
          name={name}
          label={label}
          multiple={multiple}
          value={value === null || value === undefined ? '' : value}
          onChange={onChange}
          onClose={() => onClose?.(value)}
          renderValue={
            multiple
              ? (selected) => {
                  if (Array.isArray(selected)) {
                    return selected
                      .map((value) => getLabelByValue(options, value) || value)
                      .join(', ')
                  }
                }
              : undefined
          }
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={String(option.value)}
                // Any wird hier benötigt, damit das MenuItem auch mit einem Boolean umgehen kann
                // eslint-disable-next-line
                value={option.value as any}
                dense
                disabled={Boolean(option.disabled)}
              >
                {multiple ? (
                  <>
                    <Checkbox
                      checked={!!value && Array.isArray(value) && value?.indexOf(option.value) > -1}
                      size='small'
                    />
                    <ListItemText primary={option.label} />
                  </>
                ) : (
                  option.label
                )}
              </MenuItem>
            )
          })}
        </Select>
        <>{formHelperText && formHelperText}</>
      </FormControl>
    </InputWrapper>
  )
}
