import { handlePercent, nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { ImmobilieFormModel } from 'form/KundeFormModel'
import { createDefaultImmobilieFormModel } from 'form/kundeFormModelCreator'
import { Immobilie } from 'interfaces/mynorm-api-model-interfaces'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

const defaultImmobilieFormModel = createDefaultImmobilieFormModel()

export const convertToImmobilieFormModel = (
  immobilie: Immobilie | undefined,
): ImmobilieFormModel => {
  if (!immobilie) {
    return defaultImmobilieFormModel
  }
  return {
    meta: convertToMeta(immobilie.meta),
    immobilieNutzungsArt: valueOrDefault(
      immobilie.immobilieNutzungsArt,
      defaultImmobilieFormModel.immobilieNutzungsArt,
    ),
    entschuldetImRuhestand: valueOrDefault(
      immobilie.entschuldetImRuhestand,
      defaultImmobilieFormModel.entschuldetImRuhestand,
    ),
    kreditlinieInstandhaltung: nullableValueOrDefault(
      immobilie.kreditlinieInstandhaltung?.toString(),
      defaultImmobilieFormModel.kreditlinieInstandhaltung,
    ),
    objektArt: valueOrDefault(immobilie.objektArt, defaultImmobilieFormModel.objektArt),
    wohngebaeudeVersicherungViaETG: nullableValueOrDefault(
      immobilie.wohngebaeudeVersicherungViaETG,
      defaultImmobilieFormModel.wohngebaeudeVersicherungViaETG,
    ),
    hausUndGrundbesitzerHaftpflichtViaETG: nullableValueOrDefault(
      immobilie.hausUndGrundbesitzerHaftpflichtViaETG,
      defaultImmobilieFormModel.hausUndGrundbesitzerHaftpflichtViaETG,
    ),
    instanhaltungsruecklageViaETG: nullableValueOrDefault(
      immobilie.instanhaltungsruecklageViaETG,
      defaultImmobilieFormModel.instanhaltungsruecklageViaETG,
    ),
    aktuelleBaumassnahmen: valueOrDefault(
      immobilie.aktuelleBaumassnahmen,
      defaultImmobilieFormModel.aktuelleBaumassnahmen,
    ),
    wohnflaeche: valueOrDefault(
      immobilie.wohnflaeche?.toString(),
      defaultImmobilieFormModel.wohnflaeche,
    ),
    baujahr: valueOrDefault(immobilie.baujahr?.toString(), defaultImmobilieFormModel.baujahr),
    photovoltaik: valueOrDefault(
      immobilie.photovoltaik ?? false,
      defaultImmobilieFormModel.photovoltaik,
    ),
    oeltank: valueOrDefault(immobilie.oeltank ?? false, defaultImmobilieFormModel.oeltank),
    wert1914: nullableValueOrDefault(
      immobilie.wert1914?.toString(),
      defaultImmobilieFormModel.wert1914,
    ),
    neubauSumme: valueOrDefault(
      immobilie.neubauSumme?.toString(),
      defaultImmobilieFormModel.neubauSumme,
    ),
    neuwertHausrat: nullableValueOrDefault(
      immobilie.neuwertHausrat?.toString(),
      defaultImmobilieFormModel.neuwertHausrat,
    ),
    verkehrswert: nullableValueOrDefault(
      immobilie.verkehrswert?.toString(),
      defaultImmobilieFormModel.verkehrswert,
    ),
    riskanteAnteile: nullableValueOrDefault(
      handlePercent(immobilie.riskanteAnteile)?.toString(),
      defaultImmobilieFormModel.riskanteAnteile,
    ),
    quelleRisikoklassifizierung: valueOrDefault(
      immobilie.quelleRisikoklassifizierung,
      defaultImmobilieFormModel.quelleRisikoklassifizierung,
    ),
    bemerkungen: nullableValueOrDefault(
      immobilie.bemerkungen,
      defaultImmobilieFormModel.bemerkungen,
    ),
    bemerkungenIntern: nullableValueOrDefault(
      immobilie.bemerkungenIntern,
      defaultImmobilieFormModel.bemerkungenIntern,
    ),
    adresse: {
      strasse: valueOrDefault(
        immobilie.adresse?.strasse,
        defaultImmobilieFormModel.adresse?.strasse,
      ),
      hausnummer: valueOrDefault(
        immobilie.adresse?.hausnummer,
        defaultImmobilieFormModel.adresse.hausnummer,
      ),
      postleitzahl: valueOrDefault(
        immobilie.adresse?.postleitzahl,
        defaultImmobilieFormModel.adresse.postleitzahl,
      ),
      ort: valueOrDefault(immobilie.adresse?.ort, defaultImmobilieFormModel.adresse.ort),
      bundesland: valueOrDefault(
        immobilie.adresse?.bundesland,
        defaultImmobilieFormModel.adresse.bundesland,
      ),
      land: valueOrDefault(immobilie.adresse?.land, defaultImmobilieFormModel.adresse.land),
    },
    eigentuemer: nullableValueOrDefault(
      immobilie.eigentuemer?.map((e) => e.personFinfireId ?? EXTERNE_PERSON_OPTION_VALUE),
      defaultImmobilieFormModel.eigentuemer,
    ),
    eigentuemerExternePersonName: nullableValueOrDefault(
      immobilie.eigentuemer?.find((e) => e.externePerson)?.externePersonName,
      defaultImmobilieFormModel.eigentuemerExternePersonName,
    ),
    bezeichnung: nullableValueOrDefault(
      immobilie.bezeichnung,
      defaultImmobilieFormModel.bezeichnung,
    ),
    expanded: defaultImmobilieFormModel.expanded,
    isDeleted: immobilie.deleteFlag ?? false,
    isNew: immobilie.createFlag ?? false,
    saveError: immobilie.saveError ?? false,
  }
}
