import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button } from '@mui/material'
import axios from 'axios'

import { BackendPaths } from 'configurations/backendPaths'
import { Paths } from 'configurations/paths'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { useIsAuthenticated } from 'hooks/useIsAuthenticated'
import { useEditableKunde } from 'state/useEditableKunde'

export const LogoutButton = (): ReactElement | null => {
  const clearHaushalt = useHaushaltState(({ clear }) => clear)
  const clearEditableKunde = useEditableKunde(({ clear }) => clear)
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const handleClick = async (): Promise<void> => {
    await axios.post(BackendPaths.logout)
    clearHaushalt()
    clearEditableKunde()
    navigate(Paths.login, { state: { logout: true } })
  }

  return isAuthenticated ? (
    <Button onClick={handleClick} variant={'outlined'} color={'error'} endIcon={<LogoutIcon />}>
      Logout
    </Button>
  ) : null
}
