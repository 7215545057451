import React, { ReactElement, useEffect } from 'react'
import { Grid, Stack } from '@mui/material'

import selectiveStyles from 'components/controls/selective-inputs/SelectiveCommon.module.scss'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { useSelectiveKundeFieldsComparison, useSelectiveSwitch } from 'hooks/useSelectiveComparison'

type SelectiveInputGroupField = {
  name: string
  componentCreator?: (
    currentValue: unknown,
    originalValue: unknown,
    isDirty: boolean,
    switchActive: boolean,
  ) => ReactElement
}

type SelectiveInputGroupProps = {
  fields: SelectiveInputGroupField[]
  selectiveFieldProps: SelectiveInputProps
}

export const SelectiveInputGroup = ({
  fields,
  selectiveFieldProps,
}: SelectiveInputGroupProps): ReactElement => {
  const comparisonResults = useSelectiveKundeFieldsComparison(
    fields.map((field) => field.name),
    selectiveFieldProps.formPath,
  )
  const isAnyFieldDirty = comparisonResults.some((comp) => comp.isDirty)
  const { switchActive, switchComponent } = useSelectiveSwitch(isAnyFieldDirty)

  useEffect(() => {
    comparisonResults.map((comparisonResult) => {
      selectiveFieldProps.onSelected({
        selected: switchActive,
        fieldPath: comparisonResult.fieldPath,
      })
    })
  }, [switchActive])

  const className = switchActive ? selectiveStyles.selected : selectiveStyles.unselected
  return (
    <Stack direction='row' className={selectiveStyles.selectiveInputGroup}>
      <Grid container spacing={2} className={className}>
        {fields.map((field) => {
          const comparison = comparisonResults.find((comp) => comp.fieldName === field.name)
          if (!comparison) {
            return null
          }
          return (
            <React.Fragment key={field.name}>
              {field.componentCreator?.(
                comparison.currentValue,
                comparison.originalValue,
                comparison.isDirty,
                switchActive,
              )}
            </React.Fragment>
          )
        })}
      </Grid>
      {switchComponent}
    </Stack>
  )
}
