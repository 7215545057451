import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { KfzDetails, KfzVersicherungsumfang } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const versicherungsUmfangOptions: Option<KfzVersicherungsumfang>[] = [
  {
    label: 'Keine Kasko',
    value: 'KEINE_KASKO',
  },
  {
    label: 'Teilkasko',
    value: 'TEILKASKO',
  },
  {
    label: 'Vollkasko',
    value: 'VOLLKASKO',
  },
]

type KfzDetailsFormBlockProps = BaseDetailsFormBlockProps

const FORM_BLOCK_TITLE = 'Details - Kfz'

export const KfzDetailsFormBlock = ({
  produktId,
  name,
}: KfzDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.kfzDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('KFZ', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={6}>
        <NumberInput name={`${baseName}.deckungssummeKfz`} label={'Deckungssumme'} inputUnit='€' />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          name={`${baseName}.kfzVersicherungsumfang`}
          label={'Versicherungsumfang'}
          options={versicherungsUmfangOptions}
        />
      </Grid>
    </Grid>
  )
}

type KfzDetailReadViewProps = BaseDetailsReadViewProps & {
  kfzDetails: KfzDetails | undefined
}

export const KfzDetailReadView = ({
  produktId,
  kfzDetails,
}: KfzDetailReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('KFZ', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Deckungssumme'}
        value={formatCurrency(kfzDetails?.deckungssummeKfz)}
      />
      <LabelValueEntry
        label={'Versicherungsumfang'}
        value={getOptionLabel(kfzDetails?.kfzVersicherungsumfang, versicherungsUmfangOptions)}
      />
    </Stack>
  )
}
