import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { ImmobilieFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

export type ImmobilienMutator = {
  updateImmobilie: (
    personIndex: number,
    immobilieIndex: number,
    immobilieFormModel: ImmobilieFormModel,
  ) => void
  removeImmobilie: (personIndex: number, immobilieIndex: number) => void
  addImmobilie: (personIndex: number, immobilieFormModel: ImmobilieFormModel) => void
}

export const immobilienMutator = (set: Mutator): ImmobilienMutator => {
  return {
    updateImmobilie: (
      personIndex: number,
      immobilieIndex: number,
      immobilieFormModel: ImmobilieFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].vermoegen.immobilien[immobilieIndex] = immobilieFormModel
          }),
        }
      })
    },
    removeImmobilie: (personIndex: number, immobilieIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const immoToRemove = draft.personen[personIndex].vermoegen.immobilien[immobilieIndex]
            if (immoToRemove.isNew) {
              draft.personen[personIndex].vermoegen.immobilien.splice(immobilieIndex, 1)
            } else {
              draft.personen[personIndex].vermoegen.immobilien[immobilieIndex].isDeleted = true
            }
            draft.personen.forEach((person) => {
              person.vermoegen.geldAnlagen?.forEach((geldAnlage) => {
                if (hasId(immoToRemove, geldAnlage.immobilieId)) {
                  geldAnlage.geldanlageZielArt = 'KEIN_ZIEL'
                  geldAnlage.immobilieId = undefined
                }
              })
            })
          }),
        }
      })
    },
    addImmobilie: (personIndex: number, immobilieFormModel: ImmobilieFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            if (!immobilieFormModel.meta.myviId) {
              const id = uuidv4()
              immobilieFormModel.meta.myviId = id
              immobilieFormModel.meta.finfireId = id
            }
            immobilieFormModel.isNew = true
            draft.personen[personIndex].vermoegen.immobilien.push(immobilieFormModel)
          }),
        }
      })
    },
  }
}
