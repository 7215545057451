import React, { ReactElement } from 'react'
import CountUp from 'react-countup'
import { Box, CircularProgress, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/cockpit/CircularDisplays.module.scss'
import { formatNumberWithFractionDigits } from 'components/util/formatUtil'

type CircularDisplayProps = {
  value: number
  label: ReactElement | string | number
  thickness?: number
  className?: string
}

export const CircularDisplay = ({
  value,
  label,
  thickness = 4,
  className,
}: CircularDisplayProps): ReactElement | null => {
  return (
    <Box className={clsx(styles.circularDisplayContainer, className)}>
      <CircularProgress
        variant='determinate'
        className={styles.backgroundCircle}
        thickness={thickness}
      />
      <CircularProgress variant='determinate' value={value} thickness={thickness} />
      <Box className={styles.circularProgressLabel}>{label}</Box>
    </Box>
  )
}

type DinScoreLabelProps = {
  score: number
}

export const DinScoreLabel = ({ score }: DinScoreLabelProps): ReactElement => {
  return (
    <>
      <Typography className={styles.dinScoreLabelText}>DIN Finanzscore</Typography>
      <Typography className={styles.dinScoreLabelValue}>
        <CountUp
          end={score}
          useGrouping={true}
          useEasing={true}
          duration={1}
          formattingFn={(value) => `${formatNumberWithFractionDigits(value, 0)} %`}
        />
      </Typography>
    </>
  )
}

type SaldoLabelProps = {
  saldo: number | undefined
}

export const SaldoLabel = ({ saldo = 0 }: SaldoLabelProps): ReactElement => {
  return (
    <>
      <Typography className={styles.saldoLabelText}>Finanzielle Risikotragfähigkeit</Typography>
      <Typography className={clsx(styles.saldoLabelValue, saldo < 0 && styles.negative)}>
        <CountUp
          end={saldo}
          useGrouping={true}
          useEasing={true}
          duration={1}
          formattingFn={(value) => `${formatNumberWithFractionDigits(value, 0)} €`}
        />
      </Typography>
      <Typography className={styles.saldoSubInfo}>(Liq. II)</Typography>
    </>
  )
}
