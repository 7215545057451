import React, { ReactElement } from 'react'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { AnschriftFormModel, ImmobilieFormModel } from 'form/KundeFormModel'
import { getOptionLabel, objektArtOptions } from 'form/kundeOptions'
import { useImmobilien } from 'state/useImmobilien'

type ImmobilienSelectProps = {
  name: string
  label?: string
  multiple?: boolean
  disabled?: boolean
}

export const VersicherteImmobilieSelect = ({
  name,
  label,
  multiple,
  disabled,
}: ImmobilienSelectProps): ReactElement => {
  const { immobilien } = useImmobilien()

  const immobilienOptions: Option[] = immobilien.map((immo) => toImmobilieOption(immo))
  const noneOption: Option = { value: '', label: '-' }
  if (!multiple) {
    immobilienOptions.push(noneOption)
  }

  return (
    <SelectInput
      options={immobilienOptions}
      name={name}
      label={label ?? 'Versicherte Immobilie'}
      multiple={multiple}
      disabled={disabled}
    />
  )
}

export const toImmobilieOption = (immobilie: ImmobilieFormModel): Option => {
  let label = getOptionLabel(immobilie.objektArt, objektArtOptions)
  const adresseLabel = formatAdresse(immobilie?.adresse)
  if (adresseLabel) {
    label += ` (${adresseLabel})`
  }

  return {
    label: label || 'Ohne Bezeichnung',
    value: immobilie.meta.finfireId || immobilie.meta.myviId,
  }
}

const formatAdresse = (anschriftFormModel: AnschriftFormModel | undefined): string | null => {
  if (!anschriftFormModel?.strasse && !anschriftFormModel?.hausnummer) {
    return null
  }

  const streetParts = []

  if (anschriftFormModel.strasse) {
    streetParts.push(anschriftFormModel.strasse)
  }
  if (anschriftFormModel.hausnummer) {
    streetParts.push(anschriftFormModel.hausnummer)
  }

  return streetParts.join(' ')
}
