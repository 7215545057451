import React, { ReactElement } from 'react'

import cvCheck from 'assets/images/guidance/career/cv-check/cv_check.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer7Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={6}
      previousPagePath={Paths.guidanceCareer6}
      nextPagePath={Paths.guidanceCareer8}
      guidanceColor='career'
      backgroundImage={cvCheck}
    >
      <GuidanceSubPageTemplate
        title='PROFESSIONELLER CV-CHECK INKL. AUSWERTUNGSGESPRÄCH'
        subTitle='Das Karriere-Institut'
      />
    </GuidanceSubPage>
  )
}
