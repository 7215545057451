import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean, formatCurrency } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import {
  KrankentagegeldBeginn,
  KrankentagegeldDetails,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const krankentagegeldBeginnOptions: Option<KrankentagegeldBeginn>[] = [
  { value: 'TAG_1', label: 'Tag 1' },
  { value: 'TAG_4', label: 'Tag 4' },
  { value: 'TAG_8', label: 'Tag 8' },
  { value: 'TAG_15', label: 'Tag 15' },
  { value: 'TAG_22', label: 'Tag 22' },
  { value: 'TAG_29', label: 'Tag 29' },
  { value: 'TAG_43', label: 'Tag 43' },
  { value: 'TAG_64', label: 'Tag 64' },
  { value: 'TAG_85', label: 'Tag 85' },
  { value: 'TAG_92', label: 'Tag 92' },
  { value: 'TAG_106', label: 'Tag 106' },
  { value: 'TAG_127', label: 'Tag 127' },
  { value: 'TAG_183', label: 'Tag 183' },
  { value: 'TAG_274', label: 'Tag 274' },
  { value: 'TAG_365', label: 'Tag 365' },
  { value: 'TAG_546', label: 'Tag 546' },
]
const FORM_BLOCK_TITLE = 'Details - Krankentagegeld'

type KrankentagegeldFormBlockProps = BaseDetailsFormBlockProps

export const KrankentagegeldFormBlock = ({
  name,
  produktId,
}: KrankentagegeldFormBlockProps): ReactElement | null => {
  const baseName = `${name}.krankentagegeldDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('KRANKENTAGEGELD', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
        </Grid>
      </Grid>
      <Grid item md={3} xs={6}>
        <NumberInput name={`${baseName}.krankentagegeld`} label={'Krankentagegeld'} inputUnit='€' />
      </Grid>
      <Grid item md={3} xs={6}>
        <SelectInput
          options={krankentagegeldBeginnOptions}
          name={`${baseName}.krankentagegeldBeginn`}
          label={'Krankentagegeld ab'}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <Stack>
          <CheckboxInput name={`${baseName}.ambulant`} label={'Ambulant'} />
          <CheckboxInput name={`${baseName}.stationaer`} label={'Stationär'} />
        </Stack>
      </Grid>
      <Grid item md={3} xs={6}>
        <Stack>
          <CheckboxInput name={`${baseName}.ausland`} label={'Ausland'} />
          <CheckboxInput name={`${baseName}.zahn`} label={'Zahn'} />
        </Stack>
      </Grid>
      <Grid item md={6} xs={6}>
        <NumberInput name={`${baseName}.selbstbehalt`} label={'Selbstbehalt'} inputUnit={'€'} />
      </Grid>
    </Grid>
  )
}

type KrankentagegeldDetailsReadViewProps = BaseDetailsReadViewProps & {
  krankentagegeldDetails?: KrankentagegeldDetails
}

export const KrankentagegeldDetailsReadView = ({
  produktId,
  krankentagegeldDetails,
}: KrankentagegeldDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('KRANKENTAGEGELD', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Krankentagegeld'}
        value={formatCurrency(krankentagegeldDetails?.krankentagegeld)}
      />
      <LabelValueEntry
        label={'Krankentagegeld ab'}
        value={getOptionLabel(
          krankentagegeldDetails?.krankentagegeldBeginn,
          krankentagegeldBeginnOptions,
        )}
      />
      <LabelValueEntry
        label={'Selbstbehalt'}
        value={formatCurrency(krankentagegeldDetails?.selbstbehalt)}
      />
      <LabelValueEntry label={'Ambulant'} value={formatBoolean(krankentagegeldDetails?.ambulant)} />
      <LabelValueEntry
        label={'Stationär'}
        value={formatBoolean(krankentagegeldDetails?.stationaer)}
      />
      <LabelValueEntry label={'Ausland'} value={formatBoolean(krankentagegeldDetails?.ausland)} />
      <LabelValueEntry label={'Zahn'} value={formatBoolean(krankentagegeldDetails?.zahn)} />
    </Stack>
  )
}
