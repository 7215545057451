import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'

import { SliderInput } from 'components/controls/inputs/SliderInput'
import { SelectiveInputGroup } from 'components/controls/selective-inputs/SelectiveInputGroup'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { formatNumberWithSingleFractionDigit } from 'components/util/formatUtil'

type AnlagenstrukturSlidersProps = {
  selectiveFieldProps?: SelectiveInputProps
  disabled?: boolean
}
export const AnlagenstrukturSliders = ({
  selectiveFieldProps,
  disabled,
}: AnlagenstrukturSlidersProps): ReactElement => {
  if (selectiveFieldProps) {
    return <SelectiveAnlagenstruktur selectiveFieldProps={selectiveFieldProps} />
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SliderInput
          name={'anlagenStruktur.aktien'}
          label={'Aktien'}
          selectiveFieldProps={selectiveFieldProps}
          disabled={disabled}
          labelUnit={'%'}
        />
      </Grid>
      <Grid item xs={6}>
        <SliderInput
          name={'anlagenStruktur.zinsAnlagen'}
          label={'Zinsanlagen'}
          selectiveFieldProps={selectiveFieldProps}
          disabled={disabled}
          labelUnit={'%'}
        />
      </Grid>
      <Grid item xs={6}>
        <SliderInput
          name={'anlagenStruktur.immobilien'}
          label={'Immobilien'}
          selectiveFieldProps={selectiveFieldProps}
          disabled={disabled}
          labelUnit={'%'}
        />
      </Grid>
      <Grid item xs={6}>
        <SliderInput
          name={'anlagenStruktur.beteiligungen'}
          label={'Beteiligungen'}
          selectiveFieldProps={selectiveFieldProps}
          disabled={disabled}
          labelUnit={'%'}
        />
      </Grid>
      <Grid item xs={6}>
        <SliderInput
          name={'anlagenStruktur.sonstige'}
          label={'Sonstiges'}
          selectiveFieldProps={selectiveFieldProps}
          disabled={disabled}
          labelUnit={'%'}
        />
      </Grid>
    </Grid>
  )
}

type SelectiveSlidersProps = {
  selectiveFieldProps: SelectiveInputProps
}
const SelectiveAnlagenstruktur = ({ selectiveFieldProps }: SelectiveSlidersProps): ReactElement => {
  return (
    <SelectiveInputGroup
      selectiveFieldProps={selectiveFieldProps}
      fields={sliderKeys.map((sliderKey) => {
        const fieldPath = `anlagenStruktur.${sliderKey}`
        return {
          name: fieldPath,
          componentCreator: (currentValue, originalValue) => {
            return (
              <Grid item xs={4}>
                <TextField
                  key={fieldPath}
                  fullWidth
                  variant='standard'
                  helperText={
                    `${formatNumberWithSingleFractionDigit((originalValue || 0) as number)} %` || (
                      <>&nbsp;</>
                    )
                  }
                  disabled={true}
                  value={`${formatNumberWithSingleFractionDigit(currentValue as number)} %`}
                  label={getLabel(sliderKey)}
                />
              </Grid>
            )
          },
        }
      })}
    />
  )
}

const sliderKeys = ['aktien', 'zinsAnlagen', 'immobilien', 'beteiligungen', 'sonstige'] as const

const getLabel = (sliderKey: string): string => {
  switch (sliderKey) {
    case 'aktien':
      return 'Aktien'
    case 'zinsAnlagen':
      return 'Zinsanlagen'
    case 'immobilien':
      return 'Immobilien'
    case 'beteiligungen':
      return 'Beteiligungen'
    case 'rohstoffe':
      return 'Rohstoffe'
    case 'sonstige':
      return 'Sonstige'
  }
  return ''
}
