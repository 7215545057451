import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button } from '@mui/material'

import { useFetchDinScore } from 'api/useFetchDinScore'
import { useHaushalt } from 'api/useHaushalt'
import { CreateKundeButton } from 'components/create-kunde/CreateKundeButton'
import { HaushaltUebersicht } from 'components/kundenwaehler/HaushaltUebersicht'
import styles from 'components/kundenwaehler/Kundenwaehler.module.scss'
import { KundenwahlInput } from 'components/kundenwaehler/KundenwahlInput'
import { Paths } from 'configurations/paths'
import { usePrepareHaushaltQuery } from 'hooks/useGetHaushalt'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { HaushaltAggregate, SimplePerson } from 'interfaces/mynorm-api-model-interfaces'

export const Kundenwaehler = (): ReactElement => {
  const navigate = useNavigate()
  const { haushalt, setHaushalt } = useHaushaltState(({ haushalt, setHaushalt }) => ({
    haushalt,
    setHaushalt,
  }))
  const [selectedKunde, setSelectedKunde] = useState<SimplePerson | null>(null)
  const {
    data: fetchedHaushalt,
    isSuccess: isHaushaltSuccess,
    isError: isHaushaltError,
    isFetching: isHaushaltFetching,
  } = useHaushalt(selectedKunde)
  const {
    data: fetchedDinScore,
    isFetching: isDinScoreFetching,
    isError: isDinScoreError,
  } = useFetchDinScore(fetchedHaushalt)
  const prepareHaushaltQuery = usePrepareHaushaltQuery()
  const isSelectedKundeFirma = selectedKunde?.firmenkunde
  const handleSelect = async (person: SimplePerson | null): Promise<void> => {
    if (person) {
      setSelectedKunde(person)
    } else {
      console.warn('Es wurde keine Person von Autocomplete an handleSelect übergeben')
    }
  }

  const handleSelectHaushalt = (haushalt: HaushaltAggregate, navigationPath?: string): void => {
    setHaushalt(haushalt, selectedKunde?.finfireId)
    prepareHaushaltQuery(haushalt, selectedKunde?.finfireId)
    if (!navigationPath) {
      navigate(Paths.guidance)
    } else {
      navigate(navigationPath)
    }
  }

  return (
    <div className={styles.uebersichtWrapper}>
      <div className={styles.waehlerBox}>
        <h2>Kundenauswahl</h2>
        <p>Wählen Sie einen Kunden aus...</p>
        <KundenwahlInput
          initialSelectedKunde={haushalt?.personen[0].person}
          onSelect={handleSelect}
          className={styles.waehler}
        />
        {isHaushaltSuccess && fetchedHaushalt && (
          <div className={styles.buttonBox}>
            <Button
              variant={'outlined'}
              onClick={() => handleSelectHaushalt(fetchedHaushalt)}
              fullWidth
              disabled={selectedKunde?.firmenkunde}
            >
              Übernehmen
            </Button>
          </div>
        )}
        {isHaushaltError && (
          <Alert className={styles.error} severity={'error'}>
            Beim Laden des Kunden ist ein Fehler aufgetreten.
          </Alert>
        )}
        {isSelectedKundeFirma && (
          <Alert className={styles.error} severity={'error'}>
            Beim ausgewählten Kunden handelt es sich um einen Firmenkunden. Diese werden nicht
            unterstützt.
          </Alert>
        )}

        <div className={styles.neuerKunde}>
          <CreateKundeButton />
        </div>
      </div>
      <HaushaltUebersicht
        haushalt={fetchedHaushalt}
        isHaushaltFetching={isHaushaltFetching}
        dinScore={fetchedDinScore}
        isDinScoreFetching={isDinScoreFetching}
        isDinScoreError={isDinScoreError}
      />
    </div>
  )
}
