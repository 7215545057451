import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { DarlehenFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type DarlehenMutator = {
  updateDarlehen: (
    personIndex: number,
    darlehenIndex: number,
    darlehenFormModel: DarlehenFormModel,
  ) => void
  removeDarlehen: (personIndex: number, darlehenIndex: number) => void
  addDarlehen: (personIndex: number, darlehenFormModel: DarlehenFormModel) => void
}

export const darlehenMutator = (set: Mutator): DarlehenMutator => {
  return {
    updateDarlehen: (
      personIndex: number,
      darlehenIndex: number,
      darlehenFormModel: DarlehenFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].vermoegen.darlehen[darlehenIndex] = darlehenFormModel
          }),
        }
      })
    },
    removeDarlehen: (personIndex: number, darlehenIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const darlehen = draft.personen[personIndex].vermoegen.darlehen[darlehenIndex]
            if (darlehen.isNew) {
              draft.personen[personIndex].vermoegen.darlehen.splice(darlehenIndex, 1)
            } else {
              draft.personen[personIndex].vermoegen.darlehen[darlehenIndex].isDeleted = true
            }
          }),
        }
      })
    },
    addDarlehen: (personIndex: number, darlehenFormModel: DarlehenFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const id = uuidv4()
            darlehenFormModel.isNew = true
            darlehenFormModel.meta.myviId = id
            darlehenFormModel.meta.finfireId = id
            draft.personen[personIndex].vermoegen.darlehen.push(darlehenFormModel)
          }),
        }
      })
    },
  }
}
