import { ImgHTMLAttributes, ReactElement } from 'react'

import { useFetchUserConfig } from 'api/useFetchUserConfig'

type GuidanceMitNormImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string
  feSrc: string | undefined
}

export const GuidanceMitNormImage = ({
  src,
  alt,
  feSrc,
  ...other
}: GuidanceMitNormImageProps): ReactElement => {
  const { data: config } = useFetchUserConfig()
  const imageSrc = (config?.guidanceMitNormLpFarbe === 'GRUEN' ? feSrc : src) ?? src
  return <img src={imageSrc} alt={alt} {...other} />
}
