import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { DreadDiseaseDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Dread Disease'

type DreadDiseaseDetailsFormBlockProps = BaseDetailsFormBlockProps

export const DreadDiseaseDetailsFormBlock = ({
  name,
  produktId,
}: DreadDiseaseDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.dreadDiseaseDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('DREAD_DISEASE', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <NumberInput
          name={`${baseName}.versicherungssummeDreadDisease`}
          label={'Versicherungssumme Dread Disease'}
          inputUnit='€'
        />
      </Grid>
    </Grid>
  )
}

type DreadDiseaseDetailsReadViewProps = BaseDetailsReadViewProps & {
  dreadDiseaseDetails: DreadDiseaseDetails | undefined
}

export const DreadDiseaseDetailsReadView = ({
  produktId,
  dreadDiseaseDetails,
}: DreadDiseaseDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('DREAD_DISEASE', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Versicherungssumme Dread Disease'}
        value={formatCurrency(dreadDiseaseDetails?.versicherungssummeDreadDisease)}
      />
    </Stack>
  )
}
