import React, { ReactElement } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_0.png'
import chart1 from 'assets/images/guidance/mitnorm/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_1.png'
import chart2 from 'assets/images/guidance/mitnorm/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_2.png'
import chart3 from 'assets/images/guidance/mitnorm/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_3.png'
import chart4 from 'assets/images/guidance/mitnorm/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_4.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_2.png'
import chart3Fe from 'assets/images/guidance/mitnorm-fe/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_3.png'
import chart4Fe from 'assets/images/guidance/mitnorm-fe/lebens-und-finanzplanung-kombinieren/lebens_und_finanzplanung_kombinieren_4.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm3Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm3Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3, chart4]
  const chartsFe = [chart0Fe, chart1Fe, chart2Fe, chart3Fe, chart4Fe]
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={2}
      previousPagePath={Paths.guidanceMitNorm2}
      nextPagePath={Paths.guidanceMitNorm4}
      guidanceColor='mitnorm'
      numberOfInterPages={5}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title={'LEBENS- UND FINANZPLANUNG KOMBINIEREN'}
          subTitle={'mitNORM'}
        >
          <Stack className={styles.chartContainer}>
            <Box className={styles.chart}>
              <GuidanceMitNormImage
                src={charts[interPageIndex]}
                feSrc={chartsFe[interPageIndex]}
                alt='Lebensplanung'
              />
            </Box>
            <Stack direction='row' className={styles.textRow}>
              <div className={styles.entry}>
                <Typography className={styles.entryTitle}>
                  DEINE WÜNSCHE UND ZIELE DEFINIEREN!
                </Typography>
              </div>
              <div className={styles.entry}>
                {interPageIndex > 0 && (
                  <>
                    <Typography className={styles.entryTitle}>Kurzfristig</Typography>
                    <Typography className={styles.entrySubTitle}>
                      Tagesgelder, Gesundheit, Notgroschen
                    </Typography>
                  </>
                )}
              </div>
              <div className={styles.entry}>
                {interPageIndex > 1 && (
                  <>
                    <Typography className={styles.entryTitle}>Mittelfristig</Typography>
                    <Typography className={styles.entrySubTitle}>
                      Geldanlagen, Wohnen, Förderungen
                    </Typography>
                  </>
                )}
              </div>
              <div className={styles.entry}>
                {interPageIndex > 2 && (
                  <>
                    <Typography className={styles.entryTitle}>Langfristig</Typography>
                    <Typography className={styles.entrySubTitle}>
                      Sicherheit im Alter, Wohlstand, Enkel
                    </Typography>
                  </>
                )}
              </div>
              <div className={styles.entry}>
                {interPageIndex > 3 && (
                  <>
                    <Typography className={styles.entryTitle}>Vermögenssicherung</Typography>
                    <Typography className={styles.entrySubTitle}>
                      Sach- und Vermögenssicherung, Einkommenssicherung
                    </Typography>
                  </>
                )}
              </div>
            </Stack>
          </Stack>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
