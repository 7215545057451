import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/one-more-thing/one_more_thing_0.png'
import chart1 from 'assets/images/guidance/mitnorm/one-more-thing/one_more_thing_1.png'
import chart2 from 'assets/images/guidance/mitnorm/one-more-thing/one_more_thing_2.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/one-more-thing/one_more_thing_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/one-more-thing/one_more_thing_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/one-more-thing/one_more_thing_2.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm16Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm16Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2]
  const chartsFe = [chart0Fe, chart1Fe, chart2Fe]

  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={15}
      previousPagePath={Paths.guidanceMitNorm15}
      nextPagePath={Paths.guidanceMitNorm17}
      guidanceColor='mitnorm'
      numberOfInterPages={3}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title={"THERE'S ONE MORE THING"}>
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              <GuidanceMitNormImage
                src={charts[interPageIndex]}
                feSrc={chartsFe[interPageIndex]}
                alt='Marktanbieter'
              />
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
