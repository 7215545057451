import React, { ReactElement, useEffect } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'

import { Option } from 'components/controls/inputs/SelectInput'
import selectiveStyles from 'components/controls/selective-inputs/SelectiveCommon.module.scss'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { useSelectiveKundeFieldComparison, useSelectiveSwitch } from 'hooks/useSelectiveComparison'

type SelectiveMultiSelectCheckboxProps<OptionType> = {
  name: string
  options: Option<OptionType>[]
  selectiveFieldProps: SelectiveInputProps
}

export const SelectiveMultiSelectCheckbox = <OptionType,>({
  name,
  options,
  selectiveFieldProps,
}: SelectiveMultiSelectCheckboxProps<OptionType>): ReactElement => {
  const { isDirty, currentValue, fieldPath } = useSelectiveKundeFieldComparison(
    name,
    selectiveFieldProps.formPath,
  )
  const { switchActive, switchComponent } = useSelectiveSwitch(isDirty)
  const convertedCurrentValue = currentValue as Array<OptionType>

  useEffect(() => {
    selectiveFieldProps.onSelected({
      selected: switchActive,
      fieldPath,
    })
  }, [switchActive, fieldPath])

  const selectionColoring = switchActive ? selectiveStyles.selectedCheckbox : ''
  return (
    <Stack direction={'row'}>
      <FormGroup className={selectionColoring}>
        {options.map((option) => (
          <FormControlLabel
            key={String(option.value)}
            control={
              <Checkbox
                name={name}
                value={option.value}
                checked={convertedCurrentValue?.includes(option.value) || false}
                disabled={!switchActive}
                onChange={undefined}
                color='secondary'
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {switchComponent}
    </Stack>
  )
}
