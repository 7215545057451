import { nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { BerufFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import {
  Beruf,
  Besoldungsgruppe,
  Bundesland,
  EntgeltfortzahlungArbeitgeber,
} from 'interfaces/mynorm-api-model-interfaces'

const defaultBerufFormModel = createDefaultPersonFormModel().finanzen.beruf

export const convertToBerufFormModel = (beruf: Beruf | undefined): BerufFormModel => {
  if (!beruf) {
    return defaultBerufFormModel
  }
  return {
    taetigkeitstatus: valueOrDefault(
      beruf.taetigkeitstatus,
      defaultBerufFormModel.taetigkeitstatus,
    ),
    besoldungsgruppe: nullableValueOrDefault<Besoldungsgruppe | '' | undefined>(
      beruf.besoldungsgruppe,
      defaultBerufFormModel.besoldungsgruppe,
    ),
    versorgungsrechtlicherDienstzeitbeginnJahr: nullableValueOrDefault(
      beruf.versorgungsrechtlicherDienstzeitbeginnJahr?.toString(),
      defaultBerufFormModel.versorgungsrechtlicherDienstzeitbeginnJahr,
    ),
    arbeitstaetteBundeslandAbweichung: nullableValueOrDefault(
      beruf.arbeitstaetteBundeslandAbweichung,
      defaultBerufFormModel.arbeitstaetteBundeslandAbweichung,
    ),
    arbeitsstaetteBundesland: nullableValueOrDefault<Bundesland | '' | undefined>(
      beruf.arbeitsstaetteBundesland,
      defaultBerufFormModel.arbeitsstaetteBundesland,
    ),
    freiwilligeEntgeltfortzahlungArbeitgeber: nullableValueOrDefault<
      EntgeltfortzahlungArbeitgeber | '' | undefined
    >(
      beruf.freiwilligeEntgeltfortzahlungArbeitgeber,
      defaultBerufFormModel.freiwilligeEntgeltfortzahlungArbeitgeber,
    ),
    dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen: nullableValueOrDefault(
      beruf.dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen?.toString(),
      defaultBerufFormModel.dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen,
    ),
    absoluterBetragEntgeltfortzahlung: nullableValueOrDefault(
      beruf.absoluterBetragEntgeltfortzahlung?.toString(),
      defaultBerufFormModel.absoluterBetragEntgeltfortzahlung,
    ),
    keineAbsicherungNoetigWochen: nullableValueOrDefault(
      beruf.keineAbsicherungNoetigWochen?.toString(),
      defaultBerufFormModel.keineAbsicherungNoetigWochen,
    ),
    besondereTaetigkeiten: nullableValueOrDefault(
      beruf.besondereTaetigkeiten,
      defaultBerufFormModel.besondereTaetigkeiten,
    ),
    ausgeuebterBeruf: nullableValueOrDefault(
      beruf.ausgeuebterBeruf,
      defaultBerufFormModel.ausgeuebterBeruf,
    ),
    ausbildung: nullableValueOrDefault(beruf.ausbildung, defaultBerufFormModel.ausbildung),
    beruflicheQualifikation: nullableValueOrDefault(
      beruf.beruflicheQualifikation,
      defaultBerufFormModel.beruflicheQualifikation,
    ),
    fruehereBeruflicheTaetigkeiten: nullableValueOrDefault(
      beruf.fruehereBeruflicheTaetigkeiten,
      defaultBerufFormModel.fruehereBeruflicheTaetigkeiten,
    ),
    arbeitgeber: nullableValueOrDefault(beruf.arbeitgeber, defaultBerufFormModel.arbeitgeber),
  }
}
