import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { ErwerbsunfaehigkeitsDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Erwerbsunfaehigkeit'

type ErwerbsunfaehigkeitsDetailsFormBlockProps = BaseDetailsFormBlockProps

export const ErwerbsunfaehigkeitsDetailsFormBlock = ({
  name,
  produktId,
}: ErwerbsunfaehigkeitsDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.erwerbsunfaehigkeitsDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('ERWERBSUNFAEHIGKEIT', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <NumberInput
          name={`${baseName}.erwerbsminderungsrente`}
          label={'Erwerbsminderungsrente mtl.'}
          inputUnit='€'
        />
      </Grid>
    </Grid>
  )
}

type ErwerbsunfaehigkeitsDetailsReadViewProps = BaseDetailsReadViewProps & {
  erwerbsunfaehigkeitsDetails: ErwerbsunfaehigkeitsDetails | undefined
}

export const ErwerbsunfaehigkeitsDetailsReadView = ({
  produktId,
  erwerbsunfaehigkeitsDetails,
}: ErwerbsunfaehigkeitsDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('ERWERBSUNFAEHIGKEIT', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Erwerbsminderungsrente mtl.'}
        value={formatCurrency(erwerbsunfaehigkeitsDetails?.erwerbsminderungsrente)}
      />
    </Stack>
  )
}
