import React, { ReactElement } from 'react'
import { Grid, Icon, Stack } from '@mui/material'
import clsx from 'clsx'

import { RemoveIconButton } from 'components/controls/buttons/RemoveIconButton'
import { DateInput } from 'components/controls/inputs/DateInput'
import { InfoTooltip } from 'components/controls/inputs/InfoTooltip'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import styles from 'components/personal/haushalt/PersonalHaushaltKinderContainer.module.scss'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { KindFormModel } from 'form/KundeFormModel'
import { kindInitialTouched } from 'form/kundeFormTouched'
import { kinderValidationSchema } from 'form/kundeFormValidationSchema'
import { geschlechtOptions, risikenOptions } from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { KinderMutator } from 'state/kinderMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'

type HaushaltKinderContainerProps = {
  selectiveFormProps?: SelectiveFormProps
}

export const HaushaltKinderContainer = ({
  selectiveFormProps,
}: HaushaltKinderContainerProps): ReactElement => {
  const { editableKunde, kinderMutator } = useEditableKunde()

  return (
    <Stack>
      <Stack direction='row'>
        <h3>Wirtschaftlich abhängige Kinder</h3>
        <div className={styles.headerTooltip}>
          <InfoTooltip
            tooltip={
              'Als wirtschaftlich abhängige Kinder im Sinne der DIN-Norm gelten alle minderjährigen leiblichen, Adoptiv- und Stiefkinder sowie Enkelkinder, für die eine Kindergeldbezugsberechtigung besteht, sowie alle volljährigen Kinder soweit ein Kindergeldbezugsberechtigung besteht, diese nicht verheiratet sind und kein Erwerbseinkommen aus einem Ausbildungsverhältnis oder hauptberuflichen Beschäftigungsverhältnis bezogen wird.'
            }
          />
        </div>
      </Stack>
      <Stack rowGap={1}>
        {editableKunde.kinder.map((kind, kindIndex) => (
          <PersonalHaushaltKindContainer
            key={kindIndex}
            kindIndex={kindIndex}
            kinderMutator={kinderMutator}
            selectiveFormProps={selectiveFormProps}
          />
        ))}
      </Stack>
    </Stack>
  )
}

type PersonalHaushaltKindContainerProps = {
  kindIndex: number
  selectiveFormProps?: SelectiveFormProps
  kinderMutator: KinderMutator
}

const PersonalHaushaltKindContainer = ({
  kindIndex,
  selectiveFormProps,
  kinderMutator,
}: PersonalHaushaltKindContainerProps): ReactElement | null => {
  const basePath = `kinder`
  const formPath = `${basePath}.${kindIndex}`
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<KindFormModel>(
    formPath,
    selectiveFormProps,
  )
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: KindFormModel): void => {
    kinderMutator.removeKind(kindIndex)
    // if (!formValue.finfireId) {
    //   return
    // }
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }
  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }
  return (
    <EditableKundeFormik
      key={kindIndex}
      initialValues={initialFormValues}
      updateState={(kindFormModel) => kinderMutator.updateKind(kindIndex, kindFormModel)}
      validationSchema={kinderValidationSchema}
      initialTouched={kindInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        return (
          <Stack
            direction='row'
            key={kindIndex}
            className={clsx(
              formikProps.values.isDeleted && styles.displayAsDeleted,
              selectiveFormProps && formikProps.values.isNew && styles.displayAsNew,
            )}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <SelectInput
                  options={geschlechtOptions}
                  name={`anrede`}
                  label={'Geschlecht'}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  name={`vorname`}
                  label={'Vorname'}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  name={`nachname`}
                  label={'Familienname'}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Grid>
              <Grid item xs={2}>
                <DateInput
                  name={`geburtsdatum`}
                  label={'Geburtsdatum'}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectInput
                  options={risikenOptions}
                  name={`risiken`}
                  label={`Hobbies / Risiken`}
                  multiple
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Grid>
            </Grid>
            <div className={styles.removeChildButton}>
              {selectiveFieldProps ? (
                <Icon />
              ) : (
                <RemoveIconButton onClick={() => handleRemove(formikProps.values)} />
              )}
            </div>
          </Stack>
        )
      }}
    </EditableKundeFormik>
  )
}
