import React, { ReactElement } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'

import styles from 'components/controls/inputs/InfoTooltip.module.scss'

type InfoTooltipProps = {
  tooltip: string
}

export const InfoTooltip = ({ tooltip }: InfoTooltipProps): ReactElement => {
  return (
    <Tooltip title={tooltip} classes={{ tooltip: styles.tooltipPopper }} placement='top'>
      <InfoIcon className={styles.tooltipIcon} />
    </Tooltip>
  )
}
