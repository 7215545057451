import { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { Warning } from '@mui/icons-material'
import { Button, Tab, Tabs, Tooltip } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

import styles from 'components/personal/CustomerDatenTabs.module.scss'
import { useCustomerDatenTabs } from 'hooks/useCustomerDatenTabs'
import { useValidateKunde } from 'hooks/useValidateKunde'

type CustomerDatenTabsProps = {
  children?: ReactElement
}

export const CustomerDatenTabs = ({ children }: CustomerDatenTabsProps): ReactElement => {
  const { errors } = useValidateKunde()
  const { tabs, value, prevValue, onChange, onPrevious, onNext } = useCustomerDatenTabs()

  return (
    <>
      <Tabs value={value} onChange={onChange} textColor='secondary' indicatorColor='secondary'>
        {tabs.map((tab) => {
          const isErroneous = errors.find((e) =>
            tab.formPaths.find((formPath) => e.name.includes(formPath)),
          )
          return (
            <Tab
              key={tab.title}
              icon={
                isErroneous ? (
                  <Tooltip title={`Manche Felder im ${tab.title} Tab sind ungültig.`}>
                    <Warning color={'error'} fontSize={'small'} />
                  </Tooltip>
                ) : undefined
              }
              iconPosition={'end'}
              label={tab.title}
              sx={{ minHeight: 0 }}
            />
          )
        })}
      </Tabs>
      <CustomerDatenTabsPanel
        tabIndex={value}
        prevTabIndex={prevValue}
        onPrevious={onPrevious}
        onNext={onNext}
      >
        {children}
      </CustomerDatenTabsPanel>
    </>
  )
}

type CustomerDatenTabsPanelProps = {
  tabIndex: number
  prevTabIndex: number
  onPrevious: () => void
  onNext: () => void
  children?: ReactElement
}

const CustomerDatenTabsPanel = ({
  tabIndex,
  prevTabIndex,
  onPrevious,
  onNext,
  children,
}: CustomerDatenTabsPanelProps): ReactElement => {
  const { pathname } = useLocation()
  const startPosition = prevTabIndex === -1 ? 0 : tabIndex > prevTabIndex ? '30vw' : '-30vw'
  return (
    <>
      <AnimatePresence mode={'sync'}>
        <motion.div
          key={pathname}
          initial={{ x: startPosition, opacity: 0.75 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.3, ease: [0.6, -0.05, 0.01, 0.99] }}
          className={styles.tabPanel}
        >
          <div className={styles.content}>{children}</div>
          <div className={styles.controls}>
            <Button variant='outlined' onClick={onPrevious}>
              Zurück
            </Button>
            <Button variant='outlined' onClick={onNext}>
              Weiter
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  )
}
