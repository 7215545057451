import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import { isNil } from 'lodash'

import { BackendPaths } from 'configurations/backendPaths'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const dinScoreQueryKey = (haushalt: HaushaltAggregate | undefined): QueryKey => {
  return ['dinScore', haushalt]
}

export const useFetchDinScore = (
  haushalt?: HaushaltAggregate,
  enabled?: boolean,
): UseQueryResult<GesamtErgebnisse> => {
  return useQuery({
    queryKey: dinScoreQueryKey(haushalt),
    queryFn: () => fetchDinScore(haushalt),
    enabled: !isNil(haushalt) && enabled !== false,
    refetchOnWindowFocus: false,
  })
}

const fetchDinScore = async (haushalt?: HaushaltAggregate): Promise<GesamtErgebnisse | null> => {
  if (haushalt === undefined || haushalt === null) return Promise.resolve(null)
  const response = await axios.post<GesamtErgebnisse>(BackendPaths.createDinScore, haushalt)
  return response.data
}
