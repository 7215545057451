import { ReactElement, useEffect } from 'react'
import { useFormikContext } from 'formik'

import { useFetchRisikoTypen } from 'api/useFetchRisikoTypen'
import { determineAvailableRisikoTypesForFinfireProduktId } from 'components/personal/absicherung2/blocks/VertragsProdukteFormUtil'
import { useProduktSpecs } from 'components/personal/absicherung2/hooks/useProduktSpecs'
import { isNotNil } from 'components/util/isNotNil'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'
import { createVertragsProduktFormModel } from 'form/vertraege/VertragFormModelCreator'
import { useProduktMappings } from 'state/useProduktConfig'

export const VertragsProdukteObserver = (): ReactElement | null => {
  const { values, setFieldValue } = useFormikContext<VertragFormModel>()
  const { vertragGeschaeft, produkte } = values
  const { data: risikoTypMapping } = useFetchRisikoTypen(vertragGeschaeft, produkte)
  const { findProduktById } = useProduktSpecs(vertragGeschaeft)
  const produktMappings = useProduktMappings()

  useEffect(() => {
    if (!risikoTypMapping?.length) {
      return
    }
    const vertragsProdukte = produkte
      .map((produktId) => {
        const risikoTypes = determineAvailableRisikoTypesForFinfireProduktId(
          produktId,
          produktMappings,
          risikoTypMapping,
        )
        const produkt = findProduktById(produktId)
        if (!produkt || risikoTypes.length === 0) {
          return undefined
        }
        const existingVertragsProdukt = values.vertragsProdukte.find(
          (v) => v.produkt.produktId === produktId,
        )
        if (existingVertragsProdukt) {
          return existingVertragsProdukt
        }
        return createVertragsProduktFormModel(produkt, [risikoTypes[0]])
      })
      .filter(isNotNil)
    setFieldValue('vertragsProdukte', vertragsProdukte)
  }, [vertragGeschaeft, produkte, risikoTypMapping])
  return null
}
