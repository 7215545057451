import { LoaderFunction } from 'react-router-dom'
import { QueryClient, useQueries, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { userConfigQueryOptions } from 'api/useFetchUserConfig'
import { BackendPaths } from 'configurations/backendPaths'
import { MyviUserConfig, ProduktMapping } from 'interfaces/mynorm-api-model-interfaces'
import { useProduktConfig } from 'state/useProduktConfig'

export const useFetchApplicationConfig = (): [
  UseQueryResult<ProduktMapping[]>,
  UseQueryResult<MyviUserConfig | null>,
] => {
  return useQueries({
    queries: [produktMappingQueryOptions, userConfigQueryOptions],
  })
}

export const applicationConfigLoader = (queryClient: QueryClient): LoaderFunction => {
  return async () => {
    const [produktMappings, userConfig] = await Promise.all([
      queryClient.fetchQuery(produktMappingQueryOptions),
      queryClient.fetchQuery(userConfigQueryOptions),
    ])
    useProduktConfig.setState({ produktMappings })
    return [produktMappings, userConfig]
  }
}

const fetchProduktMapping = async (): Promise<ProduktMapping[]> => {
  try {
    const response = await axios.get<ProduktMapping[]>(BackendPaths.getProduktMapping)
    return response.data
  } catch (e) {
    return []
  }
}

const produktMappingQueryOptions: UseQueryOptions<ProduktMapping[]> = {
  queryKey: ['fetchProduktMapping'],
  queryFn: fetchProduktMapping,
  staleTime: Infinity,
  onSuccess: (produktMappings) => {
    useProduktConfig.setState({ produktMappings })
  },
}
