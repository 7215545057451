import {
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { MenschFormModel } from 'form/KundeFormModel'
import { createDefaultMenschFormModel } from 'form/kundeFormModelCreator'
import { Mensch } from 'interfaces/mynorm-api-model-interfaces'

const defaultMenschFormModel = createDefaultMenschFormModel()

export const convertToMenschFormModel = (mensch: Mensch | undefined): MenschFormModel => {
  if (!mensch) {
    return defaultMenschFormModel
  }
  return {
    meta: convertToMeta(mensch.meta),
    anrede: valueOrDefault(mensch.anrede, defaultMenschFormModel.anrede),
    vorname: valueOrDefault(mensch.vorname, defaultMenschFormModel.vorname),
    nachname: valueOrDefault(mensch.nachname, defaultMenschFormModel.nachname),
    geburtsdatum: nullableDayjsOrDefault(mensch.geburtsdatum, defaultMenschFormModel.geburtsdatum),
    risiken: nullableValueOrDefault(mensch.hobbies, defaultMenschFormModel.risiken),
  }
}
