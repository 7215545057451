import React, { ReactElement } from 'react'

import { NumberInputProps } from 'components/controls/inputs/NumberInput'
import {
  SelectiveTextInput,
  SelectiveTextInputProps,
} from 'components/controls/selective-inputs/SelectiveTextInput'
import { formatNumber } from 'components/util/formatUtil'

type SelectiveNumberInputProps<FormType> = SelectiveTextInputProps<FormType> &
  NumberInputProps<FormType> & {
    fractionDigits?: number
  }

export const SelectiveNumberInput = <FormType,>({
  name,
  label,
  inputUnit,
  selectiveFieldProps,
  fractionDigits,
  noGrouping,
  ...props
}: SelectiveNumberInputProps<FormType>): ReactElement | null => {
  if (!selectiveFieldProps) {
    return null
  }
  return (
    <SelectiveTextInput<FormType>
      name={name}
      label={label}
      selectiveFieldProps={{
        ...selectiveFieldProps,
        formatValue: (value: unknown): string => {
          if (value == null || value == '') {
            return ''
          }
          if (isNaN(value as number)) {
            return value as string
          }
          return `${formatNumber(Number(value), {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
            useGrouping: !noGrouping,
          })} ${inputUnit || ''}`.trim()
        },
      }}
      {...props}
    />
  )
}
