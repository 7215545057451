import React, { ReactElement } from 'react'
import { Stack, TypographyVariant } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

type IconTitleProps = {
  title: string
  icon?: ReactElement
  variant?: TypographyVariant
}

export const IconTitle = ({ title, icon, variant }: IconTitleProps): ReactElement => {
  return (
    <Stack flexDirection='row' alignItems='end'>
      {icon && (
        <IconButton size='small' disabled={false} color='primary' disableRipple>
          {icon}
        </IconButton>
      )}
      <Typography variant={variant ?? 'h5'} color='primary'>
        {title}
      </Typography>
    </Stack>
  )
}
