import React, { Fragment, ReactElement } from 'react'
import { Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'

import styles from 'components/personal/absicherung2/LabelValueEntry.module.scss'

type LabelValueEntryProps = {
  label: string
  value: ReactElement | string | undefined | null
  labelPosition?: 'top' | 'bottom'
}

export const LabelValueEntry = ({
  label,
  value,
  labelPosition = 'bottom',
}: LabelValueEntryProps): ReactElement => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={5}
        className={clsx(
          styles.vertragEntryLabel,
          labelPosition === 'top' ? styles.topLabel : undefined,
        )}
      >
        <Typography variant='body2'>{label}:</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant='body1'>{value || '-'}</Typography>
      </Grid>
    </Grid>
  )
}

type LabelMultiValueEntryProps = {
  label: string
  values: Array<string | ReactElement>
  separatorLine?: boolean
}

export const LabelMultiValueEntry = ({
  label,
  values,
  separatorLine,
}: LabelMultiValueEntryProps): ReactElement => {
  return (
    <Grid container>
      <>
        <Grid item xs={12} md={5} className={styles.vertragEntryLabel}>
          <Typography variant='body2'>{label}:</Typography>
        </Grid>
        {(!values || values.length === 0) && (
          <Grid item xs={12} md={7}>
            <Typography variant='body1'>-</Typography>
          </Grid>
        )}
        {values.map((value, index) => {
          if (index === 0) {
            return (
              <Grid key={_.uniqueId()} item xs={12} md={7}>
                <Typography variant='body1'>{value}</Typography>
              </Grid>
            )
          }
          return (
            <Fragment key={_.uniqueId()}>
              <Grid item xs={12} md={5}></Grid>
              <Grid
                item
                xs={12}
                md={7}
                style={{
                  borderTop: separatorLine ? `1px solid #e0e0e0` : 'none',
                }}
              >
                <Typography variant='body1'>{value}</Typography>
              </Grid>
            </Fragment>
          )
        })}
      </>
    </Grid>
  )
}
