import React, { ReactElement } from 'react'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { Zahlweise } from 'interfaces/mynorm-api-model-interfaces'

type ZahlweiseSelectProps = {
  name: string
  label?: string
  disabled?: boolean
}

export const ZahlweiseSelect = ({ name, label, disabled }: ZahlweiseSelectProps): ReactElement => {
  return (
    <SelectInput
      options={ZAHLWEISE_OPTIONS}
      name={name}
      label={label ?? 'Zahlweise'}
      disabled={disabled}
    ></SelectInput>
  )
}

const ZAHLWEISE_OPTIONS: Option<Zahlweise>[] = [
  { value: 'MONATLICH', label: 'monatlich' },
  { value: 'JAEHRLICH', label: 'jährlich' },
  { value: 'QUARTAL', label: 'quartalsweise' },
  { value: 'HALBJAEHRLICH', label: 'halbjährlich' },
  { value: 'EINMALBETRAG', label: 'einmalig' },
]
