import React, { ReactElement } from 'react'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { SachwertFormModel } from 'form/KundeFormModel'
import { getOptionLabel, sachwertTypOptions } from 'form/kundeOptions'
import { SachwertTyp } from 'interfaces/mynorm-api-model-interfaces'
import { useSachwerte } from 'state/useSachwerte'

type VersichertesFahrzeugSelectProps = {
  name: string
  label?: string
  typesFilter?: SachwertTyp[]
  multiple?: boolean
  disabled?: boolean
}

export const VersichertesFahrzeugSelect = ({
  name,
  label,
  typesFilter,
  multiple,
  disabled,
}: VersichertesFahrzeugSelectProps): ReactElement => {
  const { sachwerte } = useSachwerte()
  const filteredSachwerte = typesFilter
    ? sachwerte.filter((sachwert) => typesFilter.includes(sachwert.sachwertTyp))
    : sachwerte
  const sachwertOptions: Option[] = filteredSachwerte.map((sachwertFormModel) =>
    toSachwertOption(sachwertFormModel),
  )
  return (
    <SelectInput
      options={sachwertOptions}
      name={name}
      label={label ?? 'Versichertes Fahrzeug'}
      multiple={multiple}
      disabled={disabled}
    />
  )
}

const toSachwertOption = (sachwert: SachwertFormModel): Option => {
  let label = getOptionLabel(sachwert.sachwertTyp, sachwertTypOptions)
  const bezeichnungLabel = sachwert.bezeichnung
  if (bezeichnungLabel) {
    label += ` (${bezeichnungLabel})`
  }

  return {
    label: label || 'Ohne Bezeichnung',
    value: sachwert.meta.finfireId || sachwert.meta.myviId,
  }
}
