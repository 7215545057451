import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { GeldAnlageFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type GeldAnlagenMutator = {
  updateGeldAnlage: (
    personIndex: number,
    geldAnlageIndex: number,
    geldAnlageFormModel: GeldAnlageFormModel,
  ) => void
  removeGeldAnlage: (personIndex: number, geldAnlageIndex: number) => void
  addGeldAnlage: (personIndex: number, geldAnlageFormModel: GeldAnlageFormModel) => void
}

export const geldAnlagenMutator = (set: Mutator): GeldAnlagenMutator => {
  return {
    updateGeldAnlage: (
      personIndex: number,
      geldAnlageIndex: number,
      geldAnlageFormModel: GeldAnlageFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].vermoegen.geldAnlagen[geldAnlageIndex] = geldAnlageFormModel
          }),
        }
      })
    },
    removeGeldAnlage: (personIndex: number, geldAnlageIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const geldanlageToRemove =
              draft.personen[personIndex].vermoegen.geldAnlagen[geldAnlageIndex]
            if (geldanlageToRemove.isNew) {
              draft.personen[personIndex].vermoegen.geldAnlagen.splice(geldAnlageIndex, 1)
            } else {
              draft.personen[personIndex].vermoegen.geldAnlagen[geldAnlageIndex].isDeleted = true
            }
          }),
        }
      })
    },
    addGeldAnlage: (personIndex: number, geldAnlageFormModel: GeldAnlageFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            if (!geldAnlageFormModel.meta.myviId) {
              const id = uuidv4()
              geldAnlageFormModel.meta.myviId = id
              geldAnlageFormModel.meta.finfireId = id
            }
            geldAnlageFormModel.isNew = true
            draft.personen[personIndex].vermoegen.geldAnlagen.push(geldAnlageFormModel)
          }),
        }
      })
    },
  }
}
