import {
  ProduktMapping,
  ProduktRisikoMapping,
  RisikoTyp,
  SachwertTyp,
} from 'interfaces/mynorm-api-model-interfaces'
import { isVersicherungsTyp } from 'state/useProduktConfig'

export const determineAvailableRisikoTypesForFinfireProduktId = (
  produktId: string,
  produktMappings: ProduktMapping[],
  risikoTypMappings: ProduktRisikoMapping[] | undefined,
): RisikoTyp[] => {
  let risikoTypes
  const produktMappingsForProduktId = produktMappings.filter((pM) =>
    pM.finfireProduktIds.includes(produktId),
  )[0]
  if (
    !produktMappingsForProduktId?.dinRisikoTypes ||
    produktMappingsForProduktId?.dinRisikoTypes.length === 0
  ) {
    risikoTypes = extractRisikoTypes(produktId, risikoTypMappings)
  } else {
    risikoTypes = produktMappingsForProduktId?.dinRisikoTypes
  }
  if (risikoTypes.includes('PERSON') && risikoTypes.includes('KUNDE')) {
    return risikoTypes.filter((risikoTyp) => risikoTyp != 'KUNDE')
  }
  return risikoTypes
}

const extractRisikoTypes = (
  produktId: string,
  produktRisikoMappings: ProduktRisikoMapping[] | undefined,
): RisikoTyp[] => {
  return (produktRisikoMappings ?? [])
    .filter((pRM) => pRM.produktId === produktId)
    .flatMap((pRM) => pRM.risikoTypen)
}

export const determinePermittedFahrzeugTypes = (
  produktId: string,
  produktMappings: ProduktMapping[],
): SachwertTyp[] => {
  if (isVersicherungsTyp(produktId, 'PRIVATHAFTPFLICHT', produktMappings)) {
    return ['WASSERFAHRZEUG', 'LUFTFAHRZEUG']
  }
  if (isVersicherungsTyp(produktId, 'KFZ_VERSICHERUNG', produktMappings)) {
    return ['KRAFTFAHRZEUG']
  }
  if (isVersicherungsTyp(produktId, 'LUFTFAHRTHAFTPFLICHT', produktMappings)) {
    return ['LUFTFAHRZEUG']
  }
  if (isVersicherungsTyp(produktId, 'WASSERSPORTHAFTPFLICHT', produktMappings)) {
    return ['WASSERFAHRZEUG']
  }
  return ['KRAFTFAHRZEUG', 'WASSERFAHRZEUG', 'LUFTFAHRZEUG']
}
