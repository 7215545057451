import { getLabelByValue, Option } from 'components/controls/inputs/SelectInput'

export const formatSingleValue = (options: Option<string | boolean>[], value: unknown): string => {
  return getLabelByValue(options, String(value)) || String(value)
}

export const formatMultipleValues = (
  options: Option<string | boolean>[],
  values: string[],
): string => {
  return values.map((value) => formatSingleValue(options, value)).join(', ')
}
