import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { BackendPaths } from 'configurations/backendPaths'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { Vertrag } from 'interfaces/mynorm-api-model-interfaces'

export const useUpdateVertrag = (): UseMutationResult<Vertrag, AxiosError, Vertrag> => {
  const { updateVertrag: updateVertragInHaushalt } = useHaushaltState()

  return useMutation((vertrag: Vertrag) => updateVertrag(vertrag), {
    onSuccess: (vertrag) => {
      updateVertragInHaushalt(vertrag)
    },
  })
}

export const updateVertrag = async (vertrag: Vertrag): Promise<Vertrag> => {
  const result = await axios.put<Vertrag>(`${BackendPaths.updateVertrag}`, vertrag)
  return result.data
}
