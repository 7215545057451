import React, { ReactElement } from 'react'

import styles from 'components/controls/inputs/EndAdornment.module.scss'

type EndAdornmentProps = {
  text?: string
}

export const EndAdornment = ({ text }: EndAdornmentProps): ReactElement | null => {
  if (text === undefined || text === null) return null
  return <span className={styles.endAdornment}>{text}</span>
}
