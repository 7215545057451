import dayjs from 'dayjs'
import * as yup from 'yup'

const NUMBER_TYPE_ERROR_TEXT = 'muss eine Zahl sein.'
const MAX_DATE_TODAY_TEXT = 'muss in der Vergangenheit liegen.'
const DATE_TYPE_ERROR_TEXT = "muss im Format 'dd.mm.yyyy' sein."
export const EMAIL_ERROR_TEXT = 'muss eine valide E-Mail Adresse sein.'
export const REQUIRED_TEXT = 'ist Pflichtfeld'
export const STEUERID_LENGTH_TEXT = 'muss 11-stellig sein.'

export const greaterOrEqual = (value = 0): yup.AnySchema => {
  return yup
    .number()
    .transform(emptyStringFix)
    .nullable()
    .min(value, `muss >= ${value} sein.`)
    .typeError(NUMBER_TYPE_ERROR_TEXT)
}

export const greaterOrEqualZero = (): yup.AnySchema => {
  return greaterOrEqual(0)
}

export const minMax = (min: number, max: number): yup.AnySchema => {
  const message = `muss zwischen ${min} und ${max} liegen.`
  return yup.number().transform(emptyStringFix).nullable().min(min, message).max(max, message)
}

export const todayOrInThePast = (): yup.AnySchema => {
  return yup
    .date()
    .transform(emptyStringFix)
    .nullable()
    .typeError(DATE_TYPE_ERROR_TEXT)
    .max(dayjs().endOf('day').toDate(), MAX_DATE_TODAY_TEXT)
}

export const stringLength = (length: number, message?: string): yup.AnySchema => {
  return yup.string().transform(emptyStringFix).nullable().length(length, message)
}

export const emptyStringFix = (value: unknown, original: unknown): unknown => {
  if (original === '') return null
  return value
}
