import { handlePercent, valueOrDefault } from 'form/formModelConverterUtil'
import { EinkommenFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import { Einkommen } from 'interfaces/mynorm-api-model-interfaces'

const defaultEinkommenFormModel = createDefaultPersonFormModel().finanzen.einkommen

export const convertToEinkommenFormModel = (
  einkommen: Einkommen | undefined,
): EinkommenFormModel => {
  if (!einkommen) {
    return defaultEinkommenFormModel
  }
  return {
    nettorenteneinkommen: valueOrDefault(
      einkommen.nettorenteneinkommen,
      defaultEinkommenFormModel.nettorenteneinkommen,
    ),
    sonstigesNettovermoegenseinkommen: valueOrDefault(
      einkommen.sonstigesNettovermoegenseinkommen,
      defaultEinkommenFormModel.sonstigesNettovermoegenseinkommen,
    ),
    sonstigesNettoeinkommen: valueOrDefault(
      einkommen.sonstigesNettoeinkommen,
      defaultEinkommenFormModel.sonstigesNettoeinkommen,
    ),
    nettovermoegenseinkommenKapitalanlagen: valueOrDefault(
      einkommen.nettovermoegenseinkommenKapitalanlagen,
      defaultEinkommenFormModel.nettovermoegenseinkommenKapitalanlagen,
    ),
    nettovermoegenseinkommenBetrieb: valueOrDefault(
      einkommen.nettovermoegenseinkommenBetrieb,
      defaultEinkommenFormModel.nettovermoegenseinkommenBetrieb,
    ),
    nettoNachSteuer: valueOrDefault(
      einkommen.nettoNachSteuer,
      defaultEinkommenFormModel.nettoNachSteuer,
    ),
    liquiditaetsErgebnisVermietungRuhestand: valueOrDefault(
      einkommen.liquiditaetsErgebnisVermietungRuhestand,
      defaultEinkommenFormModel.liquiditaetsErgebnisVermietungRuhestand,
    ),
    liquiditaetsErgebnisVermietung: valueOrDefault(
      einkommen.liquiditaetsErgebnisVermietung,
      defaultEinkommenFormModel.liquiditaetsErgebnisVermietung,
    ),
    einkommenWegfallKrankheitInProzent: valueOrDefault(
      handlePercent(einkommen.einkommenWegfallKrankheitInProzent),
      defaultEinkommenFormModel.einkommenWegfallKrankheitInProzent,
    ),
  }
}
