import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import clsx from 'clsx'

import backgroundImage from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_background_pixel.png'
import optionBasis0 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_basis_0.png'
import optionBasis1 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_basis_1.png'
import optionBasis2 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_basis_2.png'
import optionPremium0 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_premium_0.png'
import optionPremium1 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_premium_1.png'
import optionPremium2 from 'assets/images/guidance/career/mitgliedschaften/mitgliedschaften_option_premium_2.png'
import styles from 'components/guidance/career/GuidanceCareer13Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer13Page = (): ReactElement => {
  const optionsBasis = [optionBasis0, optionBasis1, optionBasis2]
  const optionsPremium = [optionPremium0, optionPremium1, optionPremium2]
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={12}
      previousPagePath={Paths.guidanceCareer12}
      nextPagePath={Paths.guidanceCareer14}
      guidanceColor='career'
      backgroundImage={backgroundImage}
      backgroundImageClass={styles.backgroundClass}
      numberOfInterPages={3}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title='DIE MITGLIEDSCHAFTEN' subTitle='Das Karriere-Institut'>
          <Grid container className={styles.optionsContainer}>
            <Grid
              item
              lg={6}
              xs={12}
              className={clsx(styles.optionContainer, styles.basisContainer)}
            >
              <img src={optionsBasis[interPageIndex]} alt='Basis Option' />
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              className={clsx(styles.optionContainer, styles.premiumContainer)}
            >
              <img src={optionsPremium[interPageIndex]} alt='Premium Option' />
            </Grid>
          </Grid>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
