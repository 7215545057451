import { filterNotDeleted } from 'components/util/listUtil'
import { SachwertFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

type UseSachwerteResult = {
  sachwerte: SachwertFormModel[]
  autos: SachwertFormModel[]
  wasserfahrzeuge: SachwertFormModel[]
  luftfahrzeuge: SachwertFormModel[]
  findSachwert: (sachwertId: string | undefined) => SachwertFormModel | undefined
}
export const useSachwerte = (index?: number): UseSachwerteResult => {
  const { editableKunde } = useEditableKunde()
  const sachwerte = filterNotDeleted(editableKunde.personen[index ?? 0].vermoegen.sachwerte ?? [])
  const autos = sachwerte.filter((sachwert) => sachwert.sachwertTyp === 'KRAFTFAHRZEUG')
  const wasserfahrzeuge = sachwerte.filter((sachwert) => sachwert.sachwertTyp === 'WASSERFAHRZEUG')
  const luftfahrzeuge = sachwerte.filter((sachwert) => sachwert.sachwertTyp === 'LUFTFAHRZEUG')

  return {
    sachwerte,
    autos,
    wasserfahrzeuge,
    luftfahrzeuge,
    findSachwert: (sachwertId) => {
      return sachwerte.find((sachwert) => hasId(sachwert, sachwertId))
    },
  }
}
