import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import {
  mergeVertraegeIntoHaushalt,
  mergeVertraegeOfKundeFormModelIntoKundeFormModel,
} from 'api/mergeVertraege'
import { BackendPaths } from 'configurations/backendPaths'
import { convertToKundeFormModel } from 'form/kunde-form-model-converter/kundeFormModelConverter'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'

export type HaushaltUpdateError = {
  message: string | undefined
  trace: string | undefined
}

export const useUpdateHaushalt = (): UseMutationResult<
  HaushaltAggregate,
  AxiosError<HaushaltUpdateError>,
  HaushaltAggregate
> => {
  const { haushalt, setHaushalt, setHaushaltPartially } = useHaushaltState()
  const { editableKunde } = useEditableKunde()
  return useMutation((haushalt: HaushaltAggregate) => putHaushalt(haushalt), {
    onSuccess: async (responseHaushalt: HaushaltAggregate) => {
      if (!haushalt) {
        setHaushalt(responseHaushalt)
        return
      }

      const mergedHaushalt = mergeVertraegeIntoHaushalt(responseHaushalt, haushalt)
      const mergedEditableFormModel = mergeVertraegeOfKundeFormModelIntoKundeFormModel(
        convertToKundeFormModel(responseHaushalt),
        editableKunde,
      )
      setHaushaltPartially(mergedHaushalt, mergedEditableFormModel)
    },
  })
}

export const putHaushalt = async (haushalt: HaushaltAggregate): Promise<HaushaltAggregate> => {
  const result = await axios.put<HaushaltAggregate>(`${BackendPaths.updateHaushalt}`, haushalt)
  return result.data
}
