import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { TextInput } from 'components/controls/inputs/TextInput'
import { VersichertesRisikoBlockProps } from 'components/personal/absicherung2/blocks/RisikenFormBlock'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

export const VersichertePersonenBlock = ({
  name,
  risiko,
  disabled,
}: VersichertesRisikoBlockProps): ReactElement | null => {
  const { risikoTyp, risikoPerson } = risiko
  if (risikoTyp !== 'KUNDE' && risikoTyp !== 'PERSON') {
    return null
  }
  const personFinfireId = risikoPerson?.finfireId
  const isExternePerson = personFinfireId === EXTERNE_PERSON_OPTION_VALUE

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={`${name}.risikoPerson.finfireId`}
          withExternePerson={true}
          disabled={disabled}
        />
      </Grid>
      {isExternePerson && (
        <Grid item xs={12} md={6}>
          <TextInput
            name={`${name}.risikoPerson.name`}
            label={'Name der Person'}
            disabled={disabled}
          />
        </Grid>
      )}
    </Grid>
  )
}
