import * as React from 'react'
import { ReactElement } from 'react'

import { ScreenContent } from 'components/authentication/ScreenContent'
import { Kundenwaehler } from 'components/kundenwaehler/Kundenwaehler'

export const CustomerSelectionRoute = (): ReactElement => {
  return (
    <ScreenContent>
      <Kundenwaehler />
    </ScreenContent>
  )
}
