import React, { ReactElement } from 'react'

import logoBlack from 'assets/images/logo_black.png'
import logoWhite from 'assets/images/logo_white.png'
import styles from 'components/logo/Logo.module.scss'

type LogoProps = {
  variant?: 'black' | 'white'
}

export const Logo = ({ variant = 'black' }: LogoProps): ReactElement => {
  return (
    <img src={variant === 'white' ? logoWhite : logoBlack} alt='logo' className={styles.logo} />
  )
}
