import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import background from 'assets/images/guidance/career/e-learning-plattform/e_learning_plattform_background_min.png'
import tablets from 'assets/images/guidance/career/e-learning-plattform/e_learning_plattform_tablets.png'
import styles from 'components/guidance/career/GuidanceCareer10Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer10Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={9}
      previousPagePath={Paths.guidanceCareer9}
      nextPagePath={Paths.guidanceCareer11}
      guidanceColor='career'
      backgroundImage={background}
      backgroundImageClass={styles.backgroundClass}
    >
      <GuidanceSubPageTemplate
        title='E-LEARNING-PLATTFORM FÜR PREMIUM-MITGLIEDER'
        subTitle='Das Karriere-Institut'
      >
        <Box className={styles.imageContainer}>
          <img src={tablets} alt='E-Learning' />
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
