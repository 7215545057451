import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { BerufshaftpflichtDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { privathaftpflichtTarifartOptions } from 'form/vertraege/vertragOptions'
import {
  BerufshaftpflichtDetails,
  PrivathaftpflichtDetails,
} from 'interfaces/mynorm-api-model-interfaces'
import { isVersicherungsTyp, useProduktConfig } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Privathaftpflicht'

type PrivathaftpflichtDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  berufshaftpflichtDetailsFormModel: BerufshaftpflichtDetailsFormModel | undefined
}

export const PrivathaftpflichtDetailsFormBlock = ({
  name,
  produktId,
  berufshaftpflichtDetailsFormModel,
}: PrivathaftpflichtDetailsFormBlockProps): ReactElement | null => {
  const { produktMappings } = useProduktConfig()
  const baseName = `${name}.privathaftpflichtDetails`
  const isBerufshaftpflicht = isVersicherungsTyp(
    produktId,
    'BERUFSHAFTPFLICHTVERSICHERUNG',
    produktMappings,
  )
  const isPrivathaftpflicht = isVersicherungsTyp(produktId, 'PRIVATHAFTPFLICHT', produktMappings)
  const displayBlock =
    isPrivathaftpflicht ||
    (isBerufshaftpflicht && berufshaftpflichtDetailsFormModel?.einschlussPrivathaftpflicht)
  if (!displayBlock) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        {isPrivathaftpflicht && (
          <SelectInput
            options={privathaftpflichtTarifartOptions}
            name={`${baseName}.privatHaftpflichtTarifart`}
            label={'Tarifart'}
          />
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <CheckboxInput name={`${baseName}.einschlussEhrenamt`} label={'Ehrenamt'} />
          <CheckboxInput name={`${baseName}.einschlussDiensthaftpflicht`} label={'Dienst-HV'} />
          <CheckboxInput
            name={`${baseName}.einschlussHausGrundbesitzerHaftpflicht`}
            label={'Haus-/Grundbesitzer HV'}
            tooltip={
              'Sofern der Einschluss einer Haus- und Grundbesitzer-Haftpflichtversicherung existiert, kann die Versicherung einer Immobilie zugeordnet werden, sofern die Immobilie über die Versicherung abgesichert ist.'
            }
          />
          <CheckboxInput
            name={`${baseName}.einschlussGewaesserschadenHaftpflicht`}
            label={'Gewässerschaden-HV'}
            tooltip={
              'Sofern der Einschluss einer Gewässerschaden-Haftpflichtversicherung existiert, kann die Versicherung einer Immobilie zugeordnet werden, sofern die Immobilie über die Versicherung abgesichert ist.'
            }
          />
          <CheckboxInput
            name={`${baseName}.einschlussBetreiberhaftpflicht`}
            label={'Betreiber-HV'}
            tooltip={
              'Sofern der Einschluss einer Betreiber-Haftpflichtversicherung existiert, kann die Versicherung einer Immobilie zugeordnet werden, sofern diese über die Versicherung versichert ist.'
            }
          />
          <CheckboxInput
            name={`${baseName}.einschlussWassersportHaftpflicht`}
            label={'Wassersport-HV'}
            tooltip={
              'Sofern der Einschluss einer Wassersport-Haftpflichtversicherung existiert, muss die Versicherung einem Wassersport-Fahrzeug zugeordnet werden.'
            }
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

type PrivathaftpflichtDetailsReadViewProps = BaseDetailsReadViewProps & {
  privathaftpflichtDetails: PrivathaftpflichtDetails | undefined
  berufshaftpflichtDetails: BerufshaftpflichtDetails | undefined
}

export const PrivathaftpflichtDetailsReadView = ({
  produktId,
  privathaftpflichtDetails,
  berufshaftpflichtDetails,
}: PrivathaftpflichtDetailsReadViewProps): ReactElement | null => {
  const { produktMappings } = useProduktConfig()

  const isBerufshaftpflicht = isVersicherungsTyp(
    produktId,
    'BERUFSHAFTPFLICHTVERSICHERUNG',
    produktMappings,
  )
  const isPrivathaftpflicht = isVersicherungsTyp(produktId, 'PRIVATHAFTPFLICHT', produktMappings)
  const displayBlock =
    isPrivathaftpflicht ||
    (isBerufshaftpflicht && berufshaftpflichtDetails?.einschlussPrivathaftpflicht)
  if (!displayBlock) {
    return null
  }

  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      {isPrivathaftpflicht && (
        <LabelValueEntry
          label={'Tarifart'}
          value={getOptionLabel(
            privathaftpflichtDetails?.privatHaftpflichtTarifart,
            privathaftpflichtTarifartOptions,
          )}
        />
      )}
      <LabelValueEntry
        label={'Ehrenamt'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussEhrenamt)}
      />
      <LabelValueEntry
        label={'Dienst-HV'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussDiensthaftpflicht)}
      />
      <LabelValueEntry
        label={'Haus-/Grundbesitzer-HV'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussHausGrundbesitzerHaftpflicht)}
      />
      <LabelValueEntry
        label={'Gewässerschaden-HV'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussGewaesserschadenHaftpflicht)}
      />
      <LabelValueEntry
        label={'Betreiber-HV'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussBetreiberhaftpflicht)}
      />
      <LabelValueEntry
        label={'Wassersport-HV'}
        value={formatBoolean(privathaftpflichtDetails?.einschlussWassersportHaftpflicht)}
      />
    </Stack>
  )
}
