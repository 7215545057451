import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { FormikProps } from 'formik'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { DateInput } from 'components/controls/inputs/DateInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { GeschaeftSelect } from 'components/personal/absicherung2/selects/GeschaeftSelect'
import { ProdukteSelect } from 'components/personal/absicherung2/selects/ProdukteSelect'
import { VersicherungsGesellschaftSelect } from 'components/personal/absicherung2/selects/VersicherungsGesellschaftSelect'
import { DarlehenFormModel } from 'form/KundeFormModel'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'

type VertragFormPage1Props = {
  formProps: FormikProps<VertragFormModel>
}

export const VertragFormPage1 = ({ formProps }: VertragFormPage1Props): ReactElement => {
  const disabled = !formProps.values.fremdvertrag

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <GeschaeftSelect name='vertragGeschaeft' disabled={disabled} />
      </Grid>
      <Grid item container xs={12} md={6} alignItems='center'>
        <CheckboxInput name={'fremdvertrag'} label={'Fremdvertrag'} disabled />
      </Grid>
      <Grid item xs={12}>
        <ProdukteSelect
          name={'produkte'}
          geschaeft={formProps.values.vertragGeschaeft}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersicherungsGesellschaftSelect
          name={'vertragGesellschaft'}
          geschaeft={formProps.values.vertragGeschaeft}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput name={'vertragNummer'} label={'Vertragsnummer'} disabled={disabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput name={'beginn'} label={'Beginn'} disabled={disabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput name={'ende'} label={'Ende'} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <TextInput name={`bemerkungen`} label={'Bemerkungen'} multiline />
      </Grid>
      <Grid item xs={6}>
        <TextInput<DarlehenFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
        />
      </Grid>
    </Grid>
  )
}
