import React, { ReactElement, useMemo } from 'react'
import { Box } from '@mui/material'

import { LinearDisplay } from 'components/cockpit/LinearDisplay'
import styles from 'components/progress-bar/ProgressBar.module.scss'
import { formatNumberWithFractionDigits } from 'components/util/formatUtil'

type ProgressBarProps = {
  value?: number
}

export const ProgressBar = ({ value = 0 }: ProgressBarProps): ReactElement => {
  const valuePercent = value * 100
  const formattedScore = useMemo(
    () => `${formatNumberWithFractionDigits(valuePercent, 2)} %`,
    [value],
  )
  return (
    <Box className={styles.progressBarContainer}>
      <Box className={styles.progressBar}>
        <LinearDisplay
          value={valuePercent}
          formattedValue={formattedScore}
          progressClassName={styles.progressClassName}
          labelClassName={styles.labelClassName}
        />
      </Box>
    </Box>
  )
}
