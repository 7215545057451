import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Stack, Typography } from '@mui/material'
import clsx from 'clsx'

import logoEnergyFinance from 'assets/images/guidance/overview/logo-energy-und-finance.png'
import logoIfi from 'assets/images/guidance/overview/logo-institut-finanzielle-intelligenz.png'
import logoKarriereInstitut from 'assets/images/guidance/overview/logo-karriere-institut.png'
import logoMitNormFinanzplanung from 'assets/images/guidance/overview/logo-mitnorm-finanzplanung.png'
import logoMitNormFirmenberatung from 'assets/images/guidance/overview/logo-mitnorm-firmenberatung.png'
import logoMyNorm from 'assets/images/guidance/overview/logo-mynorm.png'
import logoPersonalBerater from 'assets/images/guidance/overview/logo-personal-berater.png'
import mainEnergyFinance from 'assets/images/guidance/overview/main-energy-finance.png'
import mainKarriereInstitut from 'assets/images/guidance/overview/main-karriere-institut.png'
import mainMitNormFinanzplanung from 'assets/images/guidance/overview/main-mitnorm-finanzplanung.png'
import mainMitNormFirmenberatung from 'assets/images/guidance/overview/main-mitnorm-firmenberatung.png'
import mainMyNorm from 'assets/images/guidance/overview/main-mynorm.png'
import mainPersonalBerater from 'assets/images/guidance/overview/main-personal-berater.png'
import logoWhite from 'assets/images/logo_black.png'
import styles from 'components/guidance/GuidanceOverview.module.scss'
import { GuidancePage } from 'components/guidance/GuidancePage'
import { Paths } from 'configurations/paths'

export const GuidanceOverview = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <GuidancePage>
      <Box paddingTop={4} className={styles.overviewWrapper}>
        <Grid container position='relative' className={styles.overviewContainer}>
          <div className={styles.myviCircle}>
            <img src={logoWhite} className={styles.myviLogo} alt='MYVI Logo' />
          </div>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainKarriereInstitut}
              textBlock={
                <>
                  EIGENE TALENTE ENTDECKEN
                  <br />
                  KOMPETENZEN ENTWICKELN
                  <br />
                  10 - 15 % MEHR GEHALT
                </>
              }
              logoImage={logoKarriereInstitut}
              navigate={() => navigate(Paths.guidanceCareer1)}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainPersonalBerater}
              textBlock={
                <>
                  ZEITEFFIZIENT DEN RICHTIGEN
                  <br />
                  ARBEITGEBER FINDEN
                  <br />
                  ZUGRIFF AUF ÜBER 850 ARBEITGEBER
                </>
              }
              logoImage={logoPersonalBerater}
              navigate={() => navigate(Paths.guidancePersonnel1)}
              reverse={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainMitNormFinanzplanung}
              textBlock={
                <>
                  ZIELE ERREICHEN
                  <br />
                  STAATLICHE FÖRDERUNGEN
                  <br />
                  MONATSNETTOGEHALT
                  <br />
                  ZUSÄTZLICH
                </>
              }
              logoImage={logoMitNormFinanzplanung}
              navigate={() => navigate(Paths.guidanceMitNorm0)}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainMitNormFirmenberatung}
              textBlock={
                <>
                  FACHKRÄFTE SUCHEN
                  <br />
                  MITARBEITER BINDEN
                  <br />
                  HAFTUNG REDUZIEREN
                  <br />
                  BENEFITS ERMÖGLICHEN
                </>
              }
              logoImage={logoMitNormFirmenberatung}
              navigate={() => navigate(Paths.guidanceCompany1)}
              reverse={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainMyNorm}
              textBlock={
                <>
                  DER DIGITALE BEGLEITER UNSERER
                  <br />
                  MANDANTEN
                  <br />
                  MIT EINEM KLICK - ALLES REGELN
                </>
              }
              logoImage={logoMyNorm}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <GuidanceOverviewBlock
              mainImage={mainEnergyFinance}
              textBlock={
                <>
                  DEUTSCHLAND WIRD KLIMANEUTRAL
                  <br />
                  STAATLICHE FÖRDERUNGEN NUTZEN
                  <br />
                  NACHHALTIGES INVESTMENT IN DIE
                  <br />
                  ENERGIE VON MORGEN
                </>
              }
              logoImage={logoEnergyFinance}
              reverse={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              className={styles.ifiBanner}
              onClick={() => window.open(Paths.externalIFIWebsite, '_blank', 'noreferrer')}
            >
              <Stack direction='row' className={styles.ifiBannerContainer}>
                <h4 className={styles.ifiText}>UNSER KOOPERATIONSPARTNER:</h4>
                <img src={logoIfi} className={styles.logoImage} alt='Logo IFI' />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GuidancePage>
  )
}

type GuidanceOverviewBlockProps = {
  mainImage: string
  logoImage: string
  textBlock: ReactElement
  navigate?: () => void
  reverse?: boolean
}

const GuidanceOverviewBlock = ({
  mainImage,
  logoImage,
  textBlock,
  reverse,
  navigate,
}: GuidanceOverviewBlockProps): ReactElement => {
  return (
    <Stack
      direction={reverse ? 'row-reverse' : 'row'}
      className={styles.overviewBlock}
      onClick={navigate}
    >
      <img className={styles.mainImage} src={mainImage} alt='Bereichsfoto' />
      <Stack className={styles.overviewBlockContent}>
        <img className={styles.logoImage} src={logoImage} alt='Bereichsfoto' />
        <Box className={styles.overviewBlockContentTextContainer}>
          <Typography className={clsx(styles.overviewBlockContentText, reverse && styles.reverse)}>
            {textBlock}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}
