import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { GesetzlicheVersorgungFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type GesetzlicheVersorgungMutator = {
  updateGesetzlicheVersorgung: (
    personIndex: number,
    versorgungIndex: number,
    gesetzlicheVersorgungFormModel: GesetzlicheVersorgungFormModel,
  ) => void
  removeGesetzlicheVersorgung: (personIndex: number, versorgungIndex: number) => void
  addGesetzlicheVersorgung: (
    personIndex: number,
    gesetzlicheVersorgungFormModel: GesetzlicheVersorgungFormModel,
  ) => void
}

export const gesetzlicheVersorgungMutator = (set: Mutator): GesetzlicheVersorgungMutator => {
  return {
    updateGesetzlicheVersorgung: (
      personIndex: number,
      versorgungIndex: number,
      gesetzlicheVersorgungFormModel: GesetzlicheVersorgungFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].gesetzlicheVersorgungen[versorgungIndex] =
              gesetzlicheVersorgungFormModel
          }),
        }
      })
    },
    removeGesetzlicheVersorgung: (personIndex: number, versorgungIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const gesetzlicheVersorgung =
              draft.personen[personIndex].gesetzlicheVersorgungen[versorgungIndex]
            if (gesetzlicheVersorgung.isNew) {
              draft.personen[personIndex].gesetzlicheVersorgungen.splice(versorgungIndex, 1)
            } else {
              draft.personen[personIndex].gesetzlicheVersorgungen[versorgungIndex].isDeleted = true
            }
          }),
        }
      })
    },
    addGesetzlicheVersorgung: (
      personIndex: number,
      gesetzlicheVersorgungFormModel: GesetzlicheVersorgungFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            gesetzlicheVersorgungFormModel.meta.myviId = uuidv4()
            gesetzlicheVersorgungFormModel.isNew = true
            draft.personen[personIndex].gesetzlicheVersorgungen.push(gesetzlicheVersorgungFormModel)
          }),
        }
      })
    },
  }
}
