import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { BetrieblicheAltersvorsorgeDetailsFormModel } from 'form/vertraege/VertragFormModel'
import {
  BavTyp,
  BetrieblicheAltersvorsorgeDetails,
  LebensDetails,
  ProduktMapping,
} from 'interfaces/mynorm-api-model-interfaces'
import {
  isRelevantForDetailsTypAndFinfireProduktId,
  isVersicherungsTyp,
  useProduktMappings,
} from 'state/useProduktConfig'

type LebensDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  bavDetails?: BetrieblicheAltersvorsorgeDetailsFormModel
}

const FORM_BLOCK_TITLE = 'Details - Lebensversicherung'

export const LebensDetailsFormBlock = ({
  produktId,
  name,
  bavDetails,
}: LebensDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.lebensDetails`
  const produktMappings = useProduktMappings()
  if (!isLebensDetailsRelevant(produktId, produktMappings, bavDetails?.bavTyp)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.versicherungssumme`}
          label={'Versicherungssumme'}
          inputUnit='€'
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.ablaufleistungPrognostiziert`}
          label={'Ablaufleistung prognostiziert'}
          inputUnit='€'
        />
      </Grid>
    </Grid>
  )
}

type LebensDetailsReadViewProps = BaseDetailsReadViewProps & {
  lebensDetails: LebensDetails | undefined
  bavDetails: BetrieblicheAltersvorsorgeDetails | undefined
}

export const LebensDetailsReadView = ({
  produktId,
  lebensDetails,
  bavDetails,
}: LebensDetailsReadViewProps): ReactElement | null => {
  const produktMappings = useProduktMappings()
  if (!isLebensDetailsRelevant(produktId, produktMappings, bavDetails?.bavTyp)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Versicherungssumme'}
        value={formatCurrency(lebensDetails?.versicherungssumme)}
      />
      <LabelValueEntry
        label={'Ablaufleistung prognostiziert'}
        value={formatCurrency(lebensDetails?.ablaufleistungPrognostiziert)}
      />
    </Stack>
  )
}

export const isLebensDetailsRelevant = (
  produktId: string,
  produktMappings: ProduktMapping[],
  bavTyp?: BavTyp,
): boolean => {
  const isBAV = isVersicherungsTyp(produktId, 'BAV', produktMappings)
  return (
    (isBAV && bavTyp === 'LEBENSVERSICHERUNG') ||
    (!isBAV && isRelevantForDetailsTypAndFinfireProduktId('LEBEN', produktId))
  )
}
