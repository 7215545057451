import { Person } from 'interfaces/mynorm-api-model-interfaces'

export type Nameable = {
  vorname?: string
  nachname?: string
}

export const EXTERNE_PERSON_OPTION_VALUE = 'externalPerson'

export const extractFullName = (person?: Nameable): string => {
  const fullName = `${stringOrEmpty(person?.vorname)} ${stringOrEmpty(person?.nachname)}`.trim()
  if (fullName === '') {
    return 'Ohne Name'
  }
  return fullName
}

const stringOrEmpty = (value?: string): string => {
  if (value === undefined || value === null) {
    return ''
  }
  return value
}

export const abbreviatedFullName = (person: Person): string => {
  const firstLetterLastname = person.nachname.at(0)
  return `${person.vorname} ${firstLetterLastname}.`
}
