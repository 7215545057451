import produce from 'immer'
import create from 'zustand'

export type ObjectToDelete = {
  path: string
  idField: string
  idValue: string
}

type SelectivePathsToUpdateStateType = {
  pathsToDelete: ObjectToDelete[]
  addPathsToDelete: (objectToDelete: ObjectToDelete) => void
  resetPathsToDelete: () => void
}

const selectivePathsToUpdateState = create<SelectivePathsToUpdateStateType>((set) => ({
  pathsToDelete: [],
  addPathsToDelete: (objectToDelete: ObjectToDelete) => {
    set(({ pathsToDelete }) => {
      return {
        pathsToDelete: produce(pathsToDelete, (draft: ObjectToDelete[]) => {
          draft.push(objectToDelete)
        }),
      }
    })
  },
  resetPathsToDelete: () => {
    set((state) => {
      return produce(state, (draft: SelectivePathsToUpdateStateType) => {
        draft.pathsToDelete = []
      })
    })
  },
}))
export const useSelectivePathsToUpdateState = (): SelectivePathsToUpdateStateType => {
  const { pathsToDelete, addPathsToDelete, resetPathsToDelete } = selectivePathsToUpdateState()
  return { pathsToDelete, addPathsToDelete, resetPathsToDelete }
}
