import axios, { AxiosResponse } from 'axios'

import { config } from 'configurations/appConfig'

export const DEFAULT_STALE_TIME = 60000
export const STALE_TIME_ONE_HOUR = 60 * 60 * 1000

axios.defaults.baseURL = config.backendBaseUrl
axios.defaults.withCredentials = true

export const addAxiosInterceptor = (unauthorizedInterceptor: () => Promise<void>): number => {
  return axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error?.response && error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true
        await unauthorizedInterceptor()
        return axios(originalRequest)
      }
      return Promise.reject(error)
    },
  )
}

export const removeAxiosInterceptor = (interceptorId: number): void => {
  axios.interceptors.response.eject(interceptorId)
}
