import React, { ReactElement } from 'react'
import SecurityIcon from '@mui/icons-material/Security'
import { Box } from '@mui/material'

import { CockpitCard } from 'components/cockpit/CockpitCard'
import summaryStyles from 'components/cockpit/CockpitSummaryCard.module.scss'
import { CockpitSummaryDialog } from 'components/cockpit/CockpitSummaryDialog'
import { InsuranceCountItem } from 'components/insurance-count/InsuranceCountItem'
import { PersonalAbsicherungFormV2 } from 'components/personal/absicherung2/PersonalAbsicherungForm'
import { useInsuranceCount } from 'hooks/useInsuranceCount'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

type CockpitInsuranceCardProps = {
  dinScore?: GesamtErgebnisse
  haushalt?: HaushaltAggregate
  dialogOpen: boolean
  setDialogVisibility: (isVisible: boolean) => void
}

export const CockpitInsuranceCard = ({
  haushalt,
  dialogOpen,
  setDialogVisibility,
}: CockpitInsuranceCardProps): ReactElement => {
  const insuranceCounts = useInsuranceCount(haushalt)
  const hasPartner = !!haushalt?.personen[1]

  return (
    <>
      <CockpitCard
        title={'Absicherung'}
        icon={<SecurityIcon />}
        onClick={() => setDialogVisibility(true)}
      >
        <Box>
          {insuranceCounts.map((count) => (
            <Box key={count.label} className={summaryStyles.entry}>
              <div>{count.label}</div>
              <InsuranceCountItem insuranceCount={count} hasPartner={hasPartner} />
            </Box>
          ))}
        </Box>
      </CockpitCard>
      {haushalt && (
        <CockpitSummaryDialog open={dialogOpen} onClose={() => setDialogVisibility(false)}>
          <PersonalAbsicherungFormV2 />
        </CockpitSummaryDialog>
      )}
    </>
  )
}
