import { useMemo } from 'react'
import { useCookies } from 'react-cookie'

type UseIsAuthenticatedResult = {
  isAuthenticated: boolean
  isExpired: boolean
}

const TOKEN_EXPIRY_THRESHOLD = 1000 * 60 * 5 // 5 Minutes
const AUTH_COOKIE_NAME = 'auth-expiry'

export const useIsAuthenticated = (): UseIsAuthenticatedResult => {
  const [cookies] = useCookies([AUTH_COOKIE_NAME])

  return useMemo(() => {
    const expiryString = cookies[AUTH_COOKIE_NAME]
    if (expiryString) {
      const expiry = parseInt(expiryString)
      return { isAuthenticated: true, isExpired: expiry <= Date.now() - TOKEN_EXPIRY_THRESHOLD }
    } else {
      return { isAuthenticated: false, isExpired: false }
    }
  }, [cookies])
}
