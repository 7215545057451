import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel } from 'components/controls/cells/FormCell'

export const PersonalAnlegerProfilAndereAnlagenLabels = (): ReactElement => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <FormCellLabel $border $highlighted>
            Andere Anlagen
          </FormCellLabel>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <FormCellLabel $border>Spar- / Tages- / Termingeld</FormCellLabel>
        </TableRow>
        <TableRow>
          <FormCellLabel $border>Immobilien</FormCellLabel>
        </TableRow>
        <TableRow>
          <FormCellLabel $border>Sonstiges</FormCellLabel>
        </TableRow>
      </TableBody>
    </Table>
  )
}
