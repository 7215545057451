import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import axios from 'axios'

import { DEFAULT_STALE_TIME } from 'api/apiUtils'
import { BackendPaths } from 'configurations/backendPaths'
import {
  CreatePerson,
  HaushaltAggregate,
  SimplePerson,
} from 'interfaces/mynorm-api-model-interfaces'

export const haushaltQueryKey = (id?: string): QueryKey => {
  return ['fetchHaushalt', id]
}

export const useHaushalt = (
  selectedPerson: SimplePerson | null,
): UseQueryResult<HaushaltAggregate | undefined> => {
  return useQuery({
    queryKey: haushaltQueryKey(selectedPerson?.finfireId),
    queryFn: () => fetchHaushalt(selectedPerson?.finfireId),
    // enabled: Boolean(selectedPerson?.finfireId) && !selectedPerson?.firmenkunde,
    enabled: Boolean(selectedPerson?.finfireId),
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STALE_TIME,
  })
}

export const fetchHaushalt = async (id?: string): Promise<HaushaltAggregate | undefined> => {
  if (id === undefined || id === null) return Promise.resolve(undefined)
  const response = await axios.get<HaushaltAggregate>(`${BackendPaths.getHaushalt}/${id}`)
  return response.data
}

export const useCreateHaushalt = (): UseMutationResult<string, unknown, CreatePerson> => {
  return useMutation((createPerson) => createHaushalt(createPerson))
}

const createHaushalt = async (createPerson: CreatePerson): Promise<string> => {
  const response = await axios.post<string>(BackendPaths.createHaushalt, createPerson)
  return response.data
}
