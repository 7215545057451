import React, { ReactElement } from 'react'
import styles from 'App.module.scss'

import DevelopmentHint from 'components/development-hint/DevelopmentHint'
import { LoginPage } from 'components/login-form/LoginPage'

export const LoginRoute = (): ReactElement => {
  return (
    <div className={styles.app}>
      <LoginPage />
      <DevelopmentHint />
    </div>
  )
}
