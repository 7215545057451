import { nullableValueOrDefault } from 'form/formModelConverterUtil'
import { KontaktdatenFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import { Kontaktdaten } from 'interfaces/mynorm-api-model-interfaces'

const defaultKontaktdatenFormModel = createDefaultPersonFormModel().kontaktdaten

export const convertToKontaktdatenFormModel = (
  kontaktdaten: Kontaktdaten | undefined,
): KontaktdatenFormModel => {
  if (!kontaktdaten) {
    return defaultKontaktdatenFormModel
  }
  return {
    email: nullableValueOrDefault(kontaktdaten.email, defaultKontaktdatenFormModel.email),
    telefon: nullableValueOrDefault(kontaktdaten.telefon, defaultKontaktdatenFormModel.telefon),
    fax: nullableValueOrDefault(kontaktdaten.fax, defaultKontaktdatenFormModel.fax),
    internet: nullableValueOrDefault(kontaktdaten.internet, defaultKontaktdatenFormModel.internet),
  }
}
