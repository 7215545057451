import produce from 'immer'
import { v4 } from 'uuid'

import { KindFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

export type KinderMutator = {
  addKind: (kindFormModel: KindFormModel) => void
  updateKind: (kindIndex: number, kindFormModel: KindFormModel) => void
  removeKind: (kindIndex: number) => void
}

export const kinderMutator = (set: Mutator): KinderMutator => {
  return {
    addKind: (kindFormModel: KindFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            kindFormModel.isNew = true
            const id = v4()
            kindFormModel.meta.finfireId = id
            kindFormModel.meta.myviId = id
            draft.kinder.push(kindFormModel)
          }),
        }
      })
    },
    removeKind: (kindIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const kindToRemove = draft.kinder[kindIndex]
            if (kindToRemove.isNew) {
              draft.kinder.splice(kindIndex, 1)
            } else {
              kindToRemove.isDeleted = true
            }
            draft.personen.forEach((person) => {
              person.vermoegen.geldAnlagen.forEach((geldAnlage) => {
                if (hasId(kindToRemove, geldAnlage.meta.finfireId)) {
                  geldAnlage.geldanlageZielArt = 'KEIN_ZIEL'
                  geldAnlage.kindId = undefined
                }
              })
            })
          }),
        }
      })
    },
    updateKind: (kindIndex: number, kindFormModel: KindFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.kinder[kindIndex] = kindFormModel
          }),
        }
      })
    },
  }
}
