import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import backgroundImage from 'assets/images/guidance/personnel/coaching-angebot/coaching_angebot_background.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/personnel/GuidancePersonnel5Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidancePersonnel5Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={1}
      menuSubIndex={4}
      previousPagePath={Paths.guidance}
      previousPageButtonText={'Zur Übersicht'}
      nextPagePath={Paths.externalWirPersonalberaterJobPortal}
      nextPageButtonText={'Zum WP-Portal'}
      guidanceColor='personnel'
      backgroundImage={backgroundImage}
    >
      <GuidanceSubPageTemplate title={'COACHING-ANGEBOT'} subTitle={'WIR PERSONALBERATER'}>
        <Box className={styles.textContainer}>
          <h2 className={styles.text}>
            DER BERUF IST WIE EIN TELEFON.
            <br />
            HAT MAN NICHT RICHTIG GEWÄHLT, IST MAN FALSCH VERBUNDEN.
          </h2>
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
