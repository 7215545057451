import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { STALE_TIME_ONE_HOUR } from 'api/apiUtils'
import { BackendPaths } from 'configurations/backendPaths'
import { Produkt, ProduktSpecs, VertragGeschaeft } from 'interfaces/mynorm-api-model-interfaces'

export const useFetchProduktSpecs = (
  vertragGeschaeft: VertragGeschaeft,
): UseQueryResult<ProduktSpecs[]> => {
  return useQuery(
    [vertragGeschaeft, 'fetchProduktSpecs'],
    () => fetchProduktSpecs(vertragGeschaeft),
    {
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME_ONE_HOUR,
    },
  )
}

const fetchProduktSpecs = async (vertragGeschaeft: VertragGeschaeft): Promise<Produkt[]> => {
  const response = await axios.get<Produkt[]>(`${BackendPaths.getProduktSpecs}/${vertragGeschaeft}`)
  return response.data
}
