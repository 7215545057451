import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { VersichertesRisikoBlockProps } from 'components/personal/absicherung2/blocks/RisikenFormBlock'
import { HaushaltTyp } from 'interfaces/mynorm-api-model-interfaces'

export const VersicherterHaushaltBlock = ({
  name,
  risiko,
  disabled,
}: VersichertesRisikoBlockProps): ReactElement | null => {
  const { risikoTyp } = risiko
  if (risikoTyp !== 'HAUSHALT') {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <HaushaltTypSelect name={`${name}.risikoSonstiges`} disabled={disabled} />
      </Grid>
    </Grid>
  )
}

const haushaltTypOptions: Array<Option<HaushaltTyp>> = [
  {
    label: 'Familie',
    value: 'FAMILIE',
  },
  {
    label: 'Gemeinschaft',
    value: 'GEMEINSCHAFT',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Senioren',
    value: 'SENIOREN',
  },
]

type HaushaltTypSelectProps = {
  name: string
  label?: string
  disabled?: boolean
}
const HaushaltTypSelect = ({ name, label, disabled }: HaushaltTypSelectProps): ReactElement => {
  return (
    <SelectInput
      options={haushaltTypOptions}
      name={name}
      label={label ?? 'Art des Haushaltes'}
      disabled={disabled}
    />
  )
}
