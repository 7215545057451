import { convertZahlweise } from 'form/haushalt-converter/person-converter/vermoegen-converter/vermoegenConverter'
import { DarlehenFormModel } from 'form/KundeFormModel'
import { Darlehen } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToDateRequestModelType,
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertDarlehen = (
  formModel: DarlehenFormModel,
  source?: Darlehen,
): RecursivePartial<Darlehen> => {
  const useFormModelAsDefault = formModel.isNew
  return {
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    darlehenTyp: mapToRequestModelType(
      formModel.darlehenTyp,
      source?.darlehenTyp,
      useFormModelAsDefault,
    ),
    bezeichnung: mapToRequestModelType(
      formModel.bezeichnung,
      source?.bezeichnung,
      useFormModelAsDefault,
    ),
    bemerkungen: mapToRequestModelType(
      formModel.bemerkungen,
      source?.bemerkungen,
      useFormModelAsDefault,
    ),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
      useFormModelAsDefault,
    ),
    darlehensnehmer: mapToRequestModelType(
      formModel.darlehensnehmer.map((personFinfireId) =>
        personFinfireId === EXTERNE_PERSON_OPTION_VALUE
          ? {
              personFinfireId: null,
              externePerson: true,
              externePersonName: formModel.darlehensnehmerExternePersonName,
            }
          : { personFinfireId, externePerson: false, externePersonName: null },
      ),
      source?.darlehensnehmer,
      useFormModelAsDefault,
    ),
    darlehensgeber: mapToRequestModelType(
      formModel.darlehensgeber,
      source?.darlehensgeber,
      useFormModelAsDefault,
    ),
    vertragsnummer: mapToRequestModelType(
      formModel.vertragsnummer,
      source?.vertragsnummer,
      useFormModelAsDefault,
    ),
    darlehensbeginn: mapToDateRequestModelType(
      formModel.darlehensbeginn,
      source?.darlehensbeginn,
      useFormModelAsDefault,
    ),
    darlehensende: mapToDateRequestModelType(
      formModel.darlehensende,
      source?.darlehensende,
      useFormModelAsDefault,
    ),
    darlehensbetrag: mapToNumericRequestModelType(
      formModel.darlehensbetrag,
      source?.darlehensbetrag,
      useFormModelAsDefault,
    ),
    darlehensstand: mapToNumericRequestModelType(
      formModel.darlehensstand,
      source?.darlehensstand,
      useFormModelAsDefault,
    ),
    datumDarlehensstand: mapToDateRequestModelType(
      formModel.datumDarlehensstand,
      source?.datumDarlehensstand,
      useFormModelAsDefault,
    ),

    zinssatz: mapToNumericPercentRequestModelType(
      formModel.zinssatz,
      source?.zinssatz,
      useFormModelAsDefault,
    ),
    zinsfestschreibung: mapToDateRequestModelType(
      formModel.zinsfestschreibung,
      source?.zinsfestschreibung,
      useFormModelAsDefault,
    ),
    tilgungsTyp: mapToRequestModelType(
      formModel.tilgungsTyp,
      source?.tilgungsTyp,
      useFormModelAsDefault,
    ),
    darlehensrate: {
      ...source?.darlehensrate,
      sparrate: mapToNumericRequestModelType(
        formModel.darlehensrate.sparrate,
        source?.darlehensrate?.sparrate,
        useFormModelAsDefault,
      ),
      zahlweise: convertZahlweise(
        formModel.darlehensrate.zahlweise,
        source?.darlehensrate?.zahlweise,
        useFormModelAsDefault,
      ),
      zahlart: mapToRequestModelType(
        formModel.darlehensrate.zahlart,
        source?.darlehensrate?.zahlart,
        useFormModelAsDefault,
      ),
    },
    createFlag: formModel.isNew,
    deleteFlag: formModel.isDeleted,
    saveError: formModel.saveError,
  }
}
