import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/in-zusammenarbeit-mit/in_zusammenarbeit_mit_0.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/in-zusammenarbeit-mit/in_zusammenarbeit_mit_0.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm13Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'
export const GuidanceMitNorm13Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={12}
      previousPagePath={Paths.guidanceMitNorm12}
      nextPagePath={Paths.guidanceMitNorm14}
      guidanceColor='mitnorm'
    >
      <GuidanceSubPageTemplate title={'IN ZUSAMMENARBEIT MIT'} subTitle={'mitNORM'}>
        <Box className={styles.chartContainer}>
          <Box className={styles.chart}>
            <GuidanceMitNormImage src={chart0} feSrc={chart0Fe} alt='Partner' />
          </Box>
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
