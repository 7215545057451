import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { TodesfallDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

type TodesfallDetailsFormBlockProps = BaseDetailsFormBlockProps

const FORM_BLOCK_TITLE = 'Details - Todesfall'

export const TodesfallDetailsFormBlock = ({
  produktId,
  name,
}: TodesfallDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.todesfallDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('TODESFALL', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.todesfallleistung`}
          label={'Todesfallleistung garantiert'}
          inputUnit='€'
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.todesfallleistungMitUeberschuss`}
          label={'Todesfallleistung mit Überschuss'}
          inputUnit='€'
        />
      </Grid>
    </Grid>
  )
}

type TodesfallDetailsReadViewProps = BaseDetailsReadViewProps & {
  todesfallDetails: TodesfallDetails | undefined
}

export const TodesfallDetailsReadView = ({
  produktId,
  todesfallDetails,
}: TodesfallDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('TODESFALL', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Todesfallleistung garantiert'}
        value={formatCurrency(todesfallDetails?.todesfallleistung)}
      />
      <LabelValueEntry
        label={'Todesfallleistung mit Überschuss'}
        value={formatCurrency(todesfallDetails?.todesfallleistungMitUeberschuss)}
      />
    </Stack>
  )
}
