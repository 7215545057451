import { isEmpty } from 'form/haushalt-converter/person-converter/personenConverter'
import { BerufFormModel } from 'form/KundeFormModel'
import { Beruf } from 'interfaces/mynorm-api-model-interfaces'
import { mapToNumericRequestModelType, mapToRequestModelType } from 'utils/converterUtils'

export const convertBeruf = (
  formModel: BerufFormModel | undefined,
  original: Beruf | undefined,
  useFormModelAsDefault: boolean,
): Beruf | undefined => {
  if (formModel == null || (isEmpty(formModel) && original === null)) {
    return original
  }

  const bundeslandAbweichend: boolean =
    mapToRequestModelType(
      formModel.arbeitstaetteBundeslandAbweichung,
      original?.arbeitstaetteBundeslandAbweichung,
      useFormModelAsDefault,
    ) ?? false

  const bundesland = bundeslandAbweichend
    ? mapToRequestModelType(
        formModel.arbeitsstaetteBundesland,
        original?.arbeitsstaetteBundesland,
        useFormModelAsDefault,
      )
    : null
  return {
    ...original,
    taetigkeitstatus: mapToRequestModelType(
      formModel.taetigkeitstatus,
      original?.taetigkeitstatus,
      useFormModelAsDefault,
    ),
    besoldungsgruppe: mapToRequestModelType(
      formModel.besoldungsgruppe,
      original?.besoldungsgruppe,
      useFormModelAsDefault,
    ),
    versorgungsrechtlicherDienstzeitbeginnJahr: mapToNumericRequestModelType(
      formModel.versorgungsrechtlicherDienstzeitbeginnJahr,
      original?.versorgungsrechtlicherDienstzeitbeginnJahr,
      useFormModelAsDefault,
    ),
    arbeitstaetteBundeslandAbweichung: bundeslandAbweichend,
    arbeitsstaetteBundesland: bundesland,
    freiwilligeEntgeltfortzahlungArbeitgeber: mapToRequestModelType(
      formModel.freiwilligeEntgeltfortzahlungArbeitgeber,
      original?.freiwilligeEntgeltfortzahlungArbeitgeber,
      useFormModelAsDefault,
    ),
    dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen: mapToNumericRequestModelType(
      formModel.dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen,
      original?.dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen,
      useFormModelAsDefault,
    ),
    absoluterBetragEntgeltfortzahlung: mapToNumericRequestModelType(
      formModel.absoluterBetragEntgeltfortzahlung,
      original?.absoluterBetragEntgeltfortzahlung,
      useFormModelAsDefault,
    ),
    keineAbsicherungNoetigWochen: mapToNumericRequestModelType(
      formModel.keineAbsicherungNoetigWochen,
      original?.keineAbsicherungNoetigWochen,
      useFormModelAsDefault,
    ),
    besondereTaetigkeiten: mapToRequestModelType(
      formModel.besondereTaetigkeiten,
      original?.besondereTaetigkeiten,
      useFormModelAsDefault,
    ),
    ausgeuebterBeruf: mapToRequestModelType(
      formModel.ausgeuebterBeruf,
      original?.ausgeuebterBeruf,
      useFormModelAsDefault,
    ),
    ausbildung: mapToRequestModelType(
      formModel.ausbildung,
      original?.ausbildung,
      useFormModelAsDefault,
    ),
    beruflicheQualifikation: mapToRequestModelType(
      formModel.beruflicheQualifikation,
      original?.beruflicheQualifikation,
      useFormModelAsDefault,
    ),
    fruehereBeruflicheTaetigkeiten: mapToRequestModelType(
      formModel.fruehereBeruflicheTaetigkeiten,
      original?.fruehereBeruflicheTaetigkeiten,
      useFormModelAsDefault,
    ),
    arbeitgeber: mapToRequestModelType(
      formModel.arbeitgeber,
      original?.arbeitgeber,
      useFormModelAsDefault,
    ),
  }
}
