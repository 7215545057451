import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

import {
  AnlagestrukturDetailsFormBlock,
  AnlagestrukturDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/AnlagestrukturDetailsFormBlock'
import {
  BerufshaftpflichtDetailReadView,
  BerufshaftpflichtDetailsFormBlock,
} from 'components/personal/absicherung2/blocks/details/BerufshaftpflichtDetailsFormBlock'
import {
  BerufsunfaehigkeitsDetailsFormBlock,
  BerufsunfaehigkeitsDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/BerufsunfaehigkeitsDetailsFormBlock'
import {
  BetrieblicheAltersvorsorgeDetailsFormBlock,
  BetrieblicheAltersvorsorgeDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/BetrieblicheAltersvorsorgeFormBlock'
import {
  DreadDiseaseDetailsFormBlock,
  DreadDiseaseDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/DreadDiseaseDetailsFormBlock'
import {
  ErwerbsunfaehigkeitsDetailsFormBlock,
  ErwerbsunfaehigkeitsDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/ErwerbsunfaehigkeitsDetailsFormBlock'
import {
  FunktionsinvaliditaetsDetailsFormBlock,
  FunktionsinvaliditaetsDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/FunktionsinvaliditaetsDetailsFormBlock'
import {
  GesetzlicheKrankenversicherungDetailsFormBlock,
  GesetzlicheKrankenversicherungDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/GesetzlicheKrankenversicherungDetailsFormBlock'
import {
  GrundfaehigkeitsDetailsFormBlock,
  GrundfaehigkeitsDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/GrundfaehigkeitsrenteDetailsFormBlock'
import {
  HaftpflichtDetailsFormBlock,
  HaftpflichtDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/HaftpflichtDetailsFormBlock'
import {
  HausratDetailsFormBlock,
  HausratDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/HausratDetailsFormBlock'
import {
  InvaliditaetsDetailsFormBlock,
  InvaliditaetsDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/InvaliditaetsDetailsFormBlock'
import {
  KfzDetailReadView,
  KfzDetailsFormBlock,
} from 'components/personal/absicherung2/blocks/details/KfzDetailsFormBlock'
import {
  KrankentagegeldDetailsReadView,
  KrankentagegeldFormBlock,
} from 'components/personal/absicherung2/blocks/details/KrankentagegeldFormBlock'
import {
  LebensDetailsFormBlock,
  LebensDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/LebensDetailsFormBlock'
import {
  LebensUndRentenDetailsFormBlock,
  LebensUndRentenDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/LebensUndRentenDetailsFormBlock'
import {
  PflegerenteDetailsFormBlock,
  PflegerenteDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/PflegerenteDetailsFormBlock'
import {
  PflegetagegeldDetailsFormBlock,
  PflegetagegeldDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/PflegetagegeldDetailsFormBlock'
import {
  PrivathaftpflichtDetailsFormBlock,
  PrivathaftpflichtDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/PrivathaftpflichtDetailsFormBlock'
import {
  RechtsschutzDetailsFormBlock,
  RechtsschutzDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/RechtsschutzDetailsFormBlock'
import {
  RentenDetailsFormBlock,
  RentenDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/RentenDetailsFormBlock'
import {
  TierhalterhaftpflichtDetailReadView,
  TierhalterhaftpflichtDetailsFormBlock,
} from 'components/personal/absicherung2/blocks/details/TierhalterhaftpflichtDetailsFormBlock'
import {
  TodesfallDetailsFormBlock,
  TodesfallDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/TodesfallDetailsFormBlock'
import {
  UnfallDetailsFormBlock,
  UnfallDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/UnfallDetailsFormBlock'
import {
  UnfallzusatzDetailsFormBlock,
  UnfallzusatzDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/UnfallzusatzDetailsFormBlock'
import {
  WohngebaeudeDetailsFormBlock,
  WohngebaeudeDetailsReadView,
} from 'components/personal/absicherung2/blocks/details/WohngebaeudeDetailsFormBlock'
import { DetailsFormModel, RisikoFormModel } from 'form/vertraege/VertragFormModel'
import { Details } from 'interfaces/mynorm-api-model-interfaces'

export type BaseDetailsFormBlockProps = {
  name: string
  produktId: string
}

type DetailsFormBlockProps = BaseDetailsFormBlockProps & {
  details: DetailsFormModel
  risiken: RisikoFormModel[]
}

export const DetailsFormBlock = ({
  name,
  produktId,
  risiken,
  details,
}: DetailsFormBlockProps): ReactElement => {
  return (
    <Stack rowGap={2}>
      <BerufshaftpflichtDetailsFormBlock name={name} produktId={produktId} />
      <PrivathaftpflichtDetailsFormBlock
        name={name}
        produktId={produktId}
        berufshaftpflichtDetailsFormModel={details?.berufshaftpflichtDetails}
      />
      <TierhalterhaftpflichtDetailsFormBlock name={name} produktId={produktId} />
      <HaftpflichtDetailsFormBlock
        name={name}
        produktId={produktId}
        haftpflichtDetails={details?.haftpflichtDetails}
      />
      <HausratDetailsFormBlock
        name={name}
        produktId={produktId}
        hausratDetails={details?.hausratDetails}
      />
      <UnfallDetailsFormBlock
        name={name}
        produktId={produktId}
        risiken={risiken}
        unfallDetails={details.unfallDetails}
      />
      <KfzDetailsFormBlock produktId={produktId} name={name} />
      <RechtsschutzDetailsFormBlock
        name={name}
        produktId={produktId}
        rechtsschutzDetails={details?.rechtsschutzDetails}
      />
      <InvaliditaetsDetailsFormBlock name={name} produktId={produktId} />
      <WohngebaeudeDetailsFormBlock
        name={name}
        produktId={produktId}
        wohngebaeudeDetails={details.wohngebaeudeDetails}
      />
      <KrankentagegeldFormBlock name={name} produktId={produktId} />
      <PflegetagegeldDetailsFormBlock
        name={name}
        produktId={produktId}
        pflegetagegeldDetailsFormModel={details?.pflegetagegeldDetails}
      />
      <GesetzlicheKrankenversicherungDetailsFormBlock
        name={name}
        produktId={produktId}
        gesetzlicheKrankenversicherungDetailsFormModel={
          details?.gesetzlicheKrankenversicherungDetails
        }
      />
      <BetrieblicheAltersvorsorgeDetailsFormBlock name={name} produktId={produktId} />
      <LebensDetailsFormBlock
        name={name}
        produktId={produktId}
        bavDetails={details.betrieblicheAltersvorsorgeDetails}
      />
      <RentenDetailsFormBlock
        name={name}
        produktId={produktId}
        bavDetails={details.betrieblicheAltersvorsorgeDetails}
      />
      <LebensUndRentenDetailsFormBlock
        name={name}
        produktId={produktId}
        lebensUndRentenDetailsFormModel={details.lebensUndRentenDetails}
      />
      <TodesfallDetailsFormBlock name={name} produktId={produktId} />
      <AnlagestrukturDetailsFormBlock name={name} produktId={produktId} />
      <BerufsunfaehigkeitsDetailsFormBlock
        name={name}
        produktId={produktId}
        berufsunfaehigkeitsDetails={details?.berufsunfaehigkeitsDetails}
      />
      <GrundfaehigkeitsDetailsFormBlock name={name} produktId={produktId} />
      <UnfallzusatzDetailsFormBlock name={name} produktId={produktId} />
      <DreadDiseaseDetailsFormBlock name={name} produktId={produktId} />
      <PflegerenteDetailsFormBlock name={name} produktId={produktId} />
      <ErwerbsunfaehigkeitsDetailsFormBlock name={name} produktId={produktId} />
      <FunktionsinvaliditaetsDetailsFormBlock name={name} produktId={produktId} />
    </Stack>
  )
}

export type BaseDetailsReadViewProps = {
  produktId: string
}

type DetailsReadViewProps = BaseDetailsReadViewProps & {
  details?: Details
}

export const DetailsReadView = ({
  produktId,
  details,
}: DetailsReadViewProps): ReactElement | null => {
  if (!details) {
    return null
  }
  return (
    <Stack rowGap={2}>
      <BerufshaftpflichtDetailReadView
        produktId={produktId}
        berufshaftpflichtDetails={details.berufshaftpflichtDetails}
      />
      <PrivathaftpflichtDetailsReadView
        produktId={produktId}
        privathaftpflichtDetails={details.privathaftpflichtDetails}
        berufshaftpflichtDetails={details.berufshaftpflichtDetails}
      />
      <TierhalterhaftpflichtDetailReadView
        produktId={produktId}
        tierhalterhaftpflichtDetails={details.tierhalterHaftpflichtDetails}
      />
      <HaftpflichtDetailsReadView
        produktId={produktId}
        haftpflichtDetails={details.haftpflichtDetails}
      />
      <HausratDetailsReadView produktId={produktId} hausratDetails={details.hausratDetails} />
      <UnfallDetailsReadView produktId={produktId} unfallDetails={details.unfallDetails} />
      <KfzDetailReadView produktId={produktId} kfzDetails={details.kfzDetails} />
      <RechtsschutzDetailsReadView
        produktId={produktId}
        rechtsschutzDetails={details?.rechtsschutzDetails}
      />
      <InvaliditaetsDetailsReadView
        produktId={produktId}
        invaliditaetsDetails={details.invaliditaetsDetails}
      />
      <WohngebaeudeDetailsReadView
        produktId={produktId}
        wohngebaeudeDetails={details.wohngebaeudeDetails}
      />
      <KrankentagegeldDetailsReadView
        produktId={produktId}
        krankentagegeldDetails={details.krankentagegeldDetails}
      />
      <PflegetagegeldDetailsReadView
        produktId={produktId}
        pflegetagegeldDetails={details.pflegetagegeldDetails}
      />
      <GesetzlicheKrankenversicherungDetailsReadView
        produktId={produktId}
        gesetzlicheKrankenversicherungDetails={details.gesetzlicheKrankenversicherungDetails}
      />
      <BetrieblicheAltersvorsorgeDetailsReadView
        produktId={produktId}
        betrieblicheAltersvorsorgeDetails={details.betrieblicheAltersvorsorgeDetails}
      />
      <LebensDetailsReadView
        produktId={produktId}
        lebensDetails={details.lebensDetails}
        bavDetails={details.betrieblicheAltersvorsorgeDetails}
      />
      <RentenDetailsReadView
        produktId={produktId}
        rentenDetails={details.rentenDetails}
        bavDetails={details.betrieblicheAltersvorsorgeDetails}
      />
      <LebensUndRentenDetailsReadView
        produktId={produktId}
        lebensUndRentenDetails={details.lebensUndRentenDetails}
      />
      <TodesfallDetailsReadView produktId={produktId} todesfallDetails={details.todesfallDetails} />
      <AnlagestrukturDetailsReadView
        produktId={produktId}
        anlagestrukturDetails={details.anlagestrukturDetails}
      />
      <BerufsunfaehigkeitsDetailsReadView
        produktId={produktId}
        berufsunfaehigkeitsDetails={details.berufsunfaehigkeitsDetails}
      />
      <GrundfaehigkeitsDetailsReadView
        produktId={produktId}
        grundfaehigkeitsDetails={details.grundfaehigkeitsDetails}
      />
      <UnfallzusatzDetailsReadView
        produktId={produktId}
        unfallzusatzDetails={details.unfallzusatzDetails}
      />
      <DreadDiseaseDetailsReadView
        produktId={produktId}
        dreadDiseaseDetails={details.dreadDiseaseDetails}
      />
      <PflegerenteDetailsReadView
        produktId={produktId}
        pflegerenteDetails={details.pflegerenteDetails}
      />
      <ErwerbsunfaehigkeitsDetailsReadView
        produktId={produktId}
        erwerbsunfaehigkeitsDetails={details.erwerbsunfaehigkeitsDetails}
      />
      <FunktionsinvaliditaetsDetailsReadView
        produktId={produktId}
        funktionsinvaliditaetsDetails={details.funktionsinvaliditaetsDetails}
      />
    </Stack>
  )
}
