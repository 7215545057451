import React, { ReactElement } from 'react'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { Button, Grid, Stack } from '@mui/material'

import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ImportHaushaltButton } from 'components/import-haushalt/ImportHaushaltButton'
import { AddPartnerButton } from 'components/partner/AddPartnerButton'
import { HaushaltKinderContainer } from 'components/personal/haushalt/PersonalHaushaltKinderContainer'
import { HaushaltPersonContainer } from 'components/personal/haushalt/PersonalHaushaltPersonContainer'
import { PersonalFormLayout } from 'components/personal/PersonalFormLayout'
import { createDefaultKindFormModel } from 'form/kundeFormModelCreator'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalHaushaltFormProps = {
  selectiveFormProps?: SelectiveFormProps
  hideImportButton?: boolean
}

export const PersonalHaushaltForm = ({
  selectiveFormProps,
  hideImportButton,
}: PersonalHaushaltFormProps): ReactElement => {
  const { editableKunde, kinderMutator } = useEditableKunde()

  return (
    <PersonalFormLayout
      title={'Haushalt'}
      titleButton={hideImportButton ? undefined : <ImportHaushaltButton />}
    >
      <Grid container spacing={8} rowSpacing={6}>
        <Grid item md={6} sm={12}>
          <HaushaltPersonContainer personIndex={0} selectiveFormProps={selectiveFormProps} />
        </Grid>
        <Grid item md={6} sm={12}>
          <HaushaltPersonContainer personIndex={1} selectiveFormProps={selectiveFormProps} />
        </Grid>
        {editableKunde.kinder && editableKunde.kinder.length > 0 && (
          <Grid item xs={12}>
            <HaushaltKinderContainer selectiveFormProps={selectiveFormProps} />
          </Grid>
        )}
        <Grid item xs={12}>
          {!selectiveFormProps && (
            <Stack direction='row' spacing={2}>
              {!editableKunde.hasPartner && <AddPartnerButton />}
              <Button
                onClick={() => kinderMutator.addKind(createDefaultKindFormModel())}
                variant='outlined'
                startIcon={<GroupAddIcon />}
              >
                Kinder
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </PersonalFormLayout>
  )
}
