import React, { ReactElement, Suspense, useEffect } from 'react'
import { Await, Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'

import { ScreenContent } from 'components/authentication/ScreenContent'
import { HaushaltStateContextProvider, useHaushaltState } from 'hooks/useHaushaltState'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const CustomerRoute = (): ReactElement => {
  const data = useLoaderData() as { haushalt: Promise<HaushaltAggregate | null> }

  return (
    <ScreenContent>
      <Suspense
        fallback={
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        }
      >
        <Await resolve={data.haushalt}>
          {(haushalt) => (
            <HaushaltResultHandler haushalt={haushalt}>
              <HaushaltStateContextProvider>
                <Outlet />
              </HaushaltStateContextProvider>
            </HaushaltResultHandler>
          )}
        </Await>
      </Suspense>
    </ScreenContent>
  )
}

type HaushaltResultHandlerProps = {
  haushalt: HaushaltAggregate
  children: ReactElement
}
const HaushaltResultHandler = ({
  haushalt,
  children,
}: HaushaltResultHandlerProps): ReactElement => {
  const { customerId } = useParams()
  const { haushalt: existingHaushalt, setHaushalt } = useHaushaltState()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!haushalt) {
      navigate('/login', { state: { redirectUri: pathname } })
    }
    if (haushalt && !existingHaushalt) {
      setHaushalt(haushalt, customerId)
    }
  }, [existingHaushalt, haushalt])

  if (!existingHaushalt) return <></>
  return <>{children}</>
}
