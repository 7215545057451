import React, { ReactElement, useMemo, useState } from 'react'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Dialog, DialogActions, DialogContent, Grid, Link } from '@mui/material'
import { compact } from 'lodash'

import { useAddPartnerOfHaushalt } from 'api/haushalt/useUpdatePartnerOfHaushalt'
import { fetchPerson } from 'api/usePerson'
import { BaseSelect } from 'components/controls/inputs/SelectInput'
import { NewKundeForm } from 'components/create-kunde/NewKundeForm'
import { KundenwahlInput } from 'components/kundenwaehler/KundenwahlInput'
import styles from 'components/partner/AddPartnerButton.module.scss'
import { beziehungsStatusOptions } from 'form/kundeOptions'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { Beziehungsstatus, SimplePerson } from 'interfaces/mynorm-api-model-interfaces'

const AddPartnerWarningText = (): ReactElement => {
  return (
    <Alert className={styles.warningText} severity={'info'}>
      Der Partner wird dem Hauptkunden sofort hinzugefügt und muss nicht nochmal mit dem Haushalt
      mitgespeichert werden.
    </Alert>
  )
}

export const AddPartnerButton = (): ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [isNewPartner, setIsNewPartner] = useState<boolean>(false)
  const [beziehungsStatus, setBeziehungsStatus] = useState<Beziehungsstatus | null>(null)

  const [selectedKunde, setSelectedKunde] = useState<SimplePerson | null>(null)
  const { haushalt } = useHaushaltState()
  const {
    mutate: addPartner,
    isLoading: isAddPartnerLoading,
    isError: isAddPartnerError,
    reset: resetAddPartnerMutation,
  } = useAddPartnerOfHaushalt()

  const excludedFinfireIds = compact(haushalt?.personen.map((p) => p.person.meta.finfireId) ?? [])

  const [isFetchPersonLoading, setIsFetchPersonLoading] = useState(false)

  const isLoading = useMemo(() => {
    return isFetchPersonLoading || isAddPartnerLoading
  }, [isAddPartnerLoading, isFetchPersonLoading])

  const handleSelect = async (person: SimplePerson | null): Promise<void> => {
    setSelectedKunde(person)
  }

  const handleAddPartner = async (
    partnerId: string,
    beziehungsstatus?: Beziehungsstatus,
  ): Promise<void> => {
    setIsFetchPersonLoading(true)
    const partner = await fetchPerson(partnerId)
    setIsFetchPersonLoading(false)
    if (haushalt && partner && beziehungsstatus) {
      addPartner(
        { haushalt: haushalt, partner: partner, beziehungsStatus: beziehungsstatus },
        {
          onSuccess: () => handleClose,
        },
      )
    }
  }

  const handleClose = (): void => {
    setOpen(false)
    setIsNewPartner(false)
    setSelectedKunde(null)
    setBeziehungsStatus(null)
    setIsFetchPersonLoading(false)
    resetAddPartnerMutation()
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} variant='outlined' startIcon={<PersonAddAlt1Icon />}>
        Partner
      </Button>
      <Dialog open={open} fullWidth maxWidth={'sm'} onClose={() => !isNewPartner && handleClose()}>
        {!isNewPartner ? (
          <>
            <DialogContent>
              <h3>Partner hinzufügen</h3>
              <AddPartnerWarningText />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <KundenwahlInput
                    onSelect={handleSelect}
                    excludedFinfireIds={excludedFinfireIds}
                    excludeFirmenkunden={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BaseSelect
                    value={beziehungsStatus}
                    options={beziehungsStatusOptions}
                    name={'beziehungsStatus'}
                    label={'Beziehungsstatus'}
                    onChange={(event) =>
                      setBeziehungsStatus(event.target.value?.toString() as Beziehungsstatus)
                    }
                  />
                </Grid>
              </Grid>
              {!selectedKunde && (
                <div className={styles.neuerKunde}>
                  <Button variant='outlined' onClick={() => handleClose()}>
                    Abbrechen
                  </Button>
                  <Link className={styles.newKundeLink} onClick={() => setIsNewPartner(true)}>
                    Neuen Partner erstellen?
                  </Link>
                </div>
              )}
              {isAddPartnerError && (
                <Alert severity={'error'} className={styles.error}>
                  Beim Hinzufügen des Partners ist ein Fehler aufgetreten!
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              {selectedKunde && (
                <LoadingButton
                  className={styles.selectButton}
                  variant={'contained'}
                  color='primary'
                  loading={isLoading}
                  disabled={isLoading || !beziehungsStatus}
                  fullWidth
                  onClick={() => {
                    if (!beziehungsStatus) {
                      return
                    }
                    handleAddPartner(selectedKunde?.finfireId, beziehungsStatus)
                  }}
                >
                  Auswählen
                </LoadingButton>
              )}
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <h3>Neuer Partner</h3>
            <AddPartnerWarningText />
            <NewKundeForm
              onSuccess={handleAddPartner}
              onCancel={handleClose}
              isLoading={isLoading}
              isPartner={true}
              isError={isAddPartnerError}
            />
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}
