import React, { ReactElement, useState } from 'react'
import { Navigate } from 'react-router-dom'
import LoginIcon from '@mui/icons-material/Login'
import { LoadingButton } from '@mui/lab'
import { Alert } from '@mui/material'
import axios, { AxiosError } from 'axios'
import { Formik } from 'formik'
import * as yup from 'yup'

import { LoginFormProps } from 'components/login-form/LoginForm'
import styles from 'components/login-form/LoginForm.module.scss'
import { LoginFormTextField } from 'components/login-form/LoginFormTextField'
import { BackendPaths } from 'configurations/backendPaths'

type Login2FAFormProps = Omit<LoginFormProps, 'recentlyLoggedOut' | 'onSubmit'> & {
  twoFactorHash: string | null
}

export const Login2FAForm = ({
  onSuccessfulLogin,
  twoFactorHash,
}: Login2FAFormProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async ({ pin }: Login2FADaten): Promise<void> => {
    setLoading(true)
    setErrorMessage('')
    try {
      await axios.post(BackendPaths.loginValidate, {
        twoFactorHashcode: twoFactorHash,
        pinCode: pin,
      })
      onSuccessfulLogin?.()
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setErrorMessage(
            'Der eingegeben Pin ist leider ungültig. Bitte überprüfen Sie die Eingabe.',
          )
        } else {
          setErrorMessage(error.message)
        }
      } else {
        setErrorMessage('Es ist ein unbekannter Fehler aufgetreten.')
      }
    } finally {
      setLoading(false)
    }
  }

  if (!twoFactorHash) {
    return <Navigate to={'/login'} />
  }

  return (
    <div>
      <Formik<Login2FADaten>
        initialValues={{ pin: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik): ReactElement => (
          <form onSubmit={formik.handleSubmit}>
            <LoginFormTextField<Login2FADaten> name={'pin'} label={'2FA Pin'} />
            <div className={styles.buttonBox}>
              <LoadingButton
                loading={loading}
                variant={'contained'}
                loadingPosition={'end'}
                endIcon={<LoginIcon />}
                color={'primary'}
                type={'submit'}
                fullWidth
              >
                PIN Verifizieren
              </LoadingButton>
            </div>
          </form>
        )}
      </Formik>
      {errorMessage && (
        <Alert severity={'error'} className={styles.errorBox}>
          {errorMessage}
        </Alert>
      )}
    </div>
  )
}

type Login2FADaten = {
  pin: string
}

const validationSchema = yup.object({
  pin: yup
    .string()
    .min(
      1,
      'Bitte geben Sie den 2-Faktor Authentifizierungspin ein, den Sie per E-Mail erhalten haben.',
    )
    .required(
      'Bitte geben Sie den 2-Faktor Authentifizierungspin ein, den Sie per E-Mail erhalten haben.',
    ),
})
