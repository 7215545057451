import React, { ReactElement } from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import clsx from 'clsx'

import companyChallenges from 'assets/images/guidance/company/company_challenges.jpg'
import companyEducation from 'assets/images/guidance/company/company_education.jpg'
import companyFinancialGuidance from 'assets/images/guidance/company/company_financial_guidance.png'
import companyManPower from 'assets/images/guidance/company/company_manpower.jpg'
import companySlogan from 'assets/images/guidance/company/company_slogan.jpg'
import companyLogo from 'assets/images/guidance/overview/logo-mitnorm-firmenberatung.png'
import styles from 'components/guidance/company/GuidanceCompany1Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { Paths } from 'configurations/paths'

export const GuidanceCompany1Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={3}
      menuSubIndex={0}
      previousPagePath={Paths.guidance}
      previousPageButtonText={'Zur Übersicht'}
      nextPagePath={Paths.externalUnternehmerDialog}
      nextPageButtonText='Zum Unternehmerdialog'
      guidanceColor='company'
    >
      <Stack className={styles.page}>
        <Box className={styles.logo}>
          <img src={companyLogo} alt='Logo' />
        </Box>
        <Stack className={styles.cardsSection}>
          <h4 className={styles.cardsSectionTitle}>
            In welchen Bereichen unterstützt dich dein Arbeitgeber bisher?
          </h4>
          <Stack direction='row' gap={4} flexWrap='wrap' className={styles.cardsContainer}>
            <Stack gap={4}>
              <Card className={clsx(styles.card, styles.cardLighter)}>
                <Typography className={styles.cardText}>Persönlichkeits&shy;entwicklung</Typography>
              </Card>
              <Card className={clsx(styles.card, styles.cardLighter)}>
                <Typography className={styles.cardText}>
                  Betriebliche Gesundheitsvorsorge
                </Typography>
              </Card>
            </Stack>
            <Stack gap={4}>
              <Card className={clsx(styles.card, styles.cardLight)}>
                <Typography className={styles.cardText}>Fachliche Fortbildung</Typography>
              </Card>
              <Card className={clsx(styles.card, styles.cardLight)}>
                <Typography className={styles.cardText}>
                  Beitrag für die Pflegeversicherung
                </Typography>
              </Card>
            </Stack>
            <Stack gap={4}>
              <Card className={clsx(styles.card)}>
                <Typography className={styles.cardText}>Betriebliche Altersvorsorge</Typography>
              </Card>
              <Card className={clsx(styles.card)}>
                <Typography className={styles.cardText}>
                  Unterstützung der Einkommensabsicherung
                </Typography>
              </Card>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={styles.manPowerSection}>
          <Box className={styles.manPowerTitleContainer}>
            <h3 className={styles.manPowerTitle}>
              7,5 Millionen Gründe, weshalb die Arbeitgeber ihre Mitarbeiter im Bereich Karriere und
              Finanzen unterstützen sollten!
            </h3>
          </Box>
          <Box className={styles.manPowerChart}>
            <img src={companyManPower} alt='Arbeitskräftebedarf Diagramm' />
          </Box>
        </Stack>
        <Stack className={styles.blogSection}>
          <h3 className={styles.blogTitle}>HERAUSFORDERUNGEN AM MARKT</h3>
          <div className={styles.blogSeparator}></div>
          <Box className={styles.blogChallengesArticle}>
            <img src={companyChallenges} alt='Artikel' />
          </Box>
          <h4 className={styles.blogText}>
            85% der Arbeitnehmer haben nur eine geringe oder gar keine Bindung zu ihrem Arbeitgeber.
            Hinzu kommt Fach- und Führungskräftemangel, die Globalisierung und die damit verbunden
            Erreichbarkeit, die die Unternehmen auch in Zukunft vor Herausforderungen stellt.
          </h4>
        </Stack>
        <Stack className={styles.blogSection}>
          <h3 className={styles.blogTitle}>UNTERNEHMEN STARK GEMACHT</h3>
          <div className={styles.blogSeparator}></div>
          <h4 className={styles.blogText}>
            Und genau da setzen wir mit unseren vier Kernbereichen an: Mitarbeiterfindung, -Bindung,
            -Entwicklung und die Absicherungen des Unternehmens.
          </h4>
          <Box className={styles.blogFinancialGuidance}>
            <img src={companyFinancialGuidance} alt='Financial Guidance' />
          </Box>
          <h4 className={styles.blogText}>
            Betriebe mit Weiterbildung haben im Schnitt eine 16 % höhere Arbeitsproduktivität.
            Daraus resultiert, dass bei einer durchschnittlichen Produktivität von 70.110 €, 11.200
            € dazu kommen und das bei einer Investition von durchschnittlich nur 2.000 € pro
            Mitarbeiter im Jahr.
          </h4>
          <Box className={styles.blogEducation}>
            <img src={companyEducation} alt='Ausbildung' />
          </Box>
        </Stack>
        <Box className={styles.slogan}>
          <img src={companySlogan} alt='Slogan' />
        </Box>
      </Stack>
    </GuidanceSubPage>
  )
}
