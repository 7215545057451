import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { BackendPaths } from 'configurations/backendPaths'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { CreateVertrag, Vertrag } from 'interfaces/mynorm-api-model-interfaces'

export const useCreateVertrag = (): UseMutationResult<
  Vertrag,
  AxiosError<{ message?: string } | undefined>,
  CreateVertrag
> => {
  const { addVertrag: addVertragToHaushalt } = useHaushaltState()

  return useMutation((vertrag: CreateVertrag) => createVertrag(vertrag), {
    onSuccess: (vertrag) => {
      addVertragToHaushalt(vertrag)
    },
  })
}

export const createVertrag = async (vertrag: CreateVertrag): Promise<Vertrag> => {
  const result = await axios.post<Vertrag>(`${BackendPaths.createVertrag}`, vertrag)
  return result.data
}
