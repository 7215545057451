import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import { Alert, Box, Button, CircularProgress, Stack } from '@mui/material'
import clsx from 'clsx'

import { CircularDisplay, DinScoreLabel, SaldoLabel } from 'components/cockpit/CircularDisplays'
import styles from 'components/cockpit/Cockpit.module.scss'
import { CockpitGoalsCard } from 'components/cockpit/CockpitGoalsCard'
import { CockpitInsuranceCard } from 'components/cockpit/CockpitInsuranceCard'
import { CockpitPersonCard } from 'components/cockpit/CockpitPersonCard'
import { DinScoreCard } from 'components/cockpit/DinScoreCard'
import { DinReportButton } from 'components/cockpit/report/DinReportButton'
import { Logo } from 'components/logo/Logo'
import { useGetDinScore } from 'hooks/useGetDinScore'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const Cockpit = (): ReactElement => {
  const navigate = useNavigate()
  const [personCardDialogOpen, setPersonCardDialogOpen] = useState(false)
  const [insuranceCardDialogOpen, setInsuranceCardDialogOpen] = useState(false)
  const [goalsCardDialogOpen, setGoalsCardDialogOpen] = useState(false)
  const enableFetchDinScore =
    !personCardDialogOpen && !insuranceCardDialogOpen && !goalsCardDialogOpen
  const { data: dinScore, isFetching } = useGetDinScore(enableFetchDinScore)
  const { customerId, latestHaushalt } = useHaushaltState()

  const haushaltScorePercent = (dinScore?.scoreHaushalt?.gesamt.score || 0) * 100
  return (
    <Stack className={styles.cockpit}>
      {!dinScore && !isFetching && (
        <Box className={styles.error}>
          <Alert severity={'error'}>Beim Laden des DIN Scores ist ein Fehler aufgetreten.</Alert>
        </Box>
      )}
      <Stack className={styles.cardRow}>
        <CockpitPersonCard
          dinScore={dinScore}
          haushalt={latestHaushalt}
          dialogOpen={personCardDialogOpen}
          setDialogVisibility={setPersonCardDialogOpen}
        />
        <CockpitInsuranceCard
          haushalt={latestHaushalt}
          dialogOpen={insuranceCardDialogOpen}
          setDialogVisibility={setInsuranceCardDialogOpen}
        />
        <CockpitGoalsCard
          dialogOpen={goalsCardDialogOpen}
          setDialogVisibility={setGoalsCardDialogOpen}
        />
      </Stack>
      <Box className={styles.steeringWheel}>
        <Box className={styles.wheel}>
          <Box className={styles.wheelTopAccent}></Box>
          <Box className={styles.wheelProgressContainer}>
            <Box>
              <CircularDisplay
                value={0}
                label={
                  <SaldoLabel saldo={dinScore?.scoreHaushalt.finanzBilanz.liquiditaet2.geldwert} />
                }
              />
            </Box>
            <Box className={styles.wheelLogo}>
              {isFetching && (
                <Box>
                  <CircularProgress />
                </Box>
              )}
              {!isFetching && <Logo />}
            </Box>
            <Box>
              <CircularDisplay
                value={haushaltScorePercent}
                label={<DinScoreLabel score={haushaltScorePercent} />}
              />
            </Box>
          </Box>
        </Box>
        <Box className={styles.wheelHandles}>
          <Box className={clsx(styles.buttonContainer, styles.leftButtonContainer)}>
            <Box className={styles.greenBox}></Box>
            <Button
              variant='contained'
              disableRipple
              className={styles.customerDataButton}
              startIcon={<ArrowCircleLeftOutlinedIcon className={styles.buttonIcon} />}
              onClick={() => navigate(`/kunde/${customerId}/daten`)}
            >
              Kundendaten
            </Button>
          </Box>
          <Box className={clsx(styles.buttonContainer, styles.rightButtonContainer)}>
            <Box className={styles.greenBox}></Box>
            <DinReportButton haushalt={latestHaushalt} />
          </Box>
        </Box>
      </Box>
      <Stack className={styles.cardRow}>
        <DinScoreCard
          level={1}
          label={'Sicherung finanzieller Grundbedarf'}
          score={dinScore?.scoreHaushalt.stufe1.score}
          results={dinScore}
          haushalt={latestHaushalt}
        />
        <DinScoreCard
          level={2}
          label={'Erhaltung des Lebensstandards'}
          score={dinScore?.scoreHaushalt.stufe2.score}
          results={dinScore}
          haushalt={latestHaushalt}
        />
        <DinScoreCard
          level={3}
          label={'Verbesserung des Lebensstandards'}
          score={dinScore?.scoreHaushalt.stufe3.score}
          results={dinScore}
          haushalt={latestHaushalt}
        />
      </Stack>
    </Stack>
  )
}
