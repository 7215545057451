import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { BackendPaths } from 'configurations/backendPaths'
import { MyviUserConfig } from 'interfaces/mynorm-api-model-interfaces'

export const useFetchUserConfig = (): UseQueryResult<MyviUserConfig | null> => {
  return useQuery(userConfigQueryOptions)
}

const fetchUserConfig = async (): Promise<MyviUserConfig | null> => {
  try {
    const response = await axios.get<MyviUserConfig>(BackendPaths.userConfig)
    return response.data
  } catch (e) {
    return null
  }
}

export const userConfigQueryOptions: UseQueryOptions<MyviUserConfig | null> = {
  queryKey: ['fetchUserConfig'],
  queryFn: fetchUserConfig,
  staleTime: Infinity,
}
