import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { bundeslandOptions } from 'form/kundeOptions'

type HaushaltPersonAdresseFormFieldsProps = {
  selectiveFieldProps?: SelectiveInputProps
}

export const HaushaltPersonAnschriftFormFields = ({
  selectiveFieldProps,
}: HaushaltPersonAdresseFormFieldsProps): ReactElement => {
  return (
    <>
      <Grid item xs={8}>
        <TextInput
          name={`anschrift.strasse`}
          label={'Straße'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          name={`anschrift.hausnummer`}
          label={'Hausnummer'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          name={`anschrift.postleitzahl`}
          label={'Postleitzahl'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={8}>
        <TextInput name={`anschrift.ort`} label={'Ort'} selectiveFieldProps={selectiveFieldProps} />
      </Grid>
      <Grid item xs={8}>
        <SelectInput
          name={`anschrift.bundesland`}
          label={'Bundesland'}
          selectiveFieldProps={selectiveFieldProps}
          options={bundeslandOptions}
        />
      </Grid>
      <Grid item xs={6}></Grid>
    </>
  )
}
