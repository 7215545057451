import React, { ReactElement } from 'react'
import { Add } from '@mui/icons-material'
import { Button, ButtonGroup, Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik'
import produce from 'immer'

import { useFetchRisikoTypen } from 'api/useFetchRisikoTypen'
import { RemoveIconButton } from 'components/controls/buttons/RemoveIconButton'
import { EinzelPraemienBlock } from 'components/personal/absicherung2/blocks/EinzelPraemienBlock'
import styles from 'components/personal/absicherung2/blocks/RisikenFormBlock.module.scss'
import { VersicherteFahrzeugeBlock } from 'components/personal/absicherung2/blocks/VersicherteFahrzeugeBlock'
import { VersicherteImmobilieBlock } from 'components/personal/absicherung2/blocks/VersicherteImmobilieBlock'
import { VersichertePersonenBlock } from 'components/personal/absicherung2/blocks/VersichertePersonenBlock'
import { VersicherteReiseBlock } from 'components/personal/absicherung2/blocks/VersicherteReiseBlock'
import { VersicherterHaushaltBlock } from 'components/personal/absicherung2/blocks/VersicherterHaushaltBlock'
import { VersichertesObjektBlock } from 'components/personal/absicherung2/blocks/VersichertesObjektBlock'
import { VersichertesSonstigesObjektBlock } from 'components/personal/absicherung2/blocks/VersichertesSonstigesObjektBlock'
import { VersichertesTierBlock } from 'components/personal/absicherung2/blocks/VersichertesTierBlock'
import {
  determineAvailableRisikoTypesForFinfireProduktId,
  determinePermittedFahrzeugTypes,
} from 'components/personal/absicherung2/blocks/VertragsProdukteFormUtil'
import { ImmobilieFormModel, SachwertFormModel } from 'form/KundeFormModel'
import { createUnfallDetailsFormModel } from 'form/vertraege/details/DetailsCreator'
import {
  RisikoFormModel,
  UnfallDetailsFormModel,
  VertragFormModel,
  VertragsProduktFormModel,
} from 'form/vertraege/VertragFormModel'
import { createRisikoFormModel } from 'form/vertraege/VertragFormModelCreator'
import { PraemienArt, RisikoTyp } from 'interfaces/mynorm-api-model-interfaces'
import { useImmobilien } from 'state/useImmobilien'
import {
  isRelevantForDetailsTypAndFinfireProduktId,
  useProduktMappings,
} from 'state/useProduktConfig'
import { useSachwerte } from 'state/useSachwerte'

type RisikenFormBlockProps = {
  vertragsProduktIndex: number
  vertragsProduktFormModel: VertragsProduktFormModel
  vertragFormModel: VertragFormModel
}

const addingRisikoIsPossible = (
  risikoTyp: RisikoTyp,
  immobilien: ImmobilieFormModel[],
  sachwerte: SachwertFormModel[],
): boolean => {
  if (!risikoTyp) {
    return false
  }
  switch (risikoTyp) {
    case 'IMMOBILIE':
      return immobilien.length > 0
    case 'FAHRZEUG':
      return sachwerte.length > 0
    default:
      return true
  }
}
export const RisikenFormBlock = ({
  vertragsProduktIndex,
  vertragsProduktFormModel,
  vertragFormModel,
}: RisikenFormBlockProps): ReactElement => {
  const { setFieldValue } = useFormikContext()
  const { vertragGeschaeft, produkte, praemienArt } = vertragFormModel
  const { data: finfireRisikoTypMapping } = useFetchRisikoTypen(vertragGeschaeft, produkte)
  const produktId = vertragsProduktFormModel.produkt.produktId
  const produktMappings = useProduktMappings()
  const { immobilien } = useImmobilien()
  const { sachwerte } = useSachwerte()
  const permittedSachwertTypes = determinePermittedFahrzeugTypes(produktId, produktMappings)
  const availableSachwerte = sachwerte.filter((sachwert) =>
    permittedSachwertTypes.includes(sachwert.sachwertTyp),
  )
  const availableRisikoTypes = determineAvailableRisikoTypesForFinfireProduktId(
    produktId,
    produktMappings,
    finfireRisikoTypMapping,
  )
  const isFremdvertrag = vertragFormModel.fremdvertrag

  const addRisiko = (
    formArrayProps: FieldArrayRenderProps,
    produktId: string,
    risikoTyp: RisikoTyp,
  ): void => {
    if (!availableRisikoTypes?.length) {
      return
    }
    const risikoFormModel = createRisikoFormModel(risikoTyp)
    formArrayProps.push(risikoFormModel)
    if (isRelevantForDetailsTypAndFinfireProduktId('UNFALL', produktId)) {
      setFieldValue(
        `vertragsProdukte.${vertragsProduktIndex}.details.unfallDetails`,
        addUnfallDetail(vertragsProduktFormModel.details.unfallDetails, risikoFormModel),
      )
    }
  }

  const removeRisiko = (
    risikoIndex: number,
    formArrayProps: FieldArrayRenderProps,
    produktId: string,
  ): void => {
    formArrayProps.remove(risikoIndex)
    if (isRelevantForDetailsTypAndFinfireProduktId('UNFALL', produktId)) {
      setFieldValue(
        `vertragsProdukte.${vertragsProduktIndex}.details.unfallDetails`,
        removeUnfallDetail(vertragsProduktFormModel.details.unfallDetails, risikoIndex),
      )
    }
  }

  return (
    <FieldArray name={`vertragsProdukte.${vertragsProduktIndex}.risiken`}>
      {(fieldArrayProps) => {
        return (
          <Grid container rowGap={2}>
            {vertragsProduktFormModel.risiken.map((risiko, risikoIndex) => {
              const name = `${fieldArrayProps.name}.${risikoIndex}`
              const deletable = vertragsProduktFormModel.risiken.length > 1
              return (
                <Grid item xs={12} key={risiko.risikoId}>
                  <RisikoFormBlock
                    name={name}
                    risiko={risiko}
                    risikoIndex={risikoIndex}
                    deletable={deletable}
                    praemienArt={praemienArt}
                    produktId={produktId}
                    onRemove={(risikoIndex) =>
                      removeRisiko(risikoIndex, fieldArrayProps, produktId)
                    }
                    disabled={!isFremdvertrag}
                  />
                </Grid>
              )
            })}
            <Grid item xs={12} container justifyContent='center' alignItems='center'>
              <Stack>
                <Typography variant='overline' color='primary'>
                  Versichertes Risiko hinzufügen:
                </Typography>
                <ButtonGroup className={styles.addRisikoButtonGroup}>
                  {availableRisikoTypes.map((risikoTyp) => {
                    return (
                      <Button
                        key={risikoTyp}
                        disabled={
                          !isFremdvertrag ||
                          !addingRisikoIsPossible(risikoTyp, immobilien, availableSachwerte)
                        }
                        startIcon={<Add />}
                        variant='outlined'
                        size='small'
                        onClick={() => addRisiko(fieldArrayProps, produktId, risikoTyp)}
                      >
                        {`${risikoTyp}`}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </Stack>
            </Grid>
          </Grid>
        )
      }}
    </FieldArray>
  )
}

type RisikoFormBlockProps = {
  name: string
  risiko: RisikoFormModel
  praemienArt: PraemienArt
  produktId: string
  deletable: boolean
  risikoIndex: number
  onRemove: (risikoIndex: number) => void
  disabled: boolean
}

export const RisikoFormBlock = ({
  name,
  risiko,
  risikoIndex,
  deletable,
  praemienArt,
  produktId,
  onRemove,
  disabled,
}: RisikoFormBlockProps): ReactElement => {
  return (
    <Grid
      container
      style={{
        borderLeft: '0.25rem solid #b9b9b9',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
        paddingLeft: '0.5rem',
      }}
    >
      <Grid item md={11} xs={5} container rowGap={2}>
        <VersicherteImmobilieBlock
          name={name}
          risiko={risiko}
          produktId={produktId}
          disabled={disabled}
        />
        <VersichertesSonstigesObjektBlock name={name} risiko={risiko} disabled={disabled} />
        <VersichertePersonenBlock name={name} risiko={risiko} disabled={disabled} />
        <VersicherteFahrzeugeBlock
          name={name}
          risiko={risiko}
          produktId={produktId}
          disabled={disabled}
        />
        <VersicherterHaushaltBlock name={name} risiko={risiko} disabled={disabled} />
        <VersichertesTierBlock name={name} risiko={risiko} disabled={disabled} />
        <VersicherteReiseBlock name={name} risiko={risiko} disabled={disabled} />
        <VersichertesObjektBlock name={name} risiko={risiko} disabled={disabled} />
        <EinzelPraemienBlock
          name={name}
          einzelPraemien={risiko.einzelPraemien}
          praemienArt={praemienArt}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={1} container alignItems='flex-start'>
        {deletable && (
          <RemoveIconButton onClick={() => onRemove(risikoIndex)} disabled={disabled} />
        )}
      </Grid>
    </Grid>
  )
}

export type VersichertesRisikoBlockProps = {
  name: string
  risiko: RisikoFormModel
  disabled?: boolean
}

const addUnfallDetail = (
  unfallDetails: UnfallDetailsFormModel[],
  risikoFormModel: RisikoFormModel,
): UnfallDetailsFormModel[] => {
  const unfallModel = createUnfallDetailsFormModel(risikoFormModel)
  return produce(unfallDetails, (unfallDetailsDraft) => {
    unfallDetailsDraft.push(unfallModel)
  })
}

const removeUnfallDetail = (
  unfallDetails: UnfallDetailsFormModel[],
  risikoIndex: number,
): UnfallDetailsFormModel[] => {
  return produce(unfallDetails, (unfallDetailsDraft) => {
    unfallDetailsDraft.splice(risikoIndex, 1)
  })
}
