import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import produce from 'immer'

import { HaushaltUpdateError, putHaushalt } from 'api/haushalt/useUpdateHaushalt'
import { mergePartnerIntoHaushalt, mergePartnerIntoKundeFormModel } from 'api/mergePartner'
import { removeVermoegenFromHaushalt } from 'api/mergeVermoegen'
import { KundeFormModel } from 'form/KundeFormModel'
import { useHaushaltState } from 'hooks/useHaushaltState'
import {
  Beziehungsstatus,
  HaushaltAggregate,
  PersonAggregate,
} from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'

type AddPartnerModel = {
  haushalt: HaushaltAggregate
  partner: PersonAggregate
  beziehungsStatus: Beziehungsstatus
}

export const useAddPartnerOfHaushalt = (): UseMutationResult<
  HaushaltAggregate,
  AxiosError<HaushaltUpdateError>,
  AddPartnerModel
> => {
  const { setHaushaltPartially } = useHaushaltState()
  const { editableKunde } = useEditableKunde()
  return useMutation(async (addPartnerModel) => {
    const haushaltWithPartner = produce(addPartnerModel.haushalt, (draft: HaushaltAggregate) => {
      draft.personen.push(addPartnerModel.partner)
      draft.beziehungsstatus = addPartnerModel.beziehungsStatus
    })
    return await mergeHaushaltAndSave(haushaltWithPartner, editableKunde, setHaushaltPartially)
  })
}

export const useRemovePartnerOfHaushalt = (): UseMutationResult<
  HaushaltAggregate,
  AxiosError<HaushaltUpdateError>,
  HaushaltAggregate
> => {
  const { setHaushaltPartially } = useHaushaltState()
  const { editableKunde } = useEditableKunde()
  return useMutation(async (haushaltToSave) => {
    const haushaltWithoutPartner = produce(haushaltToSave, (draft: HaushaltAggregate) => {
      draft.personen[1].person.trennungFlag = true
      draft.auslandGeplant = false
      draft.gemeinsamesVermoegen = false
    })
    return await mergeHaushaltAndSave(haushaltWithoutPartner, editableKunde, setHaushaltPartially)
  })
}

async function mergeHaushaltAndSave(
  changedHaushalt: HaushaltAggregate,
  editableKunde: KundeFormModel,
  setHaushaltPartially: (
    originalHaushalt: HaushaltAggregate,
    editableHaushalt: KundeFormModel,
  ) => void,
): Promise<HaushaltAggregate> {
  const responseHaushalt = await putHaushalt(removeVermoegenFromHaushalt(changedHaushalt))

  const mergedOriginalHaushalt = mergePartnerIntoHaushalt(changedHaushalt, responseHaushalt)
  const mergedEditableFormModel = mergePartnerIntoKundeFormModel(editableKunde, responseHaushalt)

  setHaushaltPartially(mergedOriginalHaushalt, mergedEditableFormModel)
  return responseHaushalt
}
