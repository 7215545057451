import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel, FormCheckboxCell, FormTextCell } from 'components/controls/cells/FormCell'
import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { AnlegerprofilAndereAnlagenFormModel } from 'form/KundeFormModel'
import { anlegerprofilAndereAnlagenInitialTouched } from 'form/kundeFormTouched'
import { anlegerprofilAndereAnlagenValidationSchema } from 'form/kundeFormValidationSchema'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalAnlegerProfilAndereAnlagenFormProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
  isPartnerAvailable?: boolean
}

export const PersonalAnlegerProfilAndereAnlagenForm = ({
  personIndex,
  selectiveFormProps,
  isPartnerAvailable = false,
}: PersonalAnlegerProfilAndereAnlagenFormProps): ReactElement => {
  const { anlegerprofilMutator } = useEditableKunde()
  const { initialFormValues, selectiveFieldProps } =
    useSelectiveKundeForm<AnlegerprofilAndereAnlagenFormModel>(
      `personen.${personIndex}.anlegerprofil.andereAnlagen`,
      selectiveFormProps,
    )
  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(andereAnlagen) => {
        anlegerprofilMutator.updateAndereAnlagen(personIndex, andereAnlagen)
      }}
      validationSchema={anlegerprofilAndereAnlagenValidationSchema}
      initialTouched={anlegerprofilAndereAnlagenInitialTouched}
      validateOnMount
    >
      {() => (
        <Table>
          <TableHead>
            <TableRow>
              <FormCellLabel $highlighted>Kenntnisse</FormCellLabel>
              <FormCellLabel $highlighted>Anzahl p.a.</FormCellLabel>
              <FormCellLabel $border={isPartnerAvailable} $highlighted>
                Umsatz p.a.
              </FormCellLabel>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sparTagesTermingeldKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sparTagesTermingeldAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sparTagesTermingeldUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'immobilienKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'immobilienAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'immobilienUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sonstigesKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sonstigesAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilAndereAnlagenFormModel>
                  name={'sonstigesUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </EditableKundeFormik>
  )
}
