import React, { ReactElement } from 'react'
import Typography from '@mui/material/Typography'

type BlockTitleProps = {
  title: string
}

export const DetailReadViewBlockTitle = ({ title }: BlockTitleProps): ReactElement => {
  return <Typography variant='overline'>{title}</Typography>
}

export const DetailFormBlockTitle = ({ title }: BlockTitleProps): ReactElement => {
  return (
    <Typography variant='overline' color='primary'>
      {title}
    </Typography>
  )
}
