import React, { ReactElement } from 'react'
import { Box, Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import styles from 'components/personal/absicherung2/blocks/details/UnfallDetailsFormBlock.module.scss'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { HaftpflichtDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { DeckungssummeModus, HaftpflichtDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

export const deckungssummeModusOptions: Option<DeckungssummeModus>[] = [
  { value: 'DETAILLIERT', label: 'Detailliert' },
  { value: 'PAUSCHAL', label: 'Pauschal' },
  { value: 'UNBEGRENZT', label: 'Unbegrenzt' },
]

const FORM_BLOCK_TITLE = 'Details - Haftpflicht'

type HaftpflichtFormBlockProps = BaseDetailsFormBlockProps & {
  haftpflichtDetails: HaftpflichtDetailsFormModel | undefined
}

export const HaftpflichtDetailsFormBlock = ({
  name,
  produktId,
  haftpflichtDetails,
}: HaftpflichtFormBlockProps): ReactElement | null => {
  const baseName = `${name}.haftpflichtDetails`

  if (!isRelevantForDetailsTypAndFinfireProduktId('HAFTPFLICHT', produktId)) {
    return null
  }

  const isPauschal = haftpflichtDetails?.deckungssummeModus === 'PAUSCHAL'
  const isDetailliert = haftpflichtDetails?.deckungssummeModus === 'DETAILLIERT'

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          name={`${baseName}.deckungssummeModus`}
          label={'Modus Deckungssumme'}
          options={deckungssummeModusOptions}
        />
      </Grid>
      {isPauschal && (
        <Grid item xs={6}>
          <NumberInput
            name={`${baseName}.deckungssummePauschal`}
            label={'Deckungssumme Pauschal'}
            inputUnit='€'
          />
        </Grid>
      )}
      {isDetailliert && (
        <>
          <Box className={styles.gridBreak} />
          <Grid item md={4} xs={12}>
            <NumberInput
              name={`${baseName}.deckungssummePersonen`}
              label={'Deckungssumme Personen'}
              inputUnit='€'
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <NumberInput
              name={`${baseName}.deckungssummeSach`}
              label={'Deckungssumme Sachen'}
              inputUnit='€'
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <NumberInput
              name={`${baseName}.deckungssummeVermoegen`}
              label={'Deckungssumme Vermögen'}
              inputUnit='€'
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

type HaftpflichtDetailsReadViewProps = BaseDetailsReadViewProps & {
  haftpflichtDetails: HaftpflichtDetails | undefined
}

export const HaftpflichtDetailsReadView = ({
  produktId,
  haftpflichtDetails,
}: HaftpflichtDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('HAFTPFLICHT', produktId)) {
    return null
  }

  const isPauschal = haftpflichtDetails?.deckungssummeModus === 'PAUSCHAL'
  const isDetailliert = haftpflichtDetails?.deckungssummeModus === 'DETAILLIERT'
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Modus Deckungssumme'}
        value={getOptionLabel(haftpflichtDetails?.deckungssummeModus, deckungssummeModusOptions)}
      />
      {isPauschal && (
        <LabelValueEntry
          label={'Deckungssumme Pauschal'}
          value={formatCurrency(haftpflichtDetails?.deckungssummePauschal)}
        />
      )}
      {isDetailliert && (
        <>
          <LabelValueEntry
            label={'Deckungssumme Personen'}
            value={formatCurrency(haftpflichtDetails?.deckungssummePersonen)}
          />
          <LabelValueEntry
            label={'Deckungssumme Sachen'}
            value={formatCurrency(haftpflichtDetails?.deckungssummeSach)}
          />
          <LabelValueEntry
            label={'Deckungssumme Vermögen'}
            value={formatCurrency(haftpflichtDetails?.deckungssummeVermoegen)}
          />
        </>
      )}
    </Stack>
  )
}
