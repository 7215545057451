import React, { ReactElement, ReactNode, useEffect } from 'react'
import { Formik, FormikProps, FormikTouched, FormikValues, useFormikContext } from 'formik'
import * as yup from 'yup'

import { useDebounce } from 'hooks/use-debounce'

type EditableKundeFormikProps<FormType> = {
  children: (values: FormikProps<FormType>) => ReactNode
  initialValues: FormType
  validationSchema?: yup.AnySchema<Partial<FormType>>
  updateState: (value: FormType) => void
  validateOnMount?: boolean
  initialTouched?: FormikTouched<FormType>
  onSubmit?: (values: FormType) => void
}

export const EditableKundeFormik = <FormType extends FormikValues>({
  children,
  initialValues,
  validationSchema,
  updateState,
  validateOnMount,
  initialTouched,
  onSubmit,
}: EditableKundeFormikProps<FormType>): ReactElement => {
  return (
    <Formik<FormType>
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit?.(values)
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={validateOnMount}
      initialTouched={initialTouched}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            <FormObserver onUpdate={updateState} />
            {children(formikProps)}
          </>
        )
      }}
    </Formik>
  )
}

type ObserverProps<FormType> = {
  onUpdate: (formModel: FormType) => void
}

const FormObserver = <FormType,>({ onUpdate }: ObserverProps<FormType>): null => {
  const { values } = useFormikContext<FormType>()
  const debouncedValues = useDebounce(values, 150)
  useEffect(() => {
    onUpdate(values)
  }, [debouncedValues])

  return null
}
