import * as yup from 'yup'

import { KontaktdatenFormModel, NewKundeFormModel } from 'form/KundeFormModel'
import { YupShape } from 'form/kundeFormValidationSchema'
import { Beziehungsstatus } from 'interfaces/mynorm-api-model-interfaces'
import {
  EMAIL_ERROR_TEXT,
  REQUIRED_TEXT,
  STEUERID_LENGTH_TEXT,
  todayOrInThePast,
} from 'utils/validationUtil'

export const newKundeValidationSchema = yup.object().shape<YupShape<NewKundeFormModel>>({
  vorname: yup.string().trim().required(REQUIRED_TEXT),
  nachname: yup.string().trim().required(REQUIRED_TEXT),
  geburtsdatum: todayOrInThePast(),
  kontaktdaten: yup.object().shape<YupShape<KontaktdatenFormModel>>({
    telefon: yup.string(),
    email: yup.string().email(EMAIL_ERROR_TEXT),
  }),
  steuerIdentifikationsnummer: yup.string().length(11, STEUERID_LENGTH_TEXT),
})

export const newPartnerValidationSchema = yup.object().shape<YupShape<NewKundeFormModel>>({
  vorname: yup.string().trim().required(REQUIRED_TEXT),
  nachname: yup.string().trim().required(REQUIRED_TEXT),
  geburtsdatum: todayOrInThePast(),
  beziehungsStatus: yup.mixed<Beziehungsstatus>().required(REQUIRED_TEXT),
})
