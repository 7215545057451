import produce from 'immer'
import create from 'zustand'

import { KundeFormModel, PersonFormModel } from 'form/KundeFormModel'
import { createDefaultKundeFormModel } from 'form/kundeFormModelCreator'
import { AnlegerprofilMutator, anlegerprofilMutator } from 'state/anlegerprofilMutator'
import { BeteiligungenMutator, beteiligungenMutator } from 'state/beteiligungenMutator'
import { DarlehenMutator, darlehenMutator } from 'state/darlehenMutator'
import { GeldAnlagenMutator, geldAnlagenMutator } from 'state/geldAnlagenMutator'
import {
  GesetzlicheVersorgungMutator,
  gesetzlicheVersorgungMutator,
} from 'state/gesetzlicheVersorgungMutator'
import { HaushaltMutator, haushaltMutator } from 'state/haushaltMutator'
import { ImmobilienMutator, immobilienMutator } from 'state/immobilienMutator'
import { KinderMutator, kinderMutator } from 'state/kinderMutator'
import { SachwerteMutator, sachwerteMutator } from 'state/sachwerteMutator'
import { ZieleMutator, zieleMutator } from 'state/zieleMutator'

type EditableKundeType = {
  editableKunde: KundeFormModel
  originalKunde: KundeFormModel
  updatePerson: (personIndex: number, personFormModel: PersonFormModel) => void
  clear: () => void
  haushaltMutator: HaushaltMutator
  kinderMutator: KinderMutator
  immobilienMutator: ImmobilienMutator
  geldAnlagenMutator: GeldAnlagenMutator
  darlehenMutator: DarlehenMutator
  beteiligungenMutator: BeteiligungenMutator
  sachwerteMutator: SachwerteMutator
  gesetzlicheVersorgungMutator: GesetzlicheVersorgungMutator
  zieleMutator: ZieleMutator
  anlegerprofilMutator: AnlegerprofilMutator
  importEditableKunde: (kundeFormModel: KundeFormModel) => void
}

export type Mutator = (
  mutator: (editableKundeType: EditableKundeType) => Partial<EditableKundeType>,
) => void
const defaultKundeFormModel = createDefaultKundeFormModel()
export const useEditableKunde = create<EditableKundeType>((set) => ({
  editableKunde: defaultKundeFormModel,
  originalKunde: defaultKundeFormModel,
  updatePerson: (personIndex: number, personFormModel: PersonFormModel) => {
    set(({ editableKunde }) => {
      return {
        editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
          draft.personen[personIndex] = personFormModel
        }),
      }
    })
  },
  clear: () => {
    set({ editableKunde: defaultKundeFormModel, originalKunde: defaultKundeFormModel })
  },
  haushaltMutator: haushaltMutator(set),
  kinderMutator: kinderMutator(set),
  immobilienMutator: immobilienMutator(set),
  geldAnlagenMutator: geldAnlagenMutator(set),
  darlehenMutator: darlehenMutator(set),
  beteiligungenMutator: beteiligungenMutator(set),
  sachwerteMutator: sachwerteMutator(set),
  gesetzlicheVersorgungMutator: gesetzlicheVersorgungMutator(set),
  zieleMutator: zieleMutator(set),
  anlegerprofilMutator: anlegerprofilMutator(set),
  importEditableKunde: (kundeFormModel: KundeFormModel) => {
    set({
      editableKunde: { ...kundeFormModel, isImported: true },
    })
  },
}))
