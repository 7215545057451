import packageJson from '../../package.json'

type AppBaseConfig = {
  backendBaseUrl: string
  appVersion: string
}

type AppConfigWithHint = {
  showDevelopmentHint: true
  developmentHint?: string
}

type AppConfigWithoutHint = {
  showDevelopmentHint: false
}

type AppConfig = AppBaseConfig & (AppConfigWithHint | AppConfigWithoutHint)

const prodConfig: AppConfig = {
  backendBaseUrl: 'https://api.myvi-guide.de',
  showDevelopmentHint: false,
  appVersion: packageJson?.version || '',
}

const devConfig: AppConfig = {
  backendBaseUrl: 'https://api.dev.myvi-guide.de',
  showDevelopmentHint: true,
  developmentHint: 'DEVELOPMENT VERSION',
  appVersion: packageJson?.version || '',
}

const localConfig: AppConfig = {
  backendBaseUrl: '',
  showDevelopmentHint: true,
  developmentHint: 'LOCAL VERSION',
  appVersion: packageJson?.version || '',
}

const reviewConfig: AppConfig = {
  backendBaseUrl: 'https://api.dev.myvi-guide.de',
  showDevelopmentHint: true,
  developmentHint: (process.env.REACT_APP_MITNORM_ENV || 'BRANCH UNBEKANNT').toUpperCase(),
  appVersion: packageJson?.version || '',
}

enum Environment {
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT',
  LOCAL = 'LOCAL',
}

const getConfig = (): AppConfig => {
  switch (process.env.REACT_APP_MITNORM_ENV as Environment) {
    case Environment.PRODUCTION:
      return prodConfig
    case Environment.DEVELOPMENT:
      return devConfig
    case Environment.LOCAL:
      return localConfig
    default:
      return reviewConfig
  }
}

export const config = Object.freeze(getConfig())
