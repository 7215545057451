import React, { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import styles from 'components/header/MenuElement.module.scss'

type MenuElementProps = {
  title: string
  path: string
}

export const MenuElement = ({ title, path }: MenuElementProps): ReactElement => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const onClick = (): void => {
    navigate(path)
  }

  const isSelected =
    (pathname === '/' && path === '/') || (path !== '/' && pathname.startsWith(path))
  return (
    <Box onClick={onClick} className={styles.menuElement}>
      <Box>
        <CircleIcon className={clsx(styles.circle, { [styles.selected]: isSelected })} />
      </Box>
      <Box>
        <Typography className={clsx(styles.text, { [styles.selected]: isSelected })}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
