import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'

import increaseIncome from 'assets/images/guidance/career/einkommen-erhoehen/einkommen_erhoehen_0.png'
import styles from 'components/guidance/career/GuidanceCareer3Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer3Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={2}
      previousPagePath={Paths.guidanceCareer2}
      nextPagePath={Paths.guidanceCareer4}
      guidanceColor='career'
    >
      <GuidanceSubPageTemplate title='EINKOMMEN ERHÖHEN' subTitle='Das Karriere-Institut'>
        <Stack className={styles.page} direction='row'>
          <Box className={styles.textContainer}>
            <h3 className={styles.text}>
              Bei einer &quot;durchschnittlichen&quot; beruflichen Karriere beträgt der
              wirtschaftliche Vorteil <span className={styles.emphasis}>320.000 €!</span>
            </h3>
          </Box>
          <Box className={styles.imageContainer}>
            <img src={increaseIncome} alt='Einkommensgrafiken' />
          </Box>
        </Stack>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
