import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import backgroundImage from 'assets/images/guidance/career/vorteile-sichern-2/vorteile_sichern_background.png'
import styles from 'components/guidance/career/GuidanceCareer15Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer15Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={14}
      previousPagePath={Paths.guidance}
      previousPageButtonText='Zur Übersicht'
      nextPagePath={Paths.externalKIPortal}
      nextPageButtonText='Zum KI-Portal'
      guidanceColor='career'
      backgroundImage={backgroundImage}
    >
      <GuidanceSubPageTemplate title='JETZT VORTEILE SICHERN' subTitle='Das Karriere-Institut'>
        <Box className={styles.textContainer}>
          <h2 className={styles.text}>
            WER SICH NICHT WEITERBILDET,
            <br />
            DER VERLIERT DEN ANSCHLUSS!
          </h2>
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
