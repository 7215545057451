import React, { ReactElement } from 'react'

import styles from 'components/development-hint/DevelopmentHint.module.scss'
import { config } from 'configurations/appConfig'

const DevelopmentHint = (): ReactElement | null => {
  if (config.showDevelopmentHint) {
    return <div className={styles.warning}>{config.developmentHint}</div>
  }
  return null
}

export default DevelopmentHint
