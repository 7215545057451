import React, { ReactElement } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'

import { useFetchApplicationConfig } from 'api/loaders/applicationConfigLoader'
import { AuthenticationHandler } from 'components/authentication/AuthenticationHandler'
import { Header } from 'components/header/Header'
import { ScrollToTop } from 'components/util/ScrollToTop'

export const RootRoute = (): ReactElement => {
  useFetchApplicationConfig()
  return (
    <AuthenticationHandler>
      <ScrollToTop />
      <Header />
      <Outlet />
    </AuthenticationHandler>
  )
}

export const RootErrorRoute = (): ReactElement => {
  return (
    <Grid container alignItems={'center'} direction={'column'} spacing={2} sx={{ pt: 2 }}>
      <Grid item>
        <Typography>Die angeforderte Seite konnte nicht gefunden werden.</Typography>
      </Grid>
      <Grid item>
        <Button variant={'contained'} color={'primary'} component={Link} to={'/'}>
          Zurück zur Kundenauswahl
        </Button>
      </Grid>
    </Grid>
  )
}
