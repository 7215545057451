import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

import styles from 'components/insurance-count/InsuranceCountItem.module.scss'
import { InsuranceCount } from 'hooks/useInsuranceCount'

type InsuranceCountItemProps = {
  insuranceCount: InsuranceCount
  hasPartner?: boolean
}

export const InsuranceCountItem = ({
  insuranceCount,
  hasPartner,
}: InsuranceCountItemProps): ReactElement => {
  return (
    <Stack direction='row'>
      <div className={styles.insuranceCountLeft}>{insuranceCount.main}</div>
      {hasPartner && (
        <>
          <div className={styles.insuranceCountCenter}>{'+'}</div>
          <div className={styles.insuranceCountRight}>{insuranceCount.partner}</div>
        </>
      )}
    </Stack>
  )
}
