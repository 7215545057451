import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import styles from 'components/guidance/GuidanceTextBullet.module.scss'
import { UnderlineSeparator } from 'components/guidance/UnderlineSeparator'

type GuidanceTextBulletProps = {
  hideContent?: boolean
  children: ReactElement
}

export const GuidanceTextBullet = ({
  hideContent,
  children,
}: GuidanceTextBulletProps): ReactElement => {
  return (
    <Box style={{ minHeight: '138px' }}>
      {!hideContent && (
        <>
          <h2 className={styles.textBulletText}>{children}</h2>
          <UnderlineSeparator />
        </>
      )}
    </Box>
  )
}
