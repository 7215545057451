import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'

import backgroundImage from 'assets/images/guidance/mitnorm/eigene-ziele-erreichen/eigene_ziele_erreichen_background_with_arrow.png'
import backgroundImageFe from 'assets/images/guidance/mitnorm-fe/eigene-ziele-erreichen/eigene_ziele_erreichen_background_with_arrow.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { GuidanceTextBullet } from 'components/guidance/GuidanceTextBullet'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm0Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm0Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={-1}
      nextPagePath={Paths.guidanceMitNorm1}
      guidanceColor='mitnorm'
      backgroundImage={backgroundImage}
      backgroundImageFe={backgroundImageFe}
      backgroundImageClass={styles.backgroundImage}
    >
      <GuidanceSubPageTemplate hideTitleContainer={true}>
        <Stack direction='row' className={styles.boxContainer}>
          <Box className={styles.box1}>
            <h2 className={styles.box1Text}>
              EIGENE ZIELE ERREICHEN UND FINANZIELL SORGENFREI LEBEN
            </h2>
          </Box>
          <Box className={styles.box2}></Box>
          <Box className={styles.box3}>
            <GuidanceTextBullet>
              <>
                Smarter
                <br /> Einsatz der
                <br />
                finanziellen Mittel
              </>
            </GuidanceTextBullet>
          </Box>
        </Stack>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
