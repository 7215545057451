import React, { ReactElement, useState } from 'react'
import AddModeratorIcon from '@mui/icons-material/AddModerator'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FormikProps } from 'formik'

import { useErrorMessage } from 'components/debug/useErrorMessage'
import styles from 'components/personal/absicherung2/dialog/PaginatedFormikDialog.module.scss'
import { config } from 'configurations/appConfig'

const CANCEL_TEXT = 'Abbrechen'
const CLOSE_TEXT = 'Schließen'
const SAVE_TEXT = 'Speichern'

type PaginatedDialogResult = {
  currentPageIndex: number
  nextPage: () => void
  previousPage: () => void
  isOnLastPage: boolean
  isOnFirstPage: boolean
  isNextPageAvailable: boolean
  isPreviousPageAvailable: boolean
  getCurrentPage: () => ReactElement
  reset: () => void
}

const usePaginatedDialog = (pages: ReactElement[]): PaginatedDialogResult => {
  const maxPageIndex = pages.length - 1
  const minPageIndex = 0
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const isOnFirstPage = currentPageIndex === minPageIndex
  const isOnLastPage = currentPageIndex === maxPageIndex
  const isNextPageAvailable = currentPageIndex < maxPageIndex
  const isPreviousPageAvailable = currentPageIndex > minPageIndex
  const nextPage = (): void => {
    if (isNextPageAvailable) {
      setCurrentPageIndex((prev) => prev + 1)
    }
  }
  const previousPage = (): void => {
    if (isPreviousPageAvailable) {
      setCurrentPageIndex((prev) => prev - 1)
    }
  }
  const getCurrentPage = (): ReactElement => {
    return pages[currentPageIndex]
  }

  const reset = (): void => {
    setCurrentPageIndex(minPageIndex)
  }

  return {
    currentPageIndex,
    nextPage,
    previousPage,
    isOnLastPage,
    isOnFirstPage,
    isNextPageAvailable,
    isPreviousPageAvailable,
    getCurrentPage,
    reset,
  }
}

type PaginatedFormikDialogProps<T> = {
  formProps: FormikProps<T>
  pages: ReactElement[]
  title: string
  dialogButtonText: string
  onNext?: (currentPageIndex: number, formProps: FormikProps<T>) => void
  onReset?: () => void
  mutationState?: Pick<
    UseMutationResult<unknown, AxiosError>,
    'isLoading' | 'isError' | 'isSuccess' | 'error' | 'reset'
  >
  successMessage?: string
  errorMessage?: string
  printConsoleAction?: () => void
}

export const PaginatedFormikDialog = <T,>({
  formProps,
  pages,
  dialogButtonText,
  title,
  onNext,
  onReset,
  mutationState,
  successMessage,
  errorMessage,
  printConsoleAction,
}: PaginatedFormikDialogProps<T>): ReactElement => {
  const [open, setOpen] = useState(false)
  const {
    getCurrentPage,
    isNextPageAvailable,
    isPreviousPageAvailable,
    nextPage,
    previousPage,
    currentPageIndex,
    isOnLastPage,
    isOnFirstPage,
    reset,
  } = usePaginatedDialog(pages)

  const handleClose = (event: unknown, reason: string | null): void => {
    if (reason === 'backdropClick') {
      return
    }
    setOpen(false)
  }

  const closeDialog = (): void => {
    handleClose({}, null)
  }
  const handleNextClick = (): void => {
    onNext?.(currentPageIndex, formProps)
    nextPage()
  }

  const handleReset = (): void => {
    reset()
    mutationState?.reset()
    onReset?.()
  }

  const previousPageFromError = (): void => {
    mutationState?.reset()
  }
  return (
    <>
      <Button
        startIcon={<AddModeratorIcon />}
        variant='outlined'
        color='primary'
        onClick={() => setOpen(true)}
      >
        {dialogButtonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='md'
        TransitionProps={{ onExited: handleReset }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {mutationState?.isError && (
            <ErrorPage errorMessage={errorMessage} error={mutationState?.error} />
          )}
          {mutationState?.isSuccess && (
            <Typography>{successMessage || 'Vorgang war erfolgreich.'}</Typography>
          )}
          {!mutationState?.isError && !mutationState?.isSuccess && getCurrentPage()}
        </DialogContent>
        <DialogActions>
          <Stack width='100%' flexDirection='row' justifyContent='space-between'>
            {!mutationState?.isSuccess && (
              <Button
                variant='outlined'
                onClick={closeDialog}
                disabled={mutationState?.isLoading}
                className={styles.button}
              >
                {mutationState?.isSuccess ? CLOSE_TEXT : CANCEL_TEXT}
              </Button>
            )}
            {mutationState?.isSuccess && (
              <Stack flexDirection='row' gap={2} justifyContent='flex-end' width='100%'>
                <Button
                  variant='outlined'
                  onClick={closeDialog}
                  disabled={mutationState?.isLoading}
                  className={styles.button}
                >
                  {CLOSE_TEXT}
                </Button>
              </Stack>
            )}
            {mutationState?.isError && (
              <Stack flexDirection='row' gap={2}>
                <Button
                  variant='outlined'
                  onClick={previousPageFromError}
                  className={styles.button}
                >
                  Zurück
                </Button>
                <Button
                  variant='outlined'
                  onClick={closeDialog}
                  disabled={mutationState?.isLoading}
                  className={styles.button}
                >
                  {CLOSE_TEXT}
                </Button>
              </Stack>
            )}
            {!mutationState?.isSuccess && !mutationState?.isError && (
              <Stack flexDirection='row' gap={2}>
                {!isOnFirstPage && (
                  <Button
                    variant='outlined'
                    onClick={previousPage}
                    disabled={!isPreviousPageAvailable}
                    className={styles.button}
                  >
                    Zurück
                  </Button>
                )}
                {!isOnLastPage && (
                  <Button
                    variant='outlined'
                    onClick={() => handleNextClick()}
                    disabled={!isNextPageAvailable}
                    className={styles.button}
                  >
                    Weiter
                  </Button>
                )}
                {isOnLastPage && config.showDevelopmentHint && printConsoleAction && (
                  <Button variant='outlined' onClick={() => printConsoleAction()}>
                    Print Console
                  </Button>
                )}
                {isOnLastPage && (
                  <LoadingButton
                    variant='outlined'
                    onClick={() => formProps.submitForm()}
                    className={styles.button}
                    loading={mutationState?.isLoading}
                  >
                    {SAVE_TEXT}
                  </LoadingButton>
                )}
              </Stack>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

type ErrorPageProps = {
  errorMessage?: string
  error?: AxiosError | null
}

const ErrorPage = ({ errorMessage, error }: ErrorPageProps): ReactElement => {
  const errorM = useErrorMessage(error)
  const data = error?.response?.data
  return (
    <>
      <Typography color='error'>{errorMessage ?? 'Es ist ein Fehler aufgetreten.'}</Typography>
      <Stack pt={2}>
        <Typography>{errorM}</Typography>
        {config.showDevelopmentHint && (
          <>
            <Typography variant='body2'>Debug Info:</Typography>
            <Typography variant='body2'>Status: {hasMessage(data) ? data.message : ''}</Typography>
            <Typography variant='body2'>
              Message: {error?.response?.status || 'Keine Info verfügbar'}
            </Typography>
          </>
        )}
      </Stack>
    </>
  )
}

const hasMessage = (item: unknown): item is { message: string } => {
  return Object.hasOwn(item as object, 'message')
}
