import { Option } from 'components/controls/inputs/SelectInput'
import {
  PrivathaftpflichtTarifart,
  RechtsschutzTarifart,
} from 'interfaces/mynorm-api-model-interfaces'

export const rechtsschutzTarifartOptions: Option<RechtsschutzTarifart>[] = [
  {
    label: 'Normal',
    value: 'NORMAL',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Familie',
    value: 'FAMILIE',
  },
  {
    label: 'Lebensgemeinschaft',
    value: 'LEBENSGEMEINSCHAFT',
  },
  {
    label: 'Öffentlicher Dienst',
    value: 'OEFFENTLICHER_DIENST',
  },
]

export const privathaftpflichtTarifartOptions: Option<PrivathaftpflichtTarifart>[] = [
  {
    label: 'Normal',
    value: 'NORMAL',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Familie',
    value: 'FAMILIE',
  },
  {
    label: 'Lebensgemeinschaft',
    value: 'LEBENSGEMEINSCHAFT',
  },
  {
    label: 'Öffentlicher Dienst',
    value: 'OEFFENTLICHER_DIENST',
  },
]
