import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart from 'assets/images/guidance/mitnorm/ziele-und-wuensche-2/ziele_und_wuensche_0.png'
import chartFe from 'assets/images/guidance/mitnorm-fe/ziele-und-wuensche-2/ziele_und_wuensche_0.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm2Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm2Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={1}
      previousPagePath={Paths.guidanceMitNorm1}
      nextPagePath={Paths.guidanceMitNorm3}
      guidanceColor='mitnorm'
    >
      <GuidanceSubPageTemplate title={'ZIELE UND WÜNSCHE'} subTitle={'mitNORM'}>
        <Box className={styles.chartContainer}>
          <Box className={styles.chart}>
            <GuidanceMitNormImage src={chart} feSrc={chartFe} alt='Ziele Diagramm' />
          </Box>
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
