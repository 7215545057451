import dayjs from 'dayjs'

import {
  AnlagestrukturDetailsFormModel,
  BerufshaftpflichtDetailsFormModel,
  BerufsunfaehigkeitsDetailsFormModel,
  BetrieblicheAltersvorsorgeDetailsFormModel,
  DetailsFormModel,
  DreadDiseaseDetailsFormModel,
  ErwerbsunfaehigkeitsDetailsFormModel,
  FunktionsinvaliditaetsDetailsFormModel,
  GesetzlicheKrankenversicherungDetailsFormModel,
  GrundfaehigkeitsDetailsFormModel,
  HaftpflichtDetailsFormModel,
  HausratDetailsFormModel,
  InvaliditaetsDetailsFormModel,
  KfzDetailsFormModel,
  KrankentagegeldDetailsFormModel,
  LebensDetailsFormModel,
  LebensUndRentenDetailsFormModel,
  PflegerenteDetailsFormModel,
  PflegetagegeldDetailsFormModel,
  PrivathaftpflichtDetailsFormModel,
  RechtsschutzDetailsFormModel,
  RentenDetailsFormModel,
  RisikoFormModel,
  TierhalterHaftpflichtDetailsFormModel,
  TodesfallDetailsFormModel,
  UnfallDetailsFormModel,
  UnfallzusatzDetailsFormModel,
  WohngebaeudeDetailsFormModel,
} from 'form/vertraege/VertragFormModel'

export const createDetailsFormModel = (risiken?: RisikoFormModel[]): DetailsFormModel => {
  return {
    haftpflichtDetails: createHaftpflichtDetailsFormModel(),
    hausratDetails: createHausratFormModel(),
    unfallDetails: (risiken ?? []).map((risiko) => createUnfallDetailsFormModel(risiko)),
    pflegetagegeldDetails: createPflegetagegeldDetailsFormModel(),
    krankentagegeldDetails: createKrankentagegeldDetailsFormModel(),
    gesetzlicheKrankenversicherungDetails: createGesetztlicheKrankenversicherungDetailsFormModel(),
    privathaftpflichtDetails: createPrivathaftpflichtDetailsFormModel(),
    kfzDetails: createKfzDetailsFormModel(),
    invaliditaetsDetails: createInvaliditaetsDetailsFormModel(),
    berufshaftpflichtDetails: createBerufshaftpflichtDetailsFormModel(),
    wohngebaeudeDetails: createWohngebaeudeDetailsFormModel(),
    tierhalterHaftpflichtDetails: createTierhalterHaftpflichtDetailsFormModel(),
    rechtsschutzDetails: createRechtsschutzDetailsFormModel(),
    lebensDetails: createLebensDetailsFormModel(),
    todesfallDetails: createTodesfallDetailsFormModel(),
    anlagestrukturDetails: createAnlagestrukturDetailsFormModel(),
    berufsunfaehigkeitsDetails: createBerufsunfaehigkeitsDetailsFormModel(),
    betrieblicheAltersvorsorgeDetails: createBetrieblicheAltersvorsorgeDetailsFormModel(),
    dreadDiseaseDetails: createDreadDiseaseDetailsFormModel(),
    funktionsinvaliditaetsDetails: createFunktionsinvaliditaetsDetailsFormModel(),
    grundfaehigkeitsDetails: createGrundfaehigkeitsDetailsFormModel(),
    rentenDetails: createRentenDetailsFormModel(),
  }
}

export const createHaftpflichtDetailsFormModel = (): HaftpflichtDetailsFormModel => {
  return {
    deckungssummeModus: 'UNBEGRENZT',
    deckungssummeVermoegen: '',
    deckungssummePauschal: '',
    deckungssummeSach: '',
    deckungssummePersonen: '',
  }
}

export const createHausratFormModel = (): HausratDetailsFormModel => {
  return {
    hausratVersicherungssummeArt: 'UNTERVERSICHERUNGSVERZICHT',
    individuelleVersicherungssumme: '',
  }
}

export const createUnfallDetailsFormModel = (risiko?: RisikoFormModel): UnfallDetailsFormModel => {
  return {
    grundSumme: '',
    unfallRente: '',
    progression: '',
    todesfallLeistung: '',
    externePerson: risiko?.risikoPerson?.externePerson ?? false,
    externePersonName: risiko?.risikoPerson?.name ?? '',
    personId: risiko?.risikoPerson?.finfireId ?? '',
  }
}
export const createPflegetagegeldDetailsFormModel = (): PflegetagegeldDetailsFormModel => {
  return {
    ambulantPflegegrad1: '',
    ambulantPflegegrad2: '',
    ambulantPflegegrad3: '',
    ambulantPflegegrad4: '',
    ambulantPflegegrad5: '',
    leistungenPflegerestkostenProzent: false,
    stationaerPflegegrad1: '',
    stationaerPflegegrad2: '',
    stationaerPflegegrad3: '',
    stationaerPflegegrad4: '',
    stationaerPflegegrad5: '',
  }
}

export const createKrankentagegeldDetailsFormModel = (): KrankentagegeldDetailsFormModel => {
  return {
    ambulant: false,
    ausland: false,
    stationaer: false,
    zahn: false,
    krankentagegeldBeginn: 'TAG_1',
    krankentagegeld: '',
    selbstbehalt: '',
  }
}

export const createGesetztlicheKrankenversicherungDetailsFormModel =
  (): GesetzlicheKrankenversicherungDetailsFormModel => {
    return {
      gesetzlicheKrankenversicherungArt: 'PFLICHT',
      anspruchAufKrankengeld: false,
    }
  }

export const createBerufshaftpflichtDetailsFormModel = (): BerufshaftpflichtDetailsFormModel => {
  return {
    betriebsbeauftragte: false,
    einschlussPrivathaftpflicht: false,
    syndikusanwalt: false,
  }
}
export const createKfzDetailsFormModel = (): KfzDetailsFormModel => {
  return {
    deckungssummeKfz: '',
    kfzVersicherungsumfang: 'KEINE_KASKO',
  }
}
export const createInvaliditaetsDetailsFormModel = (): InvaliditaetsDetailsFormModel => {
  return {
    invaliditaetsrenteMonatlich: '',
    invaliditaetssumme: '',
    todesfallleistung: '',
  }
}
export const createRechtsschutzDetailsFormModel = (): RechtsschutzDetailsFormModel => {
  return {
    rechtsschutzTarifart: 'NORMAL',
    einschlussBeruf: false,
    einschlussMieter: false,
    einschlussPrivat: false,
    einschlussVerkehr: false,
    einschlussVermieter: false,
    versicherungssumme: '',
    versicherungssummeModus: 'UNBEGRENZT',
  }
}
export const createPrivathaftpflichtDetailsFormModel = (): PrivathaftpflichtDetailsFormModel => {
  return {
    einschlussBetreiberhaftpflicht: false,
    einschlussDiensthaftpflicht: false,
    einschlussEhrenamt: false,
    einschlussGewaesserschadenHaftpflicht: false,
    einschlussHausGrundbesitzerHaftpflicht: false,
    einschlussWassersportHaftpflicht: false,
    versicherteImmobilien: [],
    versicherteWasserfahrzeuge: [],
    privatHaftpflichtTarifart: 'NORMAL',
  }
}
export const createWohngebaeudeDetailsFormModel = (): WohngebaeudeDetailsFormModel => {
  return {
    wohnflaeche: '',
    wert1914: '',
    einschlussBauleistung: false,
    einschlussElementar: false,
    einschlussFeuer: false,
    einschlussFeuerrohbau: false,
    einschlussLeitungswasser: false,
    einschlussSturm: false,
    gleitenderNeuwert: false,
    unterversicherungsverzicht: false,
    deckungssumme: '',
  }
}
export const createTierhalterHaftpflichtDetailsFormModel =
  (): TierhalterHaftpflichtDetailsFormModel => {
    return {
      tierArt: 'HUND',
    }
  }

export const createLebensDetailsFormModel = (): LebensDetailsFormModel => {
  return {
    versicherungssumme: '',
    ablaufleistungPrognostiziert: '',
  }
}

export const createTodesfallDetailsFormModel = (): TodesfallDetailsFormModel => {
  return {
    todesfallleistung: '',
    todesfallleistungMitUeberschuss: '',
  }
}

export const createAnlagestrukturDetailsFormModel = (): AnlagestrukturDetailsFormModel => {
  return {
    aktien: '',
    zinsanlagen: '',
    immobilien: '',
    beteiligungen: '',
    sonstiges: '',
    riskanteAnteile: '',
    quelleRisikoklassifizierung: '',
  }
}

export const createBerufsunfaehigkeitsDetailsFormModel =
  (): BerufsunfaehigkeitsDetailsFormModel => {
    return {
      berufsunfaehigkeitsRente: '',
      berufsunfaehigkeitsRenteMitUeberschuss: '',
      leistungsendeBerufsunfaehigkeitsRente: null,
      weiterspargarantie: 'KEINE',
      weiterspargarantieInProzent: '',
      dienstunfaehigkeitsKlausel: false,
      abstrakterVerweisungsverzicht: false,
    }
  }

export const createBetrieblicheAltersvorsorgeDetailsFormModel =
  (): BetrieblicheAltersvorsorgeDetailsFormModel => {
    return {
      bavTyp: 'LEBENSVERSICHERUNG',
      durchfuehrungsweg: 'DIREKTVERSICHERUNG_3',
      leistungszusageProzentual: '',
      pauschalversteuert: false,
    }
  }

export const createFunktionsinvaliditaetsDetailsFormModel =
  (): FunktionsinvaliditaetsDetailsFormModel => {
    return {
      funktionsinvaliditaetsRenteMonatlich: '',
    }
  }

export const createGrundfaehigkeitsDetailsFormModel = (): GrundfaehigkeitsDetailsFormModel => {
  return {
    grundefaehigkeitsRenteMonatlich: '',
  }
}

export const createDreadDiseaseDetailsFormModel = (): DreadDiseaseDetailsFormModel => {
  return {
    versicherungssummeDreadDisease: '',
  }
}

export const createRentenDetailsFormModel = (): RentenDetailsFormModel => {
  return {
    beginnRentenzahlung: dayjs(),
    renteMonatlich: '',
    renteMonatlichMitUeberschuss: '',
    rentensteigerungJaehrlich: '',
  }
}

export const createPflegerenteDetailsFormModel = (): PflegerenteDetailsFormModel => {
  return {
    ambulantPflegerenteGrad1Monatlich: '',
    ambulantPflegerenteGrad2Monatlich: '',
    ambulantPflegerenteGrad3Monatlich: '',
    ambulantPflegerenteGrad4Monatlich: '',
    ambulantPflegerenteGrad5Monatlich: '',
    stationaerPflegerenteGrad1Monatlich: '',
    stationaerPflegerenteGrad2Monatlich: '',
    stationaerPflegerenteGrad3Monatlich: '',
    stationaerPflegerenteGrad4Monatlich: '',
    stationaerPflegerenteGrad5Monatlich: '',
  }
}

export const createUnfallzusatzDetailsFormModel = (): UnfallzusatzDetailsFormModel => {
  return {
    versicherungsssummeUnfall: '',
  }
}

export const createErwerbsunfaehigkeitsDetailsFormModel =
  (): ErwerbsunfaehigkeitsDetailsFormModel => {
    return {
      erwerbsminderungsrente: '',
    }
  }

export const createLebensUndRentenDetailsFormModel = (): LebensUndRentenDetailsFormModel => {
  return {
    rueckkaufwert: '',
    rueckkaufwertZeitpunkt: dayjs(),
    renditeklasse: 'KLASSE_1',
    ausbildungssparenKindId: '',
    zielId: '',
    zielArt: 'KEIN_ZIEL',
  }
}
