import React, { ReactElement } from 'react'
import { DateView } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { useField } from 'formik'

import { HelperText } from 'components/controls/inputs/HelperText'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import { SelectiveTextInput } from 'components/controls/selective-inputs/SelectiveTextInput'

import 'dayjs/locale/de'

type DateInputProps<FormType> = BaseInputProps<FormType> & {
  minDate?: Date
  maxDate?: Date
  views?: DateView[]
}

const formatSelectiveValue = (dateValue?: unknown): string => {
  if (!dateValue) {
    return ''
  }
  if (!dayjs(dateValue as string).isValid()) {
    return 'Invalides Datum'
  }
  return dayjs(dateValue as string)
    .locale('de')
    .format('DD.MM.YYYY')
}

export const DateInput = <FormType,>({
  name,
  label,
  tooltip,
  minDate,
  maxDate,
  disabled,
  selectiveFieldProps,
  views = ['year', 'month', 'day'],
}: DateInputProps<FormType>): ReactElement => {
  const [fieldInputProps, metaProps, { setValue, setTouched }] = useField(name)
  if (selectiveFieldProps) {
    return (
      <SelectiveTextInput<FormType>
        name={name}
        label={label}
        selectiveFieldProps={{
          ...selectiveFieldProps,
          formatValue: (value: unknown) => formatSelectiveValue(value),
        }}
      />
    )
  }

  return (
    <InputWrapper tooltip={tooltip}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
        <DatePicker
          {...fieldInputProps}
          onChange={(newValue: Dayjs | null) => {
            setValue(newValue)
            setTouched(true)
          }}
          openTo='year'
          views={views}
          minDate={minDate && dayjs(minDate)}
          maxDate={maxDate && dayjs(maxDate)}
          format={'DD.MM.YYYY'}
          disabled={disabled}
          slotProps={{
            textField: {
              variant: 'standard',
              helperText: <HelperText metaProps={metaProps} />,
              sx: { width: '100%' },
              error:
                (!!metaProps.error && metaProps.touched) ||
                (!!fieldInputProps.value && !dayjs(fieldInputProps.value).isValid()),
            },
          }}
          label={label}
        />
      </LocalizationProvider>
    </InputWrapper>
  )
}
