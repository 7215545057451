import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatPercent } from 'components/util/formatUtil'
import { AnlagestrukturDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

type AnlagestrukturDetailsFormBlockProps = BaseDetailsFormBlockProps

const FORM_BLOCK_TITLE = 'Details - Anlagestruktur'

export const AnlagestrukturDetailsFormBlock = ({
  produktId,
  name,
}: AnlagestrukturDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.anlagestrukturDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('ANLAGENSTRUKTUR', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={4}>
        <Stack>
          <NumberInput name={`${baseName}.aktien`} label={'Aktien'} inputUnit='%' />
          <NumberInput name={`${baseName}.zinsanlagen`} label={'Zinsanlagen'} inputUnit='%' />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack>
          <NumberInput name={`${baseName}.immobilien`} label={'Immobilien'} inputUnit='%' />
          <NumberInput name={`${baseName}.beteiligungen`} label={'Beteiligungen'} inputUnit='%' />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack>
          <NumberInput name={`${baseName}.sonstiges`} label={'Derivate, Sonstiges'} inputUnit='%' />
        </Stack>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item md={6}>
        <NumberInput
          name={`${baseName}.riskanteAnteile`}
          label={'Tendenziell riskante Anteile'}
          inputUnit='%'
          tooltip='Der Vermögenswert muss entsprechend seines individuellen Risikos eingestuft werden. Die Klassifizierung umfasst die Bandbreite von 100% tendenziell sichere Anlagen bis zu 100% tendenziell riskante Anlagen.'
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item md={6}>
        <TextInput
          name={`${baseName}.quelleRisikoklassifizierung`}
          label={'Quelle Risikoklassifizierung'}
          multiline
          tooltip='Als Quelle der Risikoklassifizierung sollte angegeben werden, ob es sich um eine eigene Einschätzung handelt oder ob die Klassifizierung einem Prospekt, den KIID-Informationen etc. entnommen wurde.'
        />
      </Grid>
    </Grid>
  )
}

type AnlagestrukturDetailsReadViewProps = BaseDetailsReadViewProps & {
  anlagestrukturDetails: AnlagestrukturDetails | undefined
}

export const AnlagestrukturDetailsReadView = ({
  produktId,
  anlagestrukturDetails,
}: AnlagestrukturDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('ANLAGENSTRUKTUR', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry label={'Aktien'} value={formatPercent(anlagestrukturDetails?.aktien)} />
      <LabelValueEntry
        label={'Zinsanlagen'}
        value={formatPercent(anlagestrukturDetails?.zinsanlagen)}
      />
      <LabelValueEntry
        label={'Immobilien'}
        value={formatPercent(anlagestrukturDetails?.immobilien)}
      />
      <LabelValueEntry
        label={'Beteiligungen'}
        value={formatPercent(anlagestrukturDetails?.beteiligungen)}
      />
      <LabelValueEntry
        label={'Derivate, Sonstiges'}
        value={formatPercent(anlagestrukturDetails?.sonstiges)}
      />
      <LabelValueEntry
        label={'Tendenziell riskante Anteile'}
        value={formatPercent(anlagestrukturDetails?.riskanteAnteile)}
      />
      <LabelValueEntry
        label={'Quelle Risikoklassifizierung'}
        value={anlagestrukturDetails?.quelleRisikoklassifizierung}
      />
    </Stack>
  )
}
