import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { SliderInput } from 'components/controls/inputs/SliderInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { EmphasizedFormBlock } from 'components/form-layout/EmphasizedFormBlock'
import { renditeklasseOptions } from 'components/personal/absicherung2/blocks/details/LebensUndRentenDetailsFormBlock'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { AnlagenstrukturSliders } from 'components/personal/finanzen/AnlagenstrukturSliders'
import { VerwendungszweckContainer } from 'components/personal/finanzen/VerwendungszweckContainer'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { GeldAnlageFormModel } from 'form/KundeFormModel'
import { geldAnlageInitialTouched } from 'form/kundeFormTouched'
import { geldAnlageValidationSchema } from 'form/kundeFormValidationSchema'
import {
  geldAnlageTypOptions,
  getOptionLabel,
  zahlartOptions,
  zahlweiseOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { GeldAnlagenMutator } from 'state/geldAnlagenMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

type PersonalFinanzenGeldAnlagenContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenGeldAnlagenContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenGeldAnlagenContainerProps): ReactElement => {
  const { editableKunde, geldAnlagenMutator } = useEditableKunde()
  return (
    <>
      {editableKunde.personen[personIndex].vermoegen.geldAnlagen.map(
        (geldAnlage, geldAnlageIndex) => {
          return (
            <PersonalFinanzenGeldAnlageContainer
              key={geldAnlageIndex}
              personIndex={personIndex}
              geldAnlageIndex={geldAnlageIndex}
              geldAnlagenMutator={geldAnlagenMutator}
              selectiveFormProps={selectiveFormProps}
            />
          )
        },
      )}
    </>
  )
}

type PersonalFinanzenGeldAnlageContainerProps = {
  personIndex: number
  geldAnlageIndex: number
  selectiveFormProps?: SelectiveFormProps
  geldAnlagenMutator: GeldAnlagenMutator
}

export const PersonalFinanzenGeldAnlageContainer = ({
  personIndex,
  geldAnlageIndex,
  selectiveFormProps,
  geldAnlagenMutator,
}: PersonalFinanzenGeldAnlageContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.vermoegen.geldAnlagen`
  const formPath = `${basePath}.${geldAnlageIndex}`
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<GeldAnlageFormModel>(
    formPath,
    selectiveFormProps,
  )
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: GeldAnlageFormModel): void => {
    geldAnlagenMutator.removeGeldAnlage(personIndex, geldAnlageIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }

  return (
    <EditableKundeFormik
      key={geldAnlageIndex}
      initialValues={initialFormValues}
      updateState={(geldAnlage) => {
        geldAnlagenMutator.updateGeldAnlage(personIndex, geldAnlageIndex, geldAnlage)
      }}
      validationSchema={geldAnlageValidationSchema}
      initialTouched={geldAnlageInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        const isExternePersonEigentuemer = formikProps.values.geldanlagenEigentuemer.includes(
          EXTERNE_PERSON_OPTION_VALUE,
        )
        return (
          <ExpansionPanel
            title={`${
              getOptionLabel(formikProps.values.geldAnlageTyp, geldAnlageTypOptions) || 'ohne Wert'
            } - ${formikProps.values.bezeichnung || 'ohne Bezeichnung'}`}
            index={geldAnlageIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={formikProps.values.isDeleted}
            displayAsNew={selectiveFormProps && formikProps.values.isNew}
            displayValidationError={!formikProps.isValid}
            displaySaveError={formikProps.values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container rowSpacing={1} spacing={2}>
                  <Grid item xs={12}>
                    <SelectInput<GeldAnlageFormModel>
                      options={geldAnlageTypOptions}
                      name={`geldAnlageTyp`}
                      label={'Geldanlagentyp'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput<GeldAnlageFormModel>
                      name={'bezeichnung'}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <VersichertePersonSelect
                      name={'geldanlagenEigentuemer'}
                      label={'Eigentümer'}
                      selectiveFieldProps={selectiveFieldProps}
                      withExternePerson
                      multiple
                    />
                  </Grid>
                  {isExternePersonEigentuemer && (
                    <Grid item xs={12} md={6}>
                      <TextInput<GeldAnlageFormModel>
                        name={'geldanlagenEigentuemerExternePersonName'}
                        label={'Externe Person Name'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Verwendungszweck'}>
                      <VerwendungszweckContainer
                        formikProps={formikProps}
                        personIndex={personIndex}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<GeldAnlageFormModel>
                      name={`vertragsnummer`}
                      label={'Vertragsnummer'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<GeldAnlageFormModel>
                      name={`anbieter`}
                      label={'Anbieter'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput<GeldAnlageFormModel>
                      name={`vertragsbeginn`}
                      label={'Vertragsbeginn'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput<GeldAnlageFormModel>
                      name={`vertragsende`}
                      label={'Vertragsende'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput<GeldAnlageFormModel>
                      name={`rendite`}
                      label={'Rendite (p.a.)'}
                      inputUnit={'%'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput<GeldAnlageFormModel>
                      options={renditeklasseOptions}
                      name={'renditeklasse'}
                      label={'Rendite-Klasse'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Laufende Sparrate'}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <SelectInput
                            name={`laufendeSparrate.zahlweise`}
                            label={'Zahlweise'}
                            options={zahlweiseOptions}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectInput
                            name={`laufendeSparrate.zahlart`}
                            label={'Zahlart'}
                            options={zahlartOptions}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        {formikProps.values.laufendeSparrate.zahlweise !== 'EINMALZAHLUNG' &&
                          formikProps.values.laufendeSparrate.zahlweise !== '' && (
                            <Grid item xs={6}>
                              <NumberInput
                                name={`laufendeSparrate.sparrate`}
                                label={'Beitrag'}
                                inputUnit={'€'}
                                selectiveFieldProps={selectiveFieldProps}
                              />
                            </Grid>
                          )}
                      </Grid>
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Anlagenwert'}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DateInput
                            name={`anlagenWert.zeitpunkt`}
                            label={'Zeitpunkt'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <NumberInput
                            name={`anlagenWert.zeitwert`}
                            label={'Zeitwert'}
                            inputUnit={'€'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <NumberInput
                            name={`anlagenWert.endwert1`}
                            label={'Erwarteter Endwert'}
                            inputUnit={'€'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            name={`anlagenWert.endwert2`}
                            label={'Niedriger Endwert'}
                            inputUnit={'€'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            name={`anlagenWert.endwert3`}
                            label={'Hoher Endwert'}
                            inputUnit={'€'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                      </Grid>
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Anlagenstruktur'}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <AnlagenstrukturSliders selectiveFieldProps={selectiveFieldProps} />
                        </Grid>
                        <Grid item xs={6}>
                          <SliderInput
                            name={'anlagenStruktur.riskanteAnteile'}
                            label={'Riskante Anteile'}
                            min={0}
                            max={100}
                            step={10}
                            labelUnit={'%'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            name={'anlagenStruktur.quelleRisikoklassifizierung'}
                            label={'Quelle Risikoklassifizierung'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                      </Grid>
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<GeldAnlageFormModel>
                      name={`bemerkungen`}
                      label={'Bemerkungen'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<GeldAnlageFormModel>
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}
