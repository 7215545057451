import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import { uniqBy } from 'lodash'

import { useFetchVertragGesellschaften } from 'api/useFetchGesellschaften'
import { HelperText } from 'components/controls/inputs/HelperText'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import styles from 'components/personal/absicherung2/selects/VersicherungsGesellschaftSelect.module.scss'
import { useDebounce } from 'hooks/use-debounce'
import { VertragGeschaeft, VertragGesellschaft } from 'interfaces/mynorm-api-model-interfaces'

type VersicherungsGesellschaftSelectProps<T> = Partial<BaseInputProps<T>> & {
  name: string
  geschaeft: VertragGeschaeft
}

export const VersicherungsGesellschaftSelect = <T,>({
  name,
  geschaeft,
  tooltip,
  disabled,
}: VersicherungsGesellschaftSelectProps<T>): ReactElement => {
  const { setFieldValue } = useFormikContext()
  const [{ value, ...fieldProps }, metaProps] = useField<VertragGesellschaft>(name)
  const initialGesellschaft: VertragGesellschaft | undefined = value
    ? {
        gesellschaftId: value.gesellschaftId,
        name: value.name,
      }
    : undefined
  const handleSelect = (vertragGesellschaft: VertragGesellschaft | null): void => {
    setFieldValue(name, vertragGesellschaft || '')
  }
  return (
    <InputWrapper tooltip={tooltip}>
      <BaseGesellschaftSelect
        onSelect={handleSelect}
        error={!!metaProps.error && metaProps.touched}
        helperText={<HelperText metaProps={metaProps} />}
        disabled={disabled}
        value={value}
        initialOptionData={initialGesellschaft ? [initialGesellschaft] : undefined}
        geschaeft={geschaeft}
        {...fieldProps}
      />
    </InputWrapper>
  )
}

type BaseGesellschaftSelectProps = {
  label?: string
  onSelect?: (vertragGesellschaft: VertragGesellschaft | null) => void
  error?: boolean
  helperText?: string | ReactElement
  disabled?: boolean
  value?: VertragGesellschaft
  initialOptionData?: VertragGesellschaft[]
  geschaeft: VertragGeschaeft
}
export const BaseGesellschaftSelect = ({
  label,
  onSelect,
  error,
  helperText,
  disabled,
  value,
  initialOptionData,
  geschaeft,
  ...props
}: BaseGesellschaftSelectProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const [suchbegriff, setSuchbegriff] = useState('')
  const debouncedSuchbegriff = useDebounce(suchbegriff, 200)

  const {
    data,
    fetchStatus,
    isError: isLoadingError,
  } = useFetchVertragGesellschaften(geschaeft, debouncedSuchbegriff || '', initialOptionData)

  const [selectedGesellschaftId, setSelectedGesellschaftId] = useState(value?.gesellschaftId)
  const uniqueGesellschaftIds = uniqBy(
    [...(data || []), ...(initialOptionData || [])],
    (u) => u.name,
  ).map((o) => o.gesellschaftId)

  const showError = error || isLoadingError
  let errorText: string | ReactElement | undefined
  if (isLoadingError) {
    errorText = <HelperText text={'Fehler beim Laden der Daten'} />
  } else {
    errorText = helperText
  }

  useEffect(() => {
    if (isLoadingError) {
      setOpen(false)
    }
  }, [isLoadingError])

  const findGesellschaft = (gesellschaftId: string): VertragGesellschaft | undefined => {
    return data?.find((o) => o.gesellschaftId === gesellschaftId)
  }

  const handleSelect = (event: SyntheticEvent, value: string | null): void => {
    setSelectedGesellschaftId(value || '')

    if (value == null) {
      onSelect?.(null)
      return
    }
    const gesellschaft = findGesellschaft(value)
    if (gesellschaft) {
      setSuchbegriff(gesellschaft.name)
      onSelect?.(gesellschaft)
    }
  }

  return (
    <Autocomplete
      open={open}
      options={uniqueGesellschaftIds}
      onChange={handleSelect}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={selectedGesellschaftId}
      noOptionsText={'Name der Gesellschaft eingeben...'}
      getOptionLabel={(finfireId) => findGesellschaft(finfireId)?.name || ''}
      disabled={disabled}
      loading={fetchStatus === 'fetching'}
      loadingText={
        <div className={styles.loadingContainer}>
          <CircularProgress size={'1rem'} className={styles.loadingIndicator} />
          Laden...
        </div>
      }
      renderInput={(params): ReactElement => (
        <TextField
          {...props}
          {...params}
          variant='standard'
          label={label || 'Gesellschaft'}
          onChange={(event) => setSuchbegriff(event.target.value)}
          error={showError}
          helperText={errorText}
        />
      )}
      fullWidth
    />
  )
}
