import { valueOrDefault } from 'form/formModelConverterUtil'
import { createDefaultMetadata } from 'form/kundeFormModelCreator'
import { Metadata } from 'interfaces/mynorm-api-model-interfaces'

const defaultMetadata = createDefaultMetadata()
export const convertToMeta = (meta: Metadata | undefined | null): Metadata => {
  return {
    finfireId: valueOrDefault(meta?.finfireId, defaultMetadata.finfireId),
    myviId: valueOrDefault(meta?.myviId, defaultMetadata.myviId),
    salesforceId: valueOrDefault(meta?.salesforceId, defaultMetadata.salesforceId),
    salesforceLastModified: valueOrDefault(
      meta?.salesforceLastModified,
      defaultMetadata.salesforceLastModified,
    ),
    finfireLastModified: valueOrDefault(
      meta?.finfireLastModified,
      defaultMetadata.finfireLastModified,
    ),
  }
}
