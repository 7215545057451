import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency, formatPercent } from 'components/util/formatUtil'
import { PflegetagegeldDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { PflegetagegeldDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Pflegetagegeld'

type PflegetagegeldDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  pflegetagegeldDetailsFormModel: PflegetagegeldDetailsFormModel | undefined
}

export const PflegetagegeldDetailsFormBlock = ({
  name,
  produktId,
  pflegetagegeldDetailsFormModel,
}: PflegetagegeldDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.pflegetagegeldDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('PFLEGETAGEGELD', produktId)) {
    return null
  }

  const inputUnit = pflegetagegeldDetailsFormModel?.leistungenPflegerestkostenProzent ? '%' : '€'

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={12}>
        <CheckboxInput
          name={`${baseName}.leistungenPflegerestkostenProzent`}
          label={'Leistungen als Pflegerestkosten in %'}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Typography variant={'body2'}>Ambulant</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegegrad1`}
          label={'PG 1'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegegrad2`}
          label={'PG 2'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegegrad3`}
          label={'PG 3'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegegrad4`}
          label={'PG 4'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegegrad5`}
          label={'PG 5'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Typography variant={'body2'}>Stationär</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegegrad1`}
          label={'PG 1'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegegrad2`}
          label={'PG 2'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegegrad3`}
          label={'PG 3'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegegrad4`}
          label={'PG 4'}
          inputUnit={inputUnit}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegegrad5`}
          label={'PG 5'}
          inputUnit={inputUnit}
        />
      </Grid>
    </Grid>
  )
}

type PflegetagegeldDetailsReadViewProps = BaseDetailsReadViewProps & {
  pflegetagegeldDetails: PflegetagegeldDetails | undefined
}

export const PflegetagegeldDetailsReadView = ({
  produktId,
  pflegetagegeldDetails,
}: PflegetagegeldDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('PFLEGETAGEGELD', produktId)) {
    return null
  }
  const formatFunction = pflegetagegeldDetails?.leistungenPflegerestkostenProzent
    ? formatPercent
    : formatCurrency

  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <Grid container>
        <Grid item md={6}>
          <Typography variant='body2' color='primary'>
            {`Ambulant ${
              pflegetagegeldDetails?.leistungenPflegerestkostenProzent
                ? '(Pflegerestkosten in %)'
                : ''
            }`}
          </Typography>
          <LabelValueEntry
            label={'Pflegegrad 1'}
            value={formatFunction(pflegetagegeldDetails?.ambulantPflegegrad1)}
          />
          <LabelValueEntry
            label={'Pflegegrad 2'}
            value={formatFunction(pflegetagegeldDetails?.ambulantPflegegrad2)}
          />
          <LabelValueEntry
            label={'Pflegegrad 3'}
            value={formatFunction(pflegetagegeldDetails?.ambulantPflegegrad3)}
          />
          <LabelValueEntry
            label={'Pflegegrad 4'}
            value={formatFunction(pflegetagegeldDetails?.ambulantPflegegrad4)}
          />
          <LabelValueEntry
            label={'Pflegegrad 5'}
            value={formatFunction(pflegetagegeldDetails?.ambulantPflegegrad5)}
          />
        </Grid>
        <Grid item md={6}>
          <Typography variant='body2' color='primary'>
            {`Stationär ${
              pflegetagegeldDetails?.leistungenPflegerestkostenProzent
                ? '(Pflegerestkosten in %)'
                : ''
            }`}
          </Typography>
          <LabelValueEntry
            label={'Pflegegrad 1'}
            value={formatFunction(pflegetagegeldDetails?.stationaerPflegegrad1)}
          />
          <LabelValueEntry
            label={'Pflegegrad 2'}
            value={formatFunction(pflegetagegeldDetails?.stationaerPflegegrad2)}
          />
          <LabelValueEntry
            label={'Pflegegrad 3'}
            value={formatFunction(pflegetagegeldDetails?.stationaerPflegegrad3)}
          />
          <LabelValueEntry
            label={'Pflegegrad 4'}
            value={formatFunction(pflegetagegeldDetails?.stationaerPflegegrad4)}
          />
          <LabelValueEntry
            label={'Pflegegrad 5'}
            value={formatFunction(pflegetagegeldDetails?.stationaerPflegegrad5)}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
