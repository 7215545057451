import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import { FieldArray } from 'formik'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency, formatPercent } from 'components/util/formatUtil'
import { RisikoFormModel, UnfallDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { UnfallDetails } from 'interfaces/mynorm-api-model-interfaces'
import { useHaushaltPersonen } from 'state/useHaushaltPersonen'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'
import { EXTERNE_PERSON_OPTION_VALUE, extractFullName } from 'utils/nameUtils'

const FORM_BLOCK_TITLE = 'Details - Unfall'

type UnfallDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  unfallDetails: UnfallDetailsFormModel[] | undefined
  risiken: RisikoFormModel[]
}

export const UnfallDetailsFormBlock = ({
  name,
  produktId,
  unfallDetails,
  risiken,
}: UnfallDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.unfallDetails`
  const { findMensch } = useHaushaltPersonen()
  if (!isRelevantForDetailsTypAndFinfireProduktId('UNFALL', produktId)) {
    return null
  }

  return (
    <FieldArray name={baseName}>
      {() => {
        return (
          <Grid container>
            {(unfallDetails ?? []).map((unfallDetail, index) => {
              const risiko = risiken[index]
              const personName =
                risiko?.risikoPerson?.finfireId === EXTERNE_PERSON_OPTION_VALUE
                  ? risiko?.risikoPerson?.name ?? 'Externe Person'
                  : extractFullName(findMensch(risiko?.risikoPerson?.finfireId))
              const fieldName = `${baseName}.${index}`
              return (
                <Grid container key={risiko.risikoId}>
                  <Grid item xs={12}>
                    <DetailFormBlockTitle title={`${FORM_BLOCK_TITLE} | ${personName}`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput
                      name={`${fieldName}.grundSumme`}
                      label={'Grundsumme'}
                      inputUnit='€'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      name={`${fieldName}.progression`}
                      label={'Progression'}
                      inputUnit='%'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      name={`${fieldName}.unfallRente`}
                      label={'Unfallrente'}
                      inputUnit={'€'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      name={`${fieldName}.todesfallLeistung`}
                      label={'Todesfall Leistung'}
                      inputUnit={'€'}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        )
      }}
    </FieldArray>
  )
}

type UnfallDetailsReadViewProps = BaseDetailsReadViewProps & {
  unfallDetails: UnfallDetails[] | undefined
}

export const UnfallDetailsReadView = ({
  produktId,
  unfallDetails,
}: UnfallDetailsReadViewProps): ReactElement | null => {
  const { findMensch } = useHaushaltPersonen()
  if (!isRelevantForDetailsTypAndFinfireProduktId('UNFALL', produktId)) {
    return null
  }
  return (
    <Stack rowGap={2}>
      {(unfallDetails ?? []).map((unfallDetail) => {
        const name = unfallDetail.externePerson
          ? unfallDetail.externePersonName ?? 'Externe Person'
          : extractFullName(findMensch(unfallDetail.personId))
        return (
          <Stack
            key={
              '' +
              unfallDetail?.personId +
              unfallDetail.externePerson +
              unfallDetail.externePersonName
            }
          >
            <DetailReadViewBlockTitle title={`${FORM_BLOCK_TITLE} | ${name}`} />
            <LabelValueEntry label={'Grundsumme'} value={formatCurrency(unfallDetail.grundSumme)} />
            <LabelValueEntry
              label={'Rente monatlich'}
              value={formatCurrency(unfallDetail.unfallRente)}
            />
            <LabelValueEntry
              label={'Progression'}
              value={formatPercent(unfallDetail.progression)}
            />
            <LabelValueEntry
              label={'Leistung Todesfall'}
              value={formatCurrency(unfallDetail.todesfallLeistung)}
            />
          </Stack>
        )
      })}
    </Stack>
  )
}
