import React, { ReactElement } from 'react'
import { generatePath } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import map from 'assets/images/guidance/mitnorm/wo-wir-sind/wo_wir_sind_0.png'
import mapFe from 'assets/images/guidance/mitnorm-fe/wo-wir-sind/wo_wir_sind_0.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm17Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const GuidanceMitNorm17Page = (): ReactElement => {
  const { customerId } = useHaushaltState()
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={16}
      previousPagePath={Paths.guidanceMitNorm16}
      nextPageButtonText={'DIN-Datenerfassung'}
      nextPagePath={!customerId ? Paths.customers : generatePath(Paths.personal, { customerId })}
      guidanceColor='mitnorm'
    >
      <GuidanceSubPageTemplate title={'WO WIR ZU FINDEN SIND!'} subTitle={'Unser Netzwerk'}>
        <Stack direction='row' className={styles.page}>
          <Box className={styles.map}>
            <GuidanceMitNormImage src={map} feSrc={mapFe} alt='Deutschlandkarte' />
          </Box>
          <Box className={styles.textContainer}>
            <Box className={styles.textBox}>
              <h3 className={styles.text}>
                AN EINEM STANDORT IN <span className={styles.emphasis}>DEINER</span> NÄHE!
              </h3>
            </Box>
          </Box>
        </Stack>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
