import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency, formatDate } from 'components/util/formatUtil'
import { KindFormModel, ZielFormModel } from 'form/KundeFormModel'
import { getOptionLabel } from 'form/kundeOptions'
import {
  LebensUndRentenDetailsFormModel,
  LebensUndRentenDetailsZielArt,
} from 'form/vertraege/VertragFormModel'
import { LebensUndRentenDetails, Renditeklasse } from 'interfaces/mynorm-api-model-interfaces'
import { useKinder } from 'state/useKinder'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'
import { useZiele } from 'state/useZiele'
import { extractFullName } from 'utils/nameUtils'

export const renditeklasseOptions: Option<Renditeklasse>[] = [
  {
    label: 'Renditeklasse 1 (Beitragsgarantie)',
    value: 'KLASSE_1',
  },
  {
    label: 'Renditeklasse 2 (keine/geringe Beitragsgarantie)',
    value: 'KLASSE_2',
  },
]

type LebensUndRentenDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  lebensUndRentenDetailsFormModel: LebensUndRentenDetailsFormModel | undefined
}

export const LebensUndRentenDetailsFormBlock = ({
  produktId,
  name,
  lebensUndRentenDetailsFormModel,
}: LebensUndRentenDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.lebensUndRentenDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('LEBENS_UND_RENTEN', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={6}>
        <NumberInput name={`${baseName}.rueckkaufwert`} label={'Rückkaufwert'} inputUnit='€' />
      </Grid>
      <Grid item xs={6}>
        <DateInput name={`${baseName}.rueckkaufwertZeitpunkt`} label={'zum Zeitpunkt'} />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          name={`${baseName}.renditeklasse`}
          label={'Renditeklasse Hochrechnung'}
          options={renditeklasseOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <ZielSelect
          baseName={baseName}
          lebensUnsRentenDetailsFormModel={lebensUndRentenDetailsFormModel}
        />
      </Grid>
    </Grid>
  )
}

export const zielArtOptions: Option<LebensUndRentenDetailsZielArt>[] = [
  { value: 'KEIN_ZIEL', label: 'Kein Ziel' },
  { value: 'ALTERSVORSORGE', label: 'Altersvorsorge' },
  { value: 'AUSBILDUNGSSPAREN', label: 'Ausbildungssparen' },
  { value: 'ZIEL', label: 'Ziel' },
]

type ZielSelectProps = {
  baseName: string
  lebensUnsRentenDetailsFormModel: LebensUndRentenDetailsFormModel | undefined
}

export const ZielSelect = ({
  baseName,
  lebensUnsRentenDetailsFormModel,
}: ZielSelectProps): ReactElement => {
  const { kinder } = useKinder()
  const { ziele } = useZiele()

  const isForZiel = lebensUnsRentenDetailsFormModel?.zielArt === 'ZIEL'
  const isForAusbildungssparen = lebensUnsRentenDetailsFormModel?.zielArt === 'AUSBILDUNGSSPAREN'
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SelectInput
          name={`${baseName}.zielArt`}
          label={'Verwendungsziel'}
          options={zielArtOptions.map((option) => ({
            ...option,
            disabled: isVerwendungszweckDisabled(option.value, ziele, kinder),
          }))}
        />
      </Grid>
      {isForZiel && (
        <Grid item xs={12} md={6}>
          <SelectInput
            name={`${baseName}.zielId`}
            label={'Ziel'}
            options={ziele.map(toZielOption)}
          />
        </Grid>
      )}
      {isForAusbildungssparen && (
        <Grid item xs={12} md={6}>
          <SelectInput
            name={`${baseName}.ausbildungssparenKindId`}
            label={'Kind'}
            options={kinder.map(toKindOption)}
          />
        </Grid>
      )}
    </Grid>
  )
}

const isVerwendungszweckDisabled = (
  value: LebensUndRentenDetailsZielArt,
  ziele: ZielFormModel[],
  kinder: KindFormModel[],
): boolean => {
  if (value === 'ZIEL' && ziele.length === 0) return true
  if (value === 'AUSBILDUNGSSPAREN' && kinder.length === 0) return true
  return false
}

const toZielOption = (ziel: ZielFormModel): Option => ({
  value: ziel.meta.myviId,
  label: ziel.bezeichnung,
})

const toKindOption = (kind: KindFormModel): Option => ({
  value: kind.meta.finfireId || kind.meta.myviId,
  label: `${kind.vorname} ${kind.nachname}`,
})

type LebensUndRentenDetailsReadViewProps = BaseDetailsReadViewProps & {
  lebensUndRentenDetails: LebensUndRentenDetails | undefined
}

export const LebensUndRentenDetailsReadView = ({
  produktId,
  lebensUndRentenDetails,
}: LebensUndRentenDetailsReadViewProps): ReactElement | null => {
  const { findZiel } = useZiele()
  const { findKind } = useKinder()
  if (!isRelevantForDetailsTypAndFinfireProduktId('LEBENS_UND_RENTEN', produktId)) {
    return null
  }
  let verwendungszweck = findZiel(lebensUndRentenDetails?.zielId)?.bezeichnung
  if (!verwendungszweck) {
    const kind = findKind(lebensUndRentenDetails?.ausbildungssparenKindId)
    if (kind) {
      verwendungszweck = `Ausbildungssparen für ${extractFullName(kind)}`
    }
  }
  if (!verwendungszweck) {
    verwendungszweck = lebensUndRentenDetails?.zielAltersvorsorge ? 'Altersvorsorge' : 'Kein Ziel'
  }
  return (
    <Stack>
      <LabelValueEntry
        label={'Rückkaufwert'}
        value={formatCurrency(lebensUndRentenDetails?.rueckkaufwert)}
      />
      <LabelValueEntry
        label={'zum Zeitpunkt'}
        value={formatDate(lebensUndRentenDetails?.rueckkaufwertZeitpunkt)}
      />
      <LabelValueEntry
        label={'Renditeklasse Hochrechnung'}
        value={getOptionLabel(lebensUndRentenDetails?.renditeklasse, renditeklasseOptions)}
      />
      <LabelValueEntry label={'Verwendungsziel'} value={verwendungszweck} />
    </Stack>
  )
}
