import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel, FormCheckboxCell } from 'components/controls/cells/FormCell'
import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { AnlegerprofilDienstleistungenFormModel } from 'form/KundeFormModel'
import { anlegerprofilDienstleistungeInitialTouched } from 'form/kundeFormTouched'
import { anlegerprofilDienstleistungenValidationSchema } from 'form/kundeFormValidationSchema'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalAnlegerProfilDienstleistungenFormProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
  isPartnerAvailable?: boolean
}

export const PersonalAnlegerProfilDienstleistungenForm = ({
  personIndex,
  selectiveFormProps,
  isPartnerAvailable = false,
}: PersonalAnlegerProfilDienstleistungenFormProps): ReactElement => {
  const { anlegerprofilMutator } = useEditableKunde()
  const { initialFormValues, selectiveFieldProps } =
    useSelectiveKundeForm<AnlegerprofilDienstleistungenFormModel>(
      `personen.${personIndex}.anlegerprofil.dienstleistungen`,
      selectiveFormProps,
    )
  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(dienstleistungen) => {
        anlegerprofilMutator.updateDienstleistungen(personIndex, dienstleistungen)
      }}
      validationSchema={anlegerprofilDienstleistungenValidationSchema}
      initialTouched={anlegerprofilDienstleistungeInitialTouched}
      validateOnMount
    >
      {() => (
        <Table>
          <TableHead>
            <TableRow>
              <FormCellLabel $border={isPartnerAvailable} $highlighted></FormCellLabel>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <FormCheckboxCell $border={isPartnerAvailable}>
                <CheckboxInput<AnlegerprofilDienstleistungenFormModel>
                  name={'beratungsfreieOrderausfuehrung'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell $border={isPartnerAvailable}>
                <CheckboxInput<AnlegerprofilDienstleistungenFormModel>
                  name={'anlageberatung'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell $border={isPartnerAvailable}>
                <CheckboxInput<AnlegerprofilDienstleistungenFormModel>
                  name={'vermoegensverwaltung'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </EditableKundeFormik>
  )
}
