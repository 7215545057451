import React, { ReactElement } from 'react'
import { Button, Grid, Stack } from '@mui/material'

import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { finanzenIcons } from 'components/personal/finanzen/finanzenIcons'
import { PersonalFinanzenBeteiligungenContainer } from 'components/personal/finanzen/PersonalFinanzenBeteiligungenContainer'
import { PersonalFinanzenDarlehenContainer } from 'components/personal/finanzen/PersonalFinanzenDarlehenContainer'
import { PersonalFinanzenFormBlock } from 'components/personal/finanzen/PersonalFinanzenFormBlock'
import { PersonalFinanzenGeldAnlagenContainer } from 'components/personal/finanzen/PersonalFinanzenGeldAnlagenContainer'
import { PersonalFinanzenGesetzlicheVersorgungenContainer } from 'components/personal/finanzen/PersonalFinanzenGesetzlicheVersorgungenContainer'
import { PersonalFinanzenImmobilienContainer } from 'components/personal/finanzen/PersonalFinanzenImmobilienContainer'
import { PersonalFinanzenPersonContainer } from 'components/personal/finanzen/PersonalFinanzenPersonContainer'
import { PersonalFinanzenSachwerteContainer } from 'components/personal/finanzen/PersonalFinanzenSachwerteContainer'
import { PersonalFormLayout } from 'components/personal/PersonalFormLayout'
import {
  createDefaultBeteiligungFormModel,
  createDefaultDarlehenFormModel,
  createDefaultGeldAnlageFormModel,
  createDefaultGesetzlicheVersorgungFormModel,
  createDefaultImmobilieFormModel,
  createDefaultSachwertFormModel,
} from 'form/kundeFormModelCreator'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalFinanzenFormProps = {
  selectiveFormProps?: SelectiveFormProps
}
export const PersonalFinanzenForm = ({
  selectiveFormProps,
}: PersonalFinanzenFormProps): ReactElement => {
  const {
    editableKunde,
    immobilienMutator,
    geldAnlagenMutator,
    darlehenMutator,
    beteiligungenMutator,
    sachwerteMutator,
    gesetzlicheVersorgungMutator,
  } = useEditableKunde()
  return (
    <PersonalFormLayout title={'Finanzen'}>
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <PersonalFinanzenPersonContainer
            personIndex={0}
            selectiveFormProps={selectiveFormProps}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PersonalFinanzenPersonContainer
            personIndex={1}
            selectiveFormProps={selectiveFormProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {editableKunde.personen[0].vermoegen.immobilien.length > 0 && (
              <PersonalFinanzenFormBlock
                title={'Immobilien'}
                icon={finanzenIcons.immobilien.default}
              >
                <PersonalFinanzenImmobilienContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
            {editableKunde.personen[0].vermoegen.geldAnlagen.length > 0 && (
              <PersonalFinanzenFormBlock
                title={'Geldanlagen'}
                icon={finanzenIcons.geldAnlagen.default}
              >
                <PersonalFinanzenGeldAnlagenContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
            {editableKunde.personen[0].vermoegen.darlehen.length > 0 && (
              <PersonalFinanzenFormBlock
                title={'Verbindlichkeiten'}
                icon={finanzenIcons.darlehen.default}
              >
                <PersonalFinanzenDarlehenContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
            {editableKunde.personen[0].vermoegen.beteiligungen.length > 0 && (
              <PersonalFinanzenFormBlock
                title={'Beteiligungen'}
                icon={finanzenIcons.beteiligungen.default}
              >
                <PersonalFinanzenBeteiligungenContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
            {editableKunde.personen[0].vermoegen.sachwerte.length > 0 && (
              <PersonalFinanzenFormBlock title={'Sachwerte'} icon={finanzenIcons.sachwerte.default}>
                <PersonalFinanzenSachwerteContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
            {editableKunde.personen[0].gesetzlicheVersorgungen.length > 0 && (
              <PersonalFinanzenFormBlock
                title={'Gesetzliche Versorgungen'}
                icon={finanzenIcons.gesetzlicheVersorgung.default}
              >
                <PersonalFinanzenGesetzlicheVersorgungenContainer
                  personIndex={0}
                  selectiveFormProps={selectiveFormProps}
                />
              </PersonalFinanzenFormBlock>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {!selectiveFormProps && (
            <Stack direction='row' spacing={2}>
              <Button
                onClick={() =>
                  immobilienMutator.addImmobilie(0, createDefaultImmobilieFormModel(true))
                }
                variant='outlined'
                startIcon={finanzenIcons.immobilien.add}
              >
                Immobilie
              </Button>
              <Button
                onClick={() =>
                  geldAnlagenMutator.addGeldAnlage(0, createDefaultGeldAnlageFormModel(true))
                }
                variant='outlined'
                startIcon={finanzenIcons.geldAnlagen.add}
              >
                Geldanlage
              </Button>
              <Button
                onClick={() => darlehenMutator.addDarlehen(0, createDefaultDarlehenFormModel(true))}
                variant='outlined'
                startIcon={finanzenIcons.darlehen.add}
              >
                Verbindlichkeit
              </Button>
              <Button
                onClick={() =>
                  beteiligungenMutator.addBeteiligung(0, createDefaultBeteiligungFormModel(true))
                }
                variant='outlined'
                startIcon={finanzenIcons.beteiligungen.add}
              >
                Beteiligung
              </Button>
              <Button
                onClick={() =>
                  sachwerteMutator.addSachwert(0, createDefaultSachwertFormModel(true))
                }
                variant='outlined'
                startIcon={finanzenIcons.sachwerte.add}
              >
                Sachwert
              </Button>
              <Button
                onClick={() =>
                  gesetzlicheVersorgungMutator.addGesetzlicheVersorgung(
                    0,
                    createDefaultGesetzlicheVersorgungFormModel(true),
                  )
                }
                variant='outlined'
                startIcon={finanzenIcons.gesetzlicheVersorgung.add}
              >
                Gesetzliche Versorgung
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </PersonalFormLayout>
  )
}
