import React, { ReactElement } from 'react'
import { Box, Grid } from '@mui/material'

import firmen from 'assets/images/guidance/career/institut-und-partner/institut_und_partner_firmen.png'
import karte from 'assets/images/guidance/career/institut-und-partner/institut_und_partner_landkarte.png'
import styles from 'components/guidance/career/GuidanceCareer2Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer2Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={1}
      previousPagePath={Paths.guidanceCareer1}
      nextPagePath={Paths.guidanceCareer3}
      guidanceColor='career'
    >
      <GuidanceSubPageTemplate title='DAS KARRIERE-INSTITUT' subTitle='Unser Netzwerkpartner'>
        <Grid container className={styles.pageContainer}>
          <Grid item lg={7} md={12}>
            <Box className={styles.mapContainer}>
              <img src={karte} alt='Deutschlandkarte' />
            </Box>
          </Grid>
          <Grid item lg={5} md={12}>
            <Box className={styles.partnerContainer}>
              <img src={firmen} alt='Deutschlandkarte' />
            </Box>
          </Grid>
        </Grid>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
