import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_0.png'
import chart1 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_1.png'
import chart2 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_2.png'
import chart3 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_3.png'
import chart4 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_4.png'
import chart5 from 'assets/images/guidance/mitnorm/marktanbieter/marktanbieter_5.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_2.png'
import chart3Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_3.png'
import chart4Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_4.png'
import chart5Fe from 'assets/images/guidance/mitnorm-fe/marktanbieter/marktanbieter_5.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm10Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm10Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3, chart4, chart5]
  const chartsFe = [chart0Fe, chart1Fe, chart2Fe, chart3Fe, chart4Fe, chart5Fe]
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={9}
      previousPagePath={Paths.guidanceMitNorm9}
      nextPagePath={Paths.guidanceMitNorm11}
      guidanceColor='mitnorm'
      numberOfInterPages={6}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title={'MARKTANBIETER'} subTitle={'mitNORM'}>
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              <GuidanceMitNormImage
                src={charts[interPageIndex]}
                feSrc={chartsFe[interPageIndex]}
                alt='Marktanbieter'
              />
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
