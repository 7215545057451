import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { useProduktSpecs } from 'components/personal/absicherung2/hooks/useProduktSpecs'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean, formatPercent } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import {
  BavTyp,
  BetrieblicheAltersvorsorgeDetails,
  Durchfuehrungsweg,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const durchfuehrungswegOptions: Option<Durchfuehrungsweg>[] = [
  { value: 'DIREKTVERSICHERUNG_3', label: 'Direktversicherung § 3 Nr. 63' },
  { value: 'DIREKTVERSICHERUNG_40', label: 'Direktversicherung § 40b' },
  { value: 'LEBENSVERSICHERUNG', label: 'Lebensversicherung § 100' },
  { value: 'PENSIONSKASSE', label: 'Pensionskasse § 3 Nr. 63' },
  { value: 'PENSIONSFONDS', label: 'Pensionsfonds § 3 Nr. 63' },
  { value: 'RIESTER', label: 'Riester § 10a' },
  { value: 'UNTERSTUETZUNGSKASSE', label: 'Unterstützungskasse § 4d' },
  { value: 'PENSIONSZUSAGE', label: 'Direktzusage/Pensionszusage § 6a' },
]

const bavTypOptions: Option<BavTyp>[] = [
  {
    value: 'RENTENVERSICHERUNG',
    label: 'Rentenversicherung',
  },
  {
    value: 'LEBENSVERSICHERUNG',
    label: 'Lebensversicherung',
  },
]

const FORM_BLOCK_TITLE = 'Details - Betriebl. Altersvorsorge'

type BetrieblicheAltersvorsorgeDetailsFormBlockProps = BaseDetailsFormBlockProps

export const BetrieblicheAltersvorsorgeDetailsFormBlock = ({
  name,
  produktId,
}: BetrieblicheAltersvorsorgeDetailsFormBlockProps): ReactElement | null => {
  const { findProduktById } = useProduktSpecs('LEBEN')
  const produktSpecs = findProduktById(produktId)
  const requiresDurchfuehrungsweg = produktSpecs?.durchfuehrungswege?.length

  const baseName = `${name}.betrieblicheAltersvorsorgeDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('BAV', produktId) && !requiresDurchfuehrungsweg) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          name={`${baseName}.durchfuehrungsweg`}
          label={'Durchführungsweg'}
          options={durchfuehrungswegOptions.filter((option) =>
            produktSpecs?.durchfuehrungswege.includes(option.value),
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          name={`${baseName}.bavTyp`}
          label={'Typ der Versicherung'}
          options={bavTypOptions}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CheckboxInput
          name={`${baseName}.pauschalversteuert`}
          label={'Pauschalversteuert'}
          tooltip='Bei BAV-Verträgen vor 1.1.2005 war eine Pauschalbesteuerung nach §40b EStG möglich. Die Beiträge wurden nicht individuell besteuert, sondern mit einem Pauschalsteuersatz von 20%.'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <NumberInput
          name={`${baseName}.leistungszusageProzentual`}
          label={'Leistungszusage (% des Nettoeinkommens)'}
          inputUnit='%'
        />
      </Grid>
    </Grid>
  )
}

type BetrieblicheAltersvorsorgeDetailsReadViewProps = BaseDetailsReadViewProps & {
  betrieblicheAltersvorsorgeDetails: BetrieblicheAltersvorsorgeDetails | undefined
}

export const BetrieblicheAltersvorsorgeDetailsReadView = ({
  produktId,
  betrieblicheAltersvorsorgeDetails,
}: BetrieblicheAltersvorsorgeDetailsReadViewProps): ReactElement | null => {
  const { findProduktById } = useProduktSpecs('LEBEN')
  const produktSpecs = findProduktById(produktId)
  const requiresDurchfuehrungsweg = produktSpecs?.durchfuehrungswege?.length

  if (!isRelevantForDetailsTypAndFinfireProduktId('BAV', produktId) && !requiresDurchfuehrungsweg) {
    return null
  }

  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Durchführungsweg'}
        value={getOptionLabel(
          betrieblicheAltersvorsorgeDetails?.durchfuehrungsweg,
          durchfuehrungswegOptions,
        )}
      />
      <LabelValueEntry
        label={'Typ der Versicherung'}
        value={getOptionLabel(betrieblicheAltersvorsorgeDetails?.bavTyp, bavTypOptions)}
      />
      <LabelValueEntry
        label={'Pauschalversteuert'}
        value={formatBoolean(betrieblicheAltersvorsorgeDetails?.pauschalversteuert)}
      />
      <LabelValueEntry
        label={'Leistungszusage (% des Nettoeinkommens)'}
        value={formatPercent(betrieblicheAltersvorsorgeDetails?.leistungszusageProzentual)}
      />
    </Stack>
  )
}
