import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency, formatDate, formatPercent } from 'components/util/formatUtil'
import { BetrieblicheAltersvorsorgeDetailsFormModel } from 'form/vertraege/VertragFormModel'
import {
  BavTyp,
  BetrieblicheAltersvorsorgeDetails,
  ProduktMapping,
  RentenDetails,
} from 'interfaces/mynorm-api-model-interfaces'
import {
  isRelevantForDetailsTypAndFinfireProduktId,
  isVersicherungsTyp,
  useProduktMappings,
} from 'state/useProduktConfig'

type RentenDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  bavDetails?: BetrieblicheAltersvorsorgeDetailsFormModel
}

const FORM_BLOCK_TITLE = 'Details - Rente'

export const RentenDetailsFormBlock = ({
  produktId,
  name,
  bavDetails,
}: RentenDetailsFormBlockProps): ReactElement | null => {
  const produktMappings = useProduktMappings()
  const baseName = `${name}.rentenDetails`
  if (!isRentenDetailsRelevant(produktId, produktMappings, bavDetails?.bavTyp)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>

      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.renteMonatlich`}
          label={'Rente garantiert mtl.'}
          inputUnit='€'
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.renteMonatlichMitUeberschuss`}
          label={'Rente mit Überschuss mtl.'}
          inputUnit='€'
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput name={`${baseName}.beginnRentenzahlung`} label={'Beginn Rentenzahlung'} />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          name={`${baseName}.rentensteigerungJaehrlich`}
          label={'Jährliche Rentensteigerung'}
          inputUnit='%'
        />
      </Grid>
    </Grid>
  )
}

type RentenDetailsReadViewProps = BaseDetailsReadViewProps & {
  rentenDetails: RentenDetails | undefined
  bavDetails: BetrieblicheAltersvorsorgeDetails | undefined
}

export const RentenDetailsReadView = ({
  produktId,
  rentenDetails,
  bavDetails,
}: RentenDetailsReadViewProps): ReactElement | null => {
  const produktMappings = useProduktMappings()
  if (!isRentenDetailsRelevant(produktId, produktMappings, bavDetails?.bavTyp)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Rente garantiert monatlich'}
        value={formatCurrency(rentenDetails?.renteMonatlich)}
      />
      <LabelValueEntry
        label={'Rente mit Überschuss monatlich'}
        value={formatCurrency(rentenDetails?.renteMonatlichMitUeberschuss)}
      />
      <LabelValueEntry
        label={'Beginn Rentenzahlung'}
        value={formatDate(rentenDetails?.beginnRentenzahlung)}
      />
      <LabelValueEntry
        label={'Jährliche Rentensteigerung'}
        value={formatPercent(rentenDetails?.rentensteigerungJaehrlich)}
      />
    </Stack>
  )
}

export const isRentenDetailsRelevant = (
  produktId: string,
  produktMappings: ProduktMapping[],
  bavTyp?: BavTyp,
): boolean => {
  const isBAV = isVersicherungsTyp(produktId, 'BAV', produktMappings)
  return (
    (isBAV && bavTyp === 'RENTENVERSICHERUNG') ||
    (!isBAV && isRelevantForDetailsTypAndFinfireProduktId('RENTE', produktId))
  )
}
