import { handlePercent, nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { AnlagenStrukturFormModel } from 'form/KundeFormModel'
import { Anlagestruktur } from 'interfaces/mynorm-api-model-interfaces'
import { mapToNumericPercentRequestModelType, mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertAnlageStrukturFormModel = (
  anlagenStruktur: Anlagestruktur | undefined,
  defaultAnlagenStruktur: AnlagenStrukturFormModel,
): AnlagenStrukturFormModel => {
  return {
    quelleRisikoklassifizierung: valueOrDefault(
      anlagenStruktur?.quelleRisikoklassifizierung || '',
      defaultAnlagenStruktur.quelleRisikoklassifizierung,
    ),
    riskanteAnteile: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.riskanteAnteile)?.toString(),
      defaultAnlagenStruktur.riskanteAnteile,
    ),

    immobilien: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.immobilien)?.toString(),
      defaultAnlagenStruktur.immobilien,
    ),
    sonstige: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.sonstiges)?.toString(),
      defaultAnlagenStruktur.sonstige,
    ),
    zinsAnlagen: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.zinsanlagen)?.toString(),
      defaultAnlagenStruktur.zinsAnlagen,
    ),
    beteiligungen: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.beteiligungen)?.toString(),
      defaultAnlagenStruktur.beteiligungen,
    ),
    aktien: nullableValueOrDefault(
      handlePercent(anlagenStruktur?.aktien)?.toString(),
      defaultAnlagenStruktur.aktien,
    ),
  }
}

export const convertAnlagenStruktur = (
  formModel: AnlagenStrukturFormModel,
  source?: Anlagestruktur,
  useFormModelAsDefault?: boolean,
): RecursivePartial<Anlagestruktur> => {
  return {
    ...source,
    aktien: mapToNumericPercentRequestModelType(
      formModel.aktien,
      source?.aktien,
      useFormModelAsDefault,
    ),
    zinsanlagen: mapToNumericPercentRequestModelType(
      formModel.zinsAnlagen,
      source?.zinsanlagen,
      useFormModelAsDefault,
    ),
    immobilien: mapToNumericPercentRequestModelType(
      formModel.immobilien,
      source?.immobilien,
      useFormModelAsDefault,
    ),
    beteiligungen: mapToNumericPercentRequestModelType(
      formModel.beteiligungen,
      source?.beteiligungen,
      useFormModelAsDefault,
    ),
    sonstiges: mapToNumericPercentRequestModelType(
      formModel.sonstige,
      source?.sonstiges,
      useFormModelAsDefault,
    ),
    riskanteAnteile: mapToNumericPercentRequestModelType(
      formModel.riskanteAnteile,
      source?.riskanteAnteile,
      useFormModelAsDefault,
    ),
    quelleRisikoklassifizierung: mapToRequestModelType(
      formModel.quelleRisikoklassifizierung,
      source?.quelleRisikoklassifizierung,
      useFormModelAsDefault,
    ),
  }
}
