import produce from 'immer'

import {
  AnlegerprofilAndereAnlagenFormModel,
  AnlegerprofilDienstleistungenFormModel,
  AnlegerprofilFinanzanlagenFormModel,
  AnlegerprofilKreditfinanzierteAnlagenFormModel,
  KundeFormModel,
} from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type AnlegerprofilMutator = {
  updateFinanzanlagen: (
    personIndex: number,
    finanzanlagen: AnlegerprofilFinanzanlagenFormModel,
  ) => void
  updateAndereAnlagen: (
    personIndex: number,
    andereAnlagen: AnlegerprofilAndereAnlagenFormModel,
  ) => void
  updateKreditfinanzierteAnlagen: (
    personIndex: number,
    kreditfinanzierteAnlagen: AnlegerprofilKreditfinanzierteAnlagenFormModel,
  ) => void
  updateDienstleistungen: (
    personIndex: number,
    dienstleistungen: AnlegerprofilDienstleistungenFormModel,
  ) => void
}

export const anlegerprofilMutator = (set: Mutator): AnlegerprofilMutator => {
  return {
    updateFinanzanlagen: (personIndex, finanzanlagen) => {
      set(({ editableKunde }) => ({
        editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
          draft.personen[personIndex].anlegerprofil.finanzanlagen = finanzanlagen
        }),
      }))
    },
    updateAndereAnlagen: (personIndex, andereAnlagen) => {
      set(({ editableKunde }) => ({
        editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
          draft.personen[personIndex].anlegerprofil.andereAnlagen = andereAnlagen
        }),
      }))
    },
    updateKreditfinanzierteAnlagen: (personIndex, kreditfinanzierteAnlagen) => {
      set(({ editableKunde }) => ({
        editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
          draft.personen[personIndex].anlegerprofil.kreditfinanzierteAnlagen =
            kreditfinanzierteAnlagen
        }),
      }))
    },
    updateDienstleistungen: (personIndex, dienstleistungen) => {
      set(({ editableKunde }) => ({
        editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
          draft.personen[personIndex].anlegerprofil.dienstleistungen = dienstleistungen
        }),
      }))
    },
  }
}
