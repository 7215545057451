import React, { ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { useRemovePartnerOfHaushalt } from 'api/haushalt/useUpdatePartnerOfHaushalt'
import { RemoveIconButton } from 'components/controls/buttons/RemoveIconButton'
import styles from 'components/partner/RemovePartnerButton.module.scss'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const RemovePartnerButton = (): ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { haushalt } = useHaushaltState()

  const {
    mutate: removePartner,
    isLoading: isRemovePartnerLoading,
    isError: isRemovePartnerError,
    reset: resetRemovePartnerMutation,
  } = useRemovePartnerOfHaushalt()

  const handleRemovePartner = (): void => {
    if (haushalt) {
      removePartner(haushalt, {
        onSuccess: handleClose,
      })
    }
  }

  const handleClose = async (): Promise<void> => {
    await resetRemovePartnerMutation()
    setOpen(false)
  }

  return (
    <>
      <RemoveIconButton onClick={() => setOpen(true)} />
      <Dialog open={open} fullWidth maxWidth={'sm'}>
        <DialogTitle color={'error'}>Achtung!</DialogTitle>
        <DialogContent>
          Der Partner wird sofort vom Haushalt entfernt. Möchten Sie den Partner wirklich entfernen?
          {isRemovePartnerError && (
            <Alert severity={'error'} className={styles.error}>
              Beim Entfernen des Partners ist ein Fehler aufgetreten!
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={'error'} disabled={isRemovePartnerLoading}>
            Abbrechen
          </Button>
          <LoadingButton
            onClick={handleRemovePartner}
            variant={'contained'}
            color={'error'}
            loading={isRemovePartnerLoading}
          >
            Partner entfernen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
