import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { EmphasizedFormBlock } from 'components/form-layout/EmphasizedFormBlock'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { ImmobilieFormModel } from 'form/KundeFormModel'
import { immobilieInitialTouched } from 'form/kundeFormTouched'
import { immobilieValidationSchema } from 'form/kundeFormValidationSchema'
import {
  baumassnahmenOptions,
  bundeslandOptions,
  getOptionLabel,
  immobilieNutzungsArtOptions,
  objektArtOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { ImmobilienMutator } from 'state/immobilienMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

type PersonalFinanzenImmobilienContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenImmobilienContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenImmobilienContainerProps): ReactElement => {
  const { editableKunde, immobilienMutator } = useEditableKunde()
  const immobilien = editableKunde.personen[personIndex].vermoegen.immobilien
  return (
    <>
      {immobilien.map((immobilie, immoIndex) => {
        return (
          <PersonalFinanzenImmobilieContainer
            key={immoIndex}
            personIndex={personIndex}
            immoIndex={immoIndex}
            immobilienMutator={immobilienMutator}
            selectiveFormProps={selectiveFormProps}
          />
        )
      })}
    </>
  )
}

type PersonalFinanzenImmobilieContainerProps = {
  personIndex: number
  immoIndex: number
  immobilienMutator: ImmobilienMutator
  selectiveFormProps?: SelectiveFormProps
}

const PersonalFinanzenImmobilieContainer = ({
  personIndex,
  immoIndex,
  immobilienMutator,
  selectiveFormProps,
}: PersonalFinanzenImmobilieContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.vermoegen.immobilien`
  const formPath = `${basePath}.${immoIndex}`
  const { addPathsToDelete } = useSelectivePathsToUpdateState()
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<ImmobilieFormModel>(
    formPath,
    selectiveFormProps,
  )

  const handleRemove = (formValue: ImmobilieFormModel): void => {
    immobilienMutator.removeImmobilie(personIndex, immoIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }

  return (
    <EditableKundeFormik
      key={immoIndex}
      initialValues={initialFormValues}
      updateState={(immobilie) =>
        immobilienMutator.updateImmobilie(personIndex, immoIndex, immobilie)
      }
      validationSchema={immobilieValidationSchema}
      initialTouched={immobilieInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        const isNotMietwohnung = formikProps.values.objektArt !== 'MIETWOHNUNG'
        const isNotMiete =
          isNotMietwohnung && formikProps.values.immobilieNutzungsArt !== 'GEMIETET'
        const isExternePersonEigentuemer = formikProps.values.eigentuemer?.includes(
          EXTERNE_PERSON_OPTION_VALUE,
        )
        return (
          <ExpansionPanel
            title={`${getOptionLabel(initialFormValues.objektArt, objektArtOptions)} - ${
              formikProps.values.bezeichnung || 'ohne Bezeichnung'
            }`}
            index={immoIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={initialFormValues.isDeleted}
            displayAsNew={selectiveFormProps && initialFormValues.isNew}
            displayValidationError={!formikProps.isValid}
            displaySaveError={formikProps.values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container rowSpacing={1} spacing={2}>
                  <Grid item xs={8}>
                    <SelectInput
                      options={objektArtOptions}
                      name={`objektArt`}
                      label={'Immobilientyp'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {isNotMietwohnung && (
                      <SelectInput
                        options={immobilieNutzungsArtOptions}
                        name={'immobilieNutzungsArt'}
                        label={'Nutzungsart'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput<ImmobilieFormModel>
                      name={'bezeichnung'}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <VersichertePersonSelect
                      name={'eigentuemer'}
                      label={isNotMiete ? 'Eigentümer' : 'Mieter'}
                      selectiveFieldProps={selectiveFieldProps}
                      withExternePerson
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {isExternePersonEigentuemer && (
                      <TextInput<ImmobilieFormModel>
                        name={'eigentuemerExternePersonName'}
                        label={'Externe Person Name'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    )}
                  </Grid>
                  {!isNotMiete && (
                    <>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`wohnflaeche`}
                          label={'Wohnfläche'}
                          inputUnit={'m²'}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`neuwertHausrat`}
                          label={'Neuwert Hausrat'}
                          selectiveFieldProps={selectiveFieldProps}
                          inputUnit={'€'}
                          tooltip={
                            'Der Neuwert des Hausrats muss nur angegeben\n' +
                            'werden, sofern er bekannt ist.\n' +
                            'Er wird dann als Bedarf in der Regel\n' +
                            'Hausrat verwendet. Falls der Neuwert nicht angegeben\n' +
                            'ist, wird der Bedarf anhand der Wohnfläche ermittelt.'
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {isNotMiete && (
                    <>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`baujahr`}
                          label={'Baujahr'}
                          noDecimals
                          noGrouping
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {isNotMiete && (
                          <NumberInput
                            name={`verkehrswert`}
                            label={'Verkehrswert'}
                            inputUnit={'€'}
                            selectiveFieldProps={selectiveFieldProps}
                            tooltip={'Der Verkehrswert ist relevant für die Vermögensbilanz.'}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`wohnflaeche`}
                          label={'Wohnfläche'}
                          inputUnit={'m²'}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {isNotMiete && (
                          <NumberInput
                            name={`kreditlinieInstandhaltung`}
                            label={'Kreditlinie Instandhaltung'}
                            selectiveFieldProps={selectiveFieldProps}
                            tooltip={
                              'Verfügbare Kreditlinien aus z.B. Bausparverträgen, die als Rücklage für die Instandhaltung der Immobilie dienen, können hier erfasst werden.'
                            }
                            inputUnit={'€'}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <SelectInput
                          name={`aktuelleBaumassnahmen`}
                          label={'Aktuelle Baumaßnahmen'}
                          options={baumassnahmenOptions}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {isNotMiete && (
                          <Stack>
                            <CheckboxInput
                              name={`photovoltaik`}
                              label={'Photovoltaik?'}
                              selectiveFieldProps={selectiveFieldProps}
                            />
                            <CheckboxInput
                              name={`oeltank`}
                              label={'Öltank?'}
                              selectiveFieldProps={selectiveFieldProps}
                            />
                          </Stack>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxInput
                          name={`entschuldetImRuhestand`}
                          label={'Anrechnung als Altersimmobilie'}
                          selectiveFieldProps={selectiveFieldProps}
                          tooltip={
                            'Sofern die Immobilie im Ruhestand als eigener Alterswohnsitz verwendet werden soll und dann entschuldet ist,\n' +
                            'sollte dies gekennzeichnet werden.\n' +
                            'Die Immobilie wird dann bei der Regel Altersvorsorge\n' +
                            'mit 20% vom Sollwert als Mietersparnis berücksichtigt.'
                          }
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <NumberInput
                          name={`neubauSumme`}
                          label={'Neubausumme'}
                          selectiveFieldProps={selectiveFieldProps}
                          inputUnit={'€'}
                          tooltip={
                            'Die Neubausumme ist relevant für die Berechnung der Instandhaltungsrücklage.'
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`neuwertHausrat`}
                          label={'Neuwert Hausrat'}
                          selectiveFieldProps={selectiveFieldProps}
                          inputUnit={'€'}
                          tooltip={
                            'Der Neuwert des Hausrats muss nur angegeben\n' +
                            'werden, sofern er bekannt ist.\n' +
                            'Er wird dann als Bedarf in der Regel\n' +
                            'Hausrat verwendet. Falls der Neuwert nicht angegeben\n' +
                            'ist, wird der Bedarf anhand der Wohnfläche ermittelt.'
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`wert1914`}
                          label={'Wert 1914'}
                          selectiveFieldProps={selectiveFieldProps}
                          inputUnit={'Mark'}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name={`riskanteAnteile`}
                          label={'Tendenziell riskante Anteile'}
                          selectiveFieldProps={selectiveFieldProps}
                          inputUnit={'%'}
                          tooltip={
                            'Der Vermögenswert muss entsprechend seines\n' +
                            'individuellen Risikos eingestuft werden.\n' +
                            'Die Klassifizierung umfasst die Bandbreite\n' +
                            'von 100% tendenziell sichere Anlagen\n' +
                            'bis zu 100% tendenziell riskante Anlagen.'
                          }
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextInput
                          name={'quelleRisikoklassifizierung'}
                          label={'Quelle Risikoklassifizierung'}
                          selectiveFieldProps={selectiveFieldProps}
                          tooltip={
                            'Als Quelle der Risikoklassifizierung\n' +
                            'sollte angegeben werden, ob es sich\n' +
                            'um eine eigene Einschätzung handelt\n' +
                            'oder ob die Klassifizierung einem Prospekt,\n' +
                            'den KIID-Informationen etc. entnommen wurde.'
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {formikProps.values.objektArt === 'EIGENTUMSWOHNUNG' && (
                          <Stack>
                            <CheckboxInput
                              name={`wohngebaeudeVersicherungViaETG`}
                              label={'Wohngebäudeversicherung über Eigentümergemeinschaft?'}
                              selectiveFieldProps={selectiveFieldProps}
                            />
                            <CheckboxInput
                              name={`hausUndGrundbesitzerHaftpflichtViaETG`}
                              label={'Haus- und Grundbesitzer-HV über Eigentümergemeinschaft?'}
                              selectiveFieldProps={selectiveFieldProps}
                            />
                            <CheckboxInput
                              name={`instanhaltungsruecklageViaETG`}
                              label={'Instandhaltungsrücklage über Eigentümergemeinschaft?'}
                              selectiveFieldProps={selectiveFieldProps}
                            />
                          </Stack>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Adresse'}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <TextInput
                            name={`adresse.strasse`}
                            label={'Straße'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            name={`adresse.hausnummer`}
                            label={'Hausnummer'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            name={`adresse.postleitzahl`}
                            label={'Postleitzahl'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <TextInput
                            name={`adresse.ort`}
                            label={'Ort'}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectInput
                            name={`adresse.bundesland`}
                            label={'Bundesland'}
                            options={bundeslandOptions}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                      </Grid>
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={`bemerkungen`}
                      label={'Bemerkungen'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}
