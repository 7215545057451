import React, { ReactElement, ReactNode } from 'react'
import { FormControl, FormLabel, Slider, Stack } from '@mui/material'
import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'

import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import styles from 'components/controls/inputs/SliderInput.module.scss'
import { SelectiveNumberInput } from 'components/controls/selective-inputs/SelectiveNumberInput'
import { formatNumber } from 'components/util/formatUtil'

type SliderInputProps<FormType> = BaseInputProps<FormType> & {
  min?: number
  max?: number
  step?: number
  labelUnit?: '%' | ''
  sliderLabelValueFormat?: (value: number, index: number) => React.ReactNode
  orientation?: 'horizontal' | 'vertical'
  disabled?: boolean
}

export const SliderInput = <FormType,>({
  name,
  label,
  selectiveFieldProps,
  ...props
}: SliderInputProps<FormType>): ReactElement => {
  const [{ ...fieldInputProps }] = useField(name)
  const { setFieldValue } = useFormikContext()

  const setValue = (value: number | number[], name: string): void => {
    setFieldValue(name, value)
  }

  if (selectiveFieldProps) {
    return (
      <SelectiveNumberInput<FormType>
        name={name}
        label={label}
        selectiveFieldProps={selectiveFieldProps}
        inputUnit={props.labelUnit}
      />
    )
  }
  return (
    <BaseSlider
      name={fieldInputProps.name}
      label={label}
      value={Number(fieldInputProps.value) || 0}
      setValue={setValue}
      defaultValue={Number(fieldInputProps.value) || 0}
      {...props}
    />
  )
}

type BaseSliderProps<FormType> = SliderInputProps<FormType> & {
  value: number
  setValue: (value: number | number[], name: string) => void
  defaultValue: number
  onChange?: (name: string, value: number | number[]) => void
}

export const BaseSlider = <FormType,>({
  name,
  label,
  tooltip,
  min = 0,
  max = 100,
  step = 1,
  labelUnit = '',
  sliderLabelValueFormat,
  orientation = 'horizontal',
  value,
  setValue,
  defaultValue,
  disabled,
}: BaseSliderProps<FormType>): ReactElement => {
  const formatDisplayLabel = (): string => {
    const numericValue = sliderLabelValueFormat?.(value, 0) || formatNumber(value)
    return `${numericValue} ${labelUnit}`.trim()
  }

  const formatSliderLabelValue = (value: number, index: number): ReactNode => {
    if (sliderLabelValueFormat) {
      return sliderLabelValueFormat(value, index)
    }
    return formatNumber(value)
  }

  return (
    <InputWrapper tooltip={tooltip}>
      <FormControl fullWidth>
        <Stack className={orientation === 'vertical' && styles.verticalSliderContainer}>
          <FormLabel>
            <Stack
              direction={orientation === 'vertical' ? 'column' : 'row'}
              className={
                orientation === 'vertical'
                  ? styles.verticalLabelContainer
                  : styles.horizontalLabelContainer
              }
            >
              <div>{label}</div>
              <div>{formatDisplayLabel()}</div>
            </Stack>
          </FormLabel>
          <Slider
            name={name}
            value={value}
            max={max}
            min={min}
            defaultValue={defaultValue}
            valueLabelDisplay={'auto'}
            className={clsx(
              styles.commonSlider,
              orientation === 'vertical' ? styles.verticalSlider : styles.horizontalSlider,
              value === 0 && styles.zeroThumb,
            )}
            step={step}
            onChange={(event, value) => {
              setValue(value, name)
            }}
            valueLabelFormat={formatSliderLabelValue}
            orientation={orientation}
            disabled={disabled}
          ></Slider>
        </Stack>
      </FormControl>
    </InputWrapper>
  )
}
