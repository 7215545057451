import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { TextInput } from 'components/controls/inputs/TextInput'
import { VersichertesRisikoBlockProps } from 'components/personal/absicherung2/blocks/RisikenFormBlock'

export const VersichertesObjektBlock = ({
  name,
  risiko,
  disabled,
}: VersichertesRisikoBlockProps): ReactElement | null => {
  const { risikoTyp } = risiko
  if (risikoTyp !== 'OBJEKT') {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <TextInput name={`${name}.risikoObjekt`} label={'Objektbezeichnung'} disabled={disabled} />
      </Grid>
    </Grid>
  )
}
