import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import backgroundImage from 'assets/images/guidance/personnel/zugang-sichern/zugang_sichern_background.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/personnel/GuidancePersonnel4Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidancePersonnel4Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={1}
      menuSubIndex={3}
      previousPagePath={Paths.guidancePersonnel3}
      nextPagePath={Paths.guidancePersonnel5}
      guidanceColor='personnel'
      backgroundImage={backgroundImage}
      numberOfInterPages={2}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title={'JETZT REGISTRIEREN UND DIREKTEN ZUGANG SICHERN'}
          subTitle={'WIR PERSONALBERATER'}
        >
          <>
            <Box className={styles.textContainer}>
              {interPageIndex === 1 && (
                <h3 className={styles.text}>
                  Jetzt bei <span className={styles.emphasis}>850 Unternehmen</span> bewerben und
                  Marktwert checken!
                </h3>
              )}
            </Box>
          </>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
