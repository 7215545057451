import React, { PropsWithChildren, ReactElement } from 'react'
import { Grid } from '@mui/material'

import styles from 'components/personal/finanzen/PersonalFinanzenFormBlock.module.scss'

type PersonalFinanzenFormBlockProps = {
  title?: string
  icon?: ReactElement
}

export const PersonalFinanzenFormBlock = ({
  title,
  icon,
  children,
}: PropsWithChildren<PersonalFinanzenFormBlockProps>): ReactElement => {
  return (
    <Grid container>
      <Grid item xs={12} container className={styles.title}>
        {icon}
        <h4>{title || <span>&nbsp;</span>}</h4>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}
