import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { GesetzlicheKrankenversicherungDetailsFormModel } from 'form/vertraege/VertragFormModel'
import {
  GesetzlicheKrankenversicherungArt,
  GesetzlicheKrankenversicherungDetails,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const gesetzlicheKrankenversicherungArtOptions: Option<GesetzlicheKrankenversicherungArt>[] = [
  { value: 'PFLICHT', label: 'Pflichtversicherung' },
  { value: 'FREIWILLIG', label: 'Freiwillig versichert' },
]
const FORM_BLOCK_TITLE = 'Details - Gesetzliche Krankenversicherung'

type GesetzlicheKrankenversicherungDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  gesetzlicheKrankenversicherungDetailsFormModel:
    | GesetzlicheKrankenversicherungDetailsFormModel
    | undefined
}

export const GesetzlicheKrankenversicherungDetailsFormBlock = ({
  name,
  produktId,
  gesetzlicheKrankenversicherungDetailsFormModel,
}: GesetzlicheKrankenversicherungDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.gesetzlicheKrankenversicherungDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('GESETZLICHE_KRANKENVERSICHERUNG', produktId)) {
    return null
  }

  const isFreiwillig =
    gesetzlicheKrankenversicherungDetailsFormModel?.gesetzlicheKrankenversicherungArt ===
    'FREIWILLIG'
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          options={gesetzlicheKrankenversicherungArtOptions}
          name={`${baseName}.gesetzlicheKrankenversicherungArt`}
          label={'Art der GKV'}
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item md={6} xs={12}>
        {isFreiwillig && (
          <CheckboxInput name={`${baseName}.anspruchAufKrankengeld`} label={'Anspruch auf KG'} />
        )}
      </Grid>
    </Grid>
  )
}

type GesetzlicheKrankenversicherungDetailsReadViewProps = BaseDetailsReadViewProps & {
  gesetzlicheKrankenversicherungDetails?: GesetzlicheKrankenversicherungDetails
}

export const GesetzlicheKrankenversicherungDetailsReadView = ({
  produktId,
  gesetzlicheKrankenversicherungDetails,
}: GesetzlicheKrankenversicherungDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('GESETZLICHE_KRANKENVERSICHERUNG', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Art der GKV'}
        value={getOptionLabel(
          gesetzlicheKrankenversicherungDetails?.gesetzlicheKrankenversicherungArt,
          gesetzlicheKrankenversicherungArtOptions,
        )}
      />
      {gesetzlicheKrankenversicherungDetails?.gesetzlicheKrankenversicherungArt ===
        'FREIWILLIG' && (
        <LabelValueEntry
          label={'Anspruch auf KG'}
          value={formatBoolean(gesetzlicheKrankenversicherungDetails?.anspruchAufKrankengeld)}
        />
      )}
    </Stack>
  )
}
