import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { BeteiligungFormModel, KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type BeteiligungenMutator = {
  updateBeteiligung: (
    personIndex: number,
    beteiligungIndex: number,
    beteiligungFormModel: BeteiligungFormModel,
  ) => void
  removeBeteiligung: (personIndex: number, beteiligungIndex: number) => void
  addBeteiligung: (personIndex: number, beteiligungFormModel: BeteiligungFormModel) => void
}

export const beteiligungenMutator = (set: Mutator): BeteiligungenMutator => {
  return {
    updateBeteiligung: (
      personIndex: number,
      beteiligungIndex: number,
      beteiligungFormModel: BeteiligungFormModel,
    ) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].vermoegen.beteiligungen[beteiligungIndex] =
              beteiligungFormModel
          }),
        }
      })
    },
    removeBeteiligung: (personIndex: number, beteiligungIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const beteiligung =
              draft.personen[personIndex].vermoegen.beteiligungen[beteiligungIndex]
            if (beteiligung.isNew) {
              draft.personen[personIndex].vermoegen.beteiligungen.splice(beteiligungIndex, 1)
            } else {
              draft.personen[personIndex].vermoegen.beteiligungen[beteiligungIndex].isDeleted = true
            }
          }),
        }
      })
    },
    addBeteiligung: (personIndex: number, beteiligungFormModel: BeteiligungFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const id = uuidv4()
            beteiligungFormModel.isNew = true
            beteiligungFormModel.meta.myviId = id
            beteiligungFormModel.meta.finfireId = id
            draft.personen[personIndex].vermoegen.beteiligungen.push(beteiligungFormModel)
          }),
        }
      })
    },
  }
}
