import React, { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'

import { CustomerDatenTabs } from 'components/personal/CustomerDatenTabs'

export const CustomerDatenRoute = (): ReactElement => {
  return (
    <CustomerDatenTabs>
      <Outlet />
    </CustomerDatenTabs>
  )
}
