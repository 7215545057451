import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { STALE_TIME_ONE_HOUR } from 'api/apiUtils'
import { BackendPaths } from 'configurations/backendPaths'
import { ProduktRisikoMapping, VertragGeschaeft } from 'interfaces/mynorm-api-model-interfaces'

export const useFetchRisikoTypen = (
  geschaeft: VertragGeschaeft,
  produktIds: string[],
): UseQueryResult<ProduktRisikoMapping[]> => {
  return useQuery(
    [geschaeft, produktIds, 'fetchRisikoTypen'],
    () => fetchRisikoTypen(geschaeft, produktIds),
    {
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME_ONE_HOUR,
    },
  )
}

export const fetchRisikoTypen = async (
  geschaeft: VertragGeschaeft,
  produktIds: string[],
): Promise<ProduktRisikoMapping[]> => {
  const queryParamsString = produktIds.join(',')
  const response = await axios.get<ProduktRisikoMapping[]>(
    `${BackendPaths.getRisikoTypen}/${geschaeft}?produktids=${queryParamsString}`,
  )
  return response.data ?? []
}
