import React, { PropsWithChildren, ReactElement } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Stack } from '@mui/material'

import { InfoTooltip } from 'components/controls/inputs/InfoTooltip'
import styles from 'components/controls/inputs/InputWrapper.module.scss'

export type InputWrapperProps = {
  tooltip?: string
}

export const InputWrapper = ({
  tooltip,
  children,
}: PropsWithChildren<InputWrapperProps>): ReactElement => {
  return (
    <Stack direction='row' className={styles.inputWrapper}>
      {children}
      <div>
        {tooltip ? <InfoTooltip tooltip={tooltip} /> : <InfoIcon className={styles.hiddenIcon} />}
      </div>
    </Stack>
  )
}
