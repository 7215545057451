import { KindFormModel, MenschFormModel, PersonFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

type UseHaushaltPersonenResult = {
  personen: PersonFormModel[]
  kinder: KindFormModel[]
  alle: MenschFormModel[]
  findMensch: (menschId: string | undefined) => MenschFormModel | undefined
}
export const useHaushaltPersonen = (): UseHaushaltPersonenResult => {
  const { editableKunde } = useEditableKunde()
  const alle = [...editableKunde.personen, ...editableKunde.kinder]
  return {
    personen: editableKunde.personen,
    kinder: editableKunde.kinder,
    alle: alle,
    findMensch: (menschId) => {
      return alle.find((mensch) => hasId(mensch, menschId))
    },
  }
}
