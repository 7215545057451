import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { PflegerenteDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Pflegerente'

type PflegerenteDetailsFormBlockProps = BaseDetailsFormBlockProps

export const PflegerenteDetailsFormBlock = ({
  name,
  produktId,
}: PflegerenteDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.pflegerenteDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('PFLEGE_RENTE', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Typography variant={'body2'}>Ambulant</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegerenteGrad1Monatlich`}
          label={'PG 1'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegerenteGrad2Monatlich`}
          label={'PG 2'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegerenteGrad3Monatlich`}
          label={'PG 3'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegerenteGrad4Monatlich`}
          label={'PG 4'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.ambulantPflegerenteGrad5Monatlich`}
          label={'PG 5'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Typography variant={'body2'}>Stationär</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegerenteGrad1Monatlich`}
          label={'PG 1'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegerenteGrad2Monatlich`}
          label={'PG 2'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegerenteGrad3Monatlich`}
          label={'PG 3'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegerenteGrad4Monatlich`}
          label={'PG 4'}
          inputUnit={'€'}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <NumberInput
          name={`${baseName}.stationaerPflegerenteGrad5Monatlich`}
          label={'PG 5'}
          inputUnit={'€'}
        />
      </Grid>
    </Grid>
  )
}

type PflegerenteDetailsReadViewProps = BaseDetailsReadViewProps & {
  pflegerenteDetails: PflegerenteDetails | undefined
}

export const PflegerenteDetailsReadView = ({
  produktId,
  pflegerenteDetails,
}: PflegerenteDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('PFLEGE_RENTE', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <Grid container>
        <Grid item md={6}>
          <LabelValueEntry
            label={'Pflegegrad 1'}
            value={formatCurrency(pflegerenteDetails?.ambulantPflegerenteGrad1Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 2'}
            value={formatCurrency(pflegerenteDetails?.ambulantPflegerenteGrad2Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 3'}
            value={formatCurrency(pflegerenteDetails?.ambulantPflegerenteGrad3Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 4'}
            value={formatCurrency(pflegerenteDetails?.ambulantPflegerenteGrad4Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 5'}
            value={formatCurrency(pflegerenteDetails?.ambulantPflegerenteGrad5Monatlich)}
          />
        </Grid>
        <Grid item md={6}>
          <LabelValueEntry
            label={'Pflegegrad 1'}
            value={formatCurrency(pflegerenteDetails?.stationaerPflegerenteGrad1Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 2'}
            value={formatCurrency(pflegerenteDetails?.stationaerPflegerenteGrad2Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 3'}
            value={formatCurrency(pflegerenteDetails?.stationaerPflegerenteGrad3Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 4'}
            value={formatCurrency(pflegerenteDetails?.stationaerPflegerenteGrad4Monatlich)}
          />
          <LabelValueEntry
            label={'Pflegegrad 5'}
            value={formatCurrency(pflegerenteDetails?.stationaerPflegerenteGrad5Monatlich)}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
