import { isEmpty } from 'lodash'

import { AnschriftFormModel } from 'form/KundeFormModel'
import { Anschrift } from 'interfaces/mynorm-api-model-interfaces'
import { mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertAnschrift = (
  formModel: AnschriftFormModel | undefined,
  source?: RecursivePartial<Anschrift>,
): RecursivePartial<Anschrift> | undefined => {
  if (formModel == null || (isEmpty(formModel) && source === null)) {
    return source
  }
  return {
    bundesland: mapToRequestModelType(formModel.bundesland, source?.bundesland),
    hausnummer: mapToRequestModelType(formModel.hausnummer, source?.hausnummer),
    land: source?.land,
    ort: mapToRequestModelType(formModel.ort, source?.ort),
    postleitzahl: mapToRequestModelType(formModel.postleitzahl, source?.postleitzahl),
    strasse: mapToRequestModelType(formModel.strasse, source?.strasse),
  }
}
