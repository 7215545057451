import React, { memo, ReactElement } from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'

import styles from 'components/login-form/LoginDialog.module.scss'
import { LoginForm } from 'components/login-form/LoginForm'

type LoginDialogProps = {
  open: boolean
  onSuccessfulLogin: () => void
}

const LoginDialogComponent = ({ open, onSuccessfulLogin }: LoginDialogProps): ReactElement => {
  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} sx={{ backdropFilter: 'blur(5px)' }}>
      <DialogContent>
        <div className={styles.formBox}>
          <h1>Login</h1>
          <Typography>Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an.</Typography>
          <LoginForm onSuccessfulLogin={onSuccessfulLogin} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const LoginDialog = memo(LoginDialogComponent)
