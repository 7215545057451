import { useMemo } from 'react'
import { AxiosError } from 'axios'
import { identity } from 'lodash'

type ErrorWithMessage = { message: string }

export const useErrorMessage = (error: AxiosError | null | undefined): string | undefined => {
  return useMemo(() => {
    if (!error || !hasMessage(error)) {
      return undefined
    }

    if (!error.response || !error.response.data.message) {
      return DEFAULT_ERROR_MESSAGE
    }

    const message = error.response.data.message

    return extractMessageFromDifferentSources(message)
  }, [error])
}

/**
 * @param message
 * depending on the source of the failed request, this can be a lot of different shapes
 * This will likely have to be extended later.
 * examples:
 *   Finfire: 422 unknown: "{"errors":["<key> must not be empty","<key> must not be blank"]}"
 *            Hier ist die Reihenfolge NICHT deterministisch
 *   Salesforce: [{ "message": "email address not valid: <value>", "errorCode": "INVALID_EMAIL_ADDRESS" }]
 * Ticket: https://trello.com/c/3zEvpGhr
 */
const extractMessageFromDifferentSources = (message: string): string => {
  try {
    if (message.includes('[')) {
      const errorMessages = message.substring(message.indexOf('['), message.lastIndexOf(']') + 1)
      const parsed = JSON.parse(errorMessages)
      const isSalesForceError = Boolean(parsed[0].errorCode)
      const isFinfireError = typeof parsed[0] === 'string'

      if (isSalesForceError)
        return parsed.reduce(mergeSalesforceMessages, '') || DEFAULT_ERROR_MESSAGE
      if (isFinfireError) return parsed.reduce(mergeFinfireMessages, '') || DEFAULT_ERROR_MESSAGE
    }
    return DEFAULT_ERROR_MESSAGE
  } catch (ex) {
    return DEFAULT_ERROR_MESSAGE
  }
}

const hasMessage = (error: AxiosError): error is AxiosError<ErrorWithMessage> => {
  return Boolean((error.response?.data as ErrorWithMessage)?.message)
}

const mergeSalesforceMessages = (
  acc: string,
  curr: { message: string; errorCode: string },
): string => [acc, ERROR_MESSAGES[curr.errorCode]].filter(identity).join(', ')

const mergeFinfireMessages = (acc: string, curr: string): string =>
  [acc, ERROR_MESSAGES[curr]].filter(identity).join(', ')

const DEFAULT_ERROR_MESSAGE = 'Beim Speichern ist ein Fehler aufgetreten.'

const ERROR_MESSAGES: Record<string, string> = {
  INVALID_EMAIL_ADDRESS: 'E-Mail Adresse ist nicht valide.',
  'gesellschaft.gesellschaftId must not be blank': 'Gesellschaft muss angegeben werden.',
}
