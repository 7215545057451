import { FormikTouched } from 'formik'

import {
  AnlegerprofilAndereAnlagenFormModel,
  AnlegerprofilDienstleistungenFormModel,
  AnlegerprofilFinanzanlagenFormModel,
  AnlegerprofilKreditfinanzierteAnlagenFormModel,
  BeteiligungFormModel,
  DarlehenFormModel,
  GeldAnlageFormModel,
  GesetzlicheVersorgungFormModel,
  ImmobilieFormModel,
  KindFormModel,
  PersonFormModel,
  SachwertFormModel,
  ZielFormModel,
} from 'form/KundeFormModel'

export const immobilieInitialTouched: FormikTouched<ImmobilieFormModel> = {
  baujahr: true,
  neubauSumme: true,
  verkehrswert: true,
}

export const geldAnlageInitialTouched: FormikTouched<GeldAnlageFormModel> = {
  laufendeSparrate: {
    sparrate: true,
  },
  anlagenWert: {
    zeitwert: true,
    endwert1: true,
    endwert2: true,
    endwert3: true,
  },
}

export const darlehenInitialTouched: FormikTouched<DarlehenFormModel> = {
  darlehensbetrag: true,
  darlehensstand: true,
  datumDarlehensstand: true,
  darlehensrate: {
    sparrate: true,
  },
}

export const beteiligungInitialTouched: FormikTouched<BeteiligungFormModel> = {
  laufendeSparrate: {
    sparrate: true,
  },
  zeitwert: true,
  endwert: true,
}

export const sachwertInitialTouched: FormikTouched<SachwertFormModel> = {
  kaufpreis: true,
  zeitwert: true,
  datumKauf: true,
}

export const zielInitialTouched: FormikTouched<ZielFormModel> = {
  zielkapital: true,
}

export const personInitialTouched: FormikTouched<PersonFormModel> = {
  vorname: true,
  nachname: true,
  geburtsdatum: true,
  kontaktdaten: {
    email: true,
    telefon: true,
  },
  steuerIdentifikationsnummer: true,
  finanzen: {
    beruf: {
      versorgungsrechtlicherDienstzeitbeginnJahr: true,
      dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen: true,
      absoluterBetragEntgeltfortzahlung: true,
    },
    einkommen: {
      nettoNachSteuer: true,
      liquiditaetsErgebnisVermietung: true,
      nettovermoegenseinkommenBetrieb: true,
      nettorenteneinkommen: true,
      sonstigesNettoeinkommen: true,
      sonstigesNettovermoegenseinkommen: true,
      liquiditaetsErgebnisVermietungRuhestand: true,
      nettovermoegenseinkommenKapitalanlagen: true,
      einkommenWegfallKrankheitInProzent: true,
    },
    ausgaben: {
      konsum: true,
      wohnen: true,
      sonstige: true,
    },
    zugesagteDispokredite: true,
    genommeneDispokrediteDurchschnittlich: true,
    anzahlKinderPflegeversicherung: true,
  },
}

export const gesetzlicheVersorgungInitialTouched: FormikTouched<GesetzlicheVersorgungFormModel> = {
  gesetzlicheAltersrente: true,
  anpassungssatz1: true,
  altersrenteBeiAnpassung1: true,
  anpassungssatz2: true,
  altersrenteBeiAnpassung2: true,
  erwerbsminderungsrente: true,
  berufsunfaehigkeitsrente: true,
  witwenrente: true,
  waisenrente: true,
  beitrag: true,
}

export const anlegerprofilFinanzanlagenInitialTouched: FormikTouched<AnlegerprofilFinanzanlagenFormModel> =
  {
    geldmarktfondsKenntnisse: true,
    geldmarktfondsAnzahl: true,
    geldmarktfondsUmsatz: true,
    rentenfondsAnleihenKenntnisse: true,
    rentenfondsAnleihenAnzahl: true,
    rentenfondsAnleihenUmsatz: true,
    fondsAktienKenntnisse: true,
    fondsAktienAnzahl: true,
    fondsAktienUmsatz: true,
    mischfondsKenntnisse: true,
    mischfondsAnzahl: true,
    mischfondsUmsatz: true,
    immobilienfondsKenntnisse: true,
    immobilienfondsAnzahl: true,
    immobilienfondsUmsatz: true,
    strukturierteWertpapierKenntnisse: true,
    strukturierteWertpapierAnzahl: true,
    strukturierteWertpapierUmsatz: true,
    terminGeschaefteKenntnisse: true,
    terminGeschaefteAnzahl: true,
    terminGeschaefteUmsatz: true,
    geschlosseneInvestmentvermoegenKenntnisse: true,
    geschlosseneInvestmentvermoegenAnzahl: true,
    geschlosseneInvestmentvermoegenUmsatz: true,
    sonstigeKenntnisse: true,
    sonstigeAnzahl: true,
    sonstigeUmsatz: true,
  }

export const anlegerprofilAndereAnlagenInitialTouched: FormikTouched<AnlegerprofilAndereAnlagenFormModel> =
  {
    sparTagesTermingeldKenntnisse: true,
    immobilienKenntnisse: true,
    immobilienAnzahl: true,
    immobilienUmsatz: true,
    sonstigesKenntnisse: true,
    sonstigesAnzahl: true,
    sonstigesUmsatz: true,
  }

export const anlegerprofilKreditfinanzierteAnlagenInitialTouched: FormikTouched<AnlegerprofilKreditfinanzierteAnlagenFormModel> =
  {
    finanzierungsArt: true,
    kreditVolumen: true,
  }

export const anlegerprofilDienstleistungeInitialTouched: FormikTouched<AnlegerprofilDienstleistungenFormModel> =
  {
    beratungsfreieOrderausfuehrung: true,
    anlageberatung: true,
    vermoegensverwaltung: true,
  }

export const kindInitialTouched: FormikTouched<KindFormModel> = {
  geburtsdatum: true,
}
