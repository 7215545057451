import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent } from '@mui/material'

import styles from 'components/create-kunde/CreateKundeButton.module.scss'
import { NewKundeForm } from 'components/create-kunde/NewKundeForm'

export const CreateKundeButton = (): ReactElement => {
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const onCreateKundeSuccess = async (personId: string): Promise<void> => {
    navigate(`/kunde/${personId}/daten`)
  }

  return (
    <>
      <LoadingButton className={styles.newKundeLink} onClick={() => setOpen(true)}>
        Neuen Kunden erstellen?
      </LoadingButton>
      <Dialog open={open}>
        <DialogContent>
          <h3>Neuer Kunde</h3>
          <NewKundeForm onSuccess={onCreateKundeSuccess} onCancel={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}
