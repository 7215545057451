import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { SliderInput } from 'components/controls/inputs/SliderInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { EmphasizedFormBlock } from 'components/form-layout/EmphasizedFormBlock'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { BeteiligungFormModel } from 'form/KundeFormModel'
import { beteiligungInitialTouched } from 'form/kundeFormTouched'
import { beteiligungValidationSchema } from 'form/kundeFormValidationSchema'
import {
  beteiligungTypOptions,
  beteiligungUnternehmenTypOptions,
  getOptionLabel,
  zahlartOptions,
  zahlweiseOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { BeteiligungenMutator } from 'state/beteiligungenMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

type PersonalFinanzenBeteiligungenContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenBeteiligungenContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenBeteiligungenContainerProps): ReactElement => {
  const { editableKunde, beteiligungenMutator } = useEditableKunde()
  return (
    <>
      {editableKunde.personen[personIndex].vermoegen.beteiligungen.map(
        (beteiligung, beteiligungIndex) => {
          return (
            <PersonalFinanzenBeteiligungContainer
              key={beteiligungIndex}
              personIndex={personIndex}
              beteiligungIndex={beteiligungIndex}
              beteiligungenMutator={beteiligungenMutator}
              selectiveFormProps={selectiveFormProps}
            />
          )
        },
      )}
    </>
  )
}

type PersonalFinanzenBeteiligungContainerProps = {
  personIndex: number
  beteiligungIndex: number
  beteiligungenMutator: BeteiligungenMutator
  selectiveFormProps?: SelectiveFormProps
}

const PersonalFinanzenBeteiligungContainer = ({
  personIndex,
  beteiligungIndex,
  beteiligungenMutator,
  selectiveFormProps,
}: PersonalFinanzenBeteiligungContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.vermoegen.beteiligungen`
  const formPath = `${basePath}.${beteiligungIndex}`
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<BeteiligungFormModel>(
    formPath,
    selectiveFormProps,
  )
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: BeteiligungFormModel): void => {
    beteiligungenMutator.removeBeteiligung(personIndex, beteiligungIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }

  return (
    <EditableKundeFormik
      key={beteiligungIndex}
      initialValues={initialFormValues}
      updateState={(beteiligung) => {
        beteiligungenMutator.updateBeteiligung(personIndex, beteiligungIndex, beteiligung)
      }}
      validationSchema={beteiligungValidationSchema}
      initialTouched={beteiligungInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        const isBeteiligungseigentuemerExternePerson =
          formikProps.values.beteiligungseigentuemer.includes(EXTERNE_PERSON_OPTION_VALUE)
        const isBeteiligungUnternehmen = formikProps.values.beteiligungTyp === 'UNTERNEHMEN'
        return (
          <ExpansionPanel
            title={`${getOptionLabel(formikProps.values.beteiligungTyp, beteiligungTypOptions)} - ${
              formikProps.values.bezeichnung || 'ohne Bezeichnung'
            }`}
            index={beteiligungIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={formikProps.values.isDeleted}
            displayAsNew={selectiveFormProps && formikProps.values.isNew}
            displayValidationError={!formikProps.isValid}
            displaySaveError={formikProps.values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container rowSpacing={1} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectInput<BeteiligungFormModel>
                      options={beteiligungTypOptions}
                      name={`beteiligungTyp`}
                      label={'Beteiligungstyp'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {isBeteiligungUnternehmen && (
                      <SelectInput<BeteiligungFormModel>
                        options={beteiligungUnternehmenTypOptions}
                        name={`unternehmensTyp`}
                        label={'Unternehmenstyp'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput<BeteiligungFormModel>
                      name={`bezeichnung`}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<BeteiligungFormModel>
                      name={`gesellschaft`}
                      label={'Gesellschaft'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<BeteiligungFormModel>
                      name={`vertragsnummer`}
                      label={'Vertragsnummer'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateInput<BeteiligungFormModel>
                      name={`vertragsbeginn`}
                      label={'Vertragsbeginn'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateInput<BeteiligungFormModel>
                      name={`vertragsende`}
                      label={'Vertragsende'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <VersichertePersonSelect
                      name={'beteiligungseigentuemer'}
                      label={'Beteiligungseigentümer'}
                      selectiveFieldProps={selectiveFieldProps}
                      multiple
                      withExternePerson
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {isBeteiligungseigentuemerExternePerson && (
                      <TextInput<BeteiligungFormModel>
                        name={'beteiligungseigentuemerExternePersonName'}
                        label={'Externe Person Name'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <EmphasizedFormBlock title={'Laufende Sparrate'}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <SelectInput
                            name={`laufendeSparrate.zahlweise`}
                            label={'Zahlweise'}
                            options={zahlweiseOptions}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectInput
                            name={`laufendeSparrate.zahlart`}
                            label={'Zahlart'}
                            options={zahlartOptions}
                            selectiveFieldProps={selectiveFieldProps}
                          />
                        </Grid>
                        {formikProps.values.laufendeSparrate.zahlweise !== 'EINMALZAHLUNG' &&
                          formikProps.values.laufendeSparrate.zahlweise !== '' && (
                            <Grid item xs={6}>
                              <NumberInput
                                name={`laufendeSparrate.sparrate`}
                                label={'Beitrag'}
                                inputUnit={'€'}
                                selectiveFieldProps={selectiveFieldProps}
                              />
                            </Grid>
                          )}
                      </Grid>
                    </EmphasizedFormBlock>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<BeteiligungFormModel>
                      name={`zeitwert`}
                      label={'Zeitwert'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DateInput<BeteiligungFormModel>
                      name={`datumZeitwert`}
                      label={'Datum Zeitwert'}
                      minDate={new Date()}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<BeteiligungFormModel>
                      name={`endwert`}
                      label={'Endwert'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SliderInput<BeteiligungFormModel>
                      name={`riskanteAnteile`}
                      label={'Riskanter Anteil'}
                      min={0}
                      max={100}
                      step={10}
                      labelUnit='%'
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput<BeteiligungFormModel>
                      name={`quelleRisikoklassifizierung`}
                      label={'Quelle Risikoklassifizierung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<BeteiligungFormModel>
                      name={`bemerkungen`}
                      label={'Bemerkungen'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<BeteiligungFormModel>
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}
