import { useEffect, useState } from 'react'
import * as yup from 'yup'

import { KundeFormModel } from 'form/KundeFormModel'
import { kundeValidationSchema } from 'form/kundeFormValidationSchema'
import { useEditableKunde } from 'state/useEditableKunde'

export type ValidateKundeResultError = { name: string; errors: string[] }

type UseValidateKundeResult = { isValid: boolean; errors: ValidateKundeResultError[] }

export const useValidateKunde = (
  kunde: KundeFormModel = useEditableKunde().editableKunde,
): UseValidateKundeResult => {
  const [result, setResult] = useState<UseValidateKundeResult>({ isValid: true, errors: [] })

  useEffect(() => {
    kundeValidationSchema
      .validate(kunde, { abortEarly: false })
      .then(() => setResult({ isValid: true, errors: [] }))
      .catch((e: yup.ValidationError) => {
        setResult({
          isValid: false,
          errors: e.inner.map((error) => ({
            name: error.path ?? '',
            errors: error.errors,
          })),
        })
      })
  }, [kunde])

  return result
}
