import React, { ReactElement } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

import styles from 'components/login-form/LoginForm.module.scss'

type LoginFormTextFieldProps<T> = {
  name: keyof T & string
  label: React.ReactNode
  type?: React.HTMLInputTypeAttribute | undefined
}

export const LoginFormTextField = <T,>({
  name,
  label,
  type,
}: LoginFormTextFieldProps<T>): ReactElement => {
  const [field, { touched, error }] = useField(name)

  const isError = Boolean(touched && error)
  return (
    <div className={styles.textFieldBox}>
      <TextField
        {...field}
        id={name}
        name={name}
        type={type}
        label={label}
        error={isError}
        helperText={isError ? error : ''}
        variant={'outlined'}
        className={styles.textField}
      />
    </div>
  )
}
