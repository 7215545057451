import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import contribution from 'assets/images/guidance/career/beitrag-zur-entwicklung/beitrag.png'
import styles from 'components/guidance/career/GuidanceCareer9Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer9Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={8}
      previousPagePath={Paths.guidanceCareer8}
      nextPagePath={Paths.guidanceCareer10}
      guidanceColor='career'
    >
      <GuidanceSubPageTemplate
        title='UNSER BEITRAG ZU DEINER ENTWICKLUNG'
        subTitle='Das Karriere-Institut'
      >
        <Box className={styles.imageContainer}>
          <img src={contribution} alt='Entwicklungsdiagramm' />
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
