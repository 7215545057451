import React, { ReactElement, useState } from 'react'
import { Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import { useDeleteVertrag } from 'api/useDeleteVertrag'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { Vertrag } from 'interfaces/mynorm-api-model-interfaces'

const TITLE = 'Vertrag löschen'
const BODY_MESSAGE = 'Der Vertrag wird endgültig gelöscht. Sind Sie sicher?'
const ERROR_MESSAGE = 'Beim Löschen des Vertrages ist ein Fehler aufgetreten.'
const SUCCESS_MESSAGE = 'Der Vertrag wurde erfolgreich gelöscht.'
const CANCEL_TEXT = 'Abbrechen'
const CLOSE_TEXT = 'Schließen'
const ACTION_BUTTON_TEXT = 'Löschen'

type DeleteVertragDialogProps = {
  vertrag: Vertrag
}

export const DeleteVertragDialog = ({ vertrag }: DeleteVertragDialogProps): ReactElement | null => {
  const [open, setOpen] = useState(false)
  const { isLoading, isError, isSuccess, mutate, reset } = useDeleteVertrag()
  const { removeVertrag } = useHaushaltState()
  const handleClose = (): void => {
    setOpen(false)
  }
  const resetDialog = (): void => {
    reset()
  }

  const handleAction = (): void => {
    mutate(vertrag.meta.finfireId, {
      onSuccess: (data, vertragId) => {
        if (vertragId) {
          removeVertrag(vertragId)
        }
      },
    })
  }

  return (
    <>
      <Button
        startIcon={<Delete />}
        variant='outlined'
        color='error'
        onClick={() => setOpen(true)}
        disabled={!vertrag.fremdvertrag}
      >
        {ACTION_BUTTON_TEXT}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='md'
        TransitionProps={{ onExited: resetDialog }}
      >
        <DialogTitle>{TITLE}</DialogTitle>
        <DialogContent>
          {!isSuccess && !isError && <Typography>{BODY_MESSAGE}</Typography>}
          {isError && <Alert severity={'error'}>{ERROR_MESSAGE}</Alert>}
          {isSuccess && (
            <>
              <Alert severity={'success'}>{SUCCESS_MESSAGE}</Alert>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose} disabled={isLoading}>
            {!isSuccess && !isError ? CANCEL_TEXT : CLOSE_TEXT}
          </Button>
          {!isError && !isSuccess && (
            <LoadingButton
              color='error'
              variant='outlined'
              loading={isLoading}
              onClick={handleAction}
            >
              {ACTION_BUTTON_TEXT}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
