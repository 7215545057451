import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { getOptionLabel } from 'form/kundeOptions'
import { TierArt, TierhalterHaftpflichtDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const tierArtOptions: Option<TierArt>[] = [
  { label: 'Hund', value: 'HUND' },
  { label: 'Pferd', value: 'PFERD' },
]

type TierhalterhaftpflichtDetailsFormBlockProps = BaseDetailsFormBlockProps

const FORM_BLOCK_TITLE = 'Details - Tierhalterhaftpflicht'

export const TierhalterhaftpflichtDetailsFormBlock = ({
  produktId,
  name,
}: TierhalterhaftpflichtDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.tierhalterHaftpflichtDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('TIERHALTERHAFTPFLICHT', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <SelectInput name={`${baseName}.tierArt`} label={'Tierart'} options={tierArtOptions} />
        </Stack>
      </Grid>
    </Grid>
  )
}

type TierhalterhaftpflichtDetailReadViewProps = BaseDetailsReadViewProps & {
  tierhalterhaftpflichtDetails: TierhalterHaftpflichtDetails | undefined
}

export const TierhalterhaftpflichtDetailReadView = ({
  produktId,
  tierhalterhaftpflichtDetails,
}: TierhalterhaftpflichtDetailReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('TIERHALTERHAFTPFLICHT', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Tierart'}
        value={getOptionLabel(tierhalterhaftpflichtDetails?.tierArt, tierArtOptions)}
      />
    </Stack>
  )
}
