import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { SachwertFormModel } from 'form/KundeFormModel'
import { sachwertInitialTouched } from 'form/kundeFormTouched'
import { sachwertValidationSchema } from 'form/kundeFormValidationSchema'
import { getOptionLabel, sachwertTypOptions } from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { SachwerteMutator } from 'state/sachwerteMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'

type PersonalFinanzenSachwerteContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenSachwerteContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenSachwerteContainerProps): ReactElement => {
  const { editableKunde, sachwerteMutator } = useEditableKunde()
  const sachwerte = editableKunde.personen[personIndex].vermoegen.sachwerte
  return (
    <>
      {sachwerte.map((sachwert, index) => {
        return (
          <PersonalFinanzenSachwertContainer
            key={index}
            personIndex={personIndex}
            sachwertIndex={index}
            sachwerteMutator={sachwerteMutator}
            selectiveFormProps={selectiveFormProps}
          />
        )
      })}
    </>
  )
}

type PersonalFinanzenSachwertContainerProps = {
  personIndex: number
  sachwertIndex: number
  sachwerteMutator: SachwerteMutator
  selectiveFormProps?: SelectiveFormProps
}

const PersonalFinanzenSachwertContainer = ({
  personIndex,
  sachwertIndex,
  sachwerteMutator,
  selectiveFormProps,
}: PersonalFinanzenSachwertContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.vermoegen.sachwerte`
  const formPath = `${basePath}.${sachwertIndex}`
  const { addPathsToDelete } = useSelectivePathsToUpdateState()
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<SachwertFormModel>(
    formPath,
    selectiveFormProps,
  )

  const handleRemove = (formValue: SachwertFormModel): void => {
    sachwerteMutator.removeSachwert(personIndex, sachwertIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }
  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }

  return (
    <EditableKundeFormik
      key={sachwertIndex}
      initialValues={initialFormValues}
      updateState={(sachwert) =>
        sachwerteMutator.updateSachwert(personIndex, sachwertIndex, sachwert)
      }
      validationSchema={sachwertValidationSchema}
      initialTouched={sachwertInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        return (
          <ExpansionPanel
            title={`${getOptionLabel(initialFormValues.sachwertTyp, sachwertTypOptions)} - ${
              formikProps.values.bezeichnung || 'ohne Bezeichnung'
            }`}
            index={sachwertIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={initialFormValues.isDeleted}
            displayAsNew={selectiveFormProps && initialFormValues.isNew}
            displayValidationError={!formikProps.isValid}
            displaySaveError={formikProps.values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container rowSpacing={1} spacing={2}>
                  <Grid item xs={12}>
                    <SelectInput
                      options={sachwertTypOptions}
                      name={`sachwertTyp`}
                      label={'Sachwerttyp'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput<SachwertFormModel>
                      name={`bezeichnung`}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput<SachwertFormModel>
                      name={`kennzeichen`}
                      label={'Kennzeichen'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<SachwertFormModel>
                      name={`kaufpreis`}
                      label={'Kaufpreis'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DateInput<SachwertFormModel>
                      name={`datumKauf`}
                      label={'Kaufdatum'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<SachwertFormModel>
                      name={`zeitwert`}
                      label={'Zeitwert'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DateInput<SachwertFormModel>
                      name={`datumZeitwert`}
                      label={'Datum Zeitwert'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<SachwertFormModel>
                      name={`baujahr`}
                      label={'Baujahr'}
                      noDecimals
                      noGrouping
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DateInput<SachwertFormModel>
                      name={`datumErstzulassung`}
                      label={'Datum Erstzulassung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput<SachwertFormModel>
                      name={`marke`}
                      label={'Marke'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput<SachwertFormModel>
                      name={`modell`}
                      label={'Modell'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxInput<SachwertFormModel>
                      name={`wiederbeschaffungGesichert`}
                      label={'Wiederbeschaffung gesichert?'}
                      selectiveFieldProps={selectiveFieldProps}
                      tooltip={
                        'Ist die Beschädigung, Zerstörung oder der Verlust einer Wiederbeschaffung gesichert? Dies kann durch Versicherungen oder Rücklagen der Fall sein. Falls ja, setzen Sie bitte das Häkchen.'
                      }
                    />
                    <CheckboxInput<SachwertFormModel>
                      name={`ersatzinvestitionErforderlich`}
                      label={'Ersatzinvestition erforderlich?'}
                      selectiveFieldProps={selectiveFieldProps}
                      tooltip={
                        'Sofern eine Ersatzinvestition für das Fahrzeug erforderlich ist, sollte das entsprechende Kapital angespart werden. Es sollte ein Zielbaustein mit Zielzeitpunkt und Zielkapital angelegt werden.'
                      }
                    />
                    <CheckboxInput<SachwertFormModel>
                      name={`haftplichtViaExternenVertrag`}
                      label={
                        'Besteht Haftpflicht-Versicherungsschutz über einen externen Vertrag, z. B. Arbeitgeber, Leasinggesellschaft, Verein?'
                      }
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<SachwertFormModel>
                      name={`bemerkungen`}
                      label={'Bemerkungen'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<SachwertFormModel>
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}
