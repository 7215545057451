import {
  convertZahlweiseFormModel,
  handlePercent,
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { DarlehenFormModel } from 'form/KundeFormModel'
import { createDefaultDarlehenFormModel } from 'form/kundeFormModelCreator'
import { Darlehen, TilgungsTyp } from 'interfaces/mynorm-api-model-interfaces'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

const defaultDarlehenFormModel = createDefaultDarlehenFormModel()

export const convertToDarlehenFormModel = (darlehen: Darlehen | undefined): DarlehenFormModel => {
  if (!darlehen) {
    return defaultDarlehenFormModel
  }
  return {
    meta: convertToMeta(darlehen.meta),
    darlehenTyp: valueOrDefault(darlehen.darlehenTyp, defaultDarlehenFormModel.darlehenTyp),
    bezeichnung: nullableValueOrDefault(darlehen.bezeichnung, defaultDarlehenFormModel.bezeichnung),
    bemerkungen: nullableValueOrDefault(darlehen.bemerkungen, defaultDarlehenFormModel.bemerkungen),
    bemerkungenIntern: nullableValueOrDefault(
      darlehen.bemerkungenIntern,
      defaultDarlehenFormModel.bemerkungenIntern,
    ),
    darlehensnehmer: nullableValueOrDefault(
      darlehen.darlehensnehmer?.map((e) => e.personFinfireId ?? EXTERNE_PERSON_OPTION_VALUE),
      defaultDarlehenFormModel.darlehensnehmer,
    ),
    darlehensnehmerExternePersonName: nullableValueOrDefault(
      darlehen.darlehensnehmer?.find((e) => e.externePerson)?.externePersonName,
      defaultDarlehenFormModel.darlehensnehmerExternePersonName,
    ),
    darlehensgeber: valueOrDefault(
      darlehen.darlehensgeber,
      defaultDarlehenFormModel.darlehensgeber,
    ),
    vertragsnummer: nullableValueOrDefault(
      darlehen.vertragsnummer,
      defaultDarlehenFormModel.vertragsnummer,
    ),
    darlehensbeginn: nullableDayjsOrDefault(
      darlehen.darlehensbeginn,
      defaultDarlehenFormModel.darlehensbeginn,
    ),
    darlehensende: nullableDayjsOrDefault(
      darlehen.darlehensende,
      defaultDarlehenFormModel.darlehensende,
    ),
    darlehensbetrag: nullableValueOrDefault(
      darlehen.darlehensbetrag?.toString(),
      defaultDarlehenFormModel.darlehensbetrag,
    ),
    darlehensstand: nullableValueOrDefault(
      darlehen.darlehensstand?.toString(),
      defaultDarlehenFormModel.darlehensstand,
    ),
    datumDarlehensstand: nullableDayjsOrDefault(
      darlehen.datumDarlehensstand,
      defaultDarlehenFormModel.datumDarlehensstand,
    ),
    zinssatz: nullableValueOrDefault(
      handlePercent(darlehen.zinssatz)?.toString(),
      defaultDarlehenFormModel.zinssatz,
    ),
    zinsfestschreibung: nullableDayjsOrDefault(
      darlehen.zinsfestschreibung,
      defaultDarlehenFormModel.zinsfestschreibung,
    ),
    tilgungsTyp: nullableValueOrDefault<TilgungsTyp | '' | undefined>(
      darlehen.tilgungsTyp,
      defaultDarlehenFormModel.tilgungsTyp,
    ),
    darlehensrate: {
      sparrate: nullableValueOrDefault(
        darlehen.darlehensrate?.sparrate?.toString(),
        defaultDarlehenFormModel.darlehensrate.sparrate,
      ),
      zahlart: valueOrDefault(
        darlehen.darlehensrate?.zahlart,
        defaultDarlehenFormModel.darlehensrate.zahlart,
      ),
      zahlweise: convertZahlweiseFormModel(
        darlehen.darlehensrate?.zahlweise,
        defaultDarlehenFormModel.darlehensrate.zahlweise,
      ),
    },
    isNew: darlehen.createFlag ?? false,
    isDeleted: darlehen.deleteFlag ?? false,
    saveError: darlehen.saveError ?? false,
  }
}
