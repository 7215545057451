import React, { PropsWithChildren, ReactElement } from 'react'
import { Stack } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/form-layout/EmphasizedFormBlock.module.scss'

type EmphasizedFormBlockProps = {
  title?: string
  fullWidth?: boolean
  hideEmphasis?: boolean
  noPadding?: boolean
}

export const EmphasizedFormBlock = ({
  title,
  fullWidth,
  hideEmphasis,
  noPadding,
  children,
}: PropsWithChildren<EmphasizedFormBlockProps>): ReactElement => {
  return (
    <Stack
      className={clsx(
        !hideEmphasis && styles.block,
        fullWidth && styles.fullWidth,
        noPadding && styles.noPadding,
      )}
    >
      {!hideEmphasis && title && <div className={styles.title}>{title}</div>}
      {children}
    </Stack>
  )
}
