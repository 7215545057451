import React, { ReactElement } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'

import { HelperText } from 'components/controls/inputs/HelperText'
import { BaseInputProps } from 'components/controls/inputs/InputType'
import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import styles from 'components/controls/inputs/TextInput.module.scss'
import { SelectiveTextInput } from 'components/controls/selective-inputs/SelectiveTextInput'

export type TextInputProps<FormType> = BaseInputProps<FormType> & {
  multiline?: boolean
  rows?: number
  noHelperText?: boolean
} & TextFieldProps

export const TextInput = <FormType,>({
  name,
  label,
  tooltip,
  multiline,
  rows = 2,
  variant,
  noHelperText,
  selectiveFieldProps,
  ...props
}: TextInputProps<FormType>): ReactElement => {
  const [{ ...fieldInputProps }, metaProps] = useField(name)
  if (selectiveFieldProps) {
    return (
      <SelectiveTextInput<FormType>
        name={name}
        label={label}
        multiline={multiline}
        rows={rows}
        selectiveFieldProps={{
          ...selectiveFieldProps,
        }}
        variant={variant}
        size={props.size}
      />
    )
  }
  return (
    <InputWrapper tooltip={tooltip}>
      <TextField
        {...props}
        {...fieldInputProps}
        multiline={multiline}
        rows={rows}
        inputProps={{ className: multiline && styles.multiline }}
        label={label}
        fullWidth
        error={!!metaProps.error && metaProps.touched}
        variant={variant ?? 'standard'}
        helperText={noHelperText ? undefined : <HelperText metaProps={metaProps} />}
      />
    </InputWrapper>
  )
}
