import React from 'react'
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DescriptionIcon from '@mui/icons-material/Description'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

export const finanzenIcons = {
  immobilien: {
    add: <AddHomeWorkIcon />,
    default: <HomeWorkIcon />,
  },
  geldAnlagen: {
    add: <TrendingUpIcon />,
    default: <TrendingUpIcon />,
  },
  darlehen: {
    add: <CreditCardIcon />,
    default: <CreditCardIcon />,
  },
  beteiligungen: {
    add: <DescriptionIcon />,
    default: <DescriptionIcon />,
  },
  sachwerte: {
    add: <DirectionsCarIcon />,
    default: <DirectionsCarIcon />,
  },
  gesetzlicheVersorgung: {
    add: <AssuredWorkloadIcon />,
    default: <AssuredWorkloadIcon />,
  },
}
