import React, { PropsWithChildren, ReactElement } from 'react'

import styles from 'components/authentication/ScreenContent.module.scss'

export const ScreenContent = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <div className={styles.app}>
      <div className={styles.screenContentContainer}>
        <div className={styles.screenContent}>{children}</div>
      </div>
    </div>
  )
}
