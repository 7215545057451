import {
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { SachwertFormModel } from 'form/KundeFormModel'
import { createDefaultSachwertFormModel } from 'form/kundeFormModelCreator'
import { Sachwert } from 'interfaces/mynorm-api-model-interfaces'

const defaultSachwertFormModel = createDefaultSachwertFormModel()

export const convertToSachwertFormModel = (sachwert: Sachwert | undefined): SachwertFormModel => {
  if (!sachwert) {
    return defaultSachwertFormModel
  }
  return {
    meta: convertToMeta(sachwert.meta),
    bezeichnung: valueOrDefault(sachwert.bezeichnung, defaultSachwertFormModel.bezeichnung),
    sachwertTyp: nullableValueOrDefault(sachwert.sachwertTyp, defaultSachwertFormModel.sachwertTyp),
    kennzeichen: nullableValueOrDefault(sachwert.kennzeichen, defaultSachwertFormModel.kennzeichen),
    bemerkungen: nullableValueOrDefault(sachwert.bemerkungen, defaultSachwertFormModel.bemerkungen),
    bemerkungenIntern: nullableValueOrDefault(
      sachwert.bemerkungenIntern,
      defaultSachwertFormModel.bemerkungenIntern,
    ),
    marke: nullableValueOrDefault(sachwert.marke, defaultSachwertFormModel.marke),
    modell: nullableValueOrDefault(sachwert.modell, defaultSachwertFormModel.modell),
    baujahr: nullableValueOrDefault(sachwert.baujahr?.toString(), defaultSachwertFormModel.baujahr),
    kaufpreis: nullableValueOrDefault(
      sachwert.kaufpreis?.toString(),
      defaultSachwertFormModel.kaufpreis,
    ),
    datumKauf: nullableDayjsOrDefault(sachwert.datumKauf, defaultSachwertFormModel.datumKauf),
    datumZeitwert: nullableDayjsOrDefault(
      sachwert.datumZeitwert,
      defaultSachwertFormModel.datumZeitwert,
    ),
    datumErstzulassung: nullableDayjsOrDefault(
      sachwert.datumErstzulassung,
      defaultSachwertFormModel.datumErstzulassung,
    ),
    zeitwert: nullableValueOrDefault(
      sachwert.zeitwert?.toString(),
      defaultSachwertFormModel.zeitwert,
    ),
    wiederbeschaffungGesichert: valueOrDefault(
      sachwert.wiederbeschaffungGesichert,
      defaultSachwertFormModel.wiederbeschaffungGesichert,
    ),
    ersatzinvestitionErforderlich: valueOrDefault(
      sachwert.ersatzinvestitionErforderlich,
      defaultSachwertFormModel.ersatzinvestitionErforderlich,
    ),
    haftplichtViaExternenVertrag: valueOrDefault(
      sachwert.haftplichtViaExternenVertrag,
      defaultSachwertFormModel.haftplichtViaExternenVertrag,
    ),
    isNew: sachwert.createFlag ?? false,
    isDeleted: sachwert.deleteFlag ?? false,
    saveError: sachwert.saveError ?? false,
  }
}
