import { CreateStyled } from '@emotion/styled'
import { styled, TableCell } from '@mui/material'

const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
}

export const FormCellLabel = styled(
  TableCell,
  transientOptions,
)<{ $border?: boolean; $highlighted?: boolean; $bold?: boolean }>(
  ({ theme, $border, $highlighted, $bold }) => ({
    padding: theme.spacing(1),
    height: 57,
    textWrap: 'nowrap',
    fontWeight: $bold ? 'bold' : undefined,
    backgroundColor: $highlighted ? theme.palette.divider : undefined,
    ...($border
      ? {
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          borderRightColor: theme.palette.divider,
        }
      : {}),
  }),
)

export const FormTextCell = styled(
  TableCell,
  transientOptions,
)<{ $border?: boolean }>(({ theme, $border }) => ({
  padding: theme.spacing(0, 1),
  height: 57,
  ...($border
    ? {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.divider,
      }
    : {}),
}))

export const FormCheckboxCell = styled(
  TableCell,
  transientOptions,
)<{ $border?: boolean }>(({ theme, $border }) => ({
  padding: theme.spacing(0, 1),
  height: 57,
  ...($border
    ? {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.divider,
      }
    : {}),
}))
