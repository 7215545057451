import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean, formatCurrency, formatValueWithUnit } from 'components/util/formatUtil'
import { WohngebaeudeDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { WohngebaeudeDetails } from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const FORM_BLOCK_TITLE = 'Details - Wohngebäude'

type WohngebaeudeDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  wohngebaeudeDetails: WohngebaeudeDetailsFormModel | undefined
}

export const WohngebaeudeDetailsFormBlock = ({
  name,
  produktId,
  wohngebaeudeDetails,
}: WohngebaeudeDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.wohngebaeudeDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('WOHNGEBAUEDE', produktId)) {
    return null
  }

  const showDeckungssumme =
    wohngebaeudeDetails?.einschlussBauleistung || wohngebaeudeDetails?.einschlussFeuerrohbau
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <NumberInput name={`${baseName}.wohnflaeche`} label={'Wohnfläche'} inputUnit='m²' />
          <NumberInput name={`${baseName}.wert1914`} label={'Wert 1914'} inputUnit='Mark' />
          <CheckboxInput
            name={`${baseName}.gleitenderNeuwert`}
            label={'Versicherungswert nach gleitendem Neuwert?'}
          />
          <CheckboxInput
            name={`${baseName}.unterversicherungsverzicht`}
            label={'Unterversicherungsverzicht?'}
          />
        </Stack>
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <CheckboxInput name={`${baseName}.einschlussFeuer`} label={'Einschluss Feuer'} />
          <CheckboxInput name={`${baseName}.einschlussSturm`} label={'Einschluss Sturm'} />
          <CheckboxInput
            name={`${baseName}.einschlussLeitungswasser`}
            label={'Einschluss Leitungswasser'}
          />
          <CheckboxInput name={`${baseName}.einschlussElementar`} label={'Einschluss Elementar'} />
          <CheckboxInput
            name={`${baseName}.einschlussFeuerrohbau`}
            label={'Einschluss Feuerrohbauvers.'}
          />
          <CheckboxInput
            name={`${baseName}.einschlussBauleistung`}
            label={'Einschluss Bauleistungsvers.'}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item md={6} xs={12}>
        {showDeckungssumme && (
          <NumberInput name={`${baseName}.deckungssumme`} label={'Deckungssumme'} />
        )}
      </Grid>
    </Grid>
  )
}

type WohngebaeudeDetailsReadViewProps = BaseDetailsReadViewProps & {
  wohngebaeudeDetails: WohngebaeudeDetails | undefined
}

export const WohngebaeudeDetailsReadView = ({
  produktId,
  wohngebaeudeDetails,
}: WohngebaeudeDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('WOHNGEBAUEDE', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Wohnfläche'}
        value={formatValueWithUnit(wohngebaeudeDetails?.wohnflaeche, 'm²')}
      />
      <LabelValueEntry
        label={'Wert 1914'}
        value={formatCurrency(wohngebaeudeDetails?.wert1914, 'Mark')}
      />
      <LabelValueEntry
        label={'Versicherungswert nach gleitendem Neuwert?'}
        value={formatBoolean(wohngebaeudeDetails?.gleitenderNeuwert)}
      />
      <LabelValueEntry
        label={'Unterversicherungsverzicht?'}
        value={formatBoolean(wohngebaeudeDetails?.unterversicherungsverzicht)}
      />
      <LabelValueEntry
        label={'Einschluss Feuer'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussFeuer)}
      />
      <LabelValueEntry
        label={'Einschluss Sturm'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussSturm)}
      />
      <LabelValueEntry
        label={'Einschluss Leitungswasser'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussLeitungswasser)}
      />
      <LabelValueEntry
        label={'Einschluss Elementar'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussElementar)}
      />
      <LabelValueEntry
        label={'Einschluss Feuerrohbauvers.'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussFeuerrohbau)}
      />
      <LabelValueEntry
        label={'Einschluss Bauleistungsvers.'}
        value={formatBoolean(wohngebaeudeDetails?.einschlussBauleistung)}
      />
      <LabelValueEntry
        label={'Deckungssumme'}
        value={formatCurrency(wohngebaeudeDetails?.deckungssumme)}
      />
    </Stack>
  )
}
