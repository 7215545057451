import React, { ReactElement } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { compact } from 'lodash'

import { CircularDisplay } from 'components/cockpit/CircularDisplays'
import styles from 'components/cockpit/DinLevelResultTable.module.scss'
import { formatNumber } from 'components/util/formatUtil'
import { DinVorgabe, PersoenlichesErgebnis } from 'interfaces/finoso-models-interfaces'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

type DinLevelResultTableProps = {
  level: number
  results: PersoenlichesErgebnis[]
  haushalt: HaushaltAggregate | undefined
}

export const DinLevelResultTable = ({
  level,
  results,
  haushalt,
}: DinLevelResultTableProps): ReactElement => {
  if (!haushalt || !haushalt.personen?.length) {
    return <Box>{`Die DIN hat keine Vorgaben für die Erreichung der DIN-Stufe ${level}.`}</Box>
  }
  const personName = extractFullName(haushalt.personen[0].person)
  const partnerName = haushalt.personen[1]
    ? extractFullName(haushalt.personen[1]?.person)
    : undefined
  if (!results.length) {
    return (
      <Box>{`Die DIN hat keine Vorgaben für die Erreichung der DIN-Stufe ${level} für ${personName}${
        partnerName ? ` und ${partnerName}` : ''
      }.`}</Box>
    )
  }
  return (
    <TableContainer>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>Regel</TableCell>
            <TableCell>DIN-Empfehlung</TableCell>
            <TableCell>IST-Wert</TableCell>
            <TableCell>Lücke</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result) => {
            const additionalDescription = determineAdditionalDescription(result.dinVorgabe)
            return (
              <TableRow key={result.dinVorgabe}>
                <TableCell width={433}>
                  {additionalDescription && (
                    <>
                      <span>{additionalDescription}</span>
                      <br />
                    </>
                  )}
                  <span>
                    {additionalDescription ? '• ' : ''}
                    {result.bezeichnung}
                  </span>
                </TableCell>
                <TableCell width={170}>{formatRecommendation(result.versorgungsZiel)}</TableCell>
                <TableCell>{getHedgeValue(result)}</TableCell>
                <TableCell width={118}>
                  {formatRecommendation(result.lueckeZurAktuellenStufe)}
                </TableCell>
                <TableCell>
                  <CircularDisplay
                    value={result.zielerreichung}
                    label={result.zielerreichung}
                    thickness={3}
                    className={styles.circularDisplay}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const formatRecommendation = (versorgungsZiel: string): string => {
  return isEmptyString(versorgungsZiel) ? 'N/A' : checkCurrency(versorgungsZiel)
}

const getHedgeValue = (result: PersoenlichesErgebnis): string => {
  const { privateAbsicherung, sonstigeAbsicherung, gesetzlicheAbsicherung } = result
  const personal = isEmptyString(privateAbsicherung) ? '' : checkCurrency(privateAbsicherung)
  const legal = isEmptyString(gesetzlicheAbsicherung) ? '' : checkCurrency(gesetzlicheAbsicherung)
  const other = isEmptyString(sonstigeAbsicherung) ? '' : checkCurrency(sonstigeAbsicherung)

  return (personal + legal + other).length ? compact([personal, legal, other]).join(' / ') : 'N/A'
}

const isEmptyString = (value: string): boolean => {
  return ['', '-', '[-]'].includes(value)
}
const checkCurrency = (value: string): string => {
  return isNaN(+value)
    ? value
    : `${formatNumber(+value, { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 })}`
}

const determineAdditionalDescription = (dinVorgabe: DinVorgabe): string => {
  switch (dinVorgabe) {
    case 'HAFTUNGSRISIKO_HAUS_UND_GRUNDBESITZ':
      return 'Haftungsrisiko Haus-/Grundbesitz'
    case 'HAFTUNGSRISIKO_BAU_SANIERUNG':
      return 'Haftungsrisiko Bau/Sanierung'
    case 'HAFTUNGSRISIKO_GEWAESSERSCHAEDEN':
      return 'Haftungsrisiko Gewässerschäden'
    case 'HAFTUNGSRISIKO_PHOTOVOLTAIK':
      return 'Haftungsrisiko Photovoltaikanlage'
    case 'RISIKO_VERLUST_BESCHAEDIGUNG_IMMO':
      return 'Risiko Verlust/Beschäd. Immobilie'
    case 'HAFTUNGSRISIKO_KFZ':
      return 'Haftungsrisiko Kraftfahrzeug'
    case 'KOSTENRISIKO_VERLUST_BESCHAEDIGUNG_FAHRZEUG':
      return 'Kostenrisiko Verlust/Beschäd. Fzg.'
    case 'RISIKO_VERLUST_BESCHAEDIGUNG_HAUSRAT':
      return 'Risiko Verlust/Beschäd. Hausrat'
    case 'INSTANDHALTUNG_IMMO':
      return 'Instandhaltung Immobilien'
    case 'STARTKAPITAL_AUSBILDUNG':
      return 'Startkapital Ausbildung'
    default:
      return ''
  }
}
