import React, { PropsWithChildren, ReactElement } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Stack } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/cockpit/CockpitCard.module.scss'

type CockpitCardProps = {
  title: string
  titleAlign?: 'center' | 'left'
  icon?: ReactElement
  onClick?: () => void
}

export const CockpitCard = ({
  title,
  titleAlign = 'left',
  icon,
  onClick,
  children,
}: PropsWithChildren<CockpitCardProps>): ReactElement => {
  return (
    <Box className={styles.cockpitCard} onClick={onClick}>
      <Stack direction='row' className={styles.titleRow}>
        <Box className={styles.icon}>{icon}</Box>
        <Box className={clsx(styles.title, titleAlign === 'center' && styles.alignCenter)}>
          <h3>{title}</h3>
        </Box>
        <OpenInNewIcon className={styles.maximize} />
      </Stack>
      <Box>{children}</Box>
    </Box>
  )
}
