import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { DEFAULT_STALE_TIME } from 'api/apiUtils'
import { BackendPaths } from 'configurations/backendPaths'
import { CreatePerson, PersonAggregate, SimplePerson } from 'interfaces/mynorm-api-model-interfaces'

export const useCreatePerson = (): UseMutationResult<string, unknown, CreatePerson> => {
  return useMutation((createPerson) => executeCreatePerson(createPerson))
}

const executeCreatePerson = async (createPerson: CreatePerson): Promise<string> => {
  const response = await axios.post<string>(BackendPaths.createPerson, createPerson)
  return response.data
}

export const useFetchPerson = (id?: string): UseQueryResult<PersonAggregate | undefined> => {
  return useQuery({
    queryKey: ['fetchPerson', id],
    queryFn: () => fetchPerson(id),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STALE_TIME,
  })
}

export const fetchPerson = async (id?: string): Promise<PersonAggregate | undefined> => {
  if (id === undefined || id === null) return Promise.resolve(undefined)
  const response = await axios.get<PersonAggregate>(`${BackendPaths.getPersonen}/${id}`)
  return response.data
}

export const useFetchPersonen = (
  suchbegriff: string,
  direkteKunden: boolean,
  select?: (persons: SimplePerson[]) => SimplePerson[],
): UseQueryResult<SimplePerson[]> => {
  return useQuery(
    [suchbegriff, 'fetchPersonen', direkteKunden],
    () => fetchPersonen(suchbegriff, direkteKunden),
    {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_STALE_TIME,
      select: select,
      keepPreviousData: true,
      enabled: suchbegriff?.length === 0 || suchbegriff?.length > 2,
    },
  )
}

const fetchPersonen = async (
  suchbegriff: string,
  direkteKunden: boolean,
): Promise<SimplePerson[]> => {
  const response = await axios.get<SimplePerson[]>(
    BackendPaths.getPersonen + `?direktkunden=${direkteKunden}&suchbegriff=${suchbegriff}`,
  )
  return response.data
}
