import React, { ReactElement } from 'react'
import Typography from '@mui/material/Typography'

import { PraemienInput } from 'components/personal/absicherung2/selects/PraemienInput'
import { PraemieFormModel } from 'form/vertraege/VertragFormModel'
import { PraemienArt } from 'interfaces/mynorm-api-model-interfaces'

type EinzelPraemienBlockProps = {
  name: string
  einzelPraemien: PraemieFormModel[]
  praemienArt: PraemienArt
  disabled?: boolean
}

export const EinzelPraemienBlock = ({
  name,
  einzelPraemien,
  praemienArt,
  disabled,
}: EinzelPraemienBlockProps): ReactElement | null => {
  if (praemienArt !== 'EINZEL') {
    return null
  }
  return (
    <>
      <Typography variant='overline' color='primary'>
        Einzelprämien
      </Typography>
      <PraemienInput
        name={`${name}.einzelPraemien`}
        praemien={einzelPraemien}
        disabled={disabled}
      />
    </>
  )
}
