import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatCurrency } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { HausratDetailsFormModel } from 'form/vertraege/VertragFormModel'
import {
  HausratDetails,
  HausratVersicherungssummeArt,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const hausratVersicherungssummeArtOptions: Option<HausratVersicherungssummeArt>[] = [
  {
    value: 'UNTERVERSICHERUNGSVERZICHT',
    label: 'Unterversicherungsverzicht',
  },
  {
    value: 'INDIVIDUELL',
    label: 'Individuell',
  },
  {
    value: 'UNBEGRENZT',
    label: 'Unbegrenzt',
  },
]
const FORM_BLOCK_TITLE = 'Details - Hausrat'

type HausratFormBlockProps = BaseDetailsFormBlockProps & {
  hausratDetails: HausratDetailsFormModel | undefined
}

export const HausratDetailsFormBlock = ({
  name,
  produktId,
  hausratDetails,
}: HausratFormBlockProps): ReactElement | null => {
  const baseName = `${name}.hausratDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('HAUSRAT', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          options={hausratVersicherungssummeArtOptions}
          name={`${baseName}.hausratVersicherungssummeArt`}
          label={'Versicherungssumme Art'}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {hausratDetails?.hausratVersicherungssummeArt === 'INDIVIDUELL' && (
          <NumberInput
            name={`${baseName}.individuelleVersicherungssumme`}
            label={'Individuelle Versicherungssumme'}
          />
        )}
      </Grid>
    </Grid>
  )
}

type HausratDetailsReadViewProps = BaseDetailsReadViewProps & {
  hausratDetails: HausratDetails | undefined
}

export const HausratDetailsReadView = ({
  produktId,
  hausratDetails,
}: HausratDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('HAUSRAT', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Versicherungssumme Art'}
        value={getOptionLabel(
          hausratDetails?.hausratVersicherungssummeArt,
          hausratVersicherungssummeArtOptions,
        )}
      />
      {hausratDetails?.hausratVersicherungssummeArt === 'INDIVIDUELL' && (
        <LabelValueEntry
          label={'Individuelle Versicherungssumme'}
          value={formatCurrency(hausratDetails?.individuelleVersicherungssumme)}
        />
      )}
    </Stack>
  )
}
