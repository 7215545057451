import React, { ReactElement } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton } from '@mui/material'

import styles from 'components/controls/buttons/RemoveIconButton.module.scss'

type RemoveIconButtonProps = {
  onClick?: () => void
  disabled?: boolean
}

export const RemoveIconButton = ({ onClick, disabled }: RemoveIconButtonProps): ReactElement => {
  return (
    <IconButton className={styles.iconButton} onClick={onClick} disabled={disabled}>
      <RemoveCircleOutlineIcon />
    </IconButton>
  )
}
