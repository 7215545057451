import React, { ReactElement } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import clsx from 'clsx'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { RemovePartnerButton } from 'components/partner/RemovePartnerButton'
import { HaushaltPersonAnschriftFormFields } from 'components/personal/haushalt/PersonalHaushaltPersonAnschriftFormFields'
import styles from 'components/personal/haushalt/PersonalHaushaltPersonContainer.module.scss'
import { HaushaltPersonFormFields } from 'components/personal/haushalt/PersonalHaushaltPersonFormFields'
import { HaushaltPersonKontaktdatenFormFields } from 'components/personal/haushalt/PersonalHaushaltPersonKontaktdatenFormFields'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { KundeFormModel, PersonFormModel } from 'form/KundeFormModel'
import { personInitialTouched } from 'form/kundeFormTouched'
import { personValidationSchema } from 'form/kundeFormValidationSchema'
import { beziehungsStatusOptions } from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const HaushaltPersonContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonContainerProps): ReactElement | null => {
  const { editableKunde, updatePerson, haushaltMutator } = useEditableKunde()
  const formPath = `personen.${personIndex}`
  const {
    initialFormValues: initialPersonFormValues,
    selectiveFieldProps: personSelectiveFieldProps,
  } = useSelectiveKundeForm<PersonFormModel>(formPath, selectiveFormProps)
  const { selectiveFieldProps: kundeSelectiveFieldProps } = useSelectiveKundeForm<KundeFormModel>(
    '',
    selectiveFormProps,
  )
  const displayPartnerAsRemoved = !editableKunde.hasPartner && personIndex === 1
  const displayPartnerAsNew =
    selectiveFormProps && initialPersonFormValues?.isNew && personIndex === 1

  if ((!selectiveFormProps && displayPartnerAsRemoved) || !initialPersonFormValues) {
    return null
  }

  const content = (
    <Box
      className={clsx(
        displayPartnerAsRemoved && styles.displayAsDeleted,
        displayPartnerAsNew && styles.displayAsNew,
      )}
    >
      <Grid container spacing={1}>
        <EditableKundeFormik
          initialValues={initialPersonFormValues}
          updateState={(personFormModel) => updatePerson(personIndex, personFormModel)}
          validationSchema={personValidationSchema}
          initialTouched={personInitialTouched}
          validateOnMount
        >
          {() => (
            <>
              <Grid item xs={12}>
                <h3>{personIndex === 0 ? 'Person' : 'Partner'}</h3>
              </Grid>
              <HaushaltPersonFormFields selectiveFieldProps={personSelectiveFieldProps} />
              {personIndex === 0 && (
                <>
                  <Grid item xs={12}>
                    <h3>Anschrift</h3>
                  </Grid>
                  <HaushaltPersonAnschriftFormFields
                    selectiveFieldProps={personSelectiveFieldProps}
                  />
                  <Grid item xs={12}>
                    <h3>Kontaktdaten</h3>
                  </Grid>
                  <HaushaltPersonKontaktdatenFormFields
                    selectiveFieldProps={personSelectiveFieldProps}
                  />
                </>
              )}
            </>
          )}
        </EditableKundeFormik>
        {personIndex === 0 && (
          <EditableKundeFormik
            initialValues={editableKunde}
            updateState={(kundeFormModel) => haushaltMutator.updateHaushalt(kundeFormModel)}
          >
            {(formikProps) => {
              if (formikProps.values.hasPartner) {
                return (
                  <>
                    <Grid item xs={12}>
                      <h3>Beziehung</h3>
                    </Grid>
                    <Grid item xs={5}>
                      <SelectInput
                        options={beziehungsStatusOptions}
                        name={'beziehungsStatus'}
                        label={'Beziehungsstatus'}
                        selectiveFieldProps={kundeSelectiveFieldProps}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <CheckboxInput
                        name={'gemeinsamesVermoegen'}
                        label={'Gemeinsames Vermögen'}
                        noWrapper
                        selectiveFieldProps={kundeSelectiveFieldProps}
                      />
                      <CheckboxInput
                        name={'auslandGeplant'}
                        label={'Ausland geplant'}
                        noWrapper
                        selectiveFieldProps={kundeSelectiveFieldProps}
                      />
                    </Grid>
                  </>
                )
              }

              return (
                <>
                  <Grid item xs={12}>
                    <h3>Pläne</h3>
                  </Grid>
                  <Grid item xs={7}>
                    <CheckboxInput
                      name={'auslandGeplant'}
                      label={'Ausland geplant'}
                      noWrapper
                      selectiveFieldProps={kundeSelectiveFieldProps}
                    />
                  </Grid>
                </>
              )
            }}
          </EditableKundeFormik>
        )}
      </Grid>
    </Box>
  )
  if (personIndex > 0 && !selectiveFormProps) {
    return (
      <Stack direction='row' className={styles.partnerStack}>
        <div>{content}</div>
        <h5>
          &nbsp;
          <RemovePartnerButton />
        </h5>
      </Stack>
    )
  }
  return content
}
