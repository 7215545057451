import React, { ReactElement } from 'react'

import { InsuranceCountItem } from 'components/insurance-count/InsuranceCountItem'
import styles from 'components/kundenwaehler/HaushaltUebersicht.module.scss'
import { HaushaltuebersichtBox } from 'components/kundenwaehler/HaushaltuebersichtBox'
import { ProgressBar } from 'components/progress-bar/ProgressBar'
import { useInsuranceCount } from 'hooks/useInsuranceCount'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'
import {
  HaushaltAggregate,
  Kind,
  Mensch,
  PersonAggregate,
} from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName, Nameable } from 'utils/nameUtils'

const getVollenNamen = (person?: Nameable & { firmenkunde?: boolean; firma?: string }): string => {
  if (person?.firmenkunde) {
    return person.firma || '-'
  }
  if (person) {
    return extractFullName(person)
  } else return '-'
}

const getAnzahlImmobilien = (haushalt: HaushaltAggregate): string => {
  return gebeAnzahlAus(
    haushalt.personen[0].vermoegen?.immobilien?.length,
    haushalt.personen[1]?.vermoegen?.immobilien?.length || 0,
  )
}

const gebeAnzahlAus = (anzahlKunde: number, anzahlPartner: number): string => {
  if (anzahlPartner === 0) return anzahlKunde.toString()
  return anzahlKunde + ' + ' + anzahlPartner
}

type UebersichtProps = {
  haushalt: HaushaltAggregate | undefined
  isHaushaltFetching: boolean
  dinScore: GesamtErgebnisse | undefined
  isDinScoreFetching: boolean
  isDinScoreError: boolean
}

export const HaushaltUebersicht = ({
  haushalt,
  isHaushaltFetching,
  dinScore,
  isDinScoreFetching,
  isDinScoreError,
}: UebersichtProps): ReactElement | null => {
  const insuranceCounts = useInsuranceCount(haushalt)

  const hauptkunde = haushalt?.personen[0]
  const partner = haushalt?.personen[1]

  if (!haushalt && !isHaushaltFetching) {
    return null
  }
  return (
    <div className={styles.uebersichtBox}>
      <HaushaltuebersichtBox title={'Haushalt'} isLoading={isHaushaltFetching}>
        {haushalt && hauptkunde && (
          <div className={styles.listBlocks}>
            <div>
              <div className={styles.listTitle}>
                <h6>Kundendaten</h6>
              </div>
              <ul className={styles.list}>
                <li>Name:</li>
                <li>{getVollenNamen(hauptkunde.person)}</li>
                <li>Partner:</li>
                <li>{getVollenNamen(partner?.person)}</li>
                <li>Kinder:</li>
                <ul className={styles.sublist}>
                  {haushalt.kinder.length === 0 ? (
                    <li>-</li>
                  ) : (
                    haushalt.kinder.map((kind) => (
                      <li key={kind.meta.myviId}>{getVollenNamen(kind)}</li>
                    ))
                  )}
                </ul>
                <li>Anzahl Immobilien:</li>
                <li>{getAnzahlImmobilien(haushalt)}</li>
              </ul>
            </div>
            <div>
              <div className={styles.listTitle}>
                <h6>Versicherungen</h6>
              </div>
              <ul className={styles.list}>
                {insuranceCounts.map((count) => (
                  <React.Fragment key={count.label}>
                    <li>{count.label}:</li>
                    <li>
                      <InsuranceCountItem insuranceCount={count} hasPartner={!!partner} />
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        )}
      </HaushaltuebersichtBox>
      <HaushaltuebersichtBox
        title={'DIN Score'}
        isLoading={isHaushaltFetching || isDinScoreFetching}
        isError={isDinScoreError}
      >
        <ul className={styles.list}>
          {dinScore?.scoreHaushalt?.gesamt && (
            <>
              <li>Gesamt</li>
              <li>
                <ProgressBar value={dinScore?.scoreHaushalt.gesamt.score} />
              </li>
            </>
          )}
          {haushalt?.personen?.map((personAggregate: PersonAggregate, index: number) => {
            return (
              <ProgressListEntry
                key={personAggregate.person.meta.myviId}
                mensch={personAggregate.person}
                score={dinScore?.scorePersonen[index]?.gesamt.score}
              />
            )
          })}
          {haushalt?.kinder?.map((kind: Kind, index: number) => {
            return (
              <ProgressListEntry
                key={kind.meta.myviId}
                mensch={kind}
                score={dinScore?.scoreKinder[index]?.gesamt.score}
              />
            )
          })}
        </ul>
      </HaushaltuebersichtBox>
    </div>
  )
}

type ProgressListEntryProps = {
  mensch: Mensch
  score: number | undefined
}

const ProgressListEntry = ({ mensch, score }: ProgressListEntryProps): ReactElement => {
  return (
    <>
      <li>{getVollenNamen(mensch)}</li>
      <li>
        <ProgressBar value={score} />
      </li>
    </>
  )
}
