import dayjs from 'dayjs'
import { uniqueId } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { createDefaultMetadata } from 'form/kundeFormModelCreator'
import { createDetailsFormModel } from 'form/vertraege/details/DetailsCreator'
import {
  RisikoFormModel,
  VertragFormModel,
  VertragsProduktFormModel,
} from 'form/vertraege/VertragFormModel'
import { ProduktSpecs, RisikoTyp } from 'interfaces/mynorm-api-model-interfaces'

export const createDefaultVertragFormModel = (): VertragFormModel => {
  const beginn = new Date()
  const ende = dayjs(beginn).add(1, 'year')
  return {
    meta: createDefaultMetadata(),
    fremdvertrag: true,
    vertragGeschaeft: 'SACH',
    beginn: dayjs(beginn),
    ende: ende,
    vertragNummer: '',
    produkte: [],
    vertragsProdukte: [],
    vertragGesellschaft: null,
    praemienArt: 'GESAMT',
    bemerkungen: '',
    bemerkungenIntern: '',
    gesamtPraemien: [{ summe: 0, zahlweise: 'JAEHRLICH', key: uniqueId() }],
  }
}

export const createVertragsProduktFormModel = (
  produkt: ProduktSpecs,
  risikoTypes: RisikoTyp[],
): VertragsProduktFormModel => {
  const risiken = risikoTypes.map((risikoTyp) => {
    return createRisikoFormModel(risikoTyp)
  })
  return {
    produkt: produkt,
    risiken: risiken,
    details: createDetailsFormModel(risiken),
  }
}

export const createRisikoFormModel = (risikoTyp: RisikoTyp): RisikoFormModel => {
  return {
    risikoId: uuidv4(),
    risikoTyp: risikoTyp,
    einzelPraemien: [
      {
        summe: 0,
        zahlweise: 'JAEHRLICH',
        titel: '',
        tarifArt: '',
        praemieTyp: 'BRUTTO',
        key: uuidv4(),
      },
    ],
    displayName: '',
    risikoTier: '',
    risikoReise: '',
    risikoObjekt: '',
    risikoPerson:
      risikoTyp === 'PERSON' || risikoTyp === 'KUNDE'
        ? {
            finfireId: '',
            name: '',
            bezeichnung: '',
            externePerson: false,
          }
        : undefined,
  }
}
