import React, { ReactElement } from 'react'
import { Button } from '@mui/material'
import { saveAs } from 'file-saver'

import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

type ExportHaushaltButtonProps = {
  haushalt: HaushaltAggregate | undefined
  buttonLabel?: string
}

export const ExportHaushaltButton = ({
  haushalt,
  buttonLabel,
}: ExportHaushaltButtonProps): ReactElement => {
  const handleCreateExportFile = (): void => {
    if (!haushalt) {
      return
    }
    const jsonBlob = new Blob([JSON.stringify(haushalt)], { type: 'application/json' })
    const mainPersonName = extractFullName(haushalt.personen?.[0]?.person)
    const timestamp = new Date().getTime()
    saveAs(jsonBlob, `${mainPersonName}-data-${timestamp}.json`)
  }

  return (
    <>
      <Button variant='outlined' onClick={() => handleCreateExportFile()} disabled={!haushalt}>
        {`${buttonLabel ?? 'Exportdatei erstellen'}`}
      </Button>
    </>
  )
}
