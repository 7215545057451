import { useEffect } from 'react'

export const usePreventExit = (isExitPreventable: boolean): void => {
  useEffect(() => {
    if (isExitPreventable) {
      window.onbeforeunload = () => ''
    } else {
      window.onbeforeunload = null
    }
  }, [isExitPreventable])
}
