import { KundeFormModel } from 'form/KundeFormModel'
import {
  HaushaltAggregate,
  Vertrag,
  VertragGeschaeft,
} from 'interfaces/mynorm-api-model-interfaces'

export type InsuranceCount = {
  label: string
  main: number
  partner: number
}

const getCount = (vertraege: Vertrag[], geschaeft: VertragGeschaeft): number => {
  return vertraege
    .filter((v) => v.vertragGeschaeft === geschaeft)
    .flatMap((v) => v.vertragsProdukte).length
}

export const useInsuranceCount = (
  haushalt: HaushaltAggregate | KundeFormModel | undefined,
): InsuranceCount[] => {
  const vertraege = haushalt?.personen?.[0]?.vertraege ?? []
  const partnerVertraege = haushalt?.personen?.[1]?.vertraege ?? []

  return [
    {
      label: 'Krankenversicherungen',
      main: getCount(vertraege, 'KRANKEN'),
      partner: getCount(partnerVertraege, 'KRANKEN'),
    },
    {
      label: 'Sachversicherungen',
      main: getCount(vertraege, 'SACH'),
      partner: getCount(partnerVertraege, 'SACH'),
    },
    {
      label: 'Lebens-/Rentenversicherungen',
      main: getCount(vertraege, 'LEBEN'),
      partner: getCount(partnerVertraege, 'LEBEN'),
    },
  ]
}
