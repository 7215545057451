import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios from 'axios'

import { useFetchUserConfig } from 'api/useFetchUserConfig'
import { BackendPaths } from 'configurations/backendPaths'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export type ReportRequest = {
  haushalt: HaushaltAggregate
  perPerson: boolean
}

export const useCreateVertragsspiegel = (): UseMutationResult<Blob, unknown, ReportRequest> => {
  const { data: userConfig } = useFetchUserConfig()
  const isFeFinance = userConfig?.guidanceMitNormLpFarbe === 'GRUEN'
  return useMutation((reportRequest) => createVertragsspiegel(reportRequest, isFeFinance))
}

const createVertragsspiegel = async (
  reportRequest: ReportRequest,
  isFeFinance: boolean,
): Promise<Blob> => {
  const response = await axios.post<Blob>(
    BackendPaths.generateVertragsspiegel,
    reportRequest.haushalt,
    {
      responseType: 'blob',
      params: { feFinance: isFeFinance },
    },
  )
  return response.data
}
