import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { mergeVertraegeIntoHaushalt } from 'api/mergeVertraege'
import { BackendPaths } from 'configurations/backendPaths'
import { convertToKundeFormModel } from 'form/kunde-form-model-converter/kundeFormModelConverter'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'
import { extractFullName } from 'utils/nameUtils'

export const ImportHaushaltButton = (): ReactElement | null => {
  const { customerId, haushalt } = useHaushaltState()
  const [isImportTriggered, setIsImportTriggered] = useState(false)
  const { importEditableKunde, editableKunde } = useEditableKunde()
  const [isOpen, setIsOpen] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState('')
  const navigate = useNavigate()

  const isImportAvailable = !!customerId && !editableKunde.isImported
  const hauptpersonName = extractFullName(haushalt?.personen?.[0].person)

  const { isFetching } = useQuery({
    queryKey: ['importHaushalt', customerId],
    queryFn: () => fetchImportHaushalt(customerId),
    enabled: isImportTriggered,
    retry: false,
    onSuccess: (kundeToImport) => {
      setIsImportTriggered(false)
      const haushaltToImport = haushalt
        ? mergeVertraegeIntoHaushalt(kundeToImport, haushalt)
        : kundeToImport
      importEditableKunde(convertToKundeFormModel(haushaltToImport))
      setIsOpen(false)
      navigate(`/kunde/${customerId}/daten`)
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 404) {
        setShowErrorMessage(`Keine Daten für ${hauptpersonName} gefunden.`)
      } else {
        setShowErrorMessage('Beim Importieren der Kundendaten ist ein Fehler aufgetreten.')
      }
      setIsImportTriggered(false)
    },
  })

  const handleOpenDialog = (): void => {
    setIsImportTriggered(false)
    setIsOpen(true)
  }

  const handleCloseDialog = (): void => {
    setShowErrorMessage('')
    setIsImportTriggered(false)
    setIsOpen(false)
  }

  return (
    <>
      {isImportAvailable && (
        <Button onClick={handleOpenDialog} variant='outlined' size='small'>
          Din-Daten erstmalig importieren?
        </Button>
      )}
      <Dialog open={isOpen} fullWidth maxWidth={'sm'} onClose={handleCloseDialog}>
        <DialogTitle>DIN-Daten Importieren</DialogTitle>
        <DialogContent>
          {!showErrorMessage && (
            <>
              <Typography>
                Die Importfunktion berücksichtigt aktuell nur die FINOSO-Clouddaten. Sollten
                aktuellere lokale Exportdateien existieren, werden diese noch nicht berücksichtigt.
              </Typography>
              <br />
              <Typography>{`DIN-Daten für ${hauptpersonName} importieren?`}</Typography>
            </>
          )}
          {showErrorMessage && <Typography color='error'>{showErrorMessage}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant='outlined'>
            Abbrechen
          </Button>
          <LoadingButton
            loading={isFetching}
            onClick={() => setIsImportTriggered(true)}
            variant={'contained'}
          >
            Importieren
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const fetchImportHaushalt = async (customerId: string): Promise<HaushaltAggregate> => {
  const result = await axios.get<HaushaltAggregate>(
    BackendPaths.getHaushalt + '/' + customerId + '/import',
  )
  return result.data
}
