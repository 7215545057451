import { valueOrDefault } from 'form/formModelConverterUtil'
import { convertToAusgabenFormModel } from 'form/kunde-form-model-converter/person/ausgabenFormModelConverter'
import { convertToBerufFormModel } from 'form/kunde-form-model-converter/person/berufFormModelConverter'
import { convertToEinkommenFormModel } from 'form/kunde-form-model-converter/person/einkommenFormModelConverter'
import { PersonFinanzenFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import { PersonFinanzen } from 'interfaces/mynorm-api-model-interfaces'

const defaultFinanzenFormModel = createDefaultPersonFormModel().finanzen

export const convertToFinanzenFormModel = (
  finanzen: PersonFinanzen | undefined,
): PersonFinanzenFormModel => {
  if (!finanzen) {
    return defaultFinanzenFormModel
  }
  return {
    ausgaben: convertToAusgabenFormModel(finanzen.ausgaben),
    einkommen: convertToEinkommenFormModel(finanzen.einkommen),
    beruf: convertToBerufFormModel(finanzen.beruf),
    zugesagteDispokredite: valueOrDefault(
      finanzen.zugesagteDispokredite,
      defaultFinanzenFormModel.zugesagteDispokredite,
    ),
    genommeneDispokrediteDurchschnittlich: valueOrDefault(
      finanzen.genommeneDispokrediteDurchschnittlich,
      defaultFinanzenFormModel.genommeneDispokrediteDurchschnittlich,
    ),
    anzahlKinderPflegeversicherung: valueOrDefault(
      finanzen.anzahlKinderPflegeversicherung,
      defaultFinanzenFormModel.anzahlKinderPflegeversicherung,
    ),
  }
}
