import create from 'zustand'

import {
  DetailsTyp,
  ProduktMapping,
  VersicherungsTyp,
} from 'interfaces/mynorm-api-model-interfaces'

type ProduktConfig = {
  produktMappings: ProduktMapping[]
  setProduktMappings: (produktMappings: ProduktMapping[]) => void
}

export const useProduktConfig = create<ProduktConfig>()((set) => ({
  produktMappings: [],
  setProduktMappings: (produktMappings: ProduktMapping[]) =>
    set(() => ({ produktMappings: produktMappings })),
}))

export const useProduktConfigSetter = (): ProduktConfig['setProduktMappings'] => {
  return useProduktConfig(({ setProduktMappings }) => setProduktMappings)
}

export const useProduktMappings = (): ProduktConfig['produktMappings'] => {
  return useProduktConfig(({ produktMappings }) => produktMappings)
}

export const isRelevantForDetailsTypAndFinfireProduktId = (
  detailsTyp: DetailsTyp,
  produktId: string,
): boolean => {
  return useProduktConfig
    .getState()
    .produktMappings.filter((produktMapping) => produktMapping.detailsTypes.includes(detailsTyp))
    .flatMap((produktMapping) => produktMapping.finfireProduktIds)
    .includes(produktId)
}

export const isVersicherungsTyp = (
  produktId: string,
  versicherungsTyp: VersicherungsTyp,
  produktMappings: ProduktMapping[],
): boolean => {
  return !!(produktMappings || [])
    .find((pM) => pM.versicherungsTyp === versicherungsTyp)
    ?.finfireProduktIds?.includes(produktId)
}
