import { convertAnlagenStruktur } from 'form/anlageStrukturConverterUtil'
import { convertZahlweise } from 'form/haushalt-converter/person-converter/vermoegen-converter/vermoegenConverter'
import { GeldAnlageFormModel } from 'form/KundeFormModel'
import { Geldanlage } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToDateRequestModelType,
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertGeldAnlage = (
  formModel: GeldAnlageFormModel,
  source?: Geldanlage,
): RecursivePartial<Geldanlage> => {
  const useFormModelAsDefault = formModel.isNew
  return {
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    geldAnlageTyp: mapToRequestModelType(
      formModel.geldAnlageTyp,
      source?.geldAnlageTyp,
      useFormModelAsDefault,
    ),
    bezeichnung: mapToRequestModelType(
      formModel.bezeichnung,
      source?.bezeichnung,
      useFormModelAsDefault,
    ),
    vertragsnummer: mapToRequestModelType(
      formModel.vertragsnummer,
      source?.vertragsnummer,
      useFormModelAsDefault,
    ),
    vertragsbeginn: mapToDateRequestModelType(
      formModel.vertragsbeginn,
      source?.vertragsbeginn,
      useFormModelAsDefault,
    ),
    vertragsende: mapToDateRequestModelType(
      formModel.vertragsende,
      source?.vertragsende,
      useFormModelAsDefault,
    ),
    anbieter: mapToRequestModelType(formModel.anbieter, source?.anbieter, useFormModelAsDefault),
    anlagewert: {
      ...source?.anlagewert,
      zeitwert: mapToNumericRequestModelType(
        formModel.anlagenWert.zeitwert,
        source?.anlagewert.zeitwert,
        useFormModelAsDefault,
      ),
      zeitpunkt: mapToDateRequestModelType(
        formModel.anlagenWert.zeitpunkt,
        source?.anlagewert.zeitpunkt,
        useFormModelAsDefault,
      ),
      endwert1: mapToNumericRequestModelType(
        formModel.anlagenWert.endwert1,
        source?.anlagewert.endwert1,
        useFormModelAsDefault,
      ),
      endwert2: mapToNumericRequestModelType(
        formModel.anlagenWert.endwert2,
        source?.anlagewert.endwert2,
        useFormModelAsDefault,
      ),
      endwert3: mapToNumericRequestModelType(
        formModel.anlagenWert.endwert3,
        source?.anlagewert.endwert3,
        useFormModelAsDefault,
      ),
    },
    anlagestruktur: convertAnlagenStruktur(
      formModel.anlagenStruktur,
      source?.anlagestruktur,
      useFormModelAsDefault,
    ),
    renditeklasse: mapToRequestModelType(
      formModel.renditeklasse,
      source?.renditeklasse,
      useFormModelAsDefault,
    ),
    bemerkungen: mapToRequestModelType(
      formModel.bemerkungen,
      source?.bemerkungen,
      useFormModelAsDefault,
    ),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
      useFormModelAsDefault,
    ),
    laufendeSparrate: {
      ...source?.laufendeSparrate,
      sparrate: mapToNumericRequestModelType(
        formModel.laufendeSparrate.sparrate,
        source?.laufendeSparrate?.sparrate,
        useFormModelAsDefault,
      ),
      zahlweise: convertZahlweise(
        formModel.laufendeSparrate.zahlweise,
        source?.laufendeSparrate?.zahlweise,
        useFormModelAsDefault,
      ),
      zahlart: mapToRequestModelType(
        formModel.laufendeSparrate.zahlart,
        source?.laufendeSparrate?.zahlart,
      ),
    },
    rendite: mapToNumericPercentRequestModelType(
      formModel.rendite,
      source?.rendite,
      useFormModelAsDefault,
    ),
    geldanlagenEigentuemer: mapToRequestModelType(
      formModel.geldanlagenEigentuemer.map((personFinfireId) =>
        personFinfireId === EXTERNE_PERSON_OPTION_VALUE
          ? {
              personFinfireId: null,
              externePerson: true,
              externePersonName: mapToRequestModelType(
                formModel.geldanlagenEigentuemerExternePersonName,
                source?.geldanlagenEigentuemer?.find((e) => e.externePerson)?.externePersonName,
              ),
            }
          : { personFinfireId, externePerson: false, externePersonName: null },
      ),
      source?.geldanlagenEigentuemer,
      useFormModelAsDefault,
    ),
    geldanlageZielArt: mapToRequestModelType(
      formModel.geldanlageZielArt,
      source?.geldanlageZielArt,
      useFormModelAsDefault,
    ),
    zielId: mapToRequestModelType(formModel.zielId, source?.zielId, useFormModelAsDefault),
    immobilieId: mapToRequestModelType(
      formModel.immobilieId,
      source?.immobilieId,
      useFormModelAsDefault,
    ),
    kindId: mapToRequestModelType(formModel.kindId, source?.kindId, useFormModelAsDefault),
    createFlag: formModel.isNew,
    deleteFlag: formModel.isDeleted,
    saveError: formModel.saveError,
  }
}
