import { nullableValueOrDefault, valueOrDefault } from 'form/formModelConverterUtil'
import { convertToMenschFormModel } from 'form/kunde-form-model-converter/mensch/menschFormModelConverter'
import { convertToPersonFormModel } from 'form/kunde-form-model-converter/person/personFormModelConverter'
import { KindFormModel, KundeFormModel, PersonFormModel } from 'form/KundeFormModel'
import { createDefaultKundeFormModel } from 'form/kundeFormModelCreator'
import {
  Beziehungsstatus,
  HaushaltAggregate,
  Kind,
  PersonAggregate,
} from 'interfaces/mynorm-api-model-interfaces'

const defaultKundeFormModel = createDefaultKundeFormModel()

export const convertToPersonenFormModels = (
  personen: PersonAggregate[] | undefined,
): PersonFormModel[] => {
  if (!personen) {
    return defaultKundeFormModel.personen
  }
  return personen.map(convertToPersonFormModel)
}

export const convertToKindFormModels = (kinder: Kind[] | undefined): KindFormModel[] => {
  if (!kinder) {
    return defaultKundeFormModel.kinder
  }
  return kinder.map(convertToMenschFormModel)
}

export const convertToKundeFormModel = (haushalt: HaushaltAggregate): KundeFormModel => {
  if (!haushalt) {
    return defaultKundeFormModel
  }
  return {
    beziehungsStatus: nullableValueOrDefault<Beziehungsstatus | '' | undefined>(
      haushalt.beziehungsstatus,
      defaultKundeFormModel.beziehungsStatus,
    ),
    gemeinsamesVermoegen: valueOrDefault(
      haushalt.gemeinsamesVermoegen,
      defaultKundeFormModel.gemeinsamesVermoegen,
    ),
    auslandGeplant: valueOrDefault(haushalt.auslandGeplant, defaultKundeFormModel.auslandGeplant),
    personen: convertToPersonenFormModels(haushalt.personen),
    kinder: convertToKindFormModels(haushalt.kinder),
    hasPartner: !!haushalt.personen?.[1],
    isImported: haushalt.isImported ?? true,
  }
}
