import React, { ReactElement, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Grid } from '@mui/material'
import { Form } from 'formik'
import { v4 } from 'uuid'

import { useCreateHaushalt } from 'api/useHaushalt'
import { useCreatePerson } from 'api/usePerson'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { NewKundeAdditionalInformationFields } from 'components/create-kunde/NewKundeAdditionalInformationFields'
import styles from 'components/create-kunde/NewKundeForm.module.scss'
import { HaushaltPersonFormFields } from 'components/personal/haushalt/PersonalHaushaltPersonFormFields'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { NewKundeFormModel } from 'form/KundeFormModel'
import { createDefaultNewKundeFormModel } from 'form/kundeFormModelCreator'
import { newKundeValidationSchema, newPartnerValidationSchema } from 'form/kundeFormValidation'
import { beziehungsStatusOptions } from 'form/kundeOptions'
import { Beziehungsstatus, CreatePerson } from 'interfaces/mynorm-api-model-interfaces'
import { mapToDateRequestModelType } from 'utils/converterUtils'

const convertToCreatePerson = (newKundeFormModel: NewKundeFormModel): CreatePerson => {
  return {
    anrede: newKundeFormModel.anrede ?? 'NONE',
    email: newKundeFormModel.kontaktdaten?.email,
    geburtsdatum: mapToDateRequestModelType(newKundeFormModel.geburtsdatum, null, true),
    hobbies: newKundeFormModel.risiken,
    nachname: newKundeFormModel.nachname,
    steuerIdentifikationsnummer: newKundeFormModel.steuerIdentifikationsnummer,
    telefon: newKundeFormModel.kontaktdaten?.telefon,
    titel: newKundeFormModel.titel,
    vermittlerNummer: '',
    vorname: newKundeFormModel.vorname,
    meta: {
      myviId: v4(),
    },
  }
}

const INITIAL_PERSON = createDefaultNewKundeFormModel()

type NewKundeFormProps = {
  onSuccess?: (createdId: string, beziehungstatus?: Beziehungsstatus) => Promise<void>
  onCancel?: () => void
  isLoading?: boolean
  isPartner?: boolean
  isError?: boolean
}

export const NewKundeForm = ({
  onSuccess,
  onCancel,
  isLoading,
  isPartner,
  isError,
}: NewKundeFormProps): ReactElement => {
  const [newKundeFormModel, setNewKundeFormModel] = useState<NewKundeFormModel>()
  const {
    mutate: createPartner,
    isError: isCreatePartnerError,
    isLoading: isCreatePartnerLoading,
  } = useCreatePerson()

  const {
    mutate: createHaushalt,
    isError: isCreateHaushaltError,
    isLoading: isCreateHaushaltLoading,
  } = useCreateHaushalt()

  const saveKunde = (): void => {
    if (!newKundeFormModel) {
      return
    }
    const newPerson = convertToCreatePerson(newKundeFormModel)
    if (isPartner) {
      const beziehungsstatus = newKundeFormModel.beziehungsStatus
      if (!beziehungsstatus) {
        throw new Error('Cannot create partner without beziehungsstatus')
      }
      savePartner(newPerson, beziehungsstatus)
    } else {
      saveHaushalt(newPerson)
    }
  }

  const savePartner = (person: CreatePerson, beziehungsstatus: Beziehungsstatus): void => {
    createPartner(person, {
      onSuccess: (partnerId) => onSuccess?.(partnerId, beziehungsstatus),
    })
  }

  const saveHaushalt = (person: CreatePerson): void => {
    createHaushalt(person, {
      onSuccess: (personId) => onSuccess?.(personId),
    })
  }

  const loadingIndicatorShown = isLoading || isCreateHaushaltLoading || isCreatePartnerLoading
  const errorShown = isError || isCreateHaushaltError || isCreatePartnerError

  return (
    <>
      <EditableKundeFormik
        initialValues={INITIAL_PERSON}
        updateState={(personFormModel) => setNewKundeFormModel(personFormModel)}
        validationSchema={isPartner ? newPartnerValidationSchema : newKundeValidationSchema}
        onSubmit={saveKunde}
      >
        {() => (
          <Form>
            <Grid container spacing={1}>
              <HaushaltPersonFormFields />

              <Grid item xs={12}>
                <NewKundeAdditionalInformationFields />
              </Grid>

              {isPartner && (
                <Grid item xs={6}>
                  <SelectInput
                    options={beziehungsStatusOptions}
                    name={'beziehungsStatus'}
                    label={'Beziehungsstatus'}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {errorShown && (
                  <Alert severity={'error'}>
                    Beim Speichern des Kunden ist ein Fehler aufgetreten.
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className={styles.actions}>
                  <Button variant={'outlined'} disabled={loadingIndicatorShown} onClick={onCancel}>
                    Abbrechen
                  </Button>
                  <LoadingButton
                    variant={'contained'}
                    color='primary'
                    loading={loadingIndicatorShown}
                    disabled={loadingIndicatorShown}
                    type={'submit'}
                  >
                    Speichern
                  </LoadingButton>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </EditableKundeFormik>
    </>
  )
}
