import { filterNotDeleted } from 'components/util/listUtil'
import { ImmobilieFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

type UseImmobilienResult = {
  immobilien: ImmobilieFormModel[]
  findImmobilie: (immobilieId: string | undefined) => ImmobilieFormModel | undefined
}
export const useImmobilien = (index?: number): UseImmobilienResult => {
  const { editableKunde } = useEditableKunde()
  const immobilien = editableKunde.personen[index ?? 0].vermoegen.immobilien ?? []

  return {
    immobilien: filterNotDeleted(immobilien),
    findImmobilie: (immobilieId) => {
      return immobilien.find((immobilie) => hasId(immobilie, immobilieId))
    },
  }
}
