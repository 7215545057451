import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import backgroundImage from 'assets/images/guidance/career/registrieren-und-vorteile-sichern/registrieren_und_vorteile_sichern_background.png'
import styles from 'components/guidance/career/GuidanceCareer14Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { UnderlineSeparator } from 'components/guidance/UnderlineSeparator'
import { Paths } from 'configurations/paths'

export const GuidanceCareer14Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={13}
      previousPagePath={Paths.guidanceCareer13}
      nextPagePath={Paths.guidanceCareer15}
      guidanceColor='career'
      numberOfInterPages={2}
      backgroundImage={backgroundImage}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title='JETZT REGISTRIEREN UND VORTEILE SICHERN'
          subTitle='Das Karriere-Institut'
        >
          <>
            {interPageIndex === 1 && (
              <Box className={styles.textContainer}>
                <h3 className={styles.text}>
                  TAKE THE CHANCE TO CHANGE <br />
                  <span className={styles.emphasis}>JETZT PROFITIEREN UND ENTWICKELN</span>
                </h3>
                <UnderlineSeparator />
              </Box>
            )}
          </>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
