import React, { ReactElement, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material'
import { AxiosError } from 'axios'
import JsonDiffReact from 'jsondiffpatch-react'

import { HaushaltUpdateError } from 'api/haushalt/useUpdateHaushalt'
import { fetchHaushalt } from 'api/useHaushalt'
import { config } from 'configurations/appConfig'
import { useValidateKunde } from 'hooks/useValidateKunde'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export type DiffDebugProps = {
  originalData: HaushaltAggregate | null | undefined
  requestData: HaushaltAggregate | null | undefined
  refetchedData: HaushaltAggregate | null | undefined
  error: AxiosError<HaushaltUpdateError> | null | undefined
  buttonText?: string
}

type DiffDebugType = {
  diffDebugProps: DiffDebugProps
  updateDiffDebugData: (
    personFinfireId: string,
    originalHaushalt: HaushaltAggregate | null | undefined,
    requestData: HaushaltAggregate | null | undefined,
    error: AxiosError<HaushaltUpdateError> | null | undefined,
  ) => void
}

export const useDiffDebug = (): DiffDebugType | undefined => {
  const [refetchedData, setRefetchedData] = useState<HaushaltAggregate | null | undefined>(null)
  const [requestData, setRequestData] = useState<HaushaltAggregate | null | undefined>(null)
  const [originalHaushalt, setOriginalHaushalt] = useState<HaushaltAggregate | null | undefined>(
    null,
  )
  const [error, setError] = useState<AxiosError<HaushaltUpdateError> | null | undefined>()
  if (!config.showDevelopmentHint) {
    return undefined
  }
  const updateDiffDebug = async (
    personFinfireId: string,
    originalHaushalt: HaushaltAggregate | null | undefined,
    requestData: HaushaltAggregate | null | undefined,
    error: AxiosError<HaushaltUpdateError> | null | undefined,
  ): Promise<void> => {
    const refetchedHaushalt = await fetchHaushalt(personFinfireId)
    setRefetchedData(refetchedHaushalt)
    setOriginalHaushalt(originalHaushalt)
    setRequestData(requestData)
    setError(error)
  }

  return {
    diffDebugProps: { refetchedData, requestData, originalData: originalHaushalt, error },
    updateDiffDebugData: updateDiffDebug,
  }
}

export const DiffDebugDialog = ({
  originalData,
  requestData,
  refetchedData,
  error,
  buttonText,
}: DiffDebugProps): ReactElement | null => {
  const { errors } = useValidateKunde()
  const [isOpen, setIsOpen] = useState(false)
  const [showOnlyChanges, setShowOnlyChanges] = useState(true)
  if (!config.showDevelopmentHint) {
    return null
  }
  return (
    <>
      <Button variant={'outlined'} onClick={() => setIsOpen(true)}>
        {buttonText ? buttonText : 'Debug'}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth={'xl'}>
        <DialogTitle>Debug Änderungen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <>
                <Grid item xs={12}>
                  <h4>Fehler</h4>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {error?.response?.data?.trace?.split('\n')[0]}
                    </AccordionSummary>
                    <AccordionDetails>{error?.response?.data?.trace}</AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <h4>Changes</h4>
            </Grid>
            <Grid item xs={12}>
              <Stack alignItems='flex-start' spacing={1}>
                <div>
                  <Checkbox
                    size='small'
                    color='secondary'
                    checked={showOnlyChanges}
                    onChange={() => setShowOnlyChanges((prev) => !prev)}
                  />
                  <FormLabel>Nur Änderungen anzeigen</FormLabel>
                </div>
                {errors.length > 0 && (
                  <Box sx={{ background: '#f6aeae', width: '100%', px: 1 }}>
                    <h5>Validierungsfehler</h5>
                    <ul>
                      {errors.map((e) => (
                        <li key={e.name}>
                          <code>{e.name}</code> : {e.errors.join(', ')}
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <h5>Diese Changes sollen gespeichert werden:</h5>
              <Box style={{ background: '#e0e0e0' }}>
                <JsonDiffReact
                  left={originalData}
                  right={requestData}
                  show={!showOnlyChanges}
                  tips={'Kein Änderungen'}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <h5>Diese Changes wurden gespeichert</h5>
              <Box style={{ background: '#e0e0e0' }}>
                <JsonDiffReact
                  left={originalData}
                  right={refetchedData}
                  show={!showOnlyChanges}
                  tips={
                    'Keine Änderungen zwischen Daten vor dem Speichern und Daten nach dem Speichern.'
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
