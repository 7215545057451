import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import {
  formatBoolean,
  formatCurrency,
  formatDate,
  formatPercent,
} from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { BerufsunfaehigkeitsDetailsFormModel } from 'form/vertraege/VertragFormModel'
import {
  BerufsunfaehigkeitsDetails,
  Weiterspargarantie,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const weiterspargarantieOptions: Option<Weiterspargarantie>[] = [
  {
    value: 'KEINE',
    label: 'Keine',
  },
  {
    value: 'BEITRAGSBEFREIUNG',
    label: 'Beitragsbefreiung',
  },
  {
    value: 'BEITRAGSBEFREIUNG_MIT_DYNAMIK',
    label: 'Beitragsbefreiung mit Dynamik',
  },
  {
    value: 'BEITRAGSFREISTELLUNG',
    label: 'Beitragsfreistellung',
  },
]
const FORM_BLOCK_TITLE = 'Details - Berufsunfähigkeit'

type BerufsunfaehigkeitsDetailsFormBlockProps = BaseDetailsFormBlockProps & {
  berufsunfaehigkeitsDetails: BerufsunfaehigkeitsDetailsFormModel | undefined
}

export const BerufsunfaehigkeitsDetailsFormBlock = ({
  name,
  produktId,
  berufsunfaehigkeitsDetails,
}: BerufsunfaehigkeitsDetailsFormBlockProps): ReactElement | null => {
  const baseName = `${name}.berufsunfaehigkeitsDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('BERUFSUNFAEHIGKEIT', produktId)) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <NumberInput
          name={`${baseName}.berufsunfaehigkeitsRente`}
          label={'BU-Rente garantiert mtl.'}
          inputUnit='€'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <NumberInput
          name={`${baseName}.berufsunfaehigkeitsRenteMitUeberschuss`}
          label={'BU-Rente mit Überschuss mtl.'}
          inputUnit='€'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DateInput
          name={`${baseName}.leistungsendeBerufsunfaehigkeitsRente`}
          label={'Leistungsende BU-Rente'}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <CheckboxInput
            name={`${baseName}.dienstunfaehigkeitsKlausel`}
            label={'Dienstunfähigkeitsklausel?'}
          />
          <CheckboxInput
            name={`${baseName}.abstrakterVerweisungsverzicht`}
            label={'Abstrakter Verweisungsverzicht?'}
          />
        </Stack>
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          options={weiterspargarantieOptions}
          name={`${baseName}.weiterspargarantie`}
          label={'Weiterspargarantie'}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {berufsunfaehigkeitsDetails?.weiterspargarantie === 'BEITRAGSBEFREIUNG_MIT_DYNAMIK' && (
          <NumberInput
            name={`${baseName}.weiterspargarantieInProzent`}
            label={'Weiterspargarantie in Prozent'}
            inputUnit='%'
          />
        )}
      </Grid>
    </Grid>
  )
}

type BerufsunfaehigkeitsDetailsReadViewProps = BaseDetailsReadViewProps & {
  berufsunfaehigkeitsDetails: BerufsunfaehigkeitsDetails | undefined
}

export const BerufsunfaehigkeitsDetailsReadView = ({
  produktId,
  berufsunfaehigkeitsDetails,
}: BerufsunfaehigkeitsDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('BERUFSUNFAEHIGKEIT', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'BU-Rente garantiert mtl.'}
        value={formatCurrency(berufsunfaehigkeitsDetails?.berufsunfaehigkeitsRente)}
      />
      <LabelValueEntry
        label={'BU-Rente mit Überschuss mtl.'}
        value={formatCurrency(berufsunfaehigkeitsDetails?.berufsunfaehigkeitsRenteMitUeberschuss)}
      />
      <LabelValueEntry
        label={'Leistungsende BU-Rente'}
        value={formatDate(berufsunfaehigkeitsDetails?.leistungsendeBerufsunfaehigkeitsRente)}
      />
      <LabelValueEntry
        label={'Weiterspargarantie'}
        value={getOptionLabel(
          berufsunfaehigkeitsDetails?.weiterspargarantie,
          weiterspargarantieOptions,
        )}
      />
      {berufsunfaehigkeitsDetails?.weiterspargarantie === 'BEITRAGSBEFREIUNG_MIT_DYNAMIK' && (
        <LabelValueEntry
          label={'Weiterspargarantie in Prozent'}
          value={formatPercent(berufsunfaehigkeitsDetails?.weiterspargarantieInProzent)}
        />
      )}
      <LabelValueEntry
        label={'Dienstunfähigkeitsklausel?'}
        value={formatBoolean(berufsunfaehigkeitsDetails?.dienstunfaehigkeitsKlausel)}
      />
      <LabelValueEntry
        label={'Abstrakter Verweisungsverzicht??'}
        value={formatBoolean(berufsunfaehigkeitsDetails?.abstrakterVerweisungsverzicht)}
      />
    </Stack>
  )
}
