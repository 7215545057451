import { valueOrDefault } from 'form/formModelConverterUtil'
import { AusgabenFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import { Ausgaben } from 'interfaces/mynorm-api-model-interfaces'

const defaultAusgabenFormModel = createDefaultPersonFormModel().finanzen.ausgaben

export const convertToAusgabenFormModel = (ausgaben: Ausgaben | undefined): AusgabenFormModel => {
  if (!ausgaben) {
    return defaultAusgabenFormModel
  }
  return {
    konsum: valueOrDefault(ausgaben.konsum, defaultAusgabenFormModel.konsum),
    wohnen: valueOrDefault(ausgaben.wohnen, defaultAusgabenFormModel.wohnen),
    sonstige: valueOrDefault(ausgaben.sonstige, defaultAusgabenFormModel.sonstige),
  }
}
