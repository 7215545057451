import React, { ReactElement } from 'react'
import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { FieldArray } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import { RemoveIconButton } from 'components/controls/buttons/RemoveIconButton'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { ZahlweiseSelect } from 'components/personal/absicherung2/selects/ZahlweiseSelect'
import { PraemieFormModel } from 'form/vertraege/VertragFormModel'

type PraemieInputProps = {
  name: string
  praemien: PraemieFormModel[]
  disabled?: boolean
}

export const PraemienInput = ({ name, praemien, disabled }: PraemieInputProps): ReactElement => {
  return (
    <FieldArray name={name}>
      {(arrayProps) => {
        return (
          <Grid container>
            {praemien.map((praemie, index) => {
              return (
                <React.Fragment key={praemie.key}>
                  <Grid item xs={5}>
                    <NumberInput
                      name={`${name}.${index}.summe`}
                      label={'Prämie'}
                      inputUnit='€'
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <ZahlweiseSelect name={`${name}.${index}.zahlweise`} disabled={disabled} />
                  </Grid>
                  <Grid item xs={2} container alignSelf='center'>
                    {praemien.length > 1 && (
                      <RemoveIconButton
                        onClick={() => arrayProps.remove(index)}
                        disabled={disabled}
                      />
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12} container justifyContent='flex-start'>
              <Button
                startIcon={<Add />}
                size='small'
                variant='outlined'
                disabled={disabled}
                onClick={() => arrayProps.push({ summe: 0, zahlweise: 'JAEHRLICH', key: uuidv4() })}
              >
                Prämie hinzufügen
              </Button>
            </Grid>
          </Grid>
        )
      }}
    </FieldArray>
  )
}
