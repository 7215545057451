import { isEmpty } from 'form/haushalt-converter/person-converter/personenConverter'
import { EinkommenFormModel } from 'form/KundeFormModel'
import { Einkommen } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToNumericPercentRequestModelType,
  mapToNumericRequestModelType,
} from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertEinkommen = (
  formModel: EinkommenFormModel | undefined,
  original: RecursivePartial<Einkommen> | undefined,
): RecursivePartial<Einkommen> | undefined => {
  if (formModel == null || (isEmpty(formModel) && original === null)) {
    return original
  }
  return {
    ...original,
    nettoNachSteuer: mapToNumericRequestModelType(
      formModel.nettoNachSteuer,
      original?.nettoNachSteuer,
    ),
    liquiditaetsErgebnisVermietung: mapToNumericRequestModelType(
      formModel.liquiditaetsErgebnisVermietung,
      original?.liquiditaetsErgebnisVermietung,
    ),
    liquiditaetsErgebnisVermietungRuhestand: mapToNumericRequestModelType(
      formModel.liquiditaetsErgebnisVermietungRuhestand,
      original?.liquiditaetsErgebnisVermietungRuhestand,
    ),
    nettovermoegenseinkommenKapitalanlagen: mapToNumericRequestModelType(
      formModel.nettovermoegenseinkommenKapitalanlagen,
      original?.nettovermoegenseinkommenKapitalanlagen,
    ),
    nettovermoegenseinkommenBetrieb: mapToNumericRequestModelType(
      formModel.nettovermoegenseinkommenBetrieb,
      original?.nettovermoegenseinkommenBetrieb,
    ),
    einkommenWegfallKrankheitInProzent: mapToNumericPercentRequestModelType(
      formModel.einkommenWegfallKrankheitInProzent,
      original?.einkommenWegfallKrankheitInProzent,
    ),
    sonstigesNettovermoegenseinkommen: mapToNumericRequestModelType(
      formModel.sonstigesNettovermoegenseinkommen,
      original?.sonstigesNettovermoegenseinkommen,
    ),
    nettorenteneinkommen: mapToNumericRequestModelType(
      formModel.nettorenteneinkommen,
      original?.nettorenteneinkommen,
    ),
    sonstigesNettoeinkommen: mapToNumericRequestModelType(
      formModel.sonstigesNettoeinkommen,
      original?.sonstigesNettoeinkommen,
    ),
  }
}
