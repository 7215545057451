import dayjs from 'dayjs'

const NON_BREAKABLE_SPACE = '\xa0'

export const formatNumberWithFractionDigits = (
  value?: number,
  fractionDigits?: number,
): string | undefined | null => {
  return formatNumber(value, {
    maximumFractionDigits: fractionDigits ?? 0,
    minimumFractionDigits: fractionDigits ?? 0,
  })
}

export const formatNumberWithSingleFractionDigit = (value?: number): string | undefined | null => {
  return formatNumber(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
}

export const formatNumber = (
  value?: number,
  options?: Intl.NumberFormatOptions,
): string | undefined | null => {
  if (value === undefined || value === null) {
    return value
  }
  return new Intl.NumberFormat('de', options).format(value)
}

export const formatPercent = (
  value?: number,
  fractionDigits?: number,
): string | undefined | null => {
  return formatNumber(value, {
    style: 'percent',
    minimumFractionDigits: fractionDigits ?? 0,
    maximumFractionDigits: fractionDigits ?? 0,
  })
}
const getNoneValueString = (unit?: string): string => {
  return `-${NON_BREAKABLE_SPACE}${unit ?? ''}`
}

export const formatValueWithUnit = (value: number | string | undefined, unit?: string): string => {
  if (value === undefined || value === null) {
    return getNoneValueString(unit)
  }
  const numericValue = Number(value)
  return isNaN(numericValue)
    ? getNoneValueString(unit)
    : `${formatNumberWithFractionDigits(numericValue, 2)}${NON_BREAKABLE_SPACE}${unit ?? ''}`
}

export const formatCurrency = (
  value: number | string | undefined,
  currencyUnit?: string,
): string => {
  return formatValueWithUnit(value, currencyUnit ?? '€')
}

export const abbreviateCurrency = (value: number | string | undefined): string => {
  const noneValueString = getNoneValueString()
  if (value === undefined || value === null) {
    return noneValueString
  }
  const numericValue = Number(value)
  if (isNaN(numericValue)) {
    return noneValueString
  }
  if (numericValue >= 1e9) {
    return `${formatNumberWithFractionDigits(
      numericValue / 1e9,
      2,
    )}${NON_BREAKABLE_SPACE}Mrd.${NON_BREAKABLE_SPACE}€`
  } else if (numericValue >= 1e6) {
    return `${formatNumberWithFractionDigits(
      numericValue / 1e6,
      2,
    )}${NON_BREAKABLE_SPACE}Mio.${NON_BREAKABLE_SPACE}€`
  }
  return formatCurrency(value)
}

export const formatDate = (date?: Date): string | undefined => {
  if (!date) {
    return undefined
  }
  return dayjs(date).format('DD.MM.YYYY')
}

export const formatBoolean = (value: boolean | undefined | null): string => {
  if (value) {
    return 'ja'
  }
  return 'nein'
}
