import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_0.png'
import chart1 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_1.png'
import chart2 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_2.png'
import chart3 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_3.png'
import chart4 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_4.png'
import chart5 from 'assets/images/guidance/career/coaching-angebot/coaching_angebot_5.png'
import styles from 'components/guidance/career/GuidanceCareer6Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer6Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3, chart4, chart5]
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={5}
      previousPagePath={Paths.guidanceCareer5}
      nextPagePath={Paths.guidanceCareer7}
      guidanceColor='career'
      numberOfInterPages={6}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title='COACHING-ANGEBOT' subTitle='Das Karriere-Institut'>
          <Box className={styles.imageContainer}>
            <img src={charts[interPageIndex]} alt='Coaching Angebote' />
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
