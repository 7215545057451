import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios from 'axios'

import { BackendPaths } from 'configurations/backendPaths'

export const useDeleteVertrag = (): UseMutationResult<void, unknown, string | undefined> => {
  return useMutation((vertragId) => deleteVertrag(vertragId))
}

const deleteVertrag = async (vertragId: string | undefined): Promise<void> => {
  if (!vertragId) {
    return
  }
  await axios.delete<void>(`${BackendPaths.deleteVertrag}/${vertragId}`)
}
