import * as yup from 'yup'

import {
  AnlagenWertFormModel,
  AnlegerprofilAndereAnlagenFormModel,
  AnlegerprofilDienstleistungenFormModel,
  AnlegerprofilFinanzanlagenFormModel,
  AnlegerprofilFormModel,
  AnlegerprofilKreditfinanzierteAnlagenFormModel,
  AusgabenFormModel,
  BeitragFormModel,
  BerufFormModel,
  BeteiligungFormModel,
  DarlehenFormModel,
  EinkommenFormModel,
  GeldAnlageFormModel,
  GesetzlicheVersorgungFormModel,
  ImmobilieFormModel,
  KindFormModel,
  KontaktdatenFormModel,
  KundeFormModel,
  PersonFinanzenFormModel,
  PersonFormModel,
  SachwertFormModel,
  VermoegenFormModel,
  ZielFormModel,
} from 'form/KundeFormModel'
import {
  EMAIL_ERROR_TEXT,
  greaterOrEqualZero,
  minMax,
  REQUIRED_TEXT,
  STEUERID_LENGTH_TEXT,
  stringLength,
  todayOrInThePast,
} from 'utils/validationUtil'

const MIN_BAUJAHR = 1800
const MAX_BAUJAHR = 2030

export type YupShape<T> = Partial<Record<keyof T, yup.AnySchema>>

export const kundeValidationSchema = yup.object().shape<YupShape<KundeFormModel>>({
  personen: yup.array(
    yup.object().shape<YupShape<PersonFormModel>>({
      vorname: yup.string().trim().required(REQUIRED_TEXT),
      nachname: yup.string().trim().required(REQUIRED_TEXT),
      geburtsdatum: todayOrInThePast(),
      steuerIdentifikationsnummer: stringLength(11, STEUERID_LENGTH_TEXT),
      kontaktdaten: yup.object().shape<YupShape<KontaktdatenFormModel>>({
        email: yup.string().email(EMAIL_ERROR_TEXT),
      }),
      finanzen: yup.object().shape<YupShape<PersonFinanzenFormModel>>({
        beruf: yup.object().shape<YupShape<BerufFormModel>>({
          versorgungsrechtlicherDienstzeitbeginnJahr: greaterOrEqualZero(),
          dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen: greaterOrEqualZero(),
          absoluterBetragEntgeltfortzahlung: greaterOrEqualZero(),
        }),
        ausgaben: yup.object().shape<YupShape<AusgabenFormModel>>({
          konsum: greaterOrEqualZero(),
          sonstige: greaterOrEqualZero(),
          wohnen: greaterOrEqualZero(),
        }),
        einkommen: yup.object().shape<YupShape<EinkommenFormModel>>({
          nettoNachSteuer: greaterOrEqualZero(),
          liquiditaetsErgebnisVermietung: greaterOrEqualZero(),
          nettovermoegenseinkommenBetrieb: greaterOrEqualZero(),
          nettorenteneinkommen: greaterOrEqualZero(),
          sonstigesNettoeinkommen: greaterOrEqualZero(),
          sonstigesNettovermoegenseinkommen: greaterOrEqualZero(),
          liquiditaetsErgebnisVermietungRuhestand: greaterOrEqualZero(),
          nettovermoegenseinkommenKapitalanlagen: greaterOrEqualZero(),
          einkommenWegfallKrankheitInProzent: greaterOrEqualZero(),
        }),
        anzahlKinderPflegeversicherung: greaterOrEqualZero(),
        zugesagteDispokredite: greaterOrEqualZero(),
        genommeneDispokrediteDurchschnittlich: greaterOrEqualZero(),
      }),
      gesetzlicheVersorgungen: yup.array(
        yup.object().shape<YupShape<GesetzlicheVersorgungFormModel>>({
          gesetzlicheAltersrente: greaterOrEqualZero(),
          anpassungssatz1: greaterOrEqualZero(),
          altersrenteBeiAnpassung1: greaterOrEqualZero(),
          anpassungssatz2: greaterOrEqualZero(),
          altersrenteBeiAnpassung2: greaterOrEqualZero(),
          erwerbsminderungsrente: greaterOrEqualZero(),
          berufsunfaehigkeitsrente: greaterOrEqualZero(),
          witwenrente: greaterOrEqualZero(),
          waisenrente: greaterOrEqualZero(),
          beitrag: greaterOrEqualZero(),
        }),
      ),
      vermoegen: yup.object().shape<YupShape<VermoegenFormModel>>({
        darlehen: yup.array(
          yup.object().shape<YupShape<DarlehenFormModel>>({
            darlehensbetrag: greaterOrEqualZero(),
            darlehensstand: greaterOrEqualZero(),
            datumDarlehensstand: todayOrInThePast(),
            darlehensrate: yup.object().shape<YupShape<BeitragFormModel>>({
              sparrate: greaterOrEqualZero(),
            }),
          }),
        ),
        immobilien: yup.array(
          yup.object().shape<YupShape<ImmobilieFormModel>>({
            verkehrswert: greaterOrEqualZero(),
            neubauSumme: greaterOrEqualZero(),
            baujahr: minMax(MIN_BAUJAHR, MAX_BAUJAHR),
          }),
        ),
        geldAnlagen: yup.array(
          yup.object().shape<YupShape<GeldAnlageFormModel>>({
            laufendeSparrate: yup.object().shape<YupShape<BeitragFormModel>>({
              sparrate: greaterOrEqualZero(),
            }),
            anlagenWert: yup.object().shape<YupShape<AnlagenWertFormModel>>({
              zeitwert: greaterOrEqualZero(),
              endwert1: greaterOrEqualZero(),
              endwert2: greaterOrEqualZero(),
              endwert3: greaterOrEqualZero(),
            }),
          }),
        ),
        beteiligungen: yup.array(
          yup.object().shape<YupShape<BeteiligungFormModel>>({
            laufendeSparrate: yup.object().shape<YupShape<BeitragFormModel>>({
              sparrate: greaterOrEqualZero(),
            }),
            zeitwert: greaterOrEqualZero(),
            endwert: greaterOrEqualZero(),
          }),
        ),
        sachwerte: yup.array(
          yup.object().shape<YupShape<SachwertFormModel>>({
            kaufpreis: greaterOrEqualZero(),
            zeitwert: greaterOrEqualZero(),
            datumKauf: todayOrInThePast(),
          }),
        ),
      }),
      ziele: yup.array(
        yup.object().shape<YupShape<ZielFormModel>>({
          zielkapital: greaterOrEqualZero(),
        }),
      ),
      anlegerprofil: yup.object().shape<YupShape<AnlegerprofilFormModel>>({
        finanzanlagen: yup.object().shape<YupShape<AnlegerprofilFinanzanlagenFormModel>>({
          geldmarktfondsKenntnisse: yup.boolean(),
        }),
        andereAnlagen: yup.object().shape<YupShape<AnlegerprofilAndereAnlagenFormModel>>({
          sparTagesTermingeldKenntnisse: yup.boolean(),
          immobilienKenntnisse: yup.boolean(),
          sonstigesKenntnisse: yup.boolean(),
        }),
        kreditfinanzierteAnlagen: yup
          .object()
          .shape<YupShape<AnlegerprofilKreditfinanzierteAnlagenFormModel>>({
            finanzierungsArt: yup.string(),
          }),
        dienstleistungen: yup.object().shape<YupShape<AnlegerprofilDienstleistungenFormModel>>({
          beratungsfreieOrderausfuehrung: yup.boolean(),
          anlageberatung: yup.boolean(),
          vermoegensverwaltung: yup.boolean(),
        }),
      }),
    }),
  ),
  kinder: yup.array(
    yup.object().shape<YupShape<KindFormModel>>({
      geburtsdatum: todayOrInThePast(),
    }),
  ),
})

export const personValidationSchema = yup.reach(kundeValidationSchema, 'personen[0]')
export const zielValidationSchema = yup.reach(kundeValidationSchema, 'personen.[0].ziele[0]')
export const beteiligungValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].vermoegen.beteiligungen[0]',
)
export const darlehenValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].vermoegen.darlehen[0]',
)
export const geldAnlageValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].vermoegen.geldAnlagen[0]',
)

export const sachwertValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].vermoegen.sachwerte[0]',
)

export const immobilieValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].vermoegen.immobilien[0]',
)

export const gesetzlicheVersorgungValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].gesetzlicheVersorgungen[0]',
)

export const anlegerprofilFinanzanlagenValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].anlegerprofil.finanzanlagen',
)
export const anlegerprofilAndereAnlagenValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].anlegerprofil.andereAnlagen',
)

export const anlegerprofilKreditfinanzierteAnlagenValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].anlegerprofil.kreditfinanzierteAnlagen',
)

export const anlegerprofilDienstleistungenValidationSchema = yup.reach(
  kundeValidationSchema,
  'personen[0].anlegerprofil.dienstleistungen',
)

export const kinderValidationSchema = yup.reach(kundeValidationSchema, 'kinder[0]')
