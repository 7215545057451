import React, { PropsWithChildren, ReactElement } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SaveIcon from '@mui/icons-material/Save'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tooltip,
} from '@mui/material'
import clsx from 'clsx'

import styles from 'components/expansion-panel/ExpansionPanel.module.scss'

type ExpansionPanelProps = {
  title?: string
  titleComponent?: ReactElement
  index?: number
  removeAction?: () => void
  removeButtonLabel?: string
  removeButton?: ReactElement
  editButton?: ReactElement
  expanded?: boolean
  onExpandedChanged?: (expanded: boolean, index: number) => void
  displayAsDeleted?: boolean
  displayAsNew?: boolean
  displayValidationError?: boolean
  displaySaveError?: boolean
}

export const ExpansionPanel = ({
  title,
  titleComponent,
  index = 0,
  removeAction,
  removeButtonLabel,
  removeButton,
  editButton,
  expanded = false,
  onExpandedChanged,
  displayAsDeleted,
  displayAsNew,
  displayValidationError,
  displaySaveError,
  children,
}: PropsWithChildren<ExpansionPanelProps>): ReactElement => {
  return (
    <Accordion
      disableGutters={false}
      elevation={0}
      className={clsx(
        styles.accordion,
        index % 2 !== 0 && styles.odd,
        displayAsDeleted && styles.displayAsDeleted,
        displayAsNew && styles.displayAsNew,
      )}
      expanded={expanded}
      onChange={(e, exp) => onExpandedChanged?.(exp, index)}
      TransitionProps={{ timeout: 100 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={clsx(styles.accordionSummary)}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            {titleComponent ? titleComponent : <h6>{title || <span>&nbsp;</span>}</h6>}
          </Grid>
          {displayValidationError && (
            <Grid item xs={2} container alignItems={'center'} justifyContent={'flex-end'}>
              <Tooltip title={`Manche Felder sind ungültig.`}>
                <WarningIcon sx={{ marginRight: 2 }} color={'error'} />
              </Tooltip>
            </Grid>
          )}
          {displaySaveError && (
            <Grid item xs={2} container alignItems={'center'} justifyContent={'flex-end'}>
              <Tooltip title={`Konnte nicht oder nicht korrekt gespeichert werden.`}>
                <SaveIcon sx={{ marginRight: 2 }} color={'error'} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      {children && expanded && (
        <AccordionDetails className={styles.accordionDetails}>{children}</AccordionDetails>
      )}

      {(removeAction || removeButton || editButton) && (
        <AccordionActions className={styles.accordionActions}>
          {editButton ? editButton : null}
          {removeButton ? removeButton : null}
          {removeAction && (
            <Button
              onClick={removeAction}
              startIcon={<RemoveCircleOutlineIcon />}
              color='error'
              variant='outlined'
            >
              {removeButtonLabel}
            </Button>
          )}
        </AccordionActions>
      )}
    </Accordion>
  )
}
