import { convertBeteiligung } from 'form/haushalt-converter/person-converter/vermoegen-converter/beteiligungConverter'
import { convertDarlehen } from 'form/haushalt-converter/person-converter/vermoegen-converter/darlehenConverter'
import { convertGeldAnlage } from 'form/haushalt-converter/person-converter/vermoegen-converter/geldanlageConverter'
import { convertImmobilie } from 'form/haushalt-converter/person-converter/vermoegen-converter/immobilieConverter'
import { convertSachwert } from 'form/haushalt-converter/person-converter/vermoegen-converter/sachwertConverter'
import { VermoegenFormModel, ZahlweiseFormModel } from 'form/KundeFormModel'
import { Vermoegen, Zahlweise } from 'interfaces/mynorm-api-model-interfaces'
import { mapList } from 'utils/listUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertVermoegen = (
  formModel: VermoegenFormModel | undefined,
  source?: Vermoegen | undefined,
): RecursivePartial<Vermoegen> | undefined => {
  if (formModel == null) {
    return source
  }
  return {
    ...source,
    immobilien: mapList(formModel.immobilien, source?.immobilien, convertImmobilie),
    darlehen: mapList(formModel.darlehen, source?.darlehen, convertDarlehen),
    beteiligungen: mapList(formModel.beteiligungen, source?.beteiligungen, convertBeteiligung),
    geldanlagen: mapList(formModel.geldAnlagen, source?.geldanlagen, convertGeldAnlage),
    sachwerte: mapList(formModel.sachwerte, source?.sachwerte, convertSachwert),
  }
}

export const convertZahlweise = (
  formModel?: ZahlweiseFormModel | '',
  source?: Zahlweise,
  useFormModelAsDefault?: boolean,
): Zahlweise | undefined => {
  if (!formModel && !useFormModelAsDefault) return source
  switch (formModel) {
    case 'JAEHRLICH':
      return 'JAEHRLICH'
    case 'HALB_JAEHRLICH':
      return 'HALBJAEHRLICH'
    case 'VIERTEL_JAEHRLICH':
      return 'QUARTAL'
    case 'MONATLICH':
      return 'MONATLICH'
    case 'EINMALZAHLUNG':
      return 'EINMALBETRAG'
  }
}
