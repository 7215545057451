import React, { PropsWithChildren, ReactElement } from 'react'
import { Alert, CircularProgress } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/kundenwaehler/HaushaltuebersichtBox.module.scss'

type KundenauswahlboxProps = {
  title: string
  className?: string
  isLoading?: boolean
  isError?: boolean
}

export const HaushaltuebersichtBox = ({
  title,
  className,
  isLoading,
  isError,
  children,
}: PropsWithChildren<KundenauswahlboxProps>): ReactElement => {
  return (
    <div className={clsx(styles.box, className)}>
      <div className={styles.titleContainer}>
        <h5>{title}</h5>
      </div>
      <div>
        <hr />
      </div>
      <div className={styles.bodyContainer}>
        {isLoading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && !isError && <>{children}</>}
        {isError && (
          <Alert severity={'error'}>Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
        )}
      </div>
    </div>
  )
}
