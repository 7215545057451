import dayjs, { Dayjs } from 'dayjs'

import { ZahlweiseFormModel } from 'form/KundeFormModel'
import { Zahlweise } from 'interfaces/mynorm-api-model-interfaces'

export const valueOrDefault = <T, R>(
  value: T | undefined | null,
  defaultValue: NonNullable<T> | R,
): NonNullable<T> | R => {
  if (value === undefined || value === null) {
    return defaultValue
  }
  return value
}

export const nullableValueOrDefault = <T>(
  value: T,
  defaultValue: NonNullable<T>,
): NonNullable<T> => {
  if (value === undefined || value === null) {
    return defaultValue
  }
  return value
}

export const nullableDayjsOrDefault = (
  value: Date | undefined | null,
  defaultValue: Dayjs | null,
): Dayjs | null => {
  if (value == null) {
    return defaultValue
  }
  return dayjs(value)
}

export const handlePercent = (value?: number): number | undefined | null => {
  if (value === undefined || value === null) {
    return value
  }
  return +(value * 100).toFixed(4)
}

export const convertZahlweiseFormModel = (
  zahlweise: Zahlweise | undefined,
  defaultFormModel: ZahlweiseFormModel | '',
): ZahlweiseFormModel | '' => {
  if (!zahlweise) return defaultFormModel
  switch (zahlweise) {
    case 'MONATLICH':
      return 'MONATLICH'
    case 'QUARTAL':
      return 'VIERTEL_JAEHRLICH'
    case 'HALBJAEHRLICH':
      return 'HALB_JAEHRLICH'
    case 'JAEHRLICH':
      return 'JAEHRLICH'
    case 'EINMALBETRAG':
      return 'EINMALZAHLUNG'
  }
}
