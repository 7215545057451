import React, { ReactElement, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import content from 'assets/images/login/login-content.webp'
import { LoginForm } from 'components/login-form/LoginForm'
import styles from 'components/login-form/LoginPage.module.scss'
import { config } from 'configurations/appConfig'
import { Paths } from 'configurations/paths'

export const LoginPage = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const [recentlyLoggedOut, setRecentlyLoggedOut] = useState(location.state?.logout)
  const queryClient = useQueryClient()

  const onSubmit = (): void => {
    setRecentlyLoggedOut(false)
  }

  const onSuccessfulLogin = (): void => {
    queryClient.invalidateQueries(['fetchProduktMapping'])
    queryClient.invalidateQueries(['fetchUserConfig'])
    navigate(location.state?.redirectUri ?? Paths.customers)
  }

  return (
    <div className={styles.loginPage}>
      <div className={styles.imageArea}>
        <img src={content} alt='login-content' className={styles.contentImage} />
      </div>
      <div className={styles.formArea}>
        <div className={styles.formBox}>
          <h3>Lass uns anfangen</h3>
          <LoginForm
            recentlyLoggedOut={recentlyLoggedOut}
            onSubmit={onSubmit}
            onSuccessfulLogin={onSuccessfulLogin}
          />
        </div>
        <div className={styles.footer}>
          <div>© 2023 MYVI GmbH. Alle Rechte vorbehalten. Version {config.appVersion}</div>
          <div className={styles.footerLinks}>
            <Link href={'https://myvi.de/impressum'} target={'_blank'} rel='noreferrer'>
              Impressum
            </Link>
            <Link href={'https://myvi.de/datenschutz'} target={'_blank'} rel='noreferrer'>
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
