import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { haushaltQueryKey } from 'api/useHaushalt'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

type UsePrepareHaushaltQueryResult = (haushalt: HaushaltAggregate, customerId?: string) => void
export const usePrepareHaushaltQuery = (): UsePrepareHaushaltQueryResult => {
  const queryClient = useQueryClient()
  return useCallback(
    (haushalt, customerId) => {
      queryClient.setQueryData(haushaltQueryKey(customerId), haushalt)
    },
    [queryClient],
  )
}
