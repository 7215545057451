import { WritableDraft } from 'immer/dist/types/types-external'
import { compact, some, values } from 'lodash'

import { convertAnlegerprofil } from 'form/haushalt-converter/person-converter/anlegerprofilConverter'
import { convertAnschrift } from 'form/haushalt-converter/person-converter/anschriftConverter'
import { convertFinanzen } from 'form/haushalt-converter/person-converter/finanzenConverter'
import { convertGesetzlicheVersorgungen } from 'form/haushalt-converter/person-converter/gesetzlicheVersorgungenConverter'
import { convertKontaktdaten } from 'form/haushalt-converter/person-converter/kontaktdatenConverter'
import { convertMensch } from 'form/haushalt-converter/person-converter/menschConverter'
import { convertVermoegen } from 'form/haushalt-converter/person-converter/vermoegen-converter/vermoegenConverter'
import { convertZiele } from 'form/haushalt-converter/person-converter/zieleConverter'
import { KundeFormModel, PersonFormModel } from 'form/KundeFormModel'
import {
  GesetzlicheVersorgung,
  HaushaltAggregate,
  Person,
  PersonAggregate,
  Ziel,
} from 'interfaces/mynorm-api-model-interfaces'
import { mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertPersonen = (
  kundeFormModel: KundeFormModel,
  draft: WritableDraft<RecursivePartial<HaushaltAggregate>>,
): RecursivePartial<PersonAggregate>[] => {
  return (
    compact(kundeFormModel.personen)
      // .filter(
      //   (personFormModel) =>
      //     !isNullishOrEmpty(personFormModel.finfireId) ||
      //     (isNullishOrEmpty(personFormModel.finfireId) && kundeFormModel.hasPartner),
      // )
      .map((personFormModel, index) => {
        return convertPersonAggregate(
          personFormModel,
          draft?.personen?.[index] as PersonAggregate,
          index !== 0 && !kundeFormModel.hasPartner && draft?.personen?.length == 2,
        )
      })
  )
}

export const convertPersonAggregate = (
  personFormModel: PersonFormModel,
  original: PersonAggregate | undefined,
  trennung: boolean,
): RecursivePartial<PersonAggregate> => {
  return {
    ...original,
    person: convertPerson(personFormModel, original?.person, trennung),
    vermoegen: convertVermoegen(personFormModel.vermoegen, original?.vermoegen),
    gesetzlicheVersorgungen: convertGesetzlicheVersorgungen(
      personFormModel.gesetzlicheVersorgungen,
      original?.gesetzlicheVersorgungen as GesetzlicheVersorgung[],
    ),
    ziele: convertZiele(personFormModel.ziele, original?.ziele as Ziel[]),
    anlegerprofil: convertAnlegerprofil(personFormModel.anlegerprofil, original?.anlegerprofil),
  }
}

export const convertPerson = (
  personFormModel: PersonFormModel,
  original: RecursivePartial<Person> | undefined,
  trennung: boolean,
): RecursivePartial<Person> => {
  const useFormModelAsDefault = personFormModel.isNew

  return {
    ...original,
    ...convertMensch(personFormModel, original, trennung),
    titel: mapToRequestModelType(personFormModel.titel, original?.titel),
    finanzen: convertFinanzen(
      personFormModel.finanzen,
      original?.finanzen,
      !!useFormModelAsDefault,
    ),
    anschrift: convertAnschrift(personFormModel.anschrift, original?.anschrift),
    kontaktdaten: convertKontaktdaten(personFormModel.kontaktdaten, original?.kontaktdaten),
    steuerIdentifikationsnummer: mapToRequestModelType(
      personFormModel.steuerIdentifikationsnummer,
      original?.steuerIdentifikationsnummer,
    ),
  }
}

export const isEmpty = (value: unknown): boolean => {
  return !some(values(value), (v) => v !== '')
}
