import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'

import backgroundImage from 'assets/images/guidance/mitnorm/finanzplan-nach-norm/finanzfahrplan_nach_norm.png'
import backgroundImageFe from 'assets/images/guidance/mitnorm-fe/finanzplan-nach-norm/finanzfahrplan_nach_norm.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm15Page.module.scss'
import { Paths } from 'configurations/paths'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const GuidanceMitNorm15Page = (): ReactElement => {
  const navigate = useNavigate()
  const customerId = useHaushaltState(({ customerId }) => customerId)
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={14}
      previousPagePath={Paths.guidanceMitNorm14}
      nextPagePath={Paths.guidanceMitNorm16}
      guidanceColor='mitnorm'
      backgroundImage={backgroundImage}
      backgroundImageFe={backgroundImageFe}
    >
      <GuidanceSubPageTemplate title={'FINANZFAHRPLAN NACH NORM'} subTitle={'mitNORM'}>
        <Box className={styles.textContainer}>
          <h2 className={styles.text}>WER DEN WEG ZUM ZIEL SUCHT, MUSS WISSEN WO ER STARTET!</h2>
          <Button
            variant='contained'
            className={styles.dinButton}
            onClick={() => navigate(`/kunde/${customerId}/daten`)}
          >
            DIN GUTACHTEN
          </Button>
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
