import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import {
  SelectiveFormProps,
  SelectiveInputProps,
} from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { GesetzlicheVersorgungFormModel } from 'form/KundeFormModel'
import { gesetzlicheVersorgungInitialTouched } from 'form/kundeFormTouched'
import { gesetzlicheVersorgungValidationSchema } from 'form/kundeFormValidationSchema'
import {
  gesetzlicheVersorgungTypOptions,
  getOptionLabel,
  zahlweiseOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { GesetzlicheVersorgungMutator } from 'state/gesetzlicheVersorgungMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'

type PersonalFinanzenSachwerteContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenGesetzlicheVersorgungenContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenSachwerteContainerProps): ReactElement => {
  const { editableKunde, gesetzlicheVersorgungMutator } = useEditableKunde()
  const gesetzlicheVersorgungen = editableKunde.personen[personIndex].gesetzlicheVersorgungen
  return (
    <>
      {gesetzlicheVersorgungen.map((_, index) => {
        return (
          <PersonalFinanzenGesetzlicheVersorgungContainer
            key={index}
            personIndex={personIndex}
            gesetzlicheVersorgungIndex={index}
            gesetzlicheVersorgungMutator={gesetzlicheVersorgungMutator}
            selectiveFormProps={selectiveFormProps}
          />
        )
      })}
    </>
  )
}

type PersonalFinanzenGesetzlicheVersorgungContainerProps = {
  personIndex: number
  gesetzlicheVersorgungIndex: number
  gesetzlicheVersorgungMutator: GesetzlicheVersorgungMutator
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenGesetzlicheVersorgungContainer = ({
  personIndex,
  gesetzlicheVersorgungIndex,
  gesetzlicheVersorgungMutator,
  selectiveFormProps,
}: PersonalFinanzenGesetzlicheVersorgungContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.gesetzlicheVersorgungen`
  const formPath = `${basePath}.${gesetzlicheVersorgungIndex}`
  const { initialFormValues, selectiveFieldProps } =
    useSelectiveKundeForm<GesetzlicheVersorgungFormModel>(formPath, selectiveFormProps)
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: GesetzlicheVersorgungFormModel): void => {
    gesetzlicheVersorgungMutator.removeGesetzlicheVersorgung(
      personIndex,
      gesetzlicheVersorgungIndex,
    )
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && (!selectiveFormProps || initialFormValues.isNew)) {
    return null
  }

  return (
    <EditableKundeFormik
      key={gesetzlicheVersorgungIndex}
      initialValues={initialFormValues}
      updateState={(versorgung) => {
        gesetzlicheVersorgungMutator.updateGesetzlicheVersorgung(
          personIndex,
          gesetzlicheVersorgungIndex,
          versorgung,
        )
      }}
      validationSchema={gesetzlicheVersorgungValidationSchema}
      initialTouched={gesetzlicheVersorgungInitialTouched}
      validateOnMount
    >
      {({ values, isValid, setFieldValue }) => {
        const isDeutscheRentenVersicherung =
          values.gesetzlicheVersorgungsTyp === 'DEUTSCHE_RENTENVERSICHERUNG'
        const isBeamtenVersorgung = values.gesetzlicheVersorgungsTyp === 'BEAMTENVERSORGUNG'
        const isVersorgungsWerk =
          values.gesetzlicheVersorgungsTyp === 'BERUFSSTAENDISCHES_VERSORGUNGSWERK'
        const isZusatzVersorgung = values.gesetzlicheVersorgungsTyp === 'ZUSATZVERSORGUNGSKASSE'
        const isAndere = values.gesetzlicheVersorgungsTyp === 'ANDERE_VERSORGUNGSEINRICHTUNG'
        return (
          <ExpansionPanel
            title={
              getOptionLabel(values.gesetzlicheVersorgungsTyp, gesetzlicheVersorgungTypOptions) ||
              'ohne Wert'
            }
            index={gesetzlicheVersorgungIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={values.expanded}
            onExpandedChanged={(expanded) => setFieldValue('expanded', expanded)}
            displayAsDeleted={values.isDeleted}
            displayAsNew={selectiveFormProps && values.isNew}
            displayValidationError={!isValid}
            displaySaveError={values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                {isDeutscheRentenVersicherung && (
                  <DeutscheRentenVersicherungForm selectiveFieldProps={selectiveFieldProps} />
                )}
                {isBeamtenVersorgung && (
                  <BeamtenVersorgungForm selectiveFieldProps={selectiveFieldProps} />
                )}
                {isVersorgungsWerk && (
                  <BerufsstaendischesVersorgungswerkForm
                    selectiveFieldProps={selectiveFieldProps}
                  />
                )}
                {isZusatzVersorgung && (
                  <ZusatzVersorgungForm selectiveFieldProps={selectiveFieldProps} />
                )}
                {isAndere && (
                  <AndereVersorgungseinrichtungForm selectiveFieldProps={selectiveFieldProps} />
                )}
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}

type VersorgungFormProps = {
  selectiveFieldProps?: SelectiveInputProps
}

const DeutscheRentenVersicherungForm = ({
  selectiveFieldProps,
}: VersorgungFormProps): ReactElement => {
  return (
    <Grid container rowSpacing={1} spacing={2}>
      <Grid item xs={12}>
        <SelectInput<GesetzlicheVersorgungFormModel>
          options={gesetzlicheVersorgungTypOptions}
          name={`gesetzlicheVersorgungsTyp`}
          label={'Versorgungstyp'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={'zugeordnetePersonId'}
          label={'Zugeordnete Person'}
          selectiveFieldProps={selectiveFieldProps}
          noKinder
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'gesetzlicheAltersrente'}
          label={'Gesetzliche Altersrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung1'}
          label={'Altersrente bei Anpassung 1'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz1'}
          label={'Anpassungssatz 1'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung2'}
          label={'Altersrente bei Anpassung 2'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz2'}
          label={'Anpassungssatz 2'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'erwerbsminderungsrente'}
          label={'Erwerbsminderungsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'beitrag'}
          label={'Beitrag'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          name={`zahlweise`}
          label={'Zahlweise'}
          options={zahlweiseOptions}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungen`}
          label={'Bemerkungen'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </Grid>
  )
}

const BerufsstaendischesVersorgungswerkForm = ({
  selectiveFieldProps,
}: VersorgungFormProps): ReactElement => {
  return (
    <Grid container rowSpacing={1} spacing={2}>
      <Grid item xs={12}>
        <SelectInput<GesetzlicheVersorgungFormModel>
          options={gesetzlicheVersorgungTypOptions}
          name={`gesetzlicheVersorgungsTyp`}
          label={'Versorgungstyp'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={'zugeordnetePersonId'}
          label={'Zugeordnete Person'}
          selectiveFieldProps={selectiveFieldProps}
          noKinder
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={'versorgungseinrichtung'}
          label={'Einrichtung'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'gesetzlicheAltersrente'}
          label={'Gesetzliche Altersrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung1'}
          label={'Altersrente bei Anpassung 1'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz1'}
          label={'Anpassungssatz 1'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung2'}
          label={'Altersrente bei Anpassung 2'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz2'}
          label={'Anpassungssatz 2'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'erwerbsminderungsrente'}
          label={'Erwerbsminderungsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'berufsunfaehigkeitsrente'}
          label={'Berufsunfähigkeitsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'witwenrente'}
          label={'Witwenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'waisenrente'}
          label={'Waisenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'beitrag'}
          label={'Beitrag'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          name={`zahlweise`}
          label={'Zahlweise'}
          options={zahlweiseOptions}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungen`}
          label={'Bemerkungen'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </Grid>
  )
}

const BeamtenVersorgungForm = ({ selectiveFieldProps }: VersorgungFormProps): ReactElement => {
  return (
    <Grid container rowSpacing={1} spacing={2}>
      <Grid item xs={12}>
        <SelectInput<GesetzlicheVersorgungFormModel>
          options={gesetzlicheVersorgungTypOptions}
          name={`gesetzlicheVersorgungsTyp`}
          label={'Versorgungstyp'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={'zugeordnetePersonId'}
          label={'Zugeordnete Person'}
          selectiveFieldProps={selectiveFieldProps}
          noKinder
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={'versorgungseinrichtung'}
          label={'Einrichtung'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'gesetzlicheAltersrente'}
          label={'Gesetzliche Altersrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'berufsunfaehigkeitsrente'}
          label={'Berufsunfähigkeitsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungen`}
          label={'Bemerkungen'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </Grid>
  )
}

const ZusatzVersorgungForm = ({ selectiveFieldProps }: VersorgungFormProps): ReactElement => {
  return (
    <Grid container rowSpacing={1} spacing={2}>
      <Grid item xs={12}>
        <SelectInput<GesetzlicheVersorgungFormModel>
          options={gesetzlicheVersorgungTypOptions}
          name={`gesetzlicheVersorgungsTyp`}
          label={'Versorgungstyp'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={'zugeordnetePersonId'}
          label={'Zugeordnete Person'}
          selectiveFieldProps={selectiveFieldProps}
          noKinder
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={'versorgungseinrichtung'}
          label={'Einrichtung'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'gesetzlicheAltersrente'}
          label={'Gesetzliche Altersrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung1'}
          label={'Altersrente bei Anpassung 1'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz1'}
          label={'Anpassungssatz 1'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung2'}
          label={'Altersrente bei Anpassung 2'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz2'}
          label={'Anpassungssatz 2'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'erwerbsminderungsrente'}
          label={'Erwerbsminderungsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'witwenrente'}
          label={'Witwenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'waisenrente'}
          label={'Waisenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungen`}
          label={'Bemerkungen'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </Grid>
  )
}

const AndereVersorgungseinrichtungForm = ({
  selectiveFieldProps,
}: VersorgungFormProps): ReactElement => {
  return (
    <Grid container rowSpacing={1} spacing={2}>
      <Grid item xs={12}>
        <SelectInput<GesetzlicheVersorgungFormModel>
          options={gesetzlicheVersorgungTypOptions}
          name={`gesetzlicheVersorgungsTyp`}
          label={'Versorgungstyp'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VersichertePersonSelect
          name={'zugeordnetePersonId'}
          label={'Zugeordnete Person'}
          selectiveFieldProps={selectiveFieldProps}
          noKinder
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={'versorgungseinrichtung'}
          label={'Einrichtung'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'gesetzlicheAltersrente'}
          label={'Gesetzliche Altersrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung1'}
          label={'Altersrente bei Anpassung 1'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz1'}
          label={'Anpassungssatz 1'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'altersrenteBeiAnpassung2'}
          label={'Altersrente bei Anpassung 2'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'anpassungssatz2'}
          label={'Anpassungssatz 2'}
          inputUnit={'%'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'erwerbsminderungsrente'}
          label={'Erwerbsminderungsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'berufsunfaehigkeitsrente'}
          label={'Berufsunfähigkeitsrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'witwenrente'}
          label={'Witwenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'waisenrente'}
          label={'Waisenrente'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput<GesetzlicheVersorgungFormModel>
          name={'beitrag'}
          label={'Beitrag'}
          inputUnit={'€'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          name={`zahlweise`}
          label={'Zahlweise'}
          options={zahlweiseOptions}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungen`}
          label={'Bemerkungen'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput<GesetzlicheVersorgungFormModel>
          name={`bemerkungenIntern`}
          label={'Bemerkungen Intern'}
          multiline
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </Grid>
  )
}
