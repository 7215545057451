import {
  AnlegerprofilAndereAnlagenFormModel,
  AnlegerprofilDienstleistungenFormModel,
  AnlegerprofilFinanzanlagenFormModel,
  AnlegerprofilFormModel,
  AnlegerprofilKreditfinanzierteAnlagenFormModel,
} from 'form/KundeFormModel'
import {
  Anlegerprofil,
  AnlegerprofilAndereAnlagen,
  AnlegerprofilDienstleistungen,
  AnlegerprofilFinanzanlagen,
  AnlegerprofilKreditfinanzierteAnlagen,
} from 'interfaces/mynorm-api-model-interfaces'
import { mapToNumericRequestModelType, mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertAnlegerprofil = (
  formModel: AnlegerprofilFormModel,
  source?: RecursivePartial<Anlegerprofil>,
): RecursivePartial<Anlegerprofil> | undefined => {
  return {
    finanzanlagen: convertFinanzanlagen(formModel.finanzanlagen, source?.finanzanlagen),
    andereAnlagen: convertAndereAnlagen(formModel.andereAnlagen, source?.andereAnlagen),
    kreditfinanzierteAnlagen: convertKreditfinanzierteAnlagen(
      formModel.kreditfinanzierteAnlagen,
      source?.kreditfinanzierteAnlagen,
    ),
    dienstleistungen: convertDienstleistungen(formModel.dienstleistungen),
  }
}

const convertFinanzanlagen = (
  formModel: AnlegerprofilFinanzanlagenFormModel,
  source?: RecursivePartial<AnlegerprofilFinanzanlagen>,
): RecursivePartial<AnlegerprofilFinanzanlagen> | undefined => {
  return {
    geldmarktfondsKenntnisse: formModel.geldmarktfondsKenntnisse,
    geldmarktfondsAnzahl: mapToNumericRequestModelType(
      formModel.geldmarktfondsAnzahl,
      source?.geldmarktfondsAnzahl,
    ),
    geldmarktfondsUmsatz: mapToNumericRequestModelType(
      formModel.geldmarktfondsUmsatz,
      source?.geldmarktfondsUmsatz,
    ),
    rentenfondsAnleihenKenntnisse: formModel.rentenfondsAnleihenKenntnisse,
    rentenfondsAnleihenAnzahl: mapToNumericRequestModelType(
      formModel.rentenfondsAnleihenAnzahl,
      source?.rentenfondsAnleihenAnzahl,
    ),
    rentenfondsAnleihenUmsatz: mapToNumericRequestModelType(
      formModel.rentenfondsAnleihenUmsatz,
      source?.rentenfondsAnleihenUmsatz,
    ),
    fondsAktienKenntnisse: formModel.fondsAktienKenntnisse,
    fondsAktienAnzahl: mapToNumericRequestModelType(
      formModel.fondsAktienAnzahl,
      source?.fondsAktienAnzahl,
    ),
    fondsAktienUmsatz: mapToNumericRequestModelType(
      formModel.fondsAktienUmsatz,
      source?.fondsAktienUmsatz,
    ),
    mischfondsKenntnisse: formModel.mischfondsKenntnisse,
    mischfondsAnzahl: mapToNumericRequestModelType(
      formModel.mischfondsAnzahl,
      source?.mischfondsAnzahl,
    ),
    mischfondsUmsatz: mapToNumericRequestModelType(
      formModel.mischfondsUmsatz,
      source?.mischfondsUmsatz,
    ),
    immobilienfondsKenntnisse: formModel.immobilienfondsKenntnisse,
    immobilienfondsAnzahl: mapToNumericRequestModelType(
      formModel.immobilienfondsAnzahl,
      source?.immobilienfondsAnzahl,
    ),
    immobilienfondsUmsatz: mapToNumericRequestModelType(
      formModel.immobilienfondsUmsatz,
      source?.immobilienfondsUmsatz,
    ),
    strukturierteWertpapierKenntnisse: formModel.strukturierteWertpapierKenntnisse,
    strukturierteWertpapierAnzahl: mapToNumericRequestModelType(
      formModel.strukturierteWertpapierAnzahl,
      source?.strukturierteWertpapierAnzahl,
    ),
    strukturierteWertpapierUmsatz: mapToNumericRequestModelType(
      formModel.strukturierteWertpapierUmsatz,
      source?.strukturierteWertpapierUmsatz,
    ),
    terminGeschaefteKenntnisse: formModel.terminGeschaefteKenntnisse,
    terminGeschaefteAnzahl: mapToNumericRequestModelType(
      formModel.terminGeschaefteAnzahl,
      source?.terminGeschaefteAnzahl,
    ),
    terminGeschaefteUmsatz: mapToNumericRequestModelType(
      formModel.terminGeschaefteUmsatz,
      source?.terminGeschaefteUmsatz,
    ),
    geschlosseneInvestmentvermoegenKenntnisse: formModel.geschlosseneInvestmentvermoegenKenntnisse,
    geschlosseneInvestmentvermoegenAnzahl: mapToNumericRequestModelType(
      formModel.geschlosseneInvestmentvermoegenAnzahl,
      source?.geschlosseneInvestmentvermoegenAnzahl,
    ),
    geschlosseneInvestmentvermoegenUmsatz: mapToNumericRequestModelType(
      formModel.geschlosseneInvestmentvermoegenUmsatz,
      source?.geschlosseneInvestmentvermoegenUmsatz,
    ),
    sonstigeKenntnisse: formModel.sonstigeKenntnisse,
    sonstigeAnzahl: mapToNumericRequestModelType(formModel.sonstigeAnzahl, source?.sonstigeAnzahl),
    sonstigeUmsatz: mapToNumericRequestModelType(formModel.sonstigeUmsatz, source?.sonstigeUmsatz),
  }
}

const convertAndereAnlagen = (
  formModel: AnlegerprofilAndereAnlagenFormModel,
  source?: RecursivePartial<AnlegerprofilAndereAnlagen>,
): RecursivePartial<AnlegerprofilAndereAnlagen> | undefined => {
  return {
    sparTagesTermingeldKenntnisse: formModel.sparTagesTermingeldKenntnisse,
    sparTagesTermingeldAnzahl: mapToNumericRequestModelType(
      formModel.sparTagesTermingeldAnzahl,
      source?.sparTagesTermingeldAnzahl,
    ),
    sparTagesTermingeldUmsatz: mapToNumericRequestModelType(
      formModel.sparTagesTermingeldUmsatz,
      source?.sparTagesTermingeldUmsatz,
    ),
    immobilienKenntnisse: formModel.immobilienKenntnisse,
    immobilienAnzahl: mapToNumericRequestModelType(
      formModel.immobilienAnzahl,
      source?.immobilienAnzahl,
    ),
    immobilienUmsatz: mapToNumericRequestModelType(
      formModel.immobilienUmsatz,
      source?.immobilienUmsatz,
    ),
    sonstigesKenntnisse: formModel.sonstigesKenntnisse,
    sonstigesAnzahl: mapToNumericRequestModelType(
      formModel.sonstigesAnzahl,
      source?.sonstigesAnzahl,
    ),
    sonstigesUmsatz: mapToNumericRequestModelType(
      formModel.sonstigesUmsatz,
      source?.sonstigesUmsatz,
    ),
  }
}

const convertKreditfinanzierteAnlagen = (
  formModel: AnlegerprofilKreditfinanzierteAnlagenFormModel,
  source?: RecursivePartial<AnlegerprofilKreditfinanzierteAnlagen>,
): RecursivePartial<AnlegerprofilKreditfinanzierteAnlagen> | undefined => {
  return {
    finanzierungsArt: mapToRequestModelType(formModel.finanzierungsArt, source?.finanzierungsArt),
    kreditVolumen: mapToNumericRequestModelType(formModel.kreditVolumen, source?.kreditVolumen),
  }
}

const convertDienstleistungen = (
  formModel: AnlegerprofilDienstleistungenFormModel,
): RecursivePartial<AnlegerprofilDienstleistungen> | undefined => {
  return {
    beratungsfreieOrderausfuehrung: formModel.beratungsfreieOrderausfuehrung,
    anlageberatung: formModel.anlageberatung,
    vermoegensverwaltung: formModel.vermoegensverwaltung,
  }
}
