import React, { ReactElement, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material'
import { sortBy } from 'lodash'
import { useEffectOnce } from 'usehooks-ts'

import { useFetchPersonen } from 'api/usePerson'
import { useDebounce } from 'hooks/use-debounce'
import { Person, SimplePerson } from 'interfaces/mynorm-api-model-interfaces'
import { extractFullName } from 'utils/nameUtils'

type KundenwaehlInputProps = {
  onSelect?: (person: SimplePerson | null) => Promise<void>
  initialSelectedKunde?: SimplePerson | Person
  excludedFinfireIds?: string[]
  excludeFirmenkunden?: boolean
  className?: string
}

const formatPerson = (person: SimplePerson): string => {
  if (person.firmenkunde) {
    return `${person.firma} (${person.finfireId})`
  }
  return `${extractFullName(person)} (${person.finfireId})`
}

const isSimplePerson = (person: SimplePerson | Person): person is SimplePerson => {
  return 'finfireId' in person
}

export const KundenwahlInput = ({
  onSelect,
  initialSelectedKunde,
  excludedFinfireIds,
  excludeFirmenkunden,
  className,
}: KundenwaehlInputProps): ReactElement => {
  const [suchbegriff, setSuchbegriff] = useState('')
  const [direkteKunden, setDirekteKunden] = useState(true)
  const [selectedKunde, setSelectedKunde] = useState<SimplePerson | null>(null)
  const debouncedSuchbegriff = useDebounce(suchbegriff, 500)
  const personenQueryResult = useFetchPersonen(debouncedSuchbegriff, direkteKunden, (persons) =>
    sortBy(
      persons
        .filter((person) => !excludedFinfireIds || !excludedFinfireIds?.includes(person.finfireId))
        .filter((person) => !excludeFirmenkunden || !person.firmenkunde),
      ['vorname', 'nachname'],
    ),
  )

  useEffectOnce(() => {
    if (initialSelectedKunde) {
      if (isSimplePerson(initialSelectedKunde)) {
        setSelectedKunde(initialSelectedKunde)
      } else {
        setSelectedKunde({
          vorname: initialSelectedKunde.vorname,
          nachname: initialSelectedKunde.nachname,
          titel: initialSelectedKunde.titel,
          finfireId: initialSelectedKunde.meta.finfireId || initialSelectedKunde.meta.myviId,
          firmenkunde: initialSelectedKunde.firmenkunde,
          firma: initialSelectedKunde.firma,
        })
      }
    }
  })

  const handleSelect = async (person: SimplePerson | null): Promise<void> => {
    onSelect?.(person)
    setSelectedKunde(person)
  }

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Autocomplete
          id={'waehler'}
          className={className}
          options={personenQueryResult.data || []}
          getOptionLabel={formatPerson}
          isOptionEqualToValue={(option, value): boolean => option.finfireId === value.finfireId}
          onChange={(event, value): Promise<void> => handleSelect(value)}
          value={selectedKunde}
          onEmptied={(): Promise<void> => handleSelect(null)}
          noOptionsText={'Keine Kunden gefunden.'}
          loadingText={'Lade Kunden...'}
          loading={personenQueryResult.isLoading}
          renderInput={(params): ReactElement => {
            return (
              <TextField
                {...params}
                variant={'standard'}
                id={'suchfeld'}
                name={'suchfeld'}
                label={'Vorname / Nachname (Kundennummer)'}
                value={suchbegriff}
                onChange={(event): void => setSuchbegriff(event.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {personenQueryResult.isFetching && !personenQueryResult.isLoading ? (
                        <CircularProgress size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={12} container>
        <FormControl error={false}>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                color='secondary'
                checked={direkteKunden}
                onChange={() => setDirekteKunden((prevState) => !prevState)}
              />
            }
            label={'nur direkte Kunden'}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
