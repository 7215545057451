import React, { ReactElement } from 'react'

import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import { PraemienArt } from 'interfaces/mynorm-api-model-interfaces'

type PraemienArtSelectProps = {
  name: string
  label?: string
  disabled?: boolean
}

export const PraemienArtSelect = ({
  name,
  label,
  disabled,
}: PraemienArtSelectProps): ReactElement => {
  return (
    <SelectInput
      options={PRAEMIEN_ART_OPTIONS}
      name={name}
      label={label ?? 'Prämienart'}
      disabled={disabled}
    ></SelectInput>
  )
}

const PRAEMIEN_ART_OPTIONS: Option<PraemienArt>[] = [
  { value: 'EINZEL', label: 'Einzel' },
  { value: 'GESAMT', label: 'Gesamt' },
]
