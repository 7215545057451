import React, { ReactElement } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useField } from 'formik'

import { InputWrapper } from 'components/controls/inputs/InputWrapper'
import { Option } from 'components/controls/inputs/SelectInput'
import { useProduktSpecs } from 'components/personal/absicherung2/hooks/useProduktSpecs'
import { ProduktSpecs, VertragGeschaeft } from 'interfaces/mynorm-api-model-interfaces'

type ProduktSelectProps = {
  name: string
  geschaeft: VertragGeschaeft
  availableOptions?: string[]
  label?: string
  required?: boolean
  disabled?: boolean
}

export const ProdukteSelect = ({
  name,
  geschaeft,
  availableOptions,
  label,
  required,
  disabled,
}: ProduktSelectProps): ReactElement => {
  const [{ value, ...field }, { error, touched }, { setValue }] = useField<string[]>(name)
  const options = useProduktOptions(geschaeft, availableOptions)

  const isError = !!error && touched
  return (
    <InputWrapper>
      <Autocomplete
        {...field}
        value={value}
        onChange={(_, value) => setValue(value)}
        renderInput={(props) => (
          <TextField
            {...props}
            variant={'standard'}
            label={label ?? 'Produkte'}
            error={isError}
            helperText={error && touched ? error : ' '}
            required={required}
          />
        )}
        options={options.map((option) => option.value)}
        getOptionKey={(value) => value}
        getOptionLabel={(value) => options.find((o) => o.value === value)?.label ?? ''}
        disabled={disabled}
        disableCloseOnSelect
        multiple
        fullWidth
      />
    </InputWrapper>
  )
}

const useProduktOptions = (geschaeft: VertragGeschaeft, availableOptions?: string[]): Option[] => {
  const { produkte, findProduktById } = useProduktSpecs(geschaeft)
  const produktIds =
    availableOptions === undefined ? produkte.map((p) => p.produktId) : availableOptions
  return toProduktOptions(produktIds, findProduktById)
}

const toProduktOptions = (
  produktIds: string[],
  findProdukt: (produktId: string) => ProduktSpecs | undefined,
): Option[] => {
  return produktIds
    .map((produktId) => findProdukt(produktId))
    .filter((produkt): produkt is ProduktSpecs => !!produkt)
    .map((produkt) => {
      return {
        value: produkt.produktId,
        label: extractProduktName(produkt),
      }
    })
}

const extractProduktName = (produktSpecs: ProduktSpecs): string => {
  const name = produktSpecs.produktName
  if (produktSpecs.durchfuehrungswege.length) {
    return `${name} (bAV)`
  }
  return name
}
