import React, { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'

import { GuidanceStateContextProvider } from 'hooks/useGuidanceState'

export const GuidanceRoute = (): ReactElement => {
  return (
    <GuidanceStateContextProvider>
      <Outlet />
    </GuidanceStateContextProvider>
  )
}
