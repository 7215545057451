import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FormikProps } from 'formik'

import { VertragsProdukteFormBlock } from 'components/personal/absicherung2/blocks/VertragsProdukteFormBlock'
import { PraemienArtSelect } from 'components/personal/absicherung2/selects/PraemienArtSelect'
import { PraemienInput } from 'components/personal/absicherung2/selects/PraemienInput'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'

type VertragFormPage2Props = {
  formProps: FormikProps<VertragFormModel>
}

export const VertragFormPage2 = ({ formProps }: VertragFormPage2Props): ReactElement => {
  const disabled = !formProps.values.fremdvertrag

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12} md={6}>
        <Typography variant='overline' color='primary'>
          Prämien
        </Typography>
        <Stack>
          <PraemienArtSelect name={'praemienArt'} disabled={disabled} />
          {formProps.values.praemienArt === 'GESAMT' && (
            <PraemienInput
              name={`gesamtPraemien`}
              praemien={formProps.values.gesamtPraemien}
              disabled={disabled}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline' color='primary'>
          Produkte
        </Typography>
        <VertragsProdukteFormBlock formProps={formProps} />
      </Grid>
    </Grid>
  )
}
