import { ReactElement } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { FormCellLabel } from 'components/controls/cells/FormCell'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { PersonalAnlegerProfilAndereAnlagenForm } from 'components/personal/anlegerprofil/PersonalAnlegerProfilAndereAnlagenForm'
import { PersonalAnlegerProfilAndereAnlagenLabels } from 'components/personal/anlegerprofil/PersonalAnlegerProfilAndereAnlagenLabels'
import { PersonalAnlegerProfilDienstleistungenForm } from 'components/personal/anlegerprofil/PersonalAnlegerProfilDienstleistungenForm'
import { PersonalAnlegerProfilDienstleistungenLabels } from 'components/personal/anlegerprofil/PersonalAnlegerProfilDienstleistungenLabels'
import { PersonalAnlegerProfilFinanzAnlagenForm } from 'components/personal/anlegerprofil/PersonalAnlegerProfilFinanzAnlagenForm'
import { PersonalAnlegerProfilFinanzAnlagenLabels } from 'components/personal/anlegerprofil/PersonalAnlegerProfilFinanzAnlagenLabels'
import { PersonalAnlegerProfilKreditfinanzierteAnlagenForm } from 'components/personal/anlegerprofil/PersonalAnlegerProfilKreditfinanzierteAnlagenForm'
import { PersonalAnlegerProfilKreditfinanzierteAnlagenLabels } from 'components/personal/anlegerprofil/PersonalAnlegerProfilKreditfinanzierteAnlagenLabels'
import { PersonalFormLayout } from 'components/personal/PersonalFormLayout'
import { useEditableKunde } from 'state/useEditableKunde'
import { extractFullName } from 'utils/nameUtils'

type PersonalAnlegerProfilFormProps = {
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalAnlegerProfilForm = ({
  selectiveFormProps,
}: PersonalAnlegerProfilFormProps): ReactElement => {
  const { editableKunde } = useEditableKunde()
  const isPartnerAvailable = Boolean(editableKunde.personen[1])
  return (
    <PersonalFormLayout title={'Anlegerprofil'}>
      <Table>
        <TableHead>
          <TableRow>
            <FormCellLabel $border $bold>
              Kenntnisse / Erfahrungen Vermögensanlagen
            </FormCellLabel>
            <FormCellLabel $border={isPartnerAvailable} $bold>
              {extractFullName(editableKunde.personen[0])}
            </FormCellLabel>
            {isPartnerAvailable && (
              <FormCellLabel $bold>{extractFullName(editableKunde.personen[1])}</FormCellLabel>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilFinanzAnlagenLabels />
            </TableCell>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilFinanzAnlagenForm
                personIndex={0}
                selectiveFormProps={selectiveFormProps}
                isPartnerAvailable={isPartnerAvailable}
              />
            </TableCell>
            {isPartnerAvailable && (
              <TableCell padding={'none'}>
                <PersonalAnlegerProfilFinanzAnlagenForm
                  personIndex={1}
                  selectiveFormProps={selectiveFormProps}
                />
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilAndereAnlagenLabels />
            </TableCell>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilAndereAnlagenForm
                personIndex={0}
                selectiveFormProps={selectiveFormProps}
                isPartnerAvailable={isPartnerAvailable}
              />
            </TableCell>
            {isPartnerAvailable && (
              <TableCell padding={'none'}>
                <PersonalAnlegerProfilAndereAnlagenForm
                  personIndex={1}
                  selectiveFormProps={selectiveFormProps}
                />
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilKreditfinanzierteAnlagenLabels />
            </TableCell>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilKreditfinanzierteAnlagenForm
                personIndex={0}
                selectiveFormProps={selectiveFormProps}
                isPartnerAvailable={isPartnerAvailable}
              />
            </TableCell>
            {isPartnerAvailable && (
              <TableCell padding={'none'}>
                <PersonalAnlegerProfilKreditfinanzierteAnlagenForm
                  personIndex={1}
                  selectiveFormProps={selectiveFormProps}
                />
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilDienstleistungenLabels />
            </TableCell>
            <TableCell padding={'none'}>
              <PersonalAnlegerProfilDienstleistungenForm
                personIndex={0}
                selectiveFormProps={selectiveFormProps}
                isPartnerAvailable={isPartnerAvailable}
              />
            </TableCell>
            {isPartnerAvailable && (
              <TableCell padding={'none'}>
                <PersonalAnlegerProfilDienstleistungenForm
                  personIndex={1}
                  selectiveFormProps={selectiveFormProps}
                />
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </PersonalFormLayout>
  )
}
