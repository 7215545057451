import { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { TextInput } from 'components/controls/inputs/TextInput'

export const NewKundeAdditionalInformationFields = (): ReactElement => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextInput name={'kontaktdaten.email'} label={'E-Mail'} />
      </Grid>
      <Grid item xs={6}>
        <TextInput name={'kontaktdaten.telefon'} label={'Telefon'} />
      </Grid>
    </Grid>
  )
}
