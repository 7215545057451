import { valueOrDefault } from 'form/formModelConverterUtil'
import { AnschriftFormModel } from 'form/KundeFormModel'
import { createDefaultPersonFormModel } from 'form/kundeFormModelCreator'
import { Anschrift } from 'interfaces/mynorm-api-model-interfaces'

const defaultAnschriftFormModel = createDefaultPersonFormModel().anschrift

export const convertToAnschriftFormModel = (
  anschrift: Anschrift | undefined,
): AnschriftFormModel => {
  if (!anschrift) {
    return defaultAnschriftFormModel
  }
  return {
    strasse: valueOrDefault(anschrift.strasse, defaultAnschriftFormModel.strasse),
    ort: valueOrDefault(anschrift.ort, defaultAnschriftFormModel.ort),
    postleitzahl: valueOrDefault(anschrift.postleitzahl, defaultAnschriftFormModel.postleitzahl),
    hausnummer: valueOrDefault(anschrift.hausnummer, defaultAnschriftFormModel.hausnummer),
    bundesland: valueOrDefault(anschrift.bundesland, defaultAnschriftFormModel.bundesland),
  }
}
