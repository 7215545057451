import React, { ReactElement, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Collapse,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import { uniq } from 'lodash'

import { DetailsReadView } from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { DeleteVertragDialog } from 'components/personal/absicherung2/dialog/DeleteVertragDialog'
import { EditVertragDialog } from 'components/personal/absicherung2/dialog/EditVertragDialog'
import { IconTitle } from 'components/personal/absicherung2/IconTitle'
import {
  LabelMultiValueEntry,
  LabelValueEntry,
} from 'components/personal/absicherung2/LabelValueEntry'
import {
  formatGeschaeft,
  getGeschaeftIcon,
} from 'components/personal/absicherung2/selects/GeschaeftSelect'
import styles from 'components/personal/absicherung2/VertraegeList.module.scss'
import { formatCurrency, formatDate } from 'components/util/formatUtil'
import {
  Vertrag,
  VertragGeschaeft,
  VertragsProdukt,
  Zahlweise,
} from 'interfaces/mynorm-api-model-interfaces'

type VertraegeListProps = {
  vertraege: Vertrag[]
  geschaeft: VertragGeschaeft
}

export const VertraegeList = ({
  vertraege,
  geschaeft,
}: VertraegeListProps): ReactElement | null => {
  if (!vertraege || vertraege.length === 0) {
    return null
  }
  return (
    <Stack>
      {geschaeft && (
        <IconTitle title={formatGeschaeft(geschaeft)} icon={getGeschaeftIcon(geschaeft)} />
      )}
      <TableContainer>
        <Table aria-label='collapsible table' style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow className={styles.vertraegeDataTableHeaderRow}>
              <TableCell className={styles.vertraegeDataTableCol1} />
              <TableCell>
                <Typography>Produkte</Typography>
              </TableCell>
              <TableCell>
                <Typography>Gesellschaft</Typography>
              </TableCell>
              <TableCell>
                <Typography>Versicherte Personen/Objekte</Typography>
              </TableCell>
              <TableCell className={styles.vertraegeDataTableCol5}>
                <Typography>Jahresprämie</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(vertraege ?? []).map((vertrag) => (
              <VertraegeListItem key={vertrag.meta.myviId} vertrag={vertrag} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

type RowProps = {
  vertrag: Vertrag
}

const VertraegeListItem = ({ vertrag }: RowProps): ReactElement | null => {
  const [open, setOpen] = useState(false)
  if (!vertrag) {
    return null
  }

  const risikenBezeichnungen = vertrag.vertragsProdukte
    .flatMap((vertragsProdukt) => vertragsProdukt.risiken)
    .map((risiko) => risiko.displayName)

  return (
    <>
      <TableRow
        className={clsx(styles.vertraegeTableRow, open && styles.open)}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack flexDirection='row' alignItems='center'>
            <IconButton sx={{ display: { xs: 'none', md: 'block' } }} disabled>
              {getGeschaeftIcon(vertrag.vertragGeschaeft)}
            </IconButton>
            {vertrag.vertragDisplaySummary.produkte.join(', ')}
          </Stack>
        </TableCell>
        <TableCell>{vertrag?.vertragGesellschaft?.name}</TableCell>
        <TableCell>
          {uniq(risikenBezeichnungen).map((bezeichnung) => {
            return (
              <Typography variant='body2' key={bezeichnung}>
                -&nbsp;{bezeichnung}
              </Typography>
            )
          })}
        </TableCell>
        <TableCell align='right'>
          {formatCurrency(vertrag.vertragDisplaySummary.jahrespraemie)}
        </TableCell>
      </TableRow>
      <TableRow className={clsx(styles.vertraegeTableRowContent, styles.open)}>
        <TableCell className={styles.vertraegeTableCellContent}></TableCell>
        <TableCell
          className={clsx(
            styles.vertraegeTableCellContent,
            open && styles.vertraegeTableCellContentOpen,
          )}
          colSpan={4}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <VertragOverview vertrag={vertrag} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type VertragOverviewProps = {
  vertrag: Vertrag
}

const VertragOverview = ({ vertrag }: VertragOverviewProps): ReactElement => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Stack>
          <Typography variant='overline'>Allg. Vertragsdaten:</Typography>
          <Vertragsdaten vertrag={vertrag} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={7}>
        <Stack>
          <Typography variant='overline'>Inhalte:</Typography>
          <VertragsProdukte vertrag={vertrag} />
        </Stack>
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='flex-end' gap='1rem'>
        <EditVertragDialog vertrag={vertrag} />
        <DeleteVertragDialog vertrag={vertrag} />
      </Grid>
    </Grid>
  )
}

type VertragsdatenProps = {
  vertrag: Vertrag
}

const Vertragsdaten = ({ vertrag }: VertragsdatenProps): ReactElement => {
  return (
    <Stack rowGap={2}>
      <IconTitle title={vertrag.vertragDisplaySummary.vertragGeschaeft} variant='h6' />
      <LabelValueEntry label={'Gesellschaft'} value={vertrag.vertragDisplaySummary.gesellschaft} />
      <LabelValueEntry
        label={'Versicherungsnehmer'}
        value={vertrag.vertragDisplaySummary.versicherungsnehmer}
      />
      <LabelValueEntry
        label={'Vertragsnummer'}
        value={vertrag.vertragDisplaySummary.vertragNummer}
      />
      <LabelValueEntry label={'Beginn'} value={formatDate(vertrag.vertragDisplaySummary.beginn)} />
      <LabelValueEntry label={'Ende'} value={formatDate(vertrag.vertragDisplaySummary.ende)} />
      {vertrag.praemienArt === 'GESAMT' && (
        <LabelMultiValueEntry
          label={'Prämien'}
          values={vertrag.gesamtPraemien.map((praemie) => {
            return (
              <PraemieEntry
                key={praemie.zahlweise + praemie.summe}
                praemie={praemie.summe}
                zahlweise={praemie.zahlweise}
              />
            )
          })}
        />
      )}
      <LabelValueEntry label={'Bemerkungen'} value={vertrag.bemerkungen} labelPosition='top' />
      <LabelValueEntry
        label={'Bemerkungen Intern'}
        value={vertrag.bemerkungenIntern}
        labelPosition='top'
      />
    </Stack>
  )
}

type VertragsProdukteProps = {
  vertrag: Vertrag
}

const VertragsProdukte = ({ vertrag }: VertragsProdukteProps): ReactElement => {
  return (
    <>
      {vertrag.vertragsProdukte?.map((vertragsProdukt, index, array) => {
        return (
          <VertragsProduktItem
            key={vertragsProdukt.produkt.produktId + index}
            vertrag={vertrag}
            vertragsProdukt={vertragsProdukt}
            isLastItem={index === array.length - 1}
          />
        )
      })}
    </>
  )
}

type VertragsProduktItemProps = {
  vertrag: Vertrag
  vertragsProdukt: VertragsProdukt
  isLastItem: boolean
}

const VertragsProduktItem = ({
  vertrag,
  vertragsProdukt,
  isLastItem,
}: VertragsProduktItemProps): ReactElement => {
  return (
    <Stack rowGap={2}>
      <IconTitle
        title={vertragsProdukt.produkt.produktName}
        icon={getGeschaeftIcon(vertrag.vertragGeschaeft)}
        variant='h6'
      />
      <LabelMultiValueEntry
        label={'Versicherte Risiken'}
        separatorLine={true}
        values={vertragsProdukt.risiken.map((risiko) => {
          return vertrag.praemienArt === 'EINZEL' ? (
            <LabelMultiValueEntry
              label={risiko.displayName}
              values={(risiko.einzelPraemien ?? []).map((praemie) => {
                return (
                  <PraemieEntry
                    key={praemie.zahlweise + praemie.summe}
                    praemie={praemie.summe}
                    zahlweise={praemie.zahlweise}
                  />
                )
              })}
            />
          ) : (
            risiko.displayName
          )
        })}
      />
      <DetailsReadView
        produktId={vertragsProdukt.produkt.produktId}
        details={vertragsProdukt.details}
      />
      {!isLastItem && <hr className={styles.line} />}
    </Stack>
  )
}

type PraemieEntryProps = {
  praemie: number
  zahlweise: Zahlweise
}

const PraemieEntry = ({ praemie, zahlweise }: PraemieEntryProps): ReactElement => {
  return (
    <Typography component='span' variant='body1'>
      {formatCurrency(praemie)}
      <Typography color='gray' component='span'>
        &nbsp;({formatZahlweise(zahlweise)})
      </Typography>
    </Typography>
  )
}

const formatZahlweise = (zahlweise: Zahlweise): string => {
  switch (zahlweise) {
    case 'MONATLICH':
      return 'monatlich'
    case 'QUARTAL':
      return 'quartalsweise'
    case 'HALBJAEHRLICH':
      return 'halbjährlich'
    case 'JAEHRLICH':
      return 'jährlich'
    case 'EINMALBETRAG':
      return 'einmalig'
  }
}
