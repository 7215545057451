import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { MenuElement } from 'components/header/MenuElement'
import styles from 'components/header/Navigation.module.scss'
import { useHaushaltState } from 'hooks/useHaushaltState'

export const Navigation = (): ReactElement => {
  const customerId = useCustomerId()
  return (
    <Box className={styles.navigation}>
      <Box className={styles.navigationBox}>
        <MenuElement title={'Kundenauswahl'} path={'/'} />
        <MenuElement title={'Guidance'} path={'/guidance'} />
        {customerId && (
          <>
            <MenuElement title={'Kundendaten'} path={`/kunde/${customerId}/daten`} />
            <MenuElement title={'Cockpit'} path={`/kunde/${customerId}/cockpit`} />
          </>
        )}
      </Box>
    </Box>
  )
}

const useCustomerId = (): string | undefined => {
  const { customerId: customerIdParam } = useParams()
  const customerId = useHaushaltState(({ customerId }) => customerId)
  return customerId || customerIdParam
}
