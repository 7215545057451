import {
  convertZahlweiseFormModel,
  handlePercent,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { GesetzlicheVersorgungFormModel } from 'form/KundeFormModel'
import { createDefaultGesetzlicheVersorgungFormModel } from 'form/kundeFormModelCreator'
import { GesetzlicheVersorgung } from 'interfaces/mynorm-api-model-interfaces'

const defaultGesetzlicheVersorgungFormModel = createDefaultGesetzlicheVersorgungFormModel()

export const convertToGesetzlicheVersorgungFormModel = (
  versorgung: GesetzlicheVersorgung | undefined,
): GesetzlicheVersorgungFormModel => {
  if (!versorgung) {
    return defaultGesetzlicheVersorgungFormModel
  }
  return {
    meta: convertToMeta(versorgung.meta),
    gesetzlicheVersorgungsTyp: valueOrDefault(
      versorgung.gesetzlicheVersorgungsTyp,
      defaultGesetzlicheVersorgungFormModel.gesetzlicheVersorgungsTyp,
    ),
    zugeordnetePersonId: valueOrDefault(
      versorgung.zugeordnetePersonId,
      defaultGesetzlicheVersorgungFormModel.zugeordnetePersonId,
    ),
    versorgungseinrichtung: nullableValueOrDefault(
      versorgung.versorgungseinrichtung,
      defaultGesetzlicheVersorgungFormModel.versorgungseinrichtung,
    ),
    gesetzlicheAltersrente: nullableValueOrDefault(
      versorgung.gesetzlicheAltersrente?.toString(),
      defaultGesetzlicheVersorgungFormModel.gesetzlicheAltersrente,
    ),
    altersrenteBeiAnpassung1: nullableValueOrDefault(
      versorgung.altersrenteBeiAnpassung1?.toString(),
      defaultGesetzlicheVersorgungFormModel.altersrenteBeiAnpassung1,
    ),
    anpassungssatz1: nullableValueOrDefault(
      handlePercent(versorgung.anpassungssatz1)?.toString(),
      defaultGesetzlicheVersorgungFormModel.anpassungssatz1,
    ),
    altersrenteBeiAnpassung2: nullableValueOrDefault(
      versorgung.altersrenteBeiAnpassung2?.toString(),
      defaultGesetzlicheVersorgungFormModel.altersrenteBeiAnpassung2,
    ),
    anpassungssatz2: nullableValueOrDefault(
      handlePercent(versorgung.anpassungssatz2)?.toString(),
      defaultGesetzlicheVersorgungFormModel.anpassungssatz2,
    ),
    erwerbsminderungsrente: nullableValueOrDefault(
      versorgung.erwerbsminderungsrente?.toString(),
      defaultGesetzlicheVersorgungFormModel.erwerbsminderungsrente,
    ),
    berufsunfaehigkeitsrente: nullableValueOrDefault(
      versorgung.berufsunfaehigkeitsrente?.toString(),
      defaultGesetzlicheVersorgungFormModel.berufsunfaehigkeitsrente,
    ),
    witwenrente: nullableValueOrDefault(
      versorgung.witwenrente?.toString(),
      defaultGesetzlicheVersorgungFormModel.witwenrente,
    ),
    waisenrente: nullableValueOrDefault(
      versorgung.waisenrente?.toString(),
      defaultGesetzlicheVersorgungFormModel.waisenrente,
    ),
    beitrag: nullableValueOrDefault(
      versorgung.beitrag?.toString(),
      defaultGesetzlicheVersorgungFormModel.beitrag,
    ),
    zahlweise: convertZahlweiseFormModel(
      versorgung.zahlweise,
      defaultGesetzlicheVersorgungFormModel.zahlweise,
    ),
    bemerkungen: nullableValueOrDefault(
      versorgung.bemerkungen,
      defaultGesetzlicheVersorgungFormModel.bemerkungen,
    ),
    bemerkungenIntern: nullableValueOrDefault(
      versorgung.bemerkungenIntern,
      defaultGesetzlicheVersorgungFormModel.bemerkungenIntern,
    ),
    isNew: versorgung.createFlag ?? false,
    isDeleted: versorgung.deleteFlag ?? false,
    saveError: versorgung.saveError ?? false,
  }
}
