import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { ExpansionPanel } from 'components/expansion-panel/ExpansionPanel'
import { VersichertePersonSelect } from 'components/personal/absicherung2/selects/VersichertePersonSelect'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { DarlehenFormModel } from 'form/KundeFormModel'
import { darlehenInitialTouched } from 'form/kundeFormTouched'
import { darlehenValidationSchema } from 'form/kundeFormValidationSchema'
import {
  darlehenTypOptions,
  getOptionLabel,
  tilgungTypOptions,
  zahlartOptions,
  zahlweiseOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { DarlehenMutator } from 'state/darlehenMutator'
import { useEditableKunde } from 'state/useEditableKunde'
import { useSelectivePathsToUpdateState } from 'state/useSelectiveUpdatesState'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

type PersonalFinanzenDarlehenContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenDarlehenContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenDarlehenContainerProps): ReactElement => {
  const { editableKunde, darlehenMutator } = useEditableKunde()
  return (
    <>
      {editableKunde.personen[personIndex].vermoegen.darlehen.map((darlehen, darlehenIndex) => {
        return (
          <PersonalFinanzenSingleDarlehenContainer
            key={darlehenIndex}
            personIndex={personIndex}
            darlehenIndex={darlehenIndex}
            darlehenMutator={darlehenMutator}
            selectiveFormProps={selectiveFormProps}
          />
        )
      })}
    </>
  )
}

type PersonalFinanzenSingleDarlehenContainerProps = {
  personIndex: number
  darlehenIndex: number
  darlehenMutator: DarlehenMutator
  selectiveFormProps?: SelectiveFormProps
}

const PersonalFinanzenSingleDarlehenContainer = ({
  personIndex,
  darlehenIndex,
  darlehenMutator,
  selectiveFormProps,
}: PersonalFinanzenSingleDarlehenContainerProps): ReactElement | null => {
  const basePath = `personen.${personIndex}.vermoegen.darlehen`
  const formPath = `${basePath}.${darlehenIndex}`
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<DarlehenFormModel>(
    formPath,
    selectiveFormProps,
  )
  const { addPathsToDelete } = useSelectivePathsToUpdateState()

  const handleRemove = (formValue: DarlehenFormModel): void => {
    darlehenMutator.removeDarlehen(personIndex, darlehenIndex)
    if (formValue.isNew) {
      return
    }
    addPathsToDelete({
      path: basePath,
      idField: 'meta.myviId',
      idValue: formValue.meta.myviId,
    })
  }

  if (initialFormValues.isDeleted && !selectiveFormProps) {
    return null
  }

  return (
    <EditableKundeFormik
      key={darlehenIndex}
      initialValues={initialFormValues}
      updateState={(darlehen) => {
        darlehenMutator.updateDarlehen(personIndex, darlehenIndex, darlehen)
      }}
      validationSchema={darlehenValidationSchema}
      initialTouched={darlehenInitialTouched}
      validateOnMount
    >
      {(formikProps) => {
        const isDarlehensnehmerExternePerson = formikProps.values.darlehensnehmer.includes(
          EXTERNE_PERSON_OPTION_VALUE,
        )
        return (
          <ExpansionPanel
            title={`${getOptionLabel(formikProps.values.darlehenTyp, darlehenTypOptions)} - ${
              formikProps.values.bezeichnung || 'ohne Bezeichnung'
            }`}
            index={darlehenIndex}
            removeAction={!selectiveFormProps ? () => handleRemove(formikProps.values) : undefined}
            removeButtonLabel={'Löschen'}
            expanded={formikProps.values.expanded}
            onExpandedChanged={(expanded) => formikProps.setFieldValue('expanded', expanded)}
            displayAsDeleted={formikProps.values.isDeleted}
            displayAsNew={selectiveFormProps && formikProps.values.isNew}
            displayValidationError={!formikProps.isValid}
            displaySaveError={formikProps.values.saveError}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <Grid container rowSpacing={1} spacing={2}>
                  <Grid item xs={12}>
                    <SelectInput<DarlehenFormModel>
                      options={darlehenTypOptions}
                      name={`darlehenTyp`}
                      label={'Verbindlichkeitstyp'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput<DarlehenFormModel>
                      name={`bezeichnung`}
                      label={'Bezeichnung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <VersichertePersonSelect
                      name={'darlehensnehmer'}
                      label={'Verbindlichkeitssnehmer'}
                      selectiveFieldProps={selectiveFieldProps}
                      multiple
                      withExternePerson
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {isDarlehensnehmerExternePerson && (
                      <TextInput<DarlehenFormModel>
                        name={'darlehensnehmerExternePersonName'}
                        label={'Externe Person Name'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<DarlehenFormModel>
                      name={'darlehensgeber'}
                      label={'Verbindlichkeitsgeber'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<DarlehenFormModel>
                      name={'vertragsnummer'}
                      label={'Vertragsnummer'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateInput<DarlehenFormModel>
                      name={'darlehensbeginn'}
                      label={'Beginn'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateInput<DarlehenFormModel>
                      name={'darlehensende'}
                      label={'Ende'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <NumberInput<DarlehenFormModel>
                      name={`darlehensbetrag`}
                      label={'Betrag'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <NumberInput<DarlehenFormModel>
                      name={`darlehensstand`}
                      label={'Stand'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <DateInput<DarlehenFormModel>
                      name={`datumDarlehensstand`}
                      label={'Stand zum'}
                      maxDate={new Date()}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberInput<DarlehenFormModel>
                      name={`zinssatz`}
                      label={'Zinssatz'}
                      inputUnit={'%'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DateInput<DarlehenFormModel>
                      name={`zinsfestschreibung`}
                      label={'Zinsfestschreibung'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput<DarlehenFormModel>
                      name={`tilgungsTyp`}
                      label={'Tilgungstyp'}
                      options={tilgungTypOptions}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectInput
                      name={`darlehensrate.zahlweise`}
                      label={'Zahlweise'}
                      options={zahlweiseOptions}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectInput
                      name={`darlehensrate.zahlart`}
                      label={'Zahlart'}
                      options={zahlartOptions}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  {formikProps.values.darlehensrate.zahlweise !== 'EINMALZAHLUNG' &&
                    formikProps.values.darlehensrate.zahlweise !== '' && (
                      <Grid item md={6} xs={12}>
                        <NumberInput
                          name={`darlehensrate.sparrate`}
                          label={'Tilgungsrate'}
                          inputUnit={'€'}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                    )}
                  <Grid xs={12}></Grid>
                  <Grid item xs={6}>
                    <TextInput<DarlehenFormModel>
                      name={`bemerkungen`}
                      label={'Bemerkungen'}
                      selectiveFieldProps={selectiveFieldProps}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput<DarlehenFormModel>
                      name={`bemerkungenIntern`}
                      label={'Bemerkungen Intern'}
                      multiline
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanel>
        )
      }}
    </EditableKundeFormik>
  )
}
