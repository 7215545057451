import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart0 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_0.png'
import chart1 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_1.png'
import chart2 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_2.png'
import chart3 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_3.png'
import chart4 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_4.png'
import chart5 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_5.png'
import chart6 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_6.png'
import chart7 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_7.png'
import chart8 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_8.png'
import chart9 from 'assets/images/guidance/mitnorm/unser-gemeinsamer-weg/unser_gemeinsamer_weg_9.png'
import chart0Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_0.png'
import chart1Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_1.png'
import chart2Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_2.png'
import chart3Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_3.png'
import chart4Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_4.png'
import chart5Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_5.png'
import chart6Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_6.png'
import chart7Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_7.png'
import chart8Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_8.png'
import chart9Fe from 'assets/images/guidance/mitnorm-fe/unser-gemeinsamer-weg/unser_gemeinsamer_weg_9.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/mitnorm/GuidanceMitNorm14Page.module.scss'
import { GuidanceMitNormImage } from 'components/guidance/mitnorm/GuidanceMitNormImage'
import { Paths } from 'configurations/paths'

export const GuidanceMitNorm14Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3, chart4, chart5, chart6, chart7, chart8, chart9]
  const chartsFe = [
    chart0Fe,
    chart1Fe,
    chart2Fe,
    chart3Fe,
    chart4Fe,
    chart5Fe,
    chart6Fe,
    chart7Fe,
    chart8Fe,
    chart9Fe,
  ]
  return (
    <GuidanceSubPage
      menuIndex={0}
      menuSubIndex={13}
      previousPagePath={Paths.guidanceMitNorm13}
      nextPagePath={Paths.guidanceMitNorm15}
      guidanceColor='mitnorm'
      numberOfInterPages={10}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title={'UNSER GEMEINSAMER WEG'} subTitle={'mitNORM'}>
          <Box className={styles.chartContainer}>
            <Box className={styles.chart}>
              <GuidanceMitNormImage
                src={charts[interPageIndex]}
                feSrc={chartsFe[interPageIndex]}
                alt='Marktanbieter'
              />
            </Box>
          </Box>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
