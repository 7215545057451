import { isEmpty } from 'form/haushalt-converter/person-converter/personenConverter'
import { KontaktdatenFormModel } from 'form/KundeFormModel'
import { Kontaktdaten } from 'interfaces/mynorm-api-model-interfaces'
import { mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertKontaktdaten = (
  formModel: KontaktdatenFormModel | undefined,
  source?: RecursivePartial<Kontaktdaten>,
): RecursivePartial<Kontaktdaten> | undefined => {
  if (formModel == null || (isEmpty(formModel) && source === null)) {
    return source
  }
  return {
    email: mapToRequestModelType(formModel.email, source?.email),
    fax: mapToRequestModelType(formModel.fax, source?.fax),
    internet: mapToRequestModelType(formModel.internet, source?.internet),
    mobil: source?.mobil,
    telefon: mapToRequestModelType(formModel.telefon, source?.telefon),
  }
}
