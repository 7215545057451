import { SachwertFormModel } from 'form/KundeFormModel'
import { Sachwert } from 'interfaces/mynorm-api-model-interfaces'
import {
  mapToDateRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertSachwert = (
  formModel: SachwertFormModel,
  source?: Sachwert,
): RecursivePartial<Sachwert> => {
  const useFormModelAsDefault = formModel.isNew
  return {
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    sachwertTyp: mapToRequestModelType(
      formModel.sachwertTyp,
      source?.sachwertTyp,
      useFormModelAsDefault,
    ),
    bezeichnung: mapToRequestModelType(
      formModel.bezeichnung,
      source?.bezeichnung,
      useFormModelAsDefault,
    ),
    kennzeichen: mapToRequestModelType(
      formModel.kennzeichen,
      source?.kennzeichen,
      useFormModelAsDefault,
    ),
    bemerkungen: mapToRequestModelType(
      formModel.bemerkungen,
      source?.bemerkungen,
      useFormModelAsDefault,
    ),
    bemerkungenIntern: mapToRequestModelType(
      formModel.bemerkungenIntern,
      source?.bemerkungenIntern,
      useFormModelAsDefault,
    ),
    marke: mapToRequestModelType(formModel.marke, source?.marke, useFormModelAsDefault),
    modell: mapToRequestModelType(formModel.modell, source?.modell, useFormModelAsDefault),
    baujahr: mapToNumericRequestModelType(
      formModel.baujahr,
      source?.baujahr,
      useFormModelAsDefault,
    ),
    kaufpreis: mapToNumericRequestModelType(
      formModel.kaufpreis,
      source?.kaufpreis,
      useFormModelAsDefault,
    ),
    datumKauf: mapToDateRequestModelType(
      formModel.datumKauf,
      source?.datumKauf,
      useFormModelAsDefault,
    ),
    datumZeitwert: mapToDateRequestModelType(
      formModel.datumZeitwert,
      source?.datumZeitwert,
      useFormModelAsDefault,
    ),
    datumErstzulassung: mapToDateRequestModelType(
      formModel.datumErstzulassung,
      source?.datumErstzulassung,
      useFormModelAsDefault,
    ),
    zeitwert: mapToNumericRequestModelType(
      formModel.zeitwert,
      source?.zeitwert,
      useFormModelAsDefault,
    ),
    wiederbeschaffungGesichert:
      mapToRequestModelType(
        formModel.wiederbeschaffungGesichert,
        source?.wiederbeschaffungGesichert,
        useFormModelAsDefault,
      ) ?? false,
    ersatzinvestitionErforderlich:
      mapToRequestModelType(
        formModel.ersatzinvestitionErforderlich,
        source?.ersatzinvestitionErforderlich,
        useFormModelAsDefault,
      ) ?? false,
    haftplichtViaExternenVertrag:
      mapToRequestModelType(
        formModel.haftplichtViaExternenVertrag,
        source?.haftplichtViaExternenVertrag,
        useFormModelAsDefault,
      ) ?? false,
    createFlag: formModel.isNew,
    deleteFlag: formModel.isDeleted,
    saveError: formModel.saveError,
  }
}
