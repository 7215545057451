import React, { ReactElement, useEffect } from 'react'
import { Stack, TextField } from '@mui/material'
import clsx from 'clsx'

import { TextInputProps } from 'components/controls/inputs/TextInput'
import styles from 'components/controls/inputs/TextInput.module.scss'
import selectiveStyles from 'components/controls/selective-inputs/SelectiveCommon.module.scss'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { useSelectiveKundeFieldComparison, useSelectiveSwitch } from 'hooks/useSelectiveComparison'
import { isNullishOrEmpty } from 'utils/converterUtils'

export type SelectiveTextInputProps<FormType> = TextInputProps<FormType> & {
  selectiveFieldProps: SelectiveInputProps
}

export const SelectiveTextInput = <FormType,>({
  name,
  label,
  multiline,
  rows = 2,
  selectiveFieldProps,
  ...props
}: SelectiveTextInputProps<FormType>): ReactElement => {
  const { isDirty, currentValue, originalValue, fieldPath } = useSelectiveKundeFieldComparison(
    name,
    selectiveFieldProps.formPath,
  )
  const { switchActive, switchComponent } = useSelectiveSwitch(isDirty)
  const className = switchActive ? selectiveStyles.selected : selectiveStyles.unselected
  const helperText = isNullishOrEmpty(originalValue)
    ? '<leer>'
    : selectiveFieldProps
    ? selectiveFieldProps.formatValue?.(originalValue) || originalValue
    : originalValue

  useEffect(() => {
    selectiveFieldProps.onSelected({
      selected: switchActive,
      fieldPath,
    })
  }, [switchActive])

  return (
    <Stack direction='row' className={selectiveStyles.selectiveWrapper}>
      <TextField
        {...props}
        helperText={isDirty ? `vorher: ${helperText}` : <>&nbsp;</>}
        variant='standard'
        multiline={multiline}
        rows={rows}
        inputProps={{
          className: clsx(multiline && styles.multiline, className),
        }}
        value={
          selectiveFieldProps
            ? selectiveFieldProps.formatValue?.(currentValue) || currentValue
            : currentValue
        }
        label={label}
        fullWidth
        className={className}
        disabled={true}
      />
      {switchComponent}
    </Stack>
  )
}
