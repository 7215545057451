import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'

import { MobileMenu } from 'components/guidance/GuidanceMenu'
import styles from 'components/guidance/GuidancePage.module.scss'

type GuidancePageProps = {
  children: ReactElement
  menuIndex?: number
  menuSubIndex?: number
}

export const GuidancePage = ({
  children,
  menuIndex,
  menuSubIndex,
}: GuidancePageProps): ReactElement => {
  const renderMenu = menuIndex != undefined && menuSubIndex != undefined

  return (
    <Stack className={styles.guidancePage}>
      <Stack direction={'row'}>
        {renderMenu && (
          <>
            <div className={styles.desktop}>
              <MobileMenu menuIndex={menuIndex} menuSubIndex={menuSubIndex} />
              <div className={styles.separator}></div>
            </div>
            <div className={styles.mobile}>
              <MobileMenu menuIndex={menuIndex} menuSubIndex={menuSubIndex} />
            </div>
          </>
        )}
        <Box className={styles.guidanceContentWrapper}>
          <Box className={styles.guidanceContent}>{children}</Box>
        </Box>
      </Stack>
    </Stack>
  )
}
