import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

import background from 'assets/images/guidance/career/coaching-angebot-2/coaching_angebot_background.png'
import styles from 'components/guidance/career/GuidanceCareer8Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer8Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={7}
      previousPagePath={Paths.guidanceCareer7}
      nextPagePath={Paths.guidanceCareer9}
      guidanceColor='career'
      backgroundImage={background}
    >
      <GuidanceSubPageTemplate title='COACHING-ANGEBOT' subTitle='Das Karriere-Institut'>
        <Stack direction='row' className={styles.page}>
          <Stack className={styles.textContainer}>
            <h2 className={styles.text}>
              <span className={styles.emphasis}>4 x jähr&shy;lich</span> ein
              <br />
              per&shy;sönliches Coaching für Premium-Mit&shy;glieder
            </h2>
          </Stack>
        </Stack>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
