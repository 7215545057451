import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'

import backgroundImage from 'assets/images/guidance/personnel/berufung-wird-beruf/berufung_wird_beruf_background.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { GuidanceTextBullet } from 'components/guidance/GuidanceTextBullet'
import styles from 'components/guidance/personnel/GuidancePersonnel1Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidancePersonnel1Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={1}
      menuSubIndex={0}
      previousPagePath={Paths.guidanceMitNorm17}
      nextPagePath={Paths.guidancePersonnel2}
      guidanceColor='personnel'
      backgroundImage={backgroundImage}
      numberOfInterPages={2}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate hideTitleContainer={true}>
          <Stack direction='row' className={styles.boxContainer}>
            <Box className={styles.box1}>
              {interPageIndex === 1 && (
                <h2 className={styles.box1Text}>
                  ZEITEFFIZIENTER BEWERBUNGS-
                  <br />
                  PROZESS MIT
                  <br />
                  PERSÖNLICHEM
                  <br />
                  ANSPRECHPARTNER
                </h2>
              )}
            </Box>
            <Box className={styles.box2}></Box>
            <Box className={styles.box3}>
              <GuidanceTextBullet>
                <>
                  Berufung
                  <br /> wird Beruf
                </>
              </GuidanceTextBullet>
            </Box>
          </Stack>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
