import { Metadata } from 'interfaces/mynorm-api-model-interfaces'

type Metaable = {
  meta: Metadata
}
export const hasId = (object: Metaable | undefined, id: string | undefined): boolean => {
  if (!object || !id) {
    return false
  }
  return object.meta.myviId === id || object.meta.finfireId === id
}
