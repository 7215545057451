import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

import { VersichertesRisikoBlockProps } from 'components/personal/absicherung2/blocks/RisikenFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { VersicherteImmobilieSelect } from 'components/personal/absicherung2/selects/VersicherteImmobilieSelect'
import { getOptionLabel, objektArtOptions } from 'form/kundeOptions'
import { useImmobilien } from 'state/useImmobilien'
import { isVersicherungsTyp, useProduktMappings } from 'state/useProduktConfig'

type VersicherteImmobilieBlockProps = VersichertesRisikoBlockProps & { produktId: string }

export const VersicherteImmobilieBlock = ({
  name,
  risiko,
  produktId,
  disabled,
}: VersicherteImmobilieBlockProps): ReactElement | null => {
  const { immobilien } = useImmobilien()
  const { risikoTyp, risikoImmobilie } = risiko
  const isHausratsversicherung = isVersicherungsTyp(
    produktId,
    'HAUSRATVERSICHERUNG',
    useProduktMappings(),
  )
  if (risikoTyp !== 'IMMOBILIE') {
    return null
  }

  const hasImmobilien = immobilien.length > 0
  const immobilieFinfireId = risikoImmobilie?.finfireId
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <VersicherteImmobilieSelect
          name={`${name}.risikoImmobilie.finfireId`}
          disabled={disabled}
        />
        {isHausratsversicherung && hasImmobilien && !immobilieFinfireId && (
          <Typography color={'error'}>Es wurde noch keine Immobilie verknüpft!</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <VersicherteImmobilieReadView immobilieId={immobilieFinfireId} />
      </Grid>
    </Grid>
  )
}

type VersicherteImmobilieReadViewProps = {
  immobilieId: string | undefined
}

const VersicherteImmobilieReadView = ({
  immobilieId,
}: VersicherteImmobilieReadViewProps): ReactElement | null => {
  const { findImmobilie } = useImmobilien()
  const immobilie = findImmobilie(immobilieId)
  if (!immobilie) {
    return null
  }
  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography variant='overline'>Adresse:</Typography>
        <LabelValueEntry label={'Straße'} value={immobilie.adresse?.strasse} />
        <LabelValueEntry label={'Hausnummer'} value={immobilie.adresse?.hausnummer} />
        <LabelValueEntry label={'Plz'} value={immobilie.adresse?.postleitzahl} />
        <LabelValueEntry label={'Ort'} value={immobilie.adresse?.ort} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline'>Gebäudedaten:</Typography>
        <LabelValueEntry label={'Wohnfläche'} value={`${immobilie.wohnflaeche} m²`} />
        <LabelValueEntry
          label={'Gebäudetyp'}
          value={getOptionLabel(immobilie.objektArt, objektArtOptions)}
        />
        <LabelValueEntry label={'Baujahr'} value={immobilie.baujahr} />
      </Grid>
    </Grid>
  )
}
