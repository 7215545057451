import React, { ReactElement, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined'
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined'
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined'
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { Card, Drawer, IconButton, Stack, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from 'components/guidance/GuidanceMenu.module.scss'
import { Paths } from 'configurations/paths'
import { useGuidanceState } from 'hooks/useGuidanceState'

export type MenuItemConfig = {
  label: string
  icon: ReactElement
  index: number
  path: string
  subItems: MenuSubItemConfig[]
}

type MenuSubItemConfig = {
  label: string
  index: number
  path: string
}

const menuItems: MenuItemConfig[] = [
  {
    label: 'MITNORM',
    icon: <LooksOneOutlinedIcon />,
    index: 0,
    path: Paths.guidanceMitNorm0,
    subItems: [
      { label: 'Eigene Ziele erreichen', index: -1, path: Paths.guidanceMitNorm0 },
      { label: 'Ziele und Wünsche 1', index: 0, path: Paths.guidanceMitNorm1 },
      { label: 'Ziele und Wünsche 2', index: 1, path: Paths.guidanceMitNorm2 },
      { label: 'Lebens- und Finanzplanung kombinieren', index: 2, path: Paths.guidanceMitNorm3 },
      { label: 'Vorgehensweise nach DIN', index: 3, path: Paths.guidanceMitNorm4 },
      { label: 'Herausforderungen in der Finanzwelt', index: 4, path: Paths.guidanceMitNorm5 },
      { label: 'D(e)in richtiges Ergebnis', index: 5, path: Paths.guidanceMitNorm6 },
      { label: 'Fahrplan nach Norm', index: 8, path: Paths.guidanceMitNorm9 },
      { label: 'Marktanbieter', index: 9, path: Paths.guidanceMitNorm10 },
      { label: 'Der Maklereid', index: 10, path: Paths.guidanceMitNorm11 },
      { label: 'Unser Vorgehen', index: 11, path: Paths.guidanceMitNorm12 },
      { label: 'Unsere Partner', index: 12, path: Paths.guidanceMitNorm13 },
      { label: 'Unser gemeinsamer Weg', index: 13, path: Paths.guidanceMitNorm14 },
      { label: 'Finanzfahrplan nach Norm', index: 14, path: Paths.guidanceMitNorm15 },
      { label: 'One More Thing', index: 15, path: Paths.guidanceMitNorm16 },
      { label: 'Wo sind wir?', index: 16, path: Paths.guidanceMitNorm17 },
    ],
  },
  {
    label: 'WIR: PERSONALBERATER',
    icon: <LooksTwoOutlinedIcon />,
    index: 1,
    path: Paths.guidancePersonnel1,
    subItems: [
      { label: 'Berufung wird Beruf', index: 0, path: Paths.guidancePersonnel1 },
      { label: 'Herausforderungen am Arbeitsmarkt', index: 1, path: Paths.guidancePersonnel2 },
      { label: 'Einfach und Zeiteffizient', index: 2, path: Paths.guidancePersonnel3 },
      { label: 'Zugang sichern', index: 3, path: Paths.guidancePersonnel4 },
      { label: 'Coaching Angebot', index: 4, path: Paths.guidancePersonnel5 },
    ],
  },
  {
    label: 'KARRIERE-INSTITUT',
    icon: <Looks3OutlinedIcon />,

    index: 2,
    path: Paths.guidanceCareer1,
    subItems: [
      { label: 'Übersicht', index: 0, path: Paths.guidanceCareer1 },
      { label: 'Institut und Partner', index: 1, path: Paths.guidanceCareer2 },
      { label: 'Einkommen erhöhen', index: 2, path: Paths.guidanceCareer3 },
      { label: 'Persönlichkeitsanalyse', index: 3, path: Paths.guidanceCareer4 },
      { label: 'Persönlichkeitsentwicklung', index: 4, path: Paths.guidanceCareer5 },
      { label: 'Coaching-Angebot 1', index: 5, path: Paths.guidanceCareer6 },
      { label: 'Professioneller CV-Check', index: 6, path: Paths.guidanceCareer7 },
      { label: 'Coaching-Angebot 2', index: 7, path: Paths.guidanceCareer8 },
      { label: 'Unser Beitrag', index: 8, path: Paths.guidanceCareer9 },
      { label: 'E-Learning Plattform', index: 9, path: Paths.guidanceCareer10 },
      { label: 'Investition in dich selbst', index: 10, path: Paths.guidanceCareer11 },
      { label: 'Karriereanalyse', index: 11, path: Paths.guidanceCareer12 },
      { label: 'Die Mitgliedschaften', index: 12, path: Paths.guidanceCareer13 },
      { label: 'Jetzt Registrieren', index: 13, path: Paths.guidanceCareer14 },
      { label: 'Jetzt Vorteile sichern', index: 14, path: Paths.guidanceCareer15 },
    ],
  },
  {
    label: 'FIRMENBERATUNG',
    icon: <Looks4OutlinedIcon />,
    index: 3,
    path: Paths.guidanceCompany1,
    subItems: [{ label: 'Übersicht', index: 0, path: Paths.guidanceCompany1 }],
  },
]

type GuidanceMenuTreeProps = {
  menuIndex?: number
  menuSubIndex?: number
  showToggle?: boolean
}

const GuidanceMenuTree = ({
  menuIndex,
  menuSubIndex,
  showToggle = true,
}: GuidanceMenuTreeProps): ReactElement => {
  const { open, toggleOpen } = useGuidanceState()

  return (
    <Stack className={styles.menu}>
      {!open && showToggle && (
        <div>
          <IconButton onClick={toggleOpen}>
            <MenuIcon />
          </IconButton>
        </div>
      )}
      {open && (
        <>
          <div className={styles.menuHeaderContainer}>
            <h4>Financial Guidance</h4>
            {showToggle && (
              <div>
                <IconButton onClick={toggleOpen}>
                  <ArrowBack />
                </IconButton>
              </div>
            )}
          </div>
          <Stack className={styles.menuItemList}>
            {menuItems.map((menuItemConfig) => (
              <MenuItem
                key={menuItemConfig.label}
                menuItemConfig={menuItemConfig}
                menuIndex={menuIndex}
                menuSubIndex={menuSubIndex}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  )
}

type MenuItemProps = {
  menuItemConfig: MenuItemConfig
  menuIndex?: number
  menuSubIndex?: number
}

const MenuItem = ({ menuItemConfig, menuIndex, menuSubIndex }: MenuItemProps): ReactElement => {
  return (
    <Stack>
      <Link to={menuItemConfig.path}>
        <Stack
          direction='row'
          className={clsx(
            styles.menuItem,
            menuIndex === menuItemConfig.index ? styles.selected : '',
          )}
        >
          {menuItemConfig.icon}
          &nbsp;
          <Typography>{menuItemConfig.label}</Typography>
        </Stack>
      </Link>
      <ul>
        {menuIndex === menuItemConfig.index &&
          menuItemConfig.subItems.map((subItemConfig) => (
            <Link key={subItemConfig.label} to={subItemConfig.path}>
              <li
                className={clsx(
                  styles.menuItem,
                  menuIndex === menuItemConfig.index && menuSubIndex === subItemConfig.index
                    ? styles.selected
                    : '',
                )}
              >
                {subItemConfig.label}
              </li>
            </Link>
          ))}
      </ul>
    </Stack>
  )
}

export const MobileMenu = ({ menuIndex, menuSubIndex }: GuidanceMenuTreeProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const toggleDrawer =
    (open: boolean, path?: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setOpen(open)
      if (path) {
        navigate(path)
      }
    }

  return (
    <React.Fragment>
      <Card className={styles.mobileMenu} elevation={2} onClick={toggleDrawer(true)}>
        <Stack>
          <IconButton
            className={clsx(styles.iconButton, styles.mobileMenuButton)}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          {menuItems.map((item) => (
            <IconButton
              key={item.path}
              disabled={true}
              onClick={toggleDrawer(true)}
              className={clsx(styles.iconButton, menuIndex === item.index ? styles.selected : '')}
            >
              {item.icon}
            </IconButton>
          ))}
        </Stack>
      </Card>
      <Drawer anchor='left' open={open} onClose={toggleDrawer(false)}>
        <GuidanceMenuTree menuIndex={menuIndex} menuSubIndex={menuSubIndex} showToggle={false} />
      </Drawer>
    </React.Fragment>
  )
}
