import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Navigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useEffectOnce } from 'usehooks-ts'

import { addAxiosInterceptor, removeAxiosInterceptor } from 'api/apiUtils'
import { LoginDialog } from 'components/login-form/LoginDialog'
import { useIsAuthenticated } from 'hooks/useIsAuthenticated'

export const AuthenticationHandler = ({ children }: PropsWithChildren): ReactElement => {
  const resolver = useRef<(() => void)[]>([])
  const [open, setOpen] = useState(false)
  const { isAuthenticated, isExpired } = useIsAuthenticated()
  const queryClient = useQueryClient()

  useEffectOnce(() => {
    const interceptorId = addAxiosInterceptor((): Promise<void> => {
      setOpen(true)
      return new Promise((resolve) => {
        resolver.current.push(resolve)
      })
    })
    return () => removeAxiosInterceptor(interceptorId)
  })

  useEffect(() => {
    if (isAuthenticated && isExpired) {
      setOpen(true)
    }
  }, [])

  const onLoginSuccess = useCallback((): void => {
    setOpen(false)
    resolver.current.forEach((res) => res())
    resolver.current = []
    queryClient.invalidateQueries(['fetchProduktMapping'])
    queryClient.invalidateQueries(['fetchUserConfig'])
  }, [])

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />
  }

  return (
    <>
      {children}
      <LoginDialog open={open} onSuccessfulLogin={onLoginSuccess} />
    </>
  )
}
