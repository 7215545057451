import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { DateInput } from 'components/controls/inputs/DateInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { NewKundeFormModel } from 'form/KundeFormModel'
import { anredeOptions, risikenOptions } from 'form/kundeOptions'

type HaushaltPersonFormFieldsProps = {
  selectiveFieldProps?: SelectiveInputProps
}

export const HaushaltPersonFormFields = ({
  selectiveFieldProps,
}: HaushaltPersonFormFieldsProps): ReactElement => {
  return (
    <>
      <Grid item xs={12}>
        <SelectInput
          options={anredeOptions}
          name={`anrede`}
          label={'Anrede'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput name={`titel`} label={'Titel'} selectiveFieldProps={selectiveFieldProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput name={`vorname`} label={'Vorname'} selectiveFieldProps={selectiveFieldProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name={`nachname`}
          label={'Familienname'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          name={`geburtsdatum`}
          label={'Geburtsdatum'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={8}>
        <SelectInput
          options={risikenOptions}
          name={`risiken`}
          label={`Hobbies / Risiken`}
          multiple
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput<NewKundeFormModel>
          name={'steuerIdentifikationsnummer'}
          label={'Steuer-Identifikationsnummer'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </>
  )
}
