import { ZielFormModel } from 'form/KundeFormModel'
import { Ziel } from 'interfaces/mynorm-api-model-interfaces'
import {
  isNullishOrEmpty,
  mapToDateRequestModelType,
  mapToNumericRequestModelType,
  mapToRequestModelType,
} from 'utils/converterUtils'
import { mapList } from 'utils/listUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const convertZiele = (
  zieleFormModel: ZielFormModel[],
  ziele?: Ziel[],
): RecursivePartial<Ziel & { isNew?: boolean }>[] | undefined => {
  if (isNullishOrEmpty(zieleFormModel) && isNullishOrEmpty(ziele)) {
    return ziele
  }
  return mapList(zieleFormModel, ziele, convertZiel)
}

const convertZiel = (zielFormModel: ZielFormModel, source?: Ziel): Ziel => {
  return {
    meta: {
      myviId: zielFormModel.meta.myviId,
      finfireId: mapToRequestModelType(zielFormModel.meta.finfireId, source?.meta.finfireId),
      salesforceId: mapToRequestModelType(
        zielFormModel.meta.salesforceId,
        source?.meta?.salesforceId,
      ),
      salesforceLastModified: mapToRequestModelType(
        zielFormModel.meta.salesforceLastModified,
        source?.meta?.salesforceLastModified,
      ),
      finfireLastModified: mapToRequestModelType(
        zielFormModel.meta.finfireLastModified,
        source?.meta?.finfireLastModified,
      ),
    },
    bezeichnung: mapToRequestModelType(zielFormModel.bezeichnung, source?.bezeichnung),
    zielArt: mapToRequestModelType(zielFormModel.zielArt, source?.zielArt),
    zielzeitpunkt: mapToDateRequestModelType(zielFormModel.zielzeitpunkt, source?.zielzeitpunkt),
    zielkapital: mapToNumericRequestModelType(zielFormModel.zielkapital, source?.zielkapital),
    risikoklasse: mapToRequestModelType(zielFormModel.risikoklasse, source?.risikoklasse),
    bemerkungen: mapToRequestModelType(zielFormModel.bemerkungen, source?.bemerkungen),
    bemerkungenIntern: mapToRequestModelType(
      zielFormModel.bemerkungenIntern,
      source?.bemerkungenIntern,
    ),
    zugeordnetePersonen: mapToRequestModelType(
      zielFormModel.zugeordnetePersonen,
      source?.zugeordnetePersonen,
    ),
    createFlag: zielFormModel.isNew ?? false,
    deleteFlag: zielFormModel.isDeleted ?? false,
  }
}
