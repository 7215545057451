import React, { ReactElement } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import clsx from 'clsx'

import chart0 from 'assets/images/guidance/career/persoenlichkeitsanalyse/persoenlichkeitsanalyse_0.png'
import chart1 from 'assets/images/guidance/career/persoenlichkeitsanalyse/persoenlichkeitsanalyse_1.png'
import chart2 from 'assets/images/guidance/career/persoenlichkeitsanalyse/persoenlichkeitsanalyse_2.png'
import chart3 from 'assets/images/guidance/career/persoenlichkeitsanalyse/persoenlichkeitsanalyse_3.png'
import chart4 from 'assets/images/guidance/career/persoenlichkeitsanalyse/persoenlichkeitsanalyse_4.png'
import styles from 'components/guidance/career/GuidanceCareer4Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer4Page = (): ReactElement => {
  const charts = [chart0, chart1, chart2, chart3, chart4]

  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={3}
      previousPagePath={Paths.guidanceCareer3}
      nextPagePath={Paths.guidanceCareer5}
      guidanceColor='career'
      numberOfInterPages={5}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate title='PERSÖNLICHKEITSANALYSE' subTitle='Das Karriere-Institut'>
          <Stack className={styles.page}>
            <Stack className={styles.textContainer}>
              <Box className={styles.blueBox}>
                {interPageIndex >= 1 && (
                  <>
                    <Typography>detailorientiert</Typography>
                    <Typography>analytisch</Typography>
                    <Typography>genau & kritisch</Typography>
                    <Typography>distanziert</Typography>
                  </>
                )}
              </Box>
              <Box className={styles.greenBox}>
                {interPageIndex >= 3 && (
                  <>
                    <Typography>beständig</Typography>
                    <Typography>gewohnheitsliebend</Typography>
                    <Typography>zuverlässig & einfühlsam</Typography>
                    <Typography>sicherheitsorientiert</Typography>
                  </>
                )}
              </Box>
              <Box className={clsx(styles.redBox, styles.rightBox)}>
                {interPageIndex >= 2 && (
                  <>
                    <Typography>zielstrebig</Typography>
                    <Typography>kontrolliert</Typography>
                    <Typography>beherrschend</Typography>
                    <Typography>sachorientiert</Typography>
                  </>
                )}
              </Box>
            </Stack>
            <Box className={styles.imageContainer}>
              <img src={charts[interPageIndex]} alt='Personalitätsquadranten' />
            </Box>
            <Stack className={styles.textContainer}>
              <Box className={clsx(styles.redBox, styles.bottom, styles.rightBox)}>
                {interPageIndex >= 2 && (
                  <>
                    <Typography>zielstrebig</Typography>
                    <Typography>kontrolliert</Typography>
                    <Typography>beherrschend</Typography>
                    <Typography>sachorientiert</Typography>
                  </>
                )}
              </Box>
              <Box className={clsx(styles.greenBox, styles.bottom)}>
                {interPageIndex >= 3 && (
                  <>
                    <Typography>beständig</Typography>
                    <Typography>gewohnheitsliebend</Typography>
                    <Typography>zuverlässig & einfühlsam</Typography>
                    <Typography>sicherheitsorientiert</Typography>
                  </>
                )}
              </Box>
              <Box className={clsx(styles.yellowBox, styles.rightBox)}>
                {interPageIndex >= 4 && (
                  <>
                    <Typography>kontaktfreudig</Typography>
                    <Typography>aufgeschlossen</Typography>
                    <Typography>begeisterungsfähig</Typography>
                    <Typography>optimistisch & kreativ</Typography>
                  </>
                )}
              </Box>
            </Stack>
          </Stack>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}
