import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'

type HaushaltPersonKontaktdatenFormFieldsProps = {
  selectiveFieldProps?: SelectiveInputProps
}

export const HaushaltPersonKontaktdatenFormFields = ({
  selectiveFieldProps,
}: HaushaltPersonKontaktdatenFormFieldsProps): ReactElement => {
  return (
    <>
      <Grid item xs={6}>
        <TextInput
          name={`kontaktdaten.email`}
          label={'E-Mail'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name={`kontaktdaten.telefon`}
          label={'Telefon'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name={`kontaktdaten.internet`}
          label={'Website'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name={`kontaktdaten.fax`}
          label={'Fax'}
          selectiveFieldProps={selectiveFieldProps}
        />
      </Grid>
    </>
  )
}
