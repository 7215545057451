import produce from 'immer'

import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const removeVermoegenFromHaushalt = (haushalt: HaushaltAggregate): HaushaltAggregate => {
  return produce(haushalt, (draft: HaushaltAggregate) => {
    clearVermoegen(draft, 0)
    if (draft.personen.length > 1) {
      clearVermoegen(draft, 1)
    }
  })
}

function clearVermoegen(haushalt: HaushaltAggregate, personIndex: number): void {
  haushalt.personen[personIndex].vermoegen.geldanlagen = []
  haushalt.personen[personIndex].vermoegen.darlehen = []
  haushalt.personen[personIndex].vermoegen.immobilien = []
  haushalt.personen[personIndex].vermoegen.beteiligungen = []
  haushalt.personen[personIndex].vermoegen.sachwerte = []
}
