import React, { ReactElement } from 'react'
import clsx from 'clsx'

import styles from 'components/guidance/UnderlineSeparator.module.scss'

type UnderlineSeparatorProps = {
  invisible?: boolean
}

export const UnderlineSeparator = ({ invisible }: UnderlineSeparatorProps): ReactElement => {
  return <div className={clsx(styles.underline, invisible && styles.invisible)}></div>
}
