import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel } from 'components/controls/cells/FormCell'

export const PersonalAnlegerProfilDienstleistungenLabels = (): ReactElement => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <FormCellLabel $border $highlighted>
            Bisher in Anspruch genommene Dienstleistungen
          </FormCellLabel>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <FormCellLabel $border>Beratungsfreie Orderausführung</FormCellLabel>
        </TableRow>
        <TableRow>
          <FormCellLabel $border>Anlageberatung</FormCellLabel>
        </TableRow>
        <TableRow>
          <FormCellLabel $border>Vermögensverwaltung</FormCellLabel>
        </TableRow>
      </TableBody>
    </Table>
  )
}
