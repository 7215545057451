import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type TabConfig = {
  title: string
  formPaths: string[]
}

type UseCustomerDatenTabsResult = {
  tabs: TabConfig[]
  value: number
  prevValue: number
  onChange: (event: SyntheticEvent, value: number) => void
  onPrevious: () => void
  onNext: () => void
}

export const useCustomerDatenTabs = (): UseCustomerDatenTabsResult => {
  const navigate = useNavigate()
  const { pathname, state: prevPathName } = useLocation()

  useEffect(() => {
    if (pathname.endsWith('/daten')) {
      navigate('./haushalt', { state: pathname })
    }
  }, [pathname, navigate])

  const value = useMemo(() => getTabIndexForPathname(pathname), [pathname])
  const prevValue = useMemo(() => getTabIndexForPathname(prevPathName, -1), [pathname])

  const onChange: UseCustomerDatenTabsResult['onChange'] = useCallback(
    (_, value) => {
      switch (value) {
        case 4:
          return navigate('./anlegerprofil', { state: pathname })
        case 3:
          return navigate('./absicherung', { state: pathname })
        case 2:
          return navigate('./finanzen', { state: pathname })
        case 1:
          return navigate('./ziele', { state: pathname })
        case 0:
        default:
          return navigate('./haushalt', { state: pathname })
      }
    },
    [pathname, navigate],
  )

  const onPrevious = useCallback(() => {
    onChange({} as never, value - 1)
  }, [onChange, value])

  const onNext = useCallback(() => {
    onChange({} as never, value + 1)
  }, [onChange, value])

  return {
    tabs: formTabs,
    value,
    prevValue,
    onChange,
    onPrevious,
    onNext,
  }
}

const getTabIndexForPathname = (pathname: string | null, defaultValue = 0): number => {
  if (!pathname || typeof pathname !== 'string') return defaultValue
  if (pathname.endsWith('/haushalt')) {
    return 0
  } else if (pathname.endsWith('/ziele')) {
    return 1
  } else if (pathname.endsWith('/finanzen')) {
    return 2
  } else if (pathname.endsWith('/absicherung')) {
    return 3
  } else if (pathname.endsWith('/anlegerprofil')) {
    return 4
  } else {
    return defaultValue
  }
}

const formTabs: TabConfig[] = [
  {
    title: 'Haushalt',
    formPaths: [
      'vorname',
      'nachname',
      'geburtsdatum',
      'kontaktdaten',
      'kinder',
      'beziehung',
      'anschrift',
    ],
  },
  { title: 'Ziele', formPaths: ['ziele'] },
  {
    title: 'Finanzen',
    formPaths: ['beruf', 'finanzen', 'ausgaben', 'vermoegen', 'gesetzlicheVersorgungen'],
  },
  {
    title: 'Absicherung',
    formPaths: ['vertraege', 'versicherungen'],
  },
  {
    title: 'Anlegerprofil',
    formPaths: ['anlegerprofil'],
  },
]
