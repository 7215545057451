import { WritableDraft } from 'immer/dist/types/types-external'

import { MenschFormModel } from 'form/KundeFormModel'
import { Mensch } from 'interfaces/mynorm-api-model-interfaces'
import { mapToDateRequestModelType, mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export type Deletable = {
  deleteFlag?: boolean
}
export const convertMensch = <T extends MenschFormModel>(
  formModel: T,
  original?: WritableDraft<RecursivePartial<Mensch>>,
  trennung?: boolean,
): RecursivePartial<Mensch> => {
  return {
    ...original,
    meta: {
      finfireId: formModel.meta.finfireId,
      myviId: formModel.meta.myviId,
      salesforceId: formModel.meta.salesforceId,
      salesforceLastModified: formModel.meta.salesforceLastModified,
      finfireLastModified: formModel.meta.finfireLastModified,
    },
    anrede: mapToRequestModelType(formModel.anrede, original?.anrede),
    geburtsdatum: mapToDateRequestModelType(formModel.geburtsdatum, original?.geburtsdatum),
    nachname: mapToRequestModelType(formModel.nachname, original?.nachname),
    hobbies: formModel.risiken,
    trennungFlag: !!trennung,
    vorname: mapToRequestModelType(formModel.vorname, original?.vorname),
  }
}
