import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import clsx from 'clsx'

import { useFetchUserConfig } from 'api/useFetchUserConfig'
import { GuidancePage } from 'components/guidance/GuidancePage'
import styles from 'components/guidance/GuidanceSubPage.module.scss'

export type GuidanceColor = 'mitnorm' | 'career' | 'company' | 'personnel'

type GuidanceSubPageProps = {
  menuIndex?: number
  menuSubIndex?: number
  nextPagePath?: string
  nextPageButtonText?: string
  previousPagePath?: string
  previousPageButtonText?: string
  guidanceColor?: GuidanceColor
  numberOfInterPages?: number
  backgroundImage?: ReactElement
  backgroundImageFe?: ReactElement
  backgroundImageClass?: string
  children: ReactElement | ((interPageIndex: number) => ReactElement)
}

export const GuidanceSubPage = ({
  menuIndex,
  menuSubIndex,
  nextPagePath,
  nextPageButtonText,
  previousPagePath,
  previousPageButtonText,
  guidanceColor,
  numberOfInterPages = 0,
  backgroundImage,
  backgroundImageFe,
  backgroundImageClass,
  children,
}: GuidanceSubPageProps): ReactElement => {
  const navigate = useNavigate()
  const { data: config } = useFetchUserConfig()
  const { state } = useLocation()

  const [interPageIndex, setInterPageIndex] = useState(
    state?.startFromLastPage ? numberOfInterPages - 1 : 0,
  )

  const handleNext = useCallback((): void => {
    if (interPageIndex < numberOfInterPages - 1) {
      setInterPageIndex((prevState) => ++prevState)
      return
    }
    navigateToPath(nextPagePath)
  }, [interPageIndex, numberOfInterPages, setInterPageIndex])

  const handlePrevious = useCallback((): void => {
    if (interPageIndex > 0) {
      setInterPageIndex((prevState) => --prevState)
      return
    }
    navigateToPath(previousPagePath, true)
  }, [interPageIndex, setInterPageIndex])

  const navigateToPath = (path?: string, startFromLastInterPage = false): void => {
    if (!path) {
      return
    }
    if (path.startsWith('/')) {
      navigate(path, { state: { startFromLastPage: startFromLastInterPage } })
    } else {
      window.open(path, '_blank', 'noreferrer')
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        handlePrevious()
      } else if (event.key === 'ArrowRight') {
        handleNext()
      } else if (event.key === 'ArrowDown') {
        navigateToPath(nextPagePath)
      } else if (event.key === 'ArrowUp') {
        navigateToPath(previousPagePath)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleNext, handlePrevious, nextPagePath, previousPagePath])

  const background =
    (config?.guidanceMitNormLpFarbe === 'GRUEN' ? backgroundImageFe : backgroundImage) ??
    backgroundImage
  return (
    <GuidancePage menuIndex={menuIndex} menuSubIndex={menuSubIndex}>
      <Stack direction='row'>
        <Stack
          className={clsx(styles.content, backgroundImageClass)}
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          {typeof children === 'function' ? children(interPageIndex) : children}
          <Stack direction='row' className={styles.navigation}>
            {(previousPagePath || interPageIndex > 0) && (
              <Button
                color='white'
                variant='contained'
                className={styles.navigationButton}
                onClick={handlePrevious}
              >
                {previousPageButtonText ?? 'Zurück'}
              </Button>
            )}
            {(nextPagePath || interPageIndex < numberOfInterPages - 1) && (
              <Button
                color={guidanceColor}
                variant='contained'
                className={styles.navigationButton}
                onClick={handleNext}
              >
                {nextPageButtonText ?? 'Weiter'}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </GuidancePage>
  )
}
