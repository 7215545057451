import React, { ReactElement, useMemo } from 'react'
import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/material'

import { CockpitCard } from 'components/cockpit/CockpitCard'
import styles from 'components/cockpit/CockpitSummaryCard.module.scss'
import { CockpitSummaryDialog } from 'components/cockpit/CockpitSummaryDialog'
import { CurrencyCountUp } from 'components/count-up/CurrencyCountUp'
import { PersonalZieleForm, toZielName } from 'components/personal/ziele/PersonalZieleForm'
import { filterNotDeleted } from 'components/util/listUtil'
import { ZielFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'

type CockpitGoalsCardProps = {
  dialogOpen: boolean
  setDialogVisibility: (isVisible: boolean) => void
}

export const CockpitGoalsCard = ({
  dialogOpen,
  setDialogVisibility,
}: CockpitGoalsCardProps): ReactElement => {
  const { editableKunde } = useEditableKunde()
  const ziele = editableKunde.personen.flatMap((person) => filterNotDeleted(person.ziele) || [])
  return (
    <>
      <CockpitCard title={'Ziele'} icon={<StarIcon />} onClick={() => setDialogVisibility(true)}>
        <Box className={styles.emptyMessage}>
          {!ziele?.length && 'Es bestehen keine finanziellen Ziele.'}
        </Box>
        <Box>
          {ziele.map((ziel) => {
            return <ZielEntry key={ziel.meta.myviId} ziel={ziel} />
          })}
        </Box>
      </CockpitCard>
      <CockpitSummaryDialog open={dialogOpen} onClose={() => setDialogVisibility(false)}>
        <PersonalZieleForm />
      </CockpitSummaryDialog>
    </>
  )
}

type ZielEntryProps = {
  ziel: ZielFormModel
}

const ZielEntry = ({ ziel }: ZielEntryProps): ReactElement => {
  const notwendigesKapitalCountUp = useMemo(() => {
    return <CurrencyCountUp value={Number(ziel.zielkapital) || 0} />
  }, [ziel])
  return (
    <Box key={ziel.meta.myviId} className={styles.entry}>
      <div>
        {toZielName(ziel.bezeichnung)}
        {ziel.zielzeitpunkt ? ` (${ziel.zielzeitpunkt?.year()})` : ''}
      </div>
      <div>{notwendigesKapitalCountUp}</div>
    </Box>
  )
}
