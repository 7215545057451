import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { Option, SelectInput } from 'components/controls/inputs/SelectInput'
import {
  DetailFormBlockTitle,
  DetailReadViewBlockTitle,
} from 'components/personal/absicherung2/blocks/details/DetailReadViewBlockTitle'
import {
  BaseDetailsFormBlockProps,
  BaseDetailsReadViewProps,
} from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { LabelValueEntry } from 'components/personal/absicherung2/LabelValueEntry'
import { formatBoolean, formatCurrency } from 'components/util/formatUtil'
import { getOptionLabel } from 'form/kundeOptions'
import { RechtsschutzDetailsFormModel } from 'form/vertraege/VertragFormModel'
import { rechtsschutzTarifartOptions } from 'form/vertraege/vertragOptions'
import {
  RechtsschutzDetails,
  VersicherungssummeModus,
} from 'interfaces/mynorm-api-model-interfaces'
import { isRelevantForDetailsTypAndFinfireProduktId } from 'state/useProduktConfig'

const modusVersicherungssummeOptions: Option<VersicherungssummeModus>[] = [
  {
    label: 'Individuelle Versicherungssumme',
    value: 'INDIVIDUELL',
  },
  { label: 'Unbegrenzte Versicherungssumme', value: 'UNBEGRENZT' },
]

const FORM_BLOCK_TITLE = 'Details - Rechtsschutz'

type RechtsschutzFormBlockProps = BaseDetailsFormBlockProps & {
  rechtsschutzDetails: RechtsschutzDetailsFormModel | undefined
}

export const RechtsschutzDetailsFormBlock = ({
  name,
  produktId,
  rechtsschutzDetails,
}: RechtsschutzFormBlockProps): ReactElement | null => {
  const baseName = `${name}.rechtsschutzDetails`
  if (!isRelevantForDetailsTypAndFinfireProduktId('RECHTSSCHUTZ', produktId)) {
    return null
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailFormBlockTitle title={FORM_BLOCK_TITLE} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          options={modusVersicherungssummeOptions}
          name={`${baseName}.versicherungssummeModus`}
          label={'Modus Versicherungssumme'}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {rechtsschutzDetails?.versicherungssummeModus === 'INDIVIDUELL' && (
          <NumberInput name={`${baseName}.versicherungssumme`} label={'Versicherungssumme'} />
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          options={rechtsschutzTarifartOptions}
          name={`${baseName}.rechtsschutzTarifart`}
          label={'Tarifart'}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack>
          <CheckboxInput name={`${baseName}.einschlussPrivat`} label={'Einschluss Privat'} />
          <CheckboxInput name={`${baseName}.einschlussBeruf`} label={'Einschluss Beruf'} />
          <CheckboxInput name={`${baseName}.einschlussVerkehr`} label={'Einschluss Verkehr'} />
          <CheckboxInput name={`${baseName}.einschlussMieter`} label={'Einschluss Mieter'} />
          <CheckboxInput name={`${baseName}.einschlussVermieter`} label={'Einschluss Vermieter'} />
        </Stack>
      </Grid>
    </Grid>
  )
}

type RechtsschutzDetailsReadViewProps = BaseDetailsReadViewProps & {
  rechtsschutzDetails: RechtsschutzDetails | undefined
}

export const RechtsschutzDetailsReadView = ({
  produktId,
  rechtsschutzDetails,
}: RechtsschutzDetailsReadViewProps): ReactElement | null => {
  if (!isRelevantForDetailsTypAndFinfireProduktId('RECHTSSCHUTZ', produktId)) {
    return null
  }
  return (
    <Stack>
      <DetailReadViewBlockTitle title={FORM_BLOCK_TITLE} />
      <LabelValueEntry
        label={'Modus Versicherungssumme'}
        value={getOptionLabel(
          rechtsschutzDetails?.versicherungssummeModus,
          modusVersicherungssummeOptions,
        )}
      />
      {rechtsschutzDetails?.versicherungssummeModus === 'INDIVIDUELL' && (
        <LabelValueEntry
          label={'Versicherungssumme'}
          value={formatCurrency(rechtsschutzDetails?.versicherungssumme)}
        />
      )}
      <LabelValueEntry
        label={'Tarifart'}
        value={getOptionLabel(
          rechtsschutzDetails?.rechtsschutzTarifart,
          rechtsschutzTarifartOptions,
        )}
      />
      <LabelValueEntry
        label={'Einschluss Privat'}
        value={formatBoolean(rechtsschutzDetails?.einschlussPrivat)}
      />
      <LabelValueEntry
        label={'Einschluss Beruf'}
        value={formatBoolean(rechtsschutzDetails?.einschlussBeruf)}
      />
      <LabelValueEntry
        label={'Einschluss Verkehr'}
        value={formatBoolean(rechtsschutzDetails?.einschlussVerkehr)}
      />
      <LabelValueEntry
        label={'Einschluss Mieter'}
        value={formatBoolean(rechtsschutzDetails?.einschlussMieter)}
      />
      <LabelValueEntry
        label={'Einschluss Vermieter'}
        value={formatBoolean(rechtsschutzDetails?.einschlussVermieter)}
      />
    </Stack>
  )
}
