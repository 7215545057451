import React, { PropsWithChildren, ReactElement } from 'react'

import styles from 'components/personal/PersonalFormLayout.module.scss'

export type FormLayoutProps = {
  title: string
  titleButton?: ReactElement
}

export const PersonalFormLayout = ({
  title,
  titleButton,
  children,
}: PropsWithChildren<FormLayoutProps>): ReactElement => {
  return (
    <div>
      <div className={styles.titleContainer}>
        <h2 className={styles.titleText}>{title}</h2>
        {titleButton && <div className={styles.titleButton}>{titleButton}</div>}
      </div>
      <div>{children}</div>
    </div>
  )
}
