import React, { ReactElement, useState } from 'react'
import LoginIcon from '@mui/icons-material/Login'
import { LoadingButton } from '@mui/lab'
import { Alert } from '@mui/material'
import axios, { AxiosError } from 'axios'
import { Formik } from 'formik'
import * as yup from 'yup'

import { LoginFormProps } from 'components/login-form/LoginForm'
import styles from 'components/login-form/LoginForm.module.scss'
import { LoginFormTextField } from 'components/login-form/LoginFormTextField'
import { BackendPaths } from 'configurations/backendPaths'

type LoginBaseFormProps = LoginFormProps & {
  on2FARequired: (hash: string) => void
}

export const LoginUserPassForm = ({
  recentlyLoggedOut,
  onSubmit,
  onSuccessfulLogin,
  on2FARequired,
}: LoginBaseFormProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (loginDaten: LoginDaten): Promise<void> => {
    setLoading(true)
    setErrorMessage('')
    onSubmit?.()
    try {
      await axios.post(BackendPaths.login, loginDaten)
      onSuccessfulLogin?.()
    } catch (error) {
      if (error instanceof AxiosError<TwoFactorRequiredResponse>) {
        if (error.response?.status === 401) {
          if (error.response?.data?.messageCode === '2FAKTOR_AUTH_REQUIRED') {
            on2FARequired(error.response.data.twoFactorHashcode)
          } else {
            setErrorMessage('Unzulässige Kombination aus Benutzername und Passwort')
          }
        } else {
          setErrorMessage(error.message)
        }
      } else {
        setErrorMessage('Es ist ein unbekannter Fehler aufgetreten.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Formik<LoginDaten>
        initialValues={{ username: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik): ReactElement => (
          <form onSubmit={formik.handleSubmit}>
            <LoginFormTextField<LoginDaten>
              name={'username'}
              label={'Finfire Office Benutzername'}
            />
            <LoginFormTextField<LoginDaten>
              name={'password'}
              label={'Passwort'}
              type={'password'}
            />
            <div className={styles.buttonBox}>
              <LoadingButton
                loading={loading}
                variant={'contained'}
                loadingPosition={'end'}
                endIcon={<LoginIcon />}
                color={'primary'}
                type={'submit'}
                fullWidth
              >
                Login
              </LoadingButton>
            </div>
          </form>
        )}
      </Formik>
      {recentlyLoggedOut && (
        <Alert severity={'info'} className={styles.errorBox}>
          Sie haben sich erfolgreich ausgeloggt
        </Alert>
      )}
      {errorMessage && (
        <Alert severity={'error'} className={styles.errorBox}>
          {errorMessage}
        </Alert>
      )}
    </div>
  )
}

const validationSchema = yup.object({
  username: yup.string().required('Bitte geben Sie Ihren Finfire Office Benutzernamen ein'),
  password: yup.string().required('Bitte geben Sie Ihr Passwort ein'),
})

interface LoginDaten {
  username: string
  password: string
}

type TwoFactorRequiredResponse = {
  messageCode: '2FAKTOR_AUTH_REQUIRED' | 'INVALID_CREDENTIALS' | 'UNKNOWN_ERROR'
  twoFactorHashcode: string
}
