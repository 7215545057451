import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel } from 'components/controls/cells/FormCell'

export const PersonalAnlegerProfilKreditfinanzierteAnlagenLabels = (): ReactElement => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <FormCellLabel $border $highlighted>
            Kreditfinanzierte Vermögensanlagen
          </FormCellLabel>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <FormCellLabel $border>Art der Finanzierung(en)</FormCellLabel>
        </TableRow>
        <TableRow>
          <FormCellLabel $border>Kreditvolumen (Schätzung, Durchschnitt)</FormCellLabel>
        </TableRow>
      </TableBody>
    </Table>
  )
}
