import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import { FormikProps } from 'formik'

import { DetailsFormBlock } from 'components/personal/absicherung2/blocks/DetailsFormBlock'
import { RisikenFormBlock } from 'components/personal/absicherung2/blocks/RisikenFormBlock'
import { VertragsProdukteObserver } from 'components/personal/absicherung2/blocks/VertragsProdukteObserver'
import { IconTitle } from 'components/personal/absicherung2/IconTitle'
import { getGeschaeftIcon } from 'components/personal/absicherung2/selects/GeschaeftSelect'
import { VertragFormModel } from 'form/vertraege/VertragFormModel'

type VertragsProdukteFormBlockProps = {
  formProps: FormikProps<VertragFormModel>
}

export const VertragsProdukteFormBlock = ({
  formProps,
}: VertragsProdukteFormBlockProps): ReactElement => {
  const formModel = formProps.values
  return (
    <Stack rowGap={2}>
      <VertragsProdukteObserver />
      {formModel.vertragsProdukte.map((vertragsProduktFormModel, vertragsProduktIndex) => {
        if (!vertragsProduktFormModel) {
          return null
        }
        return (
          <Grid container key={vertragsProduktFormModel.produkt.produktId}>
            <Grid
              item
              xs={12}
              key={vertragsProduktFormModel.produkt.produktId}
              style={{
                background: '#f0f0f0',
              }}
              p={1}
            >
              <IconTitle
                title={vertragsProduktFormModel.produkt.produktName}
                icon={getGeschaeftIcon(formModel.vertragGeschaeft)}
                variant='h6'
              />
              <RisikenFormBlock
                vertragsProduktIndex={vertragsProduktIndex}
                vertragsProduktFormModel={vertragsProduktFormModel}
                vertragFormModel={formModel}
              />
              <DetailsFormBlock
                name={`vertragsProdukte.${vertragsProduktIndex}.details`}
                produktId={vertragsProduktFormModel.produkt.produktId}
                details={vertragsProduktFormModel.details}
                risiken={vertragsProduktFormModel.risiken}
              />
            </Grid>
          </Grid>
        )
      })}
    </Stack>
  )
}
