import {
  nullableDayjsOrDefault,
  nullableValueOrDefault,
  valueOrDefault,
} from 'form/formModelConverterUtil'
import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { ZielFormModel } from 'form/KundeFormModel'
import { createDefaultZielFormModel } from 'form/kundeFormModelCreator'
import { Risikoklasse, Ziel, ZielArt } from 'interfaces/mynorm-api-model-interfaces'

const defaultZielFormModel = createDefaultZielFormModel()

export const convertToZielFormModel = (ziel: Ziel | undefined): ZielFormModel => {
  if (!ziel) {
    return defaultZielFormModel
  }
  return {
    meta: convertToMeta(ziel.meta),
    bezeichnung: nullableValueOrDefault(ziel.bezeichnung, defaultZielFormModel.bezeichnung),
    zielArt: nullableValueOrDefault<ZielArt | '' | undefined>(
      ziel.zielArt,
      defaultZielFormModel.zielArt,
    ),
    zielzeitpunkt: nullableDayjsOrDefault(ziel.zielzeitpunkt, defaultZielFormModel.zielzeitpunkt),
    zielkapital: nullableValueOrDefault(
      ziel.zielkapital?.toString(),
      defaultZielFormModel.zielkapital,
    ),
    risikoklasse: nullableValueOrDefault<Risikoklasse | '' | undefined>(
      ziel.risikoklasse,
      defaultZielFormModel.risikoklasse,
    ),
    bemerkungen: nullableValueOrDefault(ziel.bemerkungen, defaultZielFormModel.bemerkungen),
    bemerkungenIntern: nullableValueOrDefault(
      ziel.bemerkungenIntern,
      defaultZielFormModel.bemerkungenIntern,
    ),
    zugeordnetePersonen: valueOrDefault(
      ziel.zugeordnetePersonen,
      defaultZielFormModel.zugeordnetePersonen,
    ),
    isNew: ziel.createFlag ?? false,
    isDeleted: ziel.deleteFlag ?? false,
  }
}
