import React, { ReactElement } from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { MultiSelectCheckboxInput } from 'components/controls/inputs/MultiSelectCheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectInput } from 'components/controls/inputs/SelectInput'
import { TextInput } from 'components/controls/inputs/TextInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { PersonalFinanzenFormBlock } from 'components/personal/finanzen/PersonalFinanzenFormBlock'
import styles from 'components/personal/haushalt/PersonalHaushaltPersonContainer.module.scss'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { PersonFormModel } from 'form/KundeFormModel'
import { personInitialTouched } from 'form/kundeFormTouched'
import { personValidationSchema } from 'form/kundeFormValidationSchema'
import {
  besoldungsGruppeOptions,
  besondereTaetigkeitenStatusOptions,
  bundeslandOptions,
  entgeltforzahlungArbeitgeberOptions,
  taetigkeitsStatusOptions,
} from 'form/kundeOptions'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { Taetigkeitsstatus } from 'interfaces/mynorm-api-model-interfaces'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalFinanzenPersonContainerProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
}

export const PersonalFinanzenPersonContainer = ({
  personIndex,
  selectiveFormProps,
}: PersonalFinanzenPersonContainerProps): ReactElement | null => {
  const { updatePerson, editableKunde } = useEditableKunde()

  const formPath = `personen.${personIndex}`
  const { initialFormValues, selectiveFieldProps } = useSelectiveKundeForm<PersonFormModel>(
    formPath,
    selectiveFormProps,
  )

  const mainPerson = personIndex == 0
  const displayPartnerAsDeleted = !editableKunde.hasPartner && personIndex === 1
  const displayPartnerAsNew = selectiveFormProps && initialFormValues?.isNew && personIndex === 1

  if ((!selectiveFormProps && displayPartnerAsDeleted) || !initialFormValues) {
    return null
  }

  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(personFormModel) => {
        updatePerson(personIndex, personFormModel)
      }}
      validationSchema={personValidationSchema}
      initialTouched={personInitialTouched}
      validateOnMount
    >
      {(formProps) => {
        const taetigkeitstatus = formProps.values.finanzen?.beruf?.taetigkeitstatus
        const isBeamter = taetigkeitstatus ? beamtenTaetigkeit.includes(taetigkeitstatus) : false
        const isSelbst = taetigkeitstatus
          ? selbststaendigeTaetigkeit.includes(taetigkeitstatus)
          : false

        const entgeltfortzahlungArbeitgeber =
          formProps.values.finanzen?.beruf?.freiwilligeEntgeltfortzahlungArbeitgeber
        const isZuschussNetto = entgeltfortzahlungArbeitgeber === 'AUFSTOCKUNG_AUF_NETTO'
        const isZuschussAbsolute = entgeltfortzahlungArbeitgeber === 'ABSOLUTER_BETRAG'
        const isAbweichendesBundesland =
          formProps.values.finanzen?.beruf?.arbeitstaetteBundeslandAbweichung

        return (
          <Stack
            spacing={1}
            className={clsx(
              displayPartnerAsDeleted && styles.displayAsDeleted,
              displayPartnerAsNew && styles.displayAsNew,
            )}
          >
            <h3>{mainPerson ? 'Person' : 'Partner'}</h3>
            <PersonalFinanzenFormBlock title={'Beruf'}>
              <Stack spacing={1}>
                <SelectInput
                  options={taetigkeitsStatusOptions}
                  name={`finanzen.beruf.taetigkeitstatus`}
                  label={'Tätigkeitsstatus'}
                  selectiveFieldProps={selectiveFieldProps}
                />
                <TextInput
                  name={'finanzen.beruf.arbeitgeber'}
                  label={'Arbeitgeber'}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Stack>
              {isBeamter && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectInput
                      options={besoldungsGruppeOptions}
                      name={'finanzen.beruf.besoldungsgruppe'}
                      label={'Besoldungsgruppe'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberInput
                      name={'finanzen.beruf.versorgungsrechtlicherDienstzeitbeginnJahr'}
                      label={'Versorg.rechtl. Dienstbeginn (Jahr)'}
                      selectiveFieldProps={selectiveFieldProps}
                      noDecimals
                      noGrouping
                    />
                  </Grid>
                </Grid>
              )}
              {isSelbst && (
                <Stack>
                  <NumberInput
                    name={'finanzen.beruf.keineAbsicherungNoetigWochen'}
                    label={'Keine Absicherung im Krankheitsfall bis Woche'}
                    selectiveFieldProps={selectiveFieldProps}
                    noDecimals
                    noGrouping
                  />
                </Stack>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name={'finanzen.beruf.ausgeuebterBeruf'}
                    label={'Ausgeübter Beruf'}
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name={'finanzen.beruf.ausbildung'}
                    label={'Ausbildung'}
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name={'finanzen.beruf.beruflicheQualifikation'}
                    label={'Berufliche Qualifikation'}
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name={'finanzen.beruf.fruehereBeruflicheTaetigkeiten'}
                    label={'Frühere relevante berufliche Tätigkeiten'}
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CheckboxInput
                    name={'finanzen.beruf.arbeitstaetteBundeslandAbweichung'}
                    label={'Weicht das Bundesland der Arbeitsstätte ab?'}
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {isAbweichendesBundesland && (
                    <SelectInput
                      name={`finanzen.beruf.arbeitsstaetteBundesland`}
                      label={'Bundesland'}
                      selectiveFieldProps={selectiveFieldProps}
                      options={bundeslandOptions}
                    />
                  )}
                </Grid>
                {!isBeamter && !isSelbst && (
                  <>
                    <Grid item xs={12}>
                      <SelectInput
                        options={entgeltforzahlungArbeitgeberOptions}
                        name={'finanzen.beruf.freiwilligeEntgeltfortzahlungArbeitgeber'}
                        label={'Zuschuss'}
                        selectiveFieldProps={selectiveFieldProps}
                      />
                    </Grid>
                    {(isZuschussNetto || isZuschussAbsolute) && (
                      <Grid item xs={12} md={6}>
                        <NumberInput
                          name={
                            'finanzen.beruf.dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen'
                          }
                          label={'Dauer der freiwilligen Entgeltfortzahlung (Woche)'}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                    )}
                    {isZuschussAbsolute && (
                      <Grid item xs={12} md={6}>
                        <NumberInput
                          name={'finanzen.beruf.absoluterBetragEntgeltfortzahlung'}
                          label={'Höhe des absoluten Betrags bei Entgeltfortzahlung'}
                          inputUnit={'€'}
                          selectiveFieldProps={selectiveFieldProps}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              <h5>Besondere Tätigkeiten</h5>
              <Stack>
                <MultiSelectCheckboxInput
                  name={'finanzen.beruf.besondereTaetigkeiten'}
                  options={besondereTaetigkeitenStatusOptions}
                  selectiveFieldProps={selectiveFieldProps}
                />
              </Stack>
            </PersonalFinanzenFormBlock>
            <PersonalFinanzenFormBlock title={'Einkommen'}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='overline'>Gehalt / Rente</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.nettoNachSteuer`}
                    label={'Nettogehalt / Privatentnahmen nach Steuer'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassen Sie hier bitte bei gesetzl. krankenversicherten Angestellten das durchschn. Nettogehalt nach Lohnsteuer und Sozialabgaben. Bei privat krankenversicherten Angestellten muss der PKV-Zuschuss berücksichtigt werden. Bei Selbständigen sind die durchschn. monatl. Privatentnahmen nach Steuer der nächsten 12 Monate zu erfassen. Sofern die KV-Beiträge bzw. GVS-Beiträge selbst überwiesen werden, wird das DIN relevante Nettoerwerbseinkommen nach Krankenversicherung und Gesetzl. Versorgungssystemen berechnet und in der Einnahmen/Ausgaben-Rechnung angezeigt.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.nettorenteneinkommen`}
                    label={'Nettoeinkommen aus Rente'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassen Sie hier bitte aktuelle Zahlungen aus der privaten und gesetzlichen Altersvorsorge, Witwen-/Waisenrenten und Berufs-/Diensunfähigkeits-/Erwerbminderungenrenten nach Steuern und Sozialabgaben.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='overline'>Vermietung & Verpachtung</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.liquiditaetsErgebnisVermietung`}
                    label={'Nettoeinkommen Vermietung & Verpachtung'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassen Sie hier bitte das durchschnittliche Liquiditätsergebnis aus allen vermieteten Immobilien, d.h. Mieteinnahmen abzgl. Bewirtschaftungskosten und Zins/Tilgung nach Steuer.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.liquiditaetsErgebnisVermietungRuhestand`}
                    label={'Nettoeinkommen Vermietung & Verpachtung zum Ruhestand'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassen Sie hier bitte das durchschnittliche Liquiditätsergebnis aus allen vermieteten Immobilien zum Ruhestand, d. h. Mieteinnahmen abgzl. Bewirtschaftungskosten und ggf. Zins/Tilgung (falls Immobilie noch nicht entschuldet ist) nach Steuer.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='overline'>Kapital</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.nettovermoegenseinkommenKapitalanlagen`}
                    label={'Nettoeinkommen aus Kapitalanlagen'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassung Sie hier bitte die durchschnittlichen Erträge/Entnahmen aus Barvermögen und Kapitalanlagen abzüglich eventueller Einkommens- und Umsatzsteuer, die im Durchschnitt eines Jahres absehbar dauerhalft erzielt werden können. Die Einnahmen müssen über dem aktuellen Sparer-Pauschbetrag pro Person liegen.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.sonstigesNettovermoegenseinkommen`}
                    label={'Sonstiges Nettovermögenseinkommen'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassung Sie hier bitte Einkommen aus passiven unternehmerischen Beteiligungen, gewährten Darlehen an Privatpersonen sowie weiteren Vermögenswerten.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='overline'>Betrieblich</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.nettovermoegenseinkommenBetrieb`}
                    label={'Nettoeinkommen aus Betrieb'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassung Sie hier bitte Gewinnausschüttungen aus Unternehmensbeteiligungen bspw. als Gesellschafter-Geschäftsführer einer GmbH.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.einkommenWegfallKrankheitInProzent`}
                    label={'Prozentualer Wegfall bei Krankheit'}
                    inputUnit={'%'}
                    tooltip={
                      'Erfassung Sie hier bitte den prozentualen Anteil des Einkommens, der z.B. bei einem krankheitsbedingten Ausfall wegfallen kann.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='overline'>Sonstige</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name={`finanzen.einkommen.sonstigesNettoeinkommen`}
                    label={'Sonstiges Nettoeinkommen'}
                    inputUnit={'€'}
                    tooltip={
                      'Erfassen Sie hier bitte Unterhaltungszahlungen, Kindergeld, Arbeitslosengeld II, Wohngeld, Sozialhilfe, BAföG sowie regelmäßige private Zuwendungen jeweils nach Steuer.'
                    }
                    selectiveFieldProps={selectiveFieldProps}
                  />
                </Grid>
              </Grid>
            </PersonalFinanzenFormBlock>
            <Grid container>
              <Grid item md={6} xs={12}>
                <PersonalFinanzenFormBlock title={'Ausgaben'}>
                  <Stack spacing={1}>
                    <NumberInput
                      name={`finanzen.ausgaben.konsum`}
                      label={'Konsum'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                    <NumberInput
                      name={`finanzen.ausgaben.wohnen`}
                      label={'Wohnen'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                    <NumberInput
                      name={`finanzen.ausgaben.sonstige`}
                      label={'Sonstige'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Stack>
                </PersonalFinanzenFormBlock>
              </Grid>
              <Grid item md={6} xs={12}>
                <PersonalFinanzenFormBlock title={'Dispokredite'}>
                  <Stack spacing={1}>
                    <NumberInput
                      name={`finanzen.zugesagteDispokredite`}
                      label={'Zugesagte Dispokredite'}
                      selectiveFieldProps={selectiveFieldProps}
                      noDecimals
                    />
                    <NumberInput
                      name={`finanzen.genommeneDispokrediteDurchschnittlich`}
                      label={'In Anspruch genommener Dispokredit (Ø mtl.)'}
                      inputUnit={'€'}
                      selectiveFieldProps={selectiveFieldProps}
                    />
                  </Stack>
                </PersonalFinanzenFormBlock>
              </Grid>
            </Grid>
          </Stack>
        )
      }}
    </EditableKundeFormik>
  )
}

const beamtenTaetigkeit: Taetigkeitsstatus[] = [
  'BEAMTER',
  'BEAMTER_ANWAERTER',
  'BEAMTER_PROBE',
  'SOLDAT',
  'OEFFENTLICHER_DIENST',
]

const selbststaendigeTaetigkeit: Taetigkeitsstatus[] = ['SELBSTAENDIG', 'FREIBERUFLER']
