import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import chart from 'assets/images/guidance/career/investition/investitions_chart.png'
import styles from 'components/guidance/career/GuidanceCareer11Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer11Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={10}
      previousPagePath={Paths.guidanceCareer10}
      nextPagePath={Paths.guidanceCareer12}
      guidanceColor='career'
    >
      <GuidanceSubPageTemplate
        title='"Die beste Investition ist die in sich selbst - sie bringt die höchsten Zinsen."'
        subTitle='Benjamin Franklin'
      >
        <Box className={styles.imageContainer}>
          <img src={chart} alt='Selbstinvestment' />
        </Box>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
