import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { KundeFormModel, ZielFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

export type ZieleMutator = {
  updateZiel: (personIndex: number, zielIndex: number, zielFormModel: ZielFormModel) => void
  removeZiel: (personIndex: number, zielIndex: number) => void
  addZiel: (personIndex: number, zielFormModel: ZielFormModel) => void
}

export const zieleMutator = (set: Mutator): ZieleMutator => {
  return {
    updateZiel: (personIndex: number, zielIndex: number, zielFormModel: ZielFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.personen[personIndex].ziele[zielIndex] = zielFormModel
          }),
        }
      })
    },
    removeZiel: (personIndex: number, zielIndex: number) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            const zielToRemove = draft.personen[personIndex].ziele[zielIndex]
            if (zielToRemove.isNew) {
              draft.personen[personIndex].ziele.splice(zielIndex, 1)
            } else {
              draft.personen[personIndex].ziele[zielIndex].isDeleted = true
            }
            draft.personen.forEach((person) => {
              person.vermoegen.geldAnlagen?.forEach((geldAnlage) => {
                if (hasId(zielToRemove, geldAnlage.zielId)) {
                  geldAnlage.geldanlageZielArt = 'KEIN_ZIEL'
                  geldAnlage.zielId = undefined
                }
              })
            })
          }),
        }
      })
    },
    addZiel: (personIndex: number, zielFormModel: ZielFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            zielFormModel.meta.myviId = uuidv4()
            zielFormModel.isNew = true
            draft.personen[personIndex].ziele.push(zielFormModel)
          }),
        }
      })
    },
  }
}
