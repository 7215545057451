import React, { ReactElement, ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

import styles from 'components/guidance/GuidanceSubPageTemplate.module.scss'
import { UnderlineSeparator } from 'components/guidance/UnderlineSeparator'

type GuidanceSubPageTemplateProps = {
  title?: string
  subTitle?: string | ReactNode
  hideTitleContainer?: boolean
  children?: ReactElement
}

export const GuidanceSubPageTemplate = ({
  title,
  subTitle,
  hideTitleContainer,
  children,
}: GuidanceSubPageTemplateProps): ReactElement => {
  return (
    <Stack className={styles.subPage}>
      {!hideTitleContainer && (
        <Stack className={styles.titleContainer}>
          <div className={styles.subTitle}>{subTitle || <span>&nbsp;</span>}</div>
          <div>
            <Typography className={styles.title}>{title}</Typography>
          </div>
          <UnderlineSeparator invisible={!title && !subTitle} />
        </Stack>
      )}
      {children}
    </Stack>
  )
}
