import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import document from 'assets/images/guidance/career/karriereanalyse/karriereanalyse_analyse.png'
import chart from 'assets/images/guidance/career/karriereanalyse/karriereanalyse_chart.png'
import styles from 'components/guidance/career/GuidanceCareer12Page.module.scss'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import { Paths } from 'configurations/paths'

export const GuidanceCareer12Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={2}
      menuSubIndex={11}
      previousPagePath={Paths.guidanceCareer11}
      nextPagePath={Paths.guidanceCareer13}
      guidanceColor='career'
    >
      <GuidanceSubPageTemplate title='Erstelle deine persönliche Karriereanalyse' subTitle={''}>
        <Grid container>
          <Grid item lg={6} xs={12} className={styles.diagrammContainer}>
            <img src={chart} alt='Diagramm' />
          </Grid>
          <Grid item lg={6} xs={12} className={styles.dokumentContainer}>
            <img src={document} alt='Dokument' />
          </Grid>
        </Grid>
      </GuidanceSubPageTemplate>
    </GuidanceSubPage>
  )
}
