import React, { PropsWithChildren, ReactElement } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'

import styles from 'components/cockpit/CockpitSummaryCard.module.scss'

type CockpitSummaryDialogProps = {
  open: boolean
  onClose: () => void
}

export const CockpitSummaryDialog = ({
  open,
  onClose,
  children,
}: PropsWithChildren<CockpitSummaryDialogProps>): ReactElement => {
  return (
    <Dialog open={open} fullWidth maxWidth={'lg'} scroll='paper'>
      <DialogContent>
        <Box className={styles.closeDialogButton}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  )
}
