import {
  AnlegerprofilFormModel,
  BeteiligungFormModel,
  DarlehenFormModel,
  GeldAnlageFormModel,
  GesetzlicheVersorgungFormModel,
  ImmobilieFormModel,
  KindFormModel,
  KundeFormModel,
  MenschFormModel,
  NewKundeFormModel,
  PersonFormModel,
  SachwertFormModel,
  ZielFormModel,
} from 'form/KundeFormModel'
import { Metadata } from 'interfaces/mynorm-api-model-interfaces'

export const createDefaultMetadata = (): Metadata => {
  return {
    finfireId: '',
    finfireLastModified: undefined,
    myviId: '',
    salesforceId: '',
    salesforceLastModified: undefined,
  }
}

export const createDefaultSachwertFormModel = (expanded?: boolean): SachwertFormModel => {
  return {
    meta: createDefaultMetadata(),
    baujahr: '',
    ersatzinvestitionErforderlich: false,
    haftplichtViaExternenVertrag: false,
    kennzeichen: '',
    marke: '',
    modell: '',
    wiederbeschaffungGesichert: false,
    bemerkungen: '',
    bemerkungenIntern: '',
    bezeichnung: '',
    datumKauf: null,
    datumZeitwert: null,
    datumErstzulassung: null,
    kaufpreis: '',
    sachwertTyp: 'KRAFTFAHRZEUG',
    zeitwert: '',
    expanded: expanded,
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultBeteiligungFormModel = (expanded?: boolean): BeteiligungFormModel => {
  return {
    meta: createDefaultMetadata(),
    beteiligungTyp: 'UNTERNEHMEN',
    unternehmensTyp: 'AG',
    gesellschaft: '',
    beteiligungseigentuemer: [],
    beteiligungseigentuemerExternePersonName: '',
    vertragsnummer: '',
    vertragsbeginn: null,
    vertragsende: null,
    bezeichnung: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    zeitwert: '',
    endwert: '',
    datumZeitwert: null,
    laufendeSparrate: {
      sparrate: '',
      zahlweise: '',
      zahlart: '',
    },
    quelleRisikoklassifizierung: '',
    riskanteAnteile: 0,
    expanded: expanded,
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultDarlehenFormModel = (expanded?: boolean): DarlehenFormModel => {
  return {
    meta: createDefaultMetadata(),
    darlehenTyp: 'KONSUMENTEN_KREDIT',
    bezeichnung: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    darlehensnehmer: [],
    darlehensnehmerExternePersonName: '',
    darlehensgeber: '',
    vertragsnummer: '',
    darlehensbeginn: null,
    darlehensende: null,
    darlehensbetrag: '',
    darlehensstand: '',
    datumDarlehensstand: null,
    zinssatz: '',
    zinsfestschreibung: null,
    tilgungsTyp: '',
    darlehensrate: {
      sparrate: '',
      zahlweise: '',
      zahlart: '',
    },
    expanded: expanded,
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultZielFormModel = (expanded?: boolean): ZielFormModel => {
  return {
    meta: createDefaultMetadata(),
    bezeichnung: '',
    zielArt: '',
    zielzeitpunkt: null,
    zielkapital: '',
    risikoklasse: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    zugeordnetePersonen: [],
    expanded: expanded,
    isDeleted: false,
    isNew: false,
  }
}

export const createDefaultGeldAnlageFormModel = (expanded?: boolean): GeldAnlageFormModel => {
  return {
    meta: createDefaultMetadata(),
    anbieter: '',
    anlagenStruktur: {
      aktien: '',
      immobilien: '',
      beteiligungen: '',
      zinsAnlagen: '',
      sonstige: '',
      riskanteAnteile: '',
      quelleRisikoklassifizierung: '',
    },
    laufendeSparrate: {
      sparrate: '',
      zahlweise: '',
      zahlart: '',
    },
    renditeklasse: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    bezeichnung: '',
    expanded: expanded,
    geldAnlageTyp: 'SONSTIGE',
    zielId: '',
    kindId: '',
    immobilieId: '',
    rendite: '',
    vertragsnummer: '',
    vertragsbeginn: null,
    vertragsende: null,
    geldanlageZielArt: 'KEIN_ZIEL',
    anlagenWert: {
      zeitpunkt: null,
      zeitwert: '',
      endwert1: '',
      endwert2: '',
      endwert3: '',
    },
    geldanlagenEigentuemer: [],
    geldanlagenEigentuemerExternePersonName: '',
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultImmobilieFormModel = (expanded?: boolean): ImmobilieFormModel => {
  return {
    meta: createDefaultMetadata(),
    adresse: {
      strasse: '',
      hausnummer: '',
      postleitzahl: '',
      ort: '',
      bundesland: '',
      land: '',
    },
    aktuelleBaumassnahmen: 'KEINE',
    baujahr: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    eigentuemer: [],
    eigentuemerExternePersonName: '',
    entschuldetImRuhestand: false,
    hausUndGrundbesitzerHaftpflichtViaETG: false,
    // id: '',
    immobilieNutzungsArt: 'EIGENNUTZUNG',
    instanhaltungsruecklageViaETG: false,
    kreditlinieInstandhaltung: '',
    neubauSumme: '',
    neuwertHausrat: '',
    objektArt: 'EIGENTUMSWOHNUNG',
    oeltank: false,
    photovoltaik: false,
    quelleRisikoklassifizierung: '',
    riskanteAnteile: '',
    verkehrswert: '',
    wert1914: '',
    wohnflaeche: '',
    wohngebaeudeVersicherungViaETG: false,
    bezeichnung: '',
    expanded: expanded,
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultGesetzlicheVersorgungFormModel = (
  expanded?: boolean,
): GesetzlicheVersorgungFormModel => {
  return {
    meta: createDefaultMetadata(),
    zugeordnetePersonId: '',
    gesetzlicheVersorgungsTyp: 'DEUTSCHE_RENTENVERSICHERUNG',
    altersrenteBeiAnpassung1: '',
    altersrenteBeiAnpassung2: '',
    anpassungssatz1: '',
    anpassungssatz2: '',
    beitrag: '',
    berufsunfaehigkeitsrente: '',
    erwerbsminderungsrente: '',
    gesetzlicheAltersrente: '',
    versorgungseinrichtung: '',
    waisenrente: '',
    witwenrente: '',
    zahlweise: '',
    bemerkungen: '',
    bemerkungenIntern: '',
    expanded: expanded,
    isDeleted: false,
    isNew: false,
    saveError: false,
  }
}

export const createDefaultMenschFormModel = (): MenschFormModel => {
  return {
    meta: createDefaultMetadata(),
    vorname: '',
    nachname: '',
    anrede: 'NONE',
    risiken: [],
    geburtsdatum: null,
  }
}

export const createDefaultPersonFormModel = (): PersonFormModel => {
  return {
    ...createDefaultMenschFormModel(),
    titel: '',
    finanzen: {
      ausgaben: {
        konsum: null,
        wohnen: null,
        sonstige: null,
      },
      einkommen: {
        liquiditaetsErgebnisVermietung: null,
        liquiditaetsErgebnisVermietungRuhestand: null,
        nettoNachSteuer: null,
        nettorenteneinkommen: null,
        nettovermoegenseinkommenBetrieb: null,
        nettovermoegenseinkommenKapitalanlagen: null,
        sonstigesNettoeinkommen: null,
        sonstigesNettovermoegenseinkommen: null,
        einkommenWegfallKrankheitInProzent: null,
      },
      beruf: {
        arbeitgeber: '',
        taetigkeitstatus: '',
        besondereTaetigkeiten: [],
        arbeitstaetteBundeslandAbweichung: false,
        arbeitsstaetteBundesland: '',
        freiwilligeEntgeltfortzahlungArbeitgeber: '',
        versorgungsrechtlicherDienstzeitbeginnJahr: '',
        dauerFreiwilligeEntgeltfortzahlungArbeitgeberWochen: '',
        absoluterBetragEntgeltfortzahlung: '',
        keineAbsicherungNoetigWochen: '',
        besoldungsgruppe: '',
        ausbildung: '',
        ausgeuebterBeruf: '',
        beruflicheQualifikation: '',
        fruehereBeruflicheTaetigkeiten: '',
      },
      genommeneDispokrediteDurchschnittlich: null,
      zugesagteDispokredite: null,
      anzahlKinderPflegeversicherung: null,
    },
    vermoegen: {
      immobilien: [],
      geldAnlagen: [],
      darlehen: [],
      beteiligungen: [],
      sachwerte: [],
    },
    anschrift: {
      ort: '',
      hausnummer: '',
      postleitzahl: '',
      strasse: '',
      bundesland: '',
    },
    kontaktdaten: {
      email: '',
      fax: '',
      internet: '',
      telefon: '',
    },
    ziele: [],
    steuerIdentifikationsnummer: '',
    gesetzlicheVersorgungen: [],
    vertraege: [],
    anlegerprofil: createDefaultAnlegerprofil(),
  }
}

export const createDefaultAnlegerprofil = (): AnlegerprofilFormModel => {
  return {
    finanzanlagen: {
      geldmarktfondsKenntnisse: false,
      geldmarktfondsAnzahl: '',
      geldmarktfondsUmsatz: '',
      rentenfondsAnleihenKenntnisse: false,
      rentenfondsAnleihenAnzahl: '',
      rentenfondsAnleihenUmsatz: '',
      fondsAktienKenntnisse: false,
      fondsAktienAnzahl: '',
      fondsAktienUmsatz: '',
      mischfondsKenntnisse: false,
      mischfondsAnzahl: '',
      mischfondsUmsatz: '',
      immobilienfondsKenntnisse: false,
      immobilienfondsAnzahl: '',
      immobilienfondsUmsatz: '',
      strukturierteWertpapierKenntnisse: false,
      strukturierteWertpapierAnzahl: '',
      strukturierteWertpapierUmsatz: '',
      terminGeschaefteKenntnisse: false,
      terminGeschaefteAnzahl: '',
      terminGeschaefteUmsatz: '',
      geschlosseneInvestmentvermoegenKenntnisse: false,
      geschlosseneInvestmentvermoegenAnzahl: '',
      geschlosseneInvestmentvermoegenUmsatz: '',
      sonstigeKenntnisse: false,
      sonstigeAnzahl: '',
      sonstigeUmsatz: '',
    },
    andereAnlagen: {
      sparTagesTermingeldKenntnisse: false,
      sparTagesTermingeldAnzahl: '',
      sparTagesTermingeldUmsatz: '',
      immobilienKenntnisse: false,
      immobilienAnzahl: '',
      immobilienUmsatz: '',
      sonstigesKenntnisse: false,
      sonstigesAnzahl: '',
      sonstigesUmsatz: '',
    },
    kreditfinanzierteAnlagen: {
      finanzierungsArt: '',
      kreditVolumen: '',
    },
    dienstleistungen: {
      beratungsfreieOrderausfuehrung: false,
      anlageberatung: false,
      vermoegensverwaltung: false,
    },
  }
}

export const createDefaultKindFormModel = (): KindFormModel => {
  return createDefaultMenschFormModel()
}

export const createDefaultKundeFormModel = (): KundeFormModel => {
  return {
    beziehungsStatus: '',
    gemeinsamesVermoegen: false,
    auslandGeplant: false,
    personen: [createDefaultPersonFormModel()],
    kinder: [],
    hasPartner: false,
    isImported: true,
  }
}

export const createDefaultNewKundeFormModel = (): NewKundeFormModel => {
  return {
    ...createDefaultPersonFormModel(),
    kontaktdaten: {
      telefon: '',
      email: '',
      fax: '',
      internet: '',
    },
    steuerIdentifikationsnummer: '',
    beziehungsStatus: null,
  }
}
