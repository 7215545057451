import React, { ReactElement } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useField } from 'formik'

import { Option } from 'components/controls/inputs/SelectInput'
import { SelectiveInputProps } from 'components/controls/selective-inputs/SelectiveModels'
import { SelectiveMultiSelectCheckbox } from 'components/controls/selective-inputs/SelectiveMultiSelectCheckbox'

type MultiSelectCheckboxInputProps<FormType, OptionType> = {
  name: keyof FormType & string
  options: Option<OptionType>[]
  selectiveFieldProps?: SelectiveInputProps
}

export const MultiSelectCheckboxInput = <FormType, OptionType>({
  name,
  options,
  selectiveFieldProps,
}: MultiSelectCheckboxInputProps<FormType, OptionType>): ReactElement => {
  const [fieldInputProps] = useField<Array<OptionType> | null>(name)

  if (selectiveFieldProps) {
    return (
      <SelectiveMultiSelectCheckbox
        name={name}
        options={options}
        selectiveFieldProps={selectiveFieldProps}
      />
    )
  }

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={String(option.value)}
          control={
            <Checkbox
              {...fieldInputProps}
              name={fieldInputProps.name}
              value={option.value}
              checked={fieldInputProps.value?.includes(option.value) || false}
              onChange={fieldInputProps.onChange}
              color='secondary'
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  )
}
