import produce from 'immer'
import { diff } from 'jsondiffpatch'

import { convertKinder } from 'form/haushalt-converter/person-converter/kinderConverter'
import { convertPersonen } from 'form/haushalt-converter/person-converter/personenConverter'
import { KundeFormModel } from 'form/KundeFormModel'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'
import { mapToRequestModelType } from 'utils/converterUtils'
import { RecursivePartial } from 'utils/typeUtils'

export const mergeHaushalt = (
  formModel: KundeFormModel,
  haushalt: HaushaltAggregate,
): HaushaltAggregate => {
  return produce(haushalt as RecursivePartial<HaushaltAggregate>, (draft) => {
    draft.kinder = convertKinder(formModel.kinder, draft).filter((kind) => !kind.deleteFlag)
    draft.personen = convertPersonen(formModel, draft)
    draft.beziehungsstatus = mapToRequestModelType(
      formModel.beziehungsStatus,
      haushalt.beziehungsstatus,
    )
    draft.gemeinsamesVermoegen = formModel.gemeinsamesVermoegen ?? false
    draft.auslandGeplant = formModel.auslandGeplant
    draft.isImported = formModel.isImported
    draft.imported = formModel.isImported
  }) as HaushaltAggregate
}

export const hasChanges = (value1: unknown, value2: unknown): boolean => {
  return !!diff(value1, value2)
}
