import React, { memo, ReactElement } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import styles from 'components/header/Header.module.scss'
import { MobileNavigation } from 'components/header/MobileNavigation'
import { Navigation } from 'components/header/Navigation'
import { Logo } from 'components/logo/Logo'
import { LogoutButton } from 'components/logout-button/LogoutButton'
import { SaveHaushaltButton } from 'components/save-haushalt/SaveHaushaltButton'
import { useIsAuthenticated } from 'hooks/useIsAuthenticated'

const HeaderComponent = (): ReactElement => {
  const isAuthenticated = useIsAuthenticated()
  return (
    <AppBar position='sticky' className={styles.appBar}>
      <Toolbar disableGutters className={styles.toolbar}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {isAuthenticated && <MobileNavigation />}
        </div>
        <div className={styles.center}>
          {isAuthenticated && <Navigation />}
          <div className={styles.logoMobile}>
            <Logo />
          </div>
        </div>
        <div className={styles.right}>
          <SaveHaushaltButton />
          <LogoutButton />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export const Header = memo(HeaderComponent)
