import produce from 'immer'

import { KundeFormModel } from 'form/KundeFormModel'
import { Mutator } from 'state/useEditableKunde'

export type HaushaltMutator = {
  updateHaushalt: (kundeFormModel: KundeFormModel) => void
}

export const haushaltMutator = (set: Mutator): HaushaltMutator => {
  return {
    updateHaushalt: (kundeFormModel) => {
      set(({ editableKunde }) => {
        return {
          editableKunde: produce(editableKunde, (draft: KundeFormModel) => {
            draft.auslandGeplant = kundeFormModel.auslandGeplant
            draft.beziehungsStatus = kundeFormModel.beziehungsStatus
            draft.gemeinsamesVermoegen = kundeFormModel.gemeinsamesVermoegen
          }),
        }
      })
    },
  }
}
