import { filterNotDeleted } from 'components/util/listUtil'
import { ZielFormModel } from 'form/KundeFormModel'
import { useEditableKunde } from 'state/useEditableKunde'
import { hasId } from 'utils/comparisonUtils'

type UseZieleResult = {
  ziele: ZielFormModel[]
  findZiel: (zielId: string | undefined) => ZielFormModel | undefined
}
export const useZiele = (index?: number): UseZieleResult => {
  const { editableKunde } = useEditableKunde()
  const ziele = editableKunde.personen[index ?? 0].ziele ?? []

  const existingZiele = filterNotDeleted(ziele)
  return {
    ziele: existingZiele,
    findZiel: (zielId) => {
      return existingZiele.find((ziel) => hasId(ziel, zielId))
    },
  }
}
