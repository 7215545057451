import React, { ReactElement } from 'react'
import { Box, Stack } from '@mui/material'
import clsx from 'clsx'

import chart0 from 'assets/images/guidance/personnel/einfach-und-zeiteffizient/einfach_und_zeiteffizient_0.png'
import chart1 from 'assets/images/guidance/personnel/einfach-und-zeiteffizient/einfach_und_zeiteffizient_1.png'
import { GuidanceSubPage } from 'components/guidance/GuidanceSubPage'
import { GuidanceSubPageTemplate } from 'components/guidance/GuidanceSubPageTemplate'
import styles from 'components/guidance/personnel/GuidancePersonnel3Page.module.scss'
import { Paths } from 'configurations/paths'

export const GuidancePersonnel3Page = (): ReactElement => {
  return (
    <GuidanceSubPage
      menuIndex={1}
      menuSubIndex={2}
      previousPagePath={Paths.guidancePersonnel2}
      nextPagePath={Paths.guidancePersonnel4}
      guidanceColor='personnel'
      numberOfInterPages={2}
    >
      {(interPageIndex) => (
        <GuidanceSubPageTemplate
          title={'EINFACH UND ZEITEFFIZIENT'}
          subTitle={'WIR PERSONALBERATER'}
        >
          <>
            {interPageIndex === 0 && (
              <Box className={styles.chartContainer}>
                <Box className={styles.chart}>
                  <img src={chart0} alt='Effizienz Grafik' />
                </Box>
              </Box>
            )}
            {interPageIndex === 1 && <MatchingSystem />}
          </>
        </GuidanceSubPageTemplate>
      )}
    </GuidanceSubPage>
  )
}

const MatchingSystem = (): ReactElement => {
  return (
    <Stack className={styles.page}>
      <h3 className={styles.mainText}>
        MATCHING
        <br />
        SYSTEM
      </h3>
      <Stack direction='row' className={styles.row}>
        <Box className={styles.matchingBox}>
          <h3 className={clsx(styles.lightText, styles.lightTextLeft)}>
            Zeiteffizienter
            <br />
            Bewerbungsprozess
          </h3>
        </Box>
        <Box className={styles.matchingImageContainer}>
          <img src={chart1} alt='Matching Grafik' />
        </Box>
        <Box className={styles.matchingBox}>
          <h3 className={styles.lightText}>
            Persönlicher
            <br />
            Ansprechpartner
          </h3>
        </Box>
      </Stack>
    </Stack>
  )
}
