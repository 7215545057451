import produce from 'immer'

import { convertToPersonFormModel } from 'form/kunde-form-model-converter/person/personFormModelConverter'
import { KundeFormModel } from 'form/KundeFormModel'
import { HaushaltAggregate } from 'interfaces/mynorm-api-model-interfaces'

export const mergePartnerIntoHaushalt = (
  baseHaushalt: HaushaltAggregate,
  haushaltWithNewPartner: HaushaltAggregate,
): HaushaltAggregate => {
  return produce(baseHaushalt, (draft: HaushaltAggregate) => {
    if (haushaltWithNewPartner.personen.length === 1) {
      draft.personen = [draft.personen[0]]
      return
    }

    draft.personen[1] = haushaltWithNewPartner.personen[1]
    draft.beziehungsstatus = haushaltWithNewPartner.beziehungsstatus
  })
}

export const mergePartnerIntoKundeFormModel = (
  baseKundeFormModel: KundeFormModel,
  haushaltWithNewPartner: HaushaltAggregate,
): KundeFormModel => {
  return produce(baseKundeFormModel, (draft: KundeFormModel) => {
    if (haushaltWithNewPartner.personen.length === 1) {
      draft.personen = [draft.personen[0]]
      draft.auslandGeplant = false
      draft.gemeinsamesVermoegen = false
      draft.hasPartner = false
      return
    }

    draft.personen[1] = convertToPersonFormModel(haushaltWithNewPartner.personen[1])
    draft.hasPartner = true
    draft.beziehungsStatus = haushaltWithNewPartner.beziehungsstatus || ''
  })
}
