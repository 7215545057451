import { UseQueryResult } from '@tanstack/react-query'

import { useFetchDinScore } from 'api/useFetchDinScore'
import { useHaushaltState } from 'hooks/useHaushaltState'
import { GesamtErgebnisse } from 'interfaces/finoso-models-interfaces'

export const useGetDinScore = (enabled?: boolean): UseQueryResult<GesamtErgebnisse> => {
  const latestHaushalt = useHaushaltState(({ latestHaushalt }) => latestHaushalt)
  return useFetchDinScore(latestHaushalt, enabled)
}
