import dayjs from 'dayjs'
import { uniq } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { convertToMeta } from 'form/kunde-form-model-converter/common/metadataFormModelConverter'
import { toDetailsFormModel } from 'form/vertraege/details/DetailsFormModelConverter'
import {
  PraemieFormModel,
  RisikoFormModel,
  VertragFormModel,
  VertragsProduktFormModel,
} from 'form/vertraege/VertragFormModel'
import {
  Praemie,
  Risiko,
  RisikoPerson,
  Vertrag,
  VertragsProdukt,
} from 'interfaces/mynorm-api-model-interfaces'
import { EXTERNE_PERSON_OPTION_VALUE } from 'utils/nameUtils'

export const convertToVertragFormModel = (vertrag: Vertrag): VertragFormModel => {
  const beginn = new Date()
  const ende = dayjs(beginn).add(1, 'year').toDate()
  return {
    meta: convertToMeta(vertrag.meta),
    vertragNummer: vertrag.vertragNummer ?? '',
    vertragGesellschaft: {
      gesellschaftId: vertrag.vertragGesellschaft.gesellschaftId,
      name: vertrag.vertragGesellschaft.name,
    },
    beginn: dayjs(vertrag.vertragDisplaySummary?.beginn ?? beginn),
    ende: dayjs(vertrag.vertragDisplaySummary?.ende ?? ende),
    vertragGeschaeft: vertrag.vertragGeschaeft,
    produkte: uniq(vertrag.vertragsProdukte.flatMap((v) => v.produkt.produktId)),
    praemienArt: vertrag.praemienArt,
    gesamtPraemien: toPraemien(vertrag.gesamtPraemien),
    vertragsProdukte: toVertragsProdukteFormModels(vertrag.vertragsProdukte),
    fremdvertrag: vertrag.fremdvertrag,
    bemerkungen: vertrag.bemerkungen ?? '',
    bemerkungenIntern: vertrag.bemerkungenIntern ?? '',
  }
}

const toVertragsProdukteFormModels = (
  vertragsProdukte: VertragsProdukt[],
): VertragsProduktFormModel[] => {
  return (vertragsProdukte ?? []).map(toVertragsProduktFormModel)
}

const toVertragsProduktFormModel = (vertragsProdukt: VertragsProdukt): VertragsProduktFormModel => {
  const risikenFormModels = toRisikoFormModels(vertragsProdukt.risiken)
  return {
    produkt: vertragsProdukt.produkt,
    risiken: risikenFormModels,
    details: toDetailsFormModel(vertragsProdukt.details, risikenFormModels),
  }
}

const toRisikoFormModels = (risiken: Risiko[] | undefined): RisikoFormModel[] => {
  return (risiken ?? []).map(toRisikoFormModel)
}
const toRisikoFormModel = (risiko: Risiko): RisikoFormModel => {
  return {
    risikoId: risiko.risikoId,
    risikoTyp: risiko.risikoTyp,
    displayName: risiko.displayName,
    finfireDisplayName: risiko.finfireDisplayName,
    risikoImmobilie: risiko.risikoImmobilie,
    risikoPerson: toRisikoPerson(risiko.risikoPerson),
    risikoFahrzeug: risiko.risikoFahrzeug,
    risikoSonstiges: risiko.risikoSonstiges,
    risikoTier: risiko.risikoTier,
    risikoReise: risiko.risikoReise,
    risikoObjekt: risiko.risikoObjekt,
    einzelPraemien: toPraemien(risiko.einzelPraemien),
  }
}

const toRisikoPerson = (risikoPerson: RisikoPerson | undefined): RisikoPerson | undefined => {
  if (risikoPerson === undefined || risikoPerson === null) {
    return undefined
  }
  return {
    ...risikoPerson,
    finfireId: risikoPerson.externePerson ? EXTERNE_PERSON_OPTION_VALUE : risikoPerson.finfireId,
  }
}

const toPraemien = (praemien: Praemie[] | undefined): PraemieFormModel[] => {
  return (praemien ?? []).map((praemie) => ({
    summe: praemie.summe,
    zahlweise: praemie.zahlweise,
    titel: praemie.titel,
    tarifArt: praemie.tarifArt,
    praemieTyp: praemie.praemieTyp,
    key: uuidv4(),
  }))
}
