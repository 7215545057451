import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react'

export type GuidanceState = {
  open: boolean
  setOpen: (open: boolean) => void
  toggleOpen: () => void
}

export const GuidanceStateContext = createContext<GuidanceState>({} as never)

export const GuidanceStateContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [open, setOpen] = useState(true)

  const toggleOpen = (): void => {
    setOpen((currentOpen) => !currentOpen)
  }

  return (
    <GuidanceStateContext.Provider value={{ open, setOpen, toggleOpen }}>
      {children}
    </GuidanceStateContext.Provider>
  )
}

export const useGuidanceState = (): GuidanceState => {
  const guidanceStateContext = useContext(GuidanceStateContext)
  if (guidanceStateContext === undefined) {
    throw new Error('useGuidanceState must be used within a GuidanceStateContextProvider')
  }
  return guidanceStateContext
}
