import { ReactElement } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import { FormCellLabel, FormCheckboxCell, FormTextCell } from 'components/controls/cells/FormCell'
import { CheckboxInput } from 'components/controls/inputs/CheckboxInput'
import { NumberInput } from 'components/controls/inputs/NumberInput'
import { SelectiveFormProps } from 'components/controls/selective-inputs/SelectiveModels'
import { EditableKundeFormik } from 'form/EditableKundeFormik'
import { AnlegerprofilFinanzanlagenFormModel } from 'form/KundeFormModel'
import { anlegerprofilFinanzanlagenInitialTouched } from 'form/kundeFormTouched'
import { anlegerprofilFinanzanlagenValidationSchema } from 'form/kundeFormValidationSchema'
import { useSelectiveKundeForm } from 'hooks/useSelectiveComparison'
import { useEditableKunde } from 'state/useEditableKunde'

type PersonalAnlegerProfilFinanzAnlagenFormProps = {
  personIndex: number
  selectiveFormProps?: SelectiveFormProps
  isPartnerAvailable?: boolean
}

export const PersonalAnlegerProfilFinanzAnlagenForm = ({
  personIndex,
  selectiveFormProps,
  isPartnerAvailable = false,
}: PersonalAnlegerProfilFinanzAnlagenFormProps): ReactElement => {
  const { anlegerprofilMutator } = useEditableKunde()
  const { initialFormValues, selectiveFieldProps } =
    useSelectiveKundeForm<AnlegerprofilFinanzanlagenFormModel>(
      `personen.${personIndex}.anlegerprofil.finanzanlagen`,
      selectiveFormProps,
    )
  return (
    <EditableKundeFormik
      initialValues={initialFormValues}
      updateState={(finanzanlagen) => {
        anlegerprofilMutator.updateFinanzanlagen(personIndex, finanzanlagen)
      }}
      validationSchema={anlegerprofilFinanzanlagenValidationSchema}
      initialTouched={anlegerprofilFinanzanlagenInitialTouched}
      validateOnMount
    >
      {() => (
        <Table>
          <TableHead>
            <TableRow>
              <FormCellLabel $highlighted>Kenntnisse</FormCellLabel>
              <FormCellLabel $highlighted>Anzahl p.a.</FormCellLabel>
              <FormCellLabel $border={isPartnerAvailable} $highlighted>
                Umsatz p.a.
              </FormCellLabel>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geldmarktfondsKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geldmarktfondsAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geldmarktfondsUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'rentenfondsAnleihenKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'rentenfondsAnleihenAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'rentenfondsAnleihenUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'fondsAktienKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'fondsAktienAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'fondsAktienUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'mischfondsKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'mischfondsAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'mischfondsUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'immobilienfondsKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'immobilienfondsAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'immobilienfondsUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'strukturierteWertpapierKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'strukturierteWertpapierAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'strukturierteWertpapierUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'terminGeschaefteKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'terminGeschaefteAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'terminGeschaefteUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geschlosseneInvestmentvermoegenKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geschlosseneInvestmentvermoegenAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'geschlosseneInvestmentvermoegenUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
            <TableRow>
              <FormCheckboxCell>
                <CheckboxInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'sonstigeKenntnisse'}
                  label={'Ja'}
                  selectiveFieldProps={selectiveFieldProps}
                  noWrapper
                />
              </FormCheckboxCell>
              <FormTextCell>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'sonstigeAnzahl'}
                  label={''}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
              <FormTextCell $border={isPartnerAvailable}>
                <NumberInput<AnlegerprofilFinanzanlagenFormModel>
                  name={'sonstigeUmsatz'}
                  label={''}
                  inputUnit={'€'}
                  variant={'outlined'}
                  size={'small'}
                  noHelperText
                  selectiveFieldProps={selectiveFieldProps}
                />
              </FormTextCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </EditableKundeFormik>
  )
}
