import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface CustomPalette {
    white: PaletteColorOptions
    mitnorm: PaletteColorOptions
    career: PaletteColorOptions
    personnel: PaletteColorOptions
    company: PaletteColorOptions
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    mitnorm: true
    company: true
    career: true
    personnel: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string): PaletteColor =>
  augmentColor({ color: { main: mainColor } })

export const appTheme = createTheme({
  palette: {
    primary: createColor('#7c6a5a'),
    secondary: createColor('#c8b89d'),
    white: createColor('#ffffff'),
    mitnorm: createColor('#7d6b5a'),
    career: createColor('#ba3322'),
    company: createColor('#fdd8b8'),
    personnel: createColor('#4ca873'),
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'proxima-nova',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
})
